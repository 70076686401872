import { SHOW_MODAL, HIDE_MODAL} from '../actions/modal.actions';

const initialState = {
    showModal: false,
    modalType: '',
    modalProps: {},
}


export const modalReducer = (state = initialState, action)  => {
    switch(action.type){
        case SHOW_MODAL: 
            return {
                ...state,
                showModal: true,
                modalType: action.modalType,
                modalProps: action.modalProps
            } 
            
        case HIDE_MODAL: 
            return {
                ...state,
                showModal: false,
                modalType: '',
                modalProps: {}
            }
        default:
            return state;
    }
}