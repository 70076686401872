export const getPatientsData = state => state.patients || {};
export const getProData = state => state.pro || {};
export const getNotesData = state => state.notes || {};
export const getTasksData = state => state.tasks || {};
export const getCalendarData = state => state.calendar || {};
export const getFormsData = state => state.forms || {};
export const getChatData = state => state.chat || {};
export const getModalData = (state) => state.modals || {};
export const getPanelData = (state) => state.panels || {};
export const getAuthData = state => state.auth || {};
export const getTaskNotificationData = state => state.taskNotifications || {};
export const getPatientTaskFilters = (state, patientId) => state.patientTaskFilters[patientId] || {};
export const getConfirm = (state) => state.confirm;
export const getUserSettings = (state) => state.userSettings;

export const createLoadingSelector = (actions) => (state) => actions.some((action) => state.loading[action]);
export const getFileData = state => state.file || {};

export const getErrorMessage = actions => state => {
    const errors = actions.map(action => state.errors[action])
    return errors && errors[0] ? errors[0] : ''
};
