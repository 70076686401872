import React, {Component} from 'react';
import {connect} from 'react-redux';

class PatientDataNccn extends Component {

    render () {
        return  (
            <>
                TODO: Patient Data NCCN page
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataNccn);