import React from "react";

const DefaultColumnFilter = ({column: {filterValue, preFilteredRows, setFilter, Header}}) => {
    // const count = preFilteredRows.length

    return (
        <input className='form-control'
               value={filterValue || ''}
               onChange={e => {
                   setFilter(e.target.value || undefined)
               }}
               placeholder={`Search by ${Header}...`}
        />
    )
}

export default DefaultColumnFilter;