const NEGATIVE_RESULTS = ["wt", "amplification", "amplification-equivocal", "amplification - equivocal", "neg", "negative"];

export const isPositiveResult = (result) => {
    if (typeof result !== 'string' || result.trim().length === 0) {
        return false;
    }

    result = result.trim().toLowerCase();

    return result === 'positive'
}

export const isNegativeResult = (result) => {
    if (typeof result !== 'string' || result.trim().length === 0) {
        return false;
    }

    result = result.trim().toLowerCase();

    return !!NEGATIVE_RESULTS.find(negativeResult => negativeResult === result);
}