import {
    CLEAN_PATIENT_DATA,
    LOAD_ALL_PATIENTS, LOAD_CARE_PROVIDERS, LOAD_PATIENT_FILES,
    LOAD_PATIENT_PHOTO,
    LOAD_PATIENT_REPORT_COUNT, LOAD_POINTS_OF_CONTACT, LOAD_PATIENT_SERVICE
} from "../actions/patients.actions";
import {LOAD_PATIENT_INFO} from "../actions/patients.actions";
import {LOAD_PATIENT_DIAGNOSIS} from "../actions/patients.actions";
import {LOAD_PATIENT_DIAGNOSIS_DETAILED} from "../actions/patients.actions";
import {LOAD_PATIENT_MEDICAL_ID} from "../actions/patients.actions";
import {LOAD_PATIENT_MEDICAL_ID_DETAILED} from "../actions/patients.actions";
import {LOAD_PATIENT_PRO_DIALS} from "../actions/patients.actions";
import {LOAD_PATIENT_DISEASE_SUMMARY} from "../actions/patients.actions";
import {LOAD_PATIENT_DISEASE_SUMMARY_DETAILED} from "../actions/patients.actions";
import {LOAD_PATIENT_MOLECULAR} from "../actions/patients.actions";
import {LOAD_PATIENT_MOLECULAR_DETAILED} from "../actions/patients.actions";
import {LOAD_PATIENT_INTERVENTION} from "../actions/patients.actions";
import {LOAD_PATIENT_CCM_NOTES} from "../actions/patients.actions";
import {LOAD_PATIENT_PATHOLOGY} from "../actions/patients.actions";
import {LOAD_PATIENT_PATHOLOGY_DETAILED} from "../actions/patients.actions";
import {LOAD_PATIENT_PDL1} from "../actions/patients.actions";
import {LOAD_PATIENT_TREATMENT_TIMELINE} from "../actions/patients.actions";
import {LOAD_PATIENT_DISEASE_TIMELINE} from "../actions/patients.actions";
import {LOAD_PATIENT_CALENDAR} from "../actions/patients.actions";
import {LOAD_PATIENT_REPORT} from "../actions/patients.actions";

let initialState = {
    patients: [],
    patientInfo: [],
    patientFiles: null,
    patientPhoto: undefined,
    patientDiagnosis: [],
    patientDiagnosisDetailed: [],
    patientMedicalId: [],
    patientMedicalIdDetailed: [],
    patientProDials: [],
    patientDiseaseSummary: [],
    patientDiseaseSummaryDetailed: [],
    patientMolecular: [],
    patientMolecularDetailed: [],
    patientIntervention: [],
    patientCcmNotes: [],
    patientPathology: [],
    patientPathologyDetailed: [],
    patientPdl1: [],
    patientTreatmentTimeline: [],
    patientDiseaseTimeline: [],
    patientCalendar: [],
    patientReport: [],
    patientReportCount: null,
    patientPointsOfContact: [],
    patientCareProviders: [],
    patientService: null,
};

export const patientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_PATIENT_DATA.CLEAR:
            return {
                ...initialState,
                patientService: state.patientService,
            };
        case LOAD_PATIENT_FILES.SUCCESS:
            return {
                ...state,
                patientFiles: action.payload,
            };
        case LOAD_PATIENT_FILES.CLEAR:
            return {
                ...state,
                patientFiles: null,
            };
        case LOAD_ALL_PATIENTS.SUCCESS:
            return {
                ...state,
                patients: action.payload,
            };
        case LOAD_ALL_PATIENTS.CLEAR:
            return {
                ...state,
                patients: [],
            };
        case LOAD_PATIENT_INFO.SUCCESS:
            return {
                ...state,
                patientInfo: {
                    ...action.payload,
                    accessTime: new Date(),
                },
            };
        case LOAD_POINTS_OF_CONTACT.SUCCESS:
            return {
                ...state,
                patientPointsOfContact: [
                    ...action.payload,
                ],
            };
        case LOAD_POINTS_OF_CONTACT.CLEAR:
            return {
                ...state,
                patientPointsOfContact: [],
            };
        case LOAD_CARE_PROVIDERS.SUCCESS:
            return {
                ...state,
                patientCareProviders: [
                    ...action.payload,
                ],
            };
        case LOAD_CARE_PROVIDERS.CLEAR:
            return {
                ...state,
                patientCareProviders: [],
            };
        case LOAD_PATIENT_PHOTO.SUCCESS:
            return {
                ...state,
                patientPhoto: action.payload,
            };
        case LOAD_PATIENT_DIAGNOSIS.SUCCESS:
            return {
                ...state,
                patientDiagnosis: action.payload,
            };
        case LOAD_PATIENT_DIAGNOSIS_DETAILED.SUCCESS:
            return {
                ...state,
                patientDiagnosisDetailed: action.payload,
            };
        case LOAD_PATIENT_MEDICAL_ID.SUCCESS:
            return {
                ...state,
                patientMedicalId: action.payload,
            };
        case LOAD_PATIENT_MEDICAL_ID_DETAILED.SUCCESS:
            return {
                ...state,
                patientMedicalIdDetailed: action.payload,
            };
        case LOAD_PATIENT_PRO_DIALS.SUCCESS:
            return {
                ...state,
                patientProDials: action.payload,
            };
        case LOAD_PATIENT_DISEASE_SUMMARY.SUCCESS:
            return {
                ...state,
                patientDiseaseSummary: action.payload,
            };
        case LOAD_PATIENT_DISEASE_SUMMARY_DETAILED.SUCCESS:
            return {
                ...state,
                patientDiseaseSummaryDetailed: action.payload,
            };
        case LOAD_PATIENT_MOLECULAR.SUCCESS:
            return {
                ...state,
                patientMolecular: action.payload,
            };
        case LOAD_PATIENT_MOLECULAR_DETAILED.SUCCESS:
            return {
                ...state,
                patientMolecularDetailed: action.payload,
            };
        case LOAD_PATIENT_INTERVENTION.SUCCESS:
            return {
                ...state,
                patientIntervention: action.payload.actions,
            };
       case LOAD_PATIENT_CCM_NOTES.SUCCESS:
            return {
                ...state,
                patientCcmNotes: action.payload,
            };
        case LOAD_PATIENT_PATHOLOGY.SUCCESS:
            return {
                ...state,
                patientPathology: action.payload,
            };
        case LOAD_PATIENT_PATHOLOGY_DETAILED.SUCCESS:
            return {
                ...state,
                patientPathologyDetailed: action.payload,
            };
        case LOAD_PATIENT_PDL1.SUCCESS:
            return {
                ...state,
                patientPdl1: action.payload,
            };
        case LOAD_PATIENT_TREATMENT_TIMELINE.SUCCESS:
            return {
                ...state,
                patientTreatmentTimeline: action.payload,
            };
        case LOAD_PATIENT_DISEASE_TIMELINE.SUCCESS:
            return {
                ...state,
                patientDiseaseTimeline: action.payload,
            };
        case LOAD_PATIENT_CALENDAR.SUCCESS:
            return {
                ...state,
                patientCalendar: action.payload,
            };
        case LOAD_PATIENT_REPORT.SUCCESS:
            return {
                ...state,
                patientReport: action.payload,
            };
        case LOAD_PATIENT_REPORT_COUNT.SUCCESS:
            return {
                ...state,
                patientReportCount: action.payload,
            };
        case LOAD_PATIENT_REPORT_COUNT.CLEAR:
            return {
                ...state,
                patientReportCount: null,
            };
        case LOAD_PATIENT_SERVICE.SUCCESS:
            return {
                ...state,
                patientService: action.payload,
            };
        case LOAD_PATIENT_SERVICE.CLEAR:
            return {
                ...state,
                patientService: null,
            };
        default:
            return state;
    }
};
