import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadPatientDiagnosisDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import {openDocument} from "../../../utils/document-utils";
import * as _ from "lodash";

import lastFirstSvg from "../../../images/last-first-01.svg";
import firstLastSvg from "../../../images/first-last-02.svg";
import fullReportSvg from "../../../images/full_report.svg";

class DiagnosisModal extends Component {

    state = {
        patientDiagnosis: {},
        sortOrder: 'asc',
    }

    componentDidMount() {
        this.props.loadPatientDiagnosisDetailed(this.props.patientId).then((response) => {
            let res = _.orderBy(response, ['time'], ['desc']);
            this.setState({patientDiagnosis: res})
        });
    }

    compareDiagnosis = (a, b) => {
        if (a.time > b.time)
            return 1;
        else if (a.time < b.time)
            return -1;
        else
            return 0;
    }

    handleViewFullReport = (documentId, pageNumber) => {
        openDocument(this.props.patientId, documentId, pageNumber);
    }

    orderDiagnosis = (response) => {
        let patientDiagnosis = response;

        if (!isEmpty(patientDiagnosis && !isEmpty(patientDiagnosis.currentMedications))) {
            patientDiagnosis.currentMedications = patientDiagnosis.currentMedications.sort(this.compareDiagnosis)
            this.setState({patientDiagnosis: patientDiagnosis})
        }
    }

    closeModal = () => {
        this.props.hideModal();
    }

    sortByColumn = (column) => {
        let data;
        if (this.state.sortOrder === 'desc') {
            data = _.orderBy(this.state.patientDiagnosis, ['time'], ['desc']);
            this.setState({
                sortOrder: 'asc'
            });
        } else {
            data = _.orderBy(this.state.patientDiagnosis, ['time'], ['asc']);
            this.setState({
                sortOrder: 'desc'
            });
        }
        this.setState({
            patientDiagnosis: data
        });
    }

    render() {
        const {patientDiagnosis} = this.state;
        const {loading} = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Diagnosis</h5>
                </div>

                <div className="modal-body modal-body-medical-id">

                    {loading &&
                    <Loader size={10} color={"#ccc"}/>
                    }

                    {!loading && isEmpty(patientDiagnosis) &&
                    <p>No data found.</p>
                    }

                    {!loading && !isEmpty(patientDiagnosis) &&
                    <table className="table table-hover table-subtle table-responsive-sm table-responsive-md">

                        <thead>
                        <tr>
                            <th scope="col" role="columnheader">
                                <div className="header-container sorted" style={{cursor: "pointer"}} onClick={() => this.sortByColumn('time')}>
                                    Date
                                    {this.state.sortOrder === 'desc'
                                        ? <i className={"ni ni-arrow-down ni-xs ms-1"} />
                                        : <i className={"ni ni-arrow-up ni-xs ms-1"} />
                                    }
                                </div>
                            </th>
                            <th scope="col">Diagnosis</th>
                            <th scope="col">Location</th>
                            <th scope="col">Stage</th>
                            <th scope="col">TNM</th>
                            <th scope="col">Grade</th>
                            <th scope="col">Comments</th>
                            <th scope="col">View File</th>
                        </tr>
                        </thead>

                        <tbody>
                        {patientDiagnosis.map(item =>
                            <tr>
                                <td>
                                    {item.time ? moment.parseZone(item.time).format('DD-MMM-YYYY') : "Diagnosis date N/A"}
                                </td>
                                <td>
                                    <span
                                        className="font-weight-bold">{item.diseaseName ? item.diseaseName : "Cancer type N/A"}</span>
                                </td>
                                <td>
                                    {item.diseaseLocations ? item.diseaseLocations : "Sites N/A"}
                                </td>
                                <td>
                                    <span className="text-nowrap">
                                        {item.stage ? item.stage : "Stage N/A"}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-nowrap">
                                        {"T" + (item.t ? item.t : "X")} {"N" + (item.n ? item.n : "X")} {"M" + (item.m ? item.m : "X")}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-nowrap">
                                        {item.grade ? item.grade : "Grade N/A"}
                                    </span>
                                </td>
                                <td>
                                    {item.comments ? item.comments : ""}
                                </td>
                                <td style={{textAlign:"center"}}>
                                    {item.documentReference && item.documentReference.fileId &&
                                    <button
                                        className="btn btn-link"
                                        onClick={() => {
                                            this.handleViewFullReport(item.documentReference.fileId, item.documentReference.pageNumber)
                                        }}
                                    >
                                        <img alt="Full Report" style={{width: 30, height: 30}} src={fullReportSvg}/>
                                    </button>
                                    }
                                </td>
                            </tr>
                        )}
                        </tbody>

                    </table>
                    }

                </div>
            </>
        );
    }
}

const patientDiagnosisDetailedLoader = createLoadingSelector(['LOAD_PATIENT_DIAGNOSIS_DETAILED']);

const mapStateToProps = (state) => {

    const
        patientId = getModalData(state).modalProps,
        loading = patientDiagnosisDetailedLoader(state);

    return {
        patientId,
        loading
    }
}

const mapDispatchToProps = {
    loadPatientDiagnosisDetailed,
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisModal);
