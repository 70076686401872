import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import isEmpty from "../../../utils/helpers";

const EditableCell = (props) => {
    const { value, error, setValueDebounce, column } = props;

    const [editing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const inputRef = useRef();

    const startEditing = useCallback(() => {
        setEditing(true);
        setEditValue(value);
    }, [value]);

    const stopEditing = useCallback(() => {
        setEditing(false);
    }, []);

    const handleOnChange = useCallback((event) => {
        const value = event.target.value;

        setEditValue(value);
        setValueDebounce(value);
    }, [setValueDebounce]);

    useLayoutEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current, editing]);

    if (editing) {
        return (
            <>
                <input ref={inputRef} value={editValue} type='text' className='form-control' onChange={handleOnChange} onBlur={stopEditing} />
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </>
        );
    }

    return (
        <>
            <div className="ni-editable-cell" onClick={startEditing}>
                {!isEmpty(value) ? (
                    <div className="cell-value">{value}</div>
                ) : (
                    <div className="cell-empty-value">Enter {column.Header}...</div>
                )}
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </div>
        </>
    );
}

export default EditableCell;