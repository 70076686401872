import {CLEAN_CHAT_DATA} from "../actions/chat.actions";
import {LOAD_CHAT_CONTACTS} from "../actions/chat.actions";
import {LOAD_CONTACT_MESSAGES} from "../actions/chat.actions";

let initialState = {
    chatContacts: [],
    contactMessages: [],
};

export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_CHAT_DATA.CLEAR:
            return initialState;
        case LOAD_CHAT_CONTACTS.SUCCESS:
            return {
                ...state, chatContacts: action.payload.contacts,
            };
        case LOAD_CHAT_CONTACTS.CLEAR:
            return {
                ...state, chatContacts: [],
            };
        case LOAD_CONTACT_MESSAGES.SUCCESS:
            return {
                ...state, contactMessages: action.payload.messages,
            };
        case LOAD_CONTACT_MESSAGES.CLEAR:
            return {
                ...state, contactMessages: [],
            };
        default:
            return state;
    }
};
