import React, {Component, useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {loadPatientIntervention} from "../../../actions/patients.actions";
import {createLoadingSelector} from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import {showModal} from "../../../actions/modal.actions";
import isEmpty from "../../../utils/helpers";
import moment from "moment";

import systemicSvg from "../../../images/systemic.svg";
import radiationSvg from "../../../images/radiation.svg";
import surgerySvg from "../../../images/surgery.svg";
import {useParams} from "react-router-dom";

const Intervention = (
    {
        loadPatientIntervention,
        showModal,
        loading,
    }) => {

    const [activeFilters, setActiveFilters] = useState(true);
    const [showChemo, setShowChemo] = useState(true);
    const [showRadiation, setShowRadiation] = useState(true);
    const [showSurgery, setShowSurgery] = useState(true);
    const [interventionActions, setInterventionActions] = useState({});

    const { id: patientId } = useParams();

    const orderIntervention = useCallback((actions) => {
        const compareIntervention = (a, b) => {
            if (a.beginDate < b.beginDate || a.time < b.beginDate || a.beginDate < b.time || a.time < b.time)
                return 1;
            else if (a.beginDate > b.beginDate || a.time > b.beginDate || a.beginDate > b.time || a.time > b.time)
                return -1;
            else
                return 0;
        }

        const orderedActions = actions ? actions.sort(compareIntervention) : [];
        setInterventionActions(orderedActions);
    }, []);

    const showInterventionModal = useCallback(() => {
        showModal("INTERVENTION_MODAL", patientId);
    }, [showModal, patientId]);


    useEffect(() => {
        if (!showChemo && !showRadiation && !showSurgery) {
            setActiveFilters(false);
        } else {
            setActiveFilters(true);
        }

        loadPatientIntervention(patientId, showChemo, showRadiation, showSurgery).then((response) => {
            orderIntervention(response.actions)
        });
    }, [patientId, showChemo, showRadiation, showSurgery]);

    return (
        <>
            <div className="card">
                <div className="card-body">

                    <div className="card-caption">
                        <h4 className="card-title">
                            <button onClick={showInterventionModal}>Intervention</button>
                        </h4>
                        <div className="card-toolbar">
                            <button
                                type="button"
                                className={"btn btn-xs btn-round " + (showChemo ? "btn-dusty-orange" : "btn-very-light-pink")}
                                onClick={() => setShowChemo(prevState => !prevState)}
                            >
                                Systemic
                            </button>
                            <button
                                type="button"
                                className={"btn btn-xs btn-round " + (showRadiation ? "btn-dusty-orange" : "btn-very-light-pink")}
                                onClick={() => setShowRadiation(prevState => !prevState)}
                            >
                                Radiation
                            </button>
                            <button
                                type="button"
                                className={"btn btn-xs btn-round " + (showSurgery ? "btn-dusty-orange" : "btn-very-light-pink")}
                                onClick={() => setShowSurgery(prevState => !prevState)}
                            >
                                Surgery
                            </button>
                        </div>
                    </div>

                    {loading === true ? (
                        <Loader size={5} color={"#ccc"}/>
                    ) : (
                        <div className="table-container">
                            <table
                                className="table table-hover table-subtle table-responsive-sm table-responsive-md">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Details</th>
                                    {/*<th scope="col">Comments</th>*/}
                                </tr>
                                </thead>
                                <tbody>

                                {!isEmpty(interventionActions) &&
                                interventionActions.map((item, i) => ((i < 6) &&
                                    <tr key={i}>
                                        <td className="color-red">
                                            <img src={
                                                item.actionName === "treatment"
                                                    ? (
                                                        item.treatmentType === "drug"
                                                        ? systemicSvg
                                                        : radiationSvg
                                                    )
                                                    : surgerySvg
                                            } style={{height: '1.25rem'}} />
                                        </td>
                                        <td className={item.beginDate && !item.endDate ? "font-weight-bold" : ""}>
                                            {item.beginDate ? (<>
                                                {moment.parseZone(item.beginDate).format('DD-MMM-YYYY')}&nbsp;-&nbsp;
                                                {item.endDate ? (moment.parseZone(item.endDate).format('DD-MMM-YYYY')) : 'Ongoing'}
                                            </>) : (moment.parseZone(item.time).format('DD-MMM-YYYY'))}
                                        </td>
                                        <td>{item.typeName || item.type || "N/A"}</td>
                                        <td>
                                        {item.dose ? (<>
                                            {item.dose}
                                            {!isEmpty(item.doseUnit) && '\u00A0' + item.doseUnit}
                                            {!isEmpty(item.frequency) && " × " + item.frequency}
                                            {!isEmpty(item.boost) && ', ' + (item.boost === "no" ? 'no\u00A0boost' : 'boost')}
                                        </>) : "N/A"}
                                        </td>
                                        <td>
                                            {item.treatmentType === "radiation"? (<>
                                                {item.siteTargeted ? item.siteTargeted: 'Site N/A'}
                                                {item.numberCourses ? ' ' + item.numberCourses + ' courses': ' Courses N/A'}
                                            </>):(<>
                                                {item.details || "N/A"}
                                            </>)}
                                            {item.actionName === 'surgery' && (<>
                                                {item.procedureSite ? item.procedureSite : 'Procedure Site N/A'}
                                                , {item.type ? item.type : 'Type N/A'}
                                                , {item.complications ? item.complications : 'Complications N/A'}
                                                {item.comments && ', ' + item.comments}
                                            </>)}
                                        </td>
                                        {/*<td>
                                            {item.sideEffects && item.sideEffects + "; "}
                                            {item.comments ? item.comments : (item.sideEffects ? "" : "N/A")}
                                        </td>*/}
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

const patientInterventionLoader = createLoadingSelector(['LOAD_PATIENT_INTERVENTION']);

const mapStateToProps = (state) => {
    const loading = patientInterventionLoader(state);

    return {
        loading
    };
};

const mapDispatchToProps = {
    loadPatientIntervention,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Intervention);