import React, {Component} from 'react';

class PanelAction extends Component {

    render() {

        const {
            primary,
            title,
            icon,
            handleAction,
            active,
            disabled,
        } = this.props;

        return (
            <button
                type="button"
                title={title}
                className={"panel-toolbar-button btn" + (primary ? " btn-primary" : " btn-link") + (active ? " active" : "")}
                onClick={handleAction}
                disabled={disabled}
            >
                <i className={icon}/>
            </button>
        )
    }
}

export default PanelAction;
