import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {Spinner} from "react-bootstrap";
import PropTypes from "prop-types";

const EmailConfirmationStep = ({email, onResend: resendEmail}) => {
    const [sendingEmail, setSendingEmail] = useState(false);

    const handleResendEmail = useCallback(() => {
        setSendingEmail(true);
        Promise.resolve(resendEmail(email))
            .finally(() => {
                setSendingEmail(false);
            })
    }, [email]);

    return (
        <div className="auth-email-confirmation">
            <div className="auth-email-confirmation__title">Reset Password</div>
            <div className="auth-email-confirmation__description">
                An email to reset your password was sent to:
                <p className="font-weight-500 text-center">{email}</p>
            </div>
            <div className="auth-email-confirmation__resend">
                Didn't receive an email?
                <button
                    className="auth-email-confirmation__resend-btn"
                    onClick={handleResendEmail}
                    disabled={sendingEmail}
                >
                    Resend
                </button>
                {sendingEmail && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                        variant="primary"
                    />
                )}
            </div>
        </div>
    );
}

EmailConfirmationStep.propTypes = {
    email: PropTypes.string.isRequired,
    onResend: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationStep);