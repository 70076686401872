// Helper function to create simple action
import moment from "moment";
import React from "react";

export const action = (type, payload = {}) => {
    return {type, ...payload};
};

export const verboseAction = (type, payload = {}) => {
    return {
        type: type,
        payload: {...payload}
    };
};

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const EMPTY = 'EMPTY';
const CLEAR = 'CLEAR';
const MESSAGE = 'MESSAGE';

const USER_DATA_KEY = 'userData';

export const createRequestTypes = (base) => {
    return [REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY, MESSAGE].reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

export const actionCreator = {
    request: (type) => action(type.REQUEST),
    success: (type, payload) => action(type.SUCCESS, {payload}),
    failure: (type, error) => verboseAction(type.FAILURE, error),
    empty: (type) => action(type.EMPTY),
    clear: (type) => action(type.CLEAR),
    message: (type, payload) => action(type.MESSAGE, {payload}),
};

export const getPatientServiceIdParam = (location) => {
    const queryParams = new URLSearchParams(location.search);

    return queryParams.get("ps");
}

export const generateId = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
};

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);

export default isEmpty;


export const getErrorMessage = (error) => {
    let mockErrorMessage = {};
    if (error && error.message) {
        mockErrorMessage.message = error.message;
    } else {
        mockErrorMessage.message = 'Unknown error please try again later';
    }

    return mockErrorMessage;
};

export const setLocalStorageUserData = (userData) => {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
};

export const getLocalStorageUserData = () => {
    const userDataRaw = localStorage.getItem(USER_DATA_KEY);
    return userDataRaw ? JSON.parse(userDataRaw) : null;
};

export const clearLocalStorage = () => {
    localStorage.removeItem(USER_DATA_KEY);
}

export const getUserId = () => {
    return (getLocalStorageUserData || {}).userId || '';
};

export const formatDate = (date, format = 'MM-DD-YYYY') => {
    return date ? moment.parseZone(date).format(format) : date;
}

export const printDate = (date, missingString = '--/--/----') => {
    return formatDate(date, 'DD-MMM-YYYY') || missingString;
}

export const removeByIdx = (arr, index) => {
    const arrClone = [...arr];

    arrClone.splice(index, 1);

    return arrClone;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const setWindowDimensions = (name, dimensions) => {
    localStorage.setItem(name, JSON.stringify(dimensions));
}
export const getWindowDimensions = (name, width, height) => {
    return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) :
        {
            x: width ? (window.innerWidth / 2) - (width / 2) : window.innerWidth / 4,
            y: height ? (window.innerHeight / 2) - (height / 1.5) : window.innerHeight / 4,
            width: width? width: window.innerWidth / 2,
            height: height? height: window.innerHeight / 2,
        };
};

export const rendererCountdown = ({hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a completed state
        return "expired";
    } else {
        // Render a countdown
        return <span>{minutes}:{seconds}</span>;
    }
};

export const reactSelectStyles = {
    menu: provided => ({...provided, zIndex: 40000,}),
    menuPortal: base => ({...base, zIndex: 20000}),
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? "2px solid #4A2ECF" : "2px solid #CCCCCC",
        boxShadow: state.isFocused ? "0px 0px 6px #4A2ECF" : "none",
        "&:hover": {
            border: "2px solid #4A2ECF",
            boxShadow: "0px 0px 6px #4A2ECF",
        },
    }),
    option: (provided, {data, isDisabled, isFocused, isSelected}) => ({
        ...provided,
        backgroundColor: (isSelected || isFocused) ? "#DADDE8" : "white",
        color: "black",
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#4A2ECF" // Custom colour
    })

};
