import React, {Component} from 'react';
import {connect} from 'react-redux';
import NixioLink from "../../Link/NixioLink";
import {useParams} from "react-router-dom";

const TreatmentTimeline = (
    {
        isLoading,
        timelineFilters,
        handleFilterChange,
        rangeStart,
        rangeEnd,
    }) => {

    const {id: patientId} = useParams();

    return (
        <>
            <div className="col col-9">
                <div className="card">
                    <div className="card-body">
                        <div className="col">

                            <h6 className="card-title">Timeline Filter</h6>

                            <div className="card-toolbar">
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeLab ? " btn-lightish-purple" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeLab")}
                                >
                                    Lab
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeImaging ? " btn-algae-green" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeImaging")}
                                >
                                    Imaging
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeIntervention ? " btn-dusty-orange" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeIntervention")}
                                >
                                    Intervention
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includePRO ? " btn-deep-sky-blue" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includePRO")}
                                >
                                    PRO
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeMDComments ? " btn-brownish-grey" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeMDComments")}
                                >
                                    MD Comments
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeHospitalVisits ? " btn-dark-indigo" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeHospitalVisits")}
                                >
                                    Hospital Visits
                                </button>
                                <button type="button" disabled={isLoading}
                                        className={"btn btn-xs btn-round" + (timelineFilters.includeErVisits ? " btn-pinkish-red" : " btn-very-light-pink")}
                                        onClick={() => handleFilterChange("includeErVisits")}
                                >
                                    ER Visits
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentTimeline);