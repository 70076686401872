export const getDefaultRole = (roles) => {
    if (Array.isArray(roles)) {
        for (const role of roles) {
            if (role !== 'DATA_STUDIO') {
                return role;
            }
        }
    }

    return null;
}
export const getDefaultRoleObject = (roleObjects) => {
    if (Array.isArray(roleObjects)) {
        for (const role of roleObjects) {
            if (role.name !== 'DATA_STUDIO') {
                return role;
            }
        }
    }

    return null;
}
