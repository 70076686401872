import React, {Component, useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {LOAD_PATIENT_PRO_DIALS, loadPatientProDials, loadPatientReportCount} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../../utils/selectors";
import { Chart } from 'react-chartjs-2';
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import {openDocument, openPdfRaw} from "../../../utils/document-utils";
import {useParams} from "react-router-dom";

// Chart.pluginService.register({
//     beforeDraw: function(chart) {
//         if (chart.config.options.elements.center) {
//             chart.chart.ctx.textAlign = 'center';
//             chart.chart.ctx.textBaseline = 'middle';
//             chart.chart.ctx.font = '20px sans-serif';
//             chart.chart.ctx.fillStyle = chart.config.options.elements.center.color || '#000';
//             chart.chart.ctx.fillText(chart.config.options.elements.center.text,
//             (chart.chartArea.left + chart.chartArea.right) / 2,
//             (chart.chartArea.top + chart.chartArea.bottom) / 2);
//         }
//     }
// });

const PRODials = (
    {
        loadPatientReportCount,
        patientReportCount,
        loadingPatientReportCount,
        patientProDials,
        loading
    }) => {

    const { id: patientId } = useParams();


    useEffect(() => {
        // this.props.loadPatientProDials(patientId);

        const endDate = moment().endOf('day');
        const startDate = moment(endDate).subtract(30, 'days').startOf('day');

        loadPatientReportCount(patientId, startDate, endDate);
    }, [patientId]);

    const openReport = useCallback(() => {
        openDocument(patientId, 'symptoms-report', 1);
        // openPdfRaw(`/api/report/patient/${patientId}/symptoms`);
    }, [patientId]);

    const renderSymptomsReportInfo = useCallback(() => {
        if (patientReportCount == null) {
            return null;
        } else if (loadingPatientReportCount) {
            return (
                <Loader size={5} color={"#ccc"}/>
            )
        } else if (patientReportCount > 0) {
            return (
                <button onClick={openReport}>View symptoms summary report</button>
            )
        } else {
            return (
                <span>No symptoms reported in last 30 days</span>
            )
        }
    }, [openReport, loadingPatientReportCount, patientReportCount]);

    return  (
        <>
            <div className="card">
                <div className="card-body">

                    <div className="card-caption">
                        <h4 className="card-title">PRO Dials</h4>
                    </div>

                    <h6 className="card-title">
                        {renderSymptomsReportInfo()}
                    </h6>

                    {/*{!loading && !isEmpty(patientProDials)*/}
                    {/*? <>*/}

                    {/*    <div className="card-caption">*/}
                    {/*        <h4 className="card-title">PRO Dials</h4>*/}
                    {/*    </div>*/}

                    {/*    <div className="dials">*/}
                    {/*        <div className="row">*/}

                    {/*            <div className="half">*/}

                    {/*                <h5 className="dials-title">- Negative</h5>*/}

                    {/*                <div className="row">*/}

                    {/*                    <div className="col col-4">*/}
                    {/*                        {!isEmpty(patientProDials.digestive) ? (*/}
                    {/*                            <Doughnut width={100} height={100}*/}
                    {/*                                data={{labels: ['', ''], datasets: [{borderWidth: 0,*/}
                    {/*                                      data: [patientProDials.digestive, 10 - patientProDials.digestive],*/}
                    {/*                                      backgroundColor: ["#07FF00", "#CCCCCC"],*/}
                    {/*                                  }]}}*/}
                    {/*                                options={{*/}
                    {/*                                    legend: {display: false}, cutoutPercentage: 80, rotation: 2.35,*/}
                    {/*                                    elements: {center: {text: patientProDials.digestive}},*/}
                    {/*                                    plugins: {proChartsBackground: false},*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <span className="dials-na">N/A</span>*/}
                    {/*                        )}*/}
                    {/*                        <p className="dials-note"><i className="fas fa-utensils"/> Digestive</p>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="col col-4">*/}
                    {/*                        {!isEmpty(patientProDials.pain) ? (*/}
                    {/*                            <Doughnut width={100} height={100}*/}
                    {/*                                data={{labels: ['', ''], datasets: [{borderWidth: 0,*/}
                    {/*                                  data: [patientProDials.pain, 10 - patientProDials.pain],*/}
                    {/*                                  backgroundColor: ["#07FF00", "#CCCCCC"],*/}
                    {/*                                }]}}*/}
                    {/*                                options={{*/}
                    {/*                                    legend: {display: false}, cutoutPercentage: 80, rotation: 2.35,*/}
                    {/*                                    elements: {center: {text: patientProDials.pain}},*/}
                    {/*                                    plugins: {proChartsBackground: false},*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <span className="dials-na">N/A</span>*/}
                    {/*                        )}*/}
                    {/*                        <p className="dials-note"><i className="fas fa-burn"/> Pain</p>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="col col-4">*/}
                    {/*                        {!isEmpty(patientProDials.lungs) ? (*/}
                    {/*                            <Doughnut width={100} height={100}*/}
                    {/*                                data={{labels: ['', ''], datasets: [{borderWidth: 0,*/}
                    {/*                                  data: [patientProDials.lungs, 10 - patientProDials.lungs],*/}
                    {/*                                  backgroundColor: ["#07FF00", "#CCCCCC"],*/}
                    {/*                                }]}}*/}
                    {/*                                options={{*/}
                    {/*                                    legend: {display: false}, cutoutPercentage: 80, rotation: 2.35,*/}
                    {/*                                    elements: {center: {text: patientProDials.lungs}},*/}
                    {/*                                    plugins: {proChartsBackground: false},*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <span className="dials-na">N/A</span>*/}
                    {/*                        )}*/}
                    {/*                        <p className="dials-note"><i className="fas fa-lungs"/> Respiratory</p>*/}
                    {/*                    </div>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="half">*/}

                    {/*                <h5 className="dials-title">+ Positive</h5>*/}

                    {/*                <div className="row">*/}

                    {/*                    <div className="col col-6">*/}
                    {/*                        {!isEmpty(patientProDials.energy) ? (*/}
                    {/*                            <Doughnut width={100} height={100}*/}
                    {/*                                data={{labels: ['', ''], datasets: [{borderWidth: 0,*/}
                    {/*                                      data: [patientProDials.energy, 10 - patientProDials.energy],*/}
                    {/*                                      backgroundColor: ["#07FF00", "#CCCCCC"]*/}
                    {/*                                  }]}}*/}
                    {/*                                options={{*/}
                    {/*                                    legend: {display: false}, cutoutPercentage: 80, rotation: 2.35,*/}
                    {/*                                    elements: {center: {text: patientProDials.energy}},*/}
                    {/*                                    plugins: {proChartsBackground: false},*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <span className="dials-na">N/A</span>*/}
                    {/*                        )}*/}
                    {/*                        <p className="dials-note"><i className="fas fa-bolt"/> Energy</p>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="col col-6">*/}
                    {/*                        {!isEmpty(patientProDials.wellbeing) ? (*/}
                    {/*                            <Doughnut width={100} height={100}*/}
                    {/*                                data={{labels: ['', ''], datasets: [{borderWidth: 0,*/}
                    {/*                                      data: [patientProDials.wellbeing, 10 - patientProDials.wellbeing],*/}
                    {/*                                      backgroundColor: ["#07FF00", "#CCCCCC"]*/}
                    {/*                                  }]}}*/}
                    {/*                                options={{*/}
                    {/*                                    legend: {display: false}, cutoutPercentage: 80, rotation: 2.35,*/}
                    {/*                                    elements: {center: {text: patientProDials.wellbeing}},*/}
                    {/*                                    plugins: {proChartsBackground: false},*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <span className="dials-na">N/A</span>*/}
                    {/*                        )}*/}
                    {/*                        <p className="dials-note"><i className="fas fa-heart"/> Wellbeing</p>*/}
                    {/*                    </div>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</> : <>*/}

                    {/*    <div className="card-caption">*/}
                    {/*        <h4 className="card-title">PRO Dials</h4>*/}
                    {/*    </div>*/}
                    {/*    <Loader size={5} color={"#ccc"}/>*/}

                    {/*</> }*/}
                </div>
            </div>
        </>
    )
}

const patientProDialsLoader = createLoadingSelector(['LOAD_PATIENT_PRO_DIALS']);
const patientReportCountLoader = createLoadingSelector(['LOAD_PATIENT_REPORT_COUNT']);

const mapStateToProps = (state) => {
    const patientProDials = getPatientsData(state).patientProDials,
        loading = patientProDialsLoader(state),
        patientReportCount = getPatientsData(state).patientReportCount,
        loadingPatientReportCount = patientReportCountLoader(state);

    return {
        patientProDials,
        loading,
        patientReportCount,
        loadingPatientReportCount,
    };
};

const mapDispatchToProps = {
    loadPatientProDials,
    loadPatientReportCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(PRODials);