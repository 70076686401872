import React from 'react'
import {Container} from "react-bootstrap";
import Header from "./Header";

class SearchPatientLayout extends React.Component {

    render() {

        return (
            <>
                <Header {...this.props} />
                
                <main>
                    <Container fluid>
                        {this.props.children}
                    </Container>
                </main>
            </>
        );
    }
}

export default SearchPatientLayout;
