import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const LOAD_NOTIFICATIONS = createRequestTypes('LOAD_NOTIFICATIONS');

export const loadTaskNotifications = (data) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_NOTIFICATIONS));
    try {
        dispatch(actionCreator.success(LOAD_NOTIFICATIONS, data));

    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_NOTIFICATIONS, mockError));
        return err.response;
    }
}

export const clearTaskNotifications = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_NOTIFICATIONS));
}