import React, {useReducer, useEffect, useState} from 'react';
import packageJson from '../../package.json';

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) continue;
        return a > b || isNaN(b);
    }
    return false;
};

const actionTypes = {
    SET_VERSION_CHECK: 'SET_VERSION_CHECK'
};

const initialState = {
    loading: true,
    isLatestVersion: false
};

const versionCheckReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_VERSION_CHECK:
            return {
                ...state,
                loading: false,
                isLatestVersion: action.payload.isLatestVersion
            };
        default:
            return state;
    }
};

const CacheBuster = ({ children }) => {
    const [state, dispatch] = useReducer(versionCheckReducer, initialState);
    const [reload, setReload] = useState(0)

    const refreshCacheAndReload = () => {
        if (caches) {
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
            });

            window.location.reload(true);
        }
    };

    useEffect(() => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = packageJson.version;

                const isLatestVersion = !semverGreaterThan(latestVersion, currentVersion);

                dispatch({
                    type: actionTypes.SET_VERSION_CHECK,
                    payload: {
                        isLatestVersion: isLatestVersion
                    }
                });
            })
            .catch(error => {
                console.error("Error fetching meta.json: ", error);
                dispatch({
                    type: actionTypes.SET_VERSION_CHECK,
                    payload: {
                        isLatestVersion: true
                    }
                });
            });

        const intervalId = setInterval(() => {
            setReload(reload + 1);
        }, 60000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [reload]);

    return children({ ...state, refreshCacheAndReload });
};

export default CacheBuster;