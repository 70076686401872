import React, {useCallback, useMemo} from 'react';
import {getConfirm} from "../../utils/selectors";
import {connect} from 'react-redux';
import {Modal} from "react-bootstrap";
import PropTypes from 'prop-types';
import {clearConfirm, hideConfirm} from "../../actions/confirm.actions";

const ConfirmDialog = (
    {
        show,
        hideConfirm,
        clearConfirm,
        content,
        options = {},
    }) => {
    const handleOnHide = useCallback(() => {
        hideConfirm();
    }, [hideConfirm]);

    const handleOnExited = useCallback(() => {
        clearConfirm();
    }, [clearConfirm]);

    const handleOnClose = useCallback(() => {
        hideConfirm();
        options.onClose?.();
    }, [options.onClose, hideConfirm]);

    const handleOnConfirm = useCallback(() => {
        hideConfirm();
        options.onConfirm?.();
    }, [options.onConfirm, hideConfirm]);

    const showHeader = options.closeButton || options.title;

    const modalContent = useMemo(() => {
        return (
            <>
                {showHeader ? (
                    <Modal.Header closeButton={options.closeButton}>
                        {options.title ? (
                            <Modal.Title>{options.title}</Modal.Title>
                        ) : null}
                    </Modal.Header>
                ) : null}
                <Modal.Body>{content}</Modal.Body>
                <Modal.Footer>
                    {options.footer || (
                        <>
                            <button
                                className="btn btn-secondary btn-rnd btn-cancel"
                                onClick={handleOnClose}
                            >
                                {options.cancelText || "Cancel"}
                            </button>
                            <button
                                className="btn btn-primary btn-rnd btn-save"
                                onClick={handleOnConfirm}
                            >
                                {options.confirmText || "Ok"}
                            </button>
                        </>
                    )}
                </Modal.Footer>
            </>
        );
    }, [content, options, handleOnConfirm, handleOnClose]);

    return (
        <Modal
            className={"confirm-modal"}
            show={show}
            onHide={handleOnHide}
            onExited={handleOnExited}
            backdrop="static"
            keyboard={false}
        >
            {modalContent}
        </Modal>
    );
}


ConfirmDialog.propTypes = {
    hideConfirm: PropTypes.func,
    clearConfirm: PropTypes.func,
    content: PropTypes.any,
    options: PropTypes.object,
}

const mapStateToProps = (state) => {
    const confirmState = getConfirm(state);

    return {
        ...confirmState,
    };
};

const mapDispatchToProps = {
    hideConfirm,
    clearConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
