import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {loadPatientDiagnosis} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import {showModal} from "../../../actions/modal.actions";
import {useParams} from "react-router-dom";

const Diagnosis = (
    {
        loadPatientDiagnosis,
        showModal,
        patientDiagnosis,
        loading
    }) => {

    const { id: patientId } = useParams();

    useEffect(() => {
        loadPatientDiagnosis(patientId);
    }, [patientId]);

    const showDiagnosisModal = useCallback(() => {
        showModal("DIAGNOSIS_MODAL", patientId);
    }, [patientId]);


    return  (
        <>
            <div className="card">
                <div className="card-body">

                    {!loading && patientDiagnosis
                    ? <>

                        <div className="card-caption">
                            <h4 className="card-title">
                                <button onClick={showDiagnosisModal}>Diagnosis</button>
                            </h4>
                            <span className="card-subtitle">
                                {patientDiagnosis.diagnosisDate &&
                                    moment.parseZone(patientDiagnosis.diagnosisDate).format('DD-MMM-YYYY')
                                }
                            </span>
                        </div>

                        <h6>{patientDiagnosis.diseaseName || "Disease N/A"}</h6>
                        <p>
                            T{patientDiagnosis.t || "X"}&nbsp;N{patientDiagnosis.n || "X"}&nbsp;M{patientDiagnosis.m || "X"}
                            <br/>
                            Stage {patientDiagnosis.stage || "N/A"}
                            <br/>
                            Sites: {patientDiagnosis.diseaseLocation || "N/A"}
                        </p>

                    </> : <>

                        <div className="card-caption">
                            <h4 className="card-title">Diagnosis</h4>
                        </div>
                        <Loader size={5} color={"#ccc"}/>

                    </> }

                </div>
            </div>
        </>
    )
}

const patientDiagnosisLoader = createLoadingSelector(['LOAD_PATIENT_DIAGNOSIS']);

const mapStateToProps = (state) => {

    const
        patientDiagnosis = getPatientsData(state).patientDiagnosis,
        loading = patientDiagnosisLoader(state);

    return {
        patientDiagnosis,
        loading
    };
};

const mapDispatchToProps = {
    loadPatientDiagnosis,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Diagnosis);