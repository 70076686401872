import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DocumentViewer from "./DocumentViewer";
import isEmpty, {printDate} from "../../utils/helpers";
import MeetingAudioRecording from "../AudioPlayer/MeetingAudioRecording";
import {FadeLoader} from "react-spinners";
import {Alert} from "react-bootstrap";
import _ from 'lodash';
import {toast} from "react-toastify";
import moment from "moment/moment";
import Loader from "react-spinners/BeatLoader";
import logo from "../../images/nixio-logo.svg";
import {loadPatientDocument} from "../../actions/patients.actions";
import axiosInstance from "../../utils/axios-instance";
import {TextView} from "./TextView";
import {useParams} from "react-router-dom";
import {Fade, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

const get = (obj, path, defaultValue) => {
    if (typeof path === 'string') {
        path = path.split('.');
    }

    let current = obj;

    for (let i = 0; i < path.length; i++) {
        if (current === null || current === undefined) {
            return defaultValue;
        }
        current = current[path[i]];
    }

    return (current === undefined || current === null) ? defaultValue : current;
}

const printError = (error) => {
    toast.error(error);
    console.error(error);
}

const DocumentSharedViewHeader = ({
                                      file,
                                      patientId,
                                      textViewEnabled,
                                      setTextViewEnabled,
                                      showTextView,
                                      searchText,
                                      setSearchText,
                                      currentSearchEl,
                                      totalSearchEl,
                                      focusNextOnSearch,
                                      focusPreviousOnSearch
                                  }) => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [patientInfo, setPatientInfo] = useState(null);
    const [loadingPatientInfo, setLoadingPatientInfo] = useState(true);
    const [patientPhoto, setPatientPhoto] = useState(null);
    const [loadingPatientPhoto, setLoadingPatientPhoto] = useState(true);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [isUnread, setIsUnread] = useState(false);

    const fileProps = useMemo(() => [
        {
            name: 'Name',
            value: get(file, 'fileName', 'N/A'),
        }, {
            name: 'Date',
            value: (file && printDate(file.effectiveDate)) || 'N/A',
        }, {
            name: 'Facility',
            value: get(file, 'facility', 'N/A'),
        }, {
            name: 'Type',
            value: get(file, 'folder.name', 'N/A'),
        }, {
            name: 'Status',
            value: get(file, 'status.displayName', 'N/A'),
        },
    ], [file]);

    const fileChangedChannel = new BroadcastChannel('file_status_changed');

    useEffect(() => {
        if (file) {
            fileChangedChannel.postMessage(file.patientId);
        }
        if (file && file.fileAttachments) {
            const audioFile = file.fileAttachments.find((attachment) => attachment.tags && attachment.tags.includes("meeting_audio"));
            if (audioFile) {
                setAudioUrl(`/api/file/download/${file.id}/attachment/${audioFile.id}`);
                return;
            }
        }

        setAudioUrl(null);
    }, [file]);

    useEffect(() => {
        const fetchData = async (patientId) => {
            setPatientInfo(null);

            if (!patientId) {
                return;
            }

            setLoadingPatientInfo(true);

            try {
                const resp = await axiosInstance.get(`/api/patient/${patientId}`);

                if (resp.data) {
                    setPatientInfo(resp.data);
                } else {
                    printError("Fetching patient info: " + resp.data.errorMessage)
                }
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
                printError("Fetching patient info: " + errMsg);
            } finally {
                setLoadingPatientInfo(false);
            }
        };

        fetchData(patientId);
    }, [patientId]);

    useEffect(() => {
        const fetchData = async (patientId) => {
            setPatientPhoto(null);

            if (!patientId) {
                return;
            }

            setLoadingPatientPhoto(true);

            try {
                const resp = await axiosInstance.get(`/api/patient/image/${patientId}`, {responseType: 'blob'});
                setPatientPhoto(resp.data);
            } catch (err) {
                if (_.get(err, 'response.status') !== 404) {
                    const errMsg = _.get(err, 'response.data.errorMessage', err.message);
                    printError("Fetching patient photo: " + errMsg);
                }
            } finally {
                setLoadingPatientPhoto(false);
            }
        };

        fetchData(patientId);
    }, [patientId]);

    const markFileReadUnread = useCallback(() => {
        axiosInstance.get(`/api/file/${file.id}/change-read-unread`).then(resp => {
            fileChangedChannel.postMessage(file.patientId);
        });
        setIsUnread(!isUnread);
    }, [file, isUnread]);

    const downloadDocument = useCallback(() => {
        const downloadPdf = async (file) => {
            if (!file) {
                return;
            }

            setDownloadingFile(true);

            try {
                const resp = await axiosInstance.get(`/api/file/download/${file.id}`, {responseType: 'blob'});
                const blob = new Blob([resp.data], {type: 'application/pdf'});
                const link = document.createElement('a');

                if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                    link.href = window.URL.createObjectURL(blob);
                } else {
                    link.href = URL.createObjectURL(blob);
                }

                link.target = "_blank";
                link.setAttribute(
                    'download',
                    file.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
                printError("Download error: " + errMsg);
            } finally {
                setDownloadingFile(false);
            }
        };

        downloadPdf(file);
    }, [file]);

    return (
        <div className="document-view-header">
            <div className="header-logo">
                <img src={logo} alt="logo"/>
            </div>

            <div className="header-info">
                <div className="document-info-toolbar flex-grow-1">
                    <div className="info-left">
                        <div className="patient-menu-info no-padding">
                            {loadingPatientInfo ? (
                                <div className="patient-menu-loader">
                                    <Loader size={5} color={"#000"}/>
                                </div>
                            ) : !!patientInfo ? (
                                <>
                                    {loadingPatientPhoto ? (
                                        <div className="patient-menu-loader p-1">
                                            <Loader size={5} color={"#000"}/>
                                        </div>
                                    ) : (
                                        patientPhoto ? (
                                            <img className="patient-menu-image"
                                                 src={URL.createObjectURL(new Blob([patientPhoto]))}
                                                 alt="Patient Photo"/>
                                        ) : (
                                            <i className="patient-menu-image ni ni-avatar"/>
                                        )
                                    )}
                                    <div className="patient-menu-details">
                                        <div className="row">
                                            <div className="col">
                                                <span
                                                    className="patient-menu-detail patient-menu-detail-full">{patientInfo.firstName + " " + patientInfo.lastName}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span
                                                    className="patient-menu-detail">{patientInfo.birthdate && moment(patientInfo.birthdate).format("DD-MMM-YYYY")}</span>
                                                <span
                                                    className="patient-menu-detail">{patientInfo.gender && patientInfo.gender}</span>
                                                <span
                                                    className="patient-menu-detail">{!isEmpty(patientInfo.crmCases) && patientInfo.crmCases[0].crmCaseId}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>) : (
                                <></>
                            )
                            }
                        </div>
                        <div className="vertical-separator"/>
                        {fileProps.map(prop =>
                            <div className="prop" key={prop.name}>
                                <div className="name">
                                    {prop.name}
                                </div>
                                <div className="value" title={prop.value}>
                                    {prop.value}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="document-info-toolbar info-controls">
                    <div className="info-left">
                        {audioUrl && (
                            <MeetingAudioRecording url={audioUrl} useAuth={true} progressClass="progress-big"/>
                        )}
                    </div>
                    <div className="info-right">
                        {textViewEnabled && (
                            <div>
                                <input type="text" placeholder="Search" onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        focusNextOnSearch();
                                    }
                                }} value={searchText} onChange={(elem) => setSearchText(elem.target.value)}/>
                                ({currentSearchEl}/{totalSearchEl})
                                &nbsp;<i className="ni ni-arrow-down ni-xs ms-1 cursor-pointer"
                                         onClick={focusNextOnSearch}/>
                                &nbsp;<i className="ni ni-arrow-up ni-xs ms-1 cursor-pointer"
                                         onClick={focusPreviousOnSearch}/>
                            </div>
                        )}
                        {showTextView && (<>
                            <div><label htmlFor="textViewEnabled" className="form-check-label">Text View</label></div>
                            <div className="form-check form-switch d-inline-block">
                                <input
                                    id="textViewEnabled"
                                    name="textViewEnabled"
                                    type="checkbox"
                                    className="form-check-input cursor-pointer"
                                    onChange={() => setTextViewEnabled(!textViewEnabled)}
                                    checked={textViewEnabled}
                                />
                            </div>
                        </>)}

                        {file && (
                            <button
                                type="button"
                                className={"btn btn-rnd " + (isUnread ? "btn-outline-purple" : "btn-white")}
                                disabled={!file}
                                onClick={markFileReadUnread}
                            >
                                {isUnread ? "Mark as Read" : "Mark as Unread"}
                            </button>
                        )}
                        <button
                            type="button"
                            className={"btn btn-rnd btn-outline-purple"}
                            disabled={!file || downloadingFile}
                            onClick={downloadDocument}
                        >
                            {!downloadingFile ? (
                                <i className="fas fa-lg fa-fw fa-download"/>
                            ) : (
                                <div className="spinner-border text-primary spinner-border-sm me-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const DocumentInfoFullViewer = () => {
    const {id} = useParams();
    const [documentId, setDocumentId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileInfo, setFileInfo] = useState(null);
    const [patientId, setPatientId] = useState(null);
    const [loadingFileInfo, setLoadingFileInfo] = useState(true);
    const [error, setError] = useState(null);

    const [file, setFile] = useState(null);
    const [textViewEnabled, setTextViewEnabled] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [currentSearchEl, setCurrentSearchEl] = useState(0);
    const [totalSearchEl, setTotalSearchEl] = useState(0);
    const [fileText, setFileText] = useState(null);
    const documentContentRef = useRef();

    useEffect(() => {
        const documentInfo = new URLSearchParams(window.location.search);
        const pageNumber = documentInfo.get('pageNumber');
        const documentId = documentInfo.get('documentId');

        setDocumentId(documentId);
        setPageNumber(pageNumber ? parseInt(pageNumber) : 1);

        console.log(documentId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setFileInfo(null);
            setFile(null);

            setLoadingFileInfo(true);

            try {
                if (documentId) {
                    if (documentId === 'symptoms-report') {
                        const patientId = id;
                        const response = await axiosInstance.get(`/api/report/patient/${patientId}/symptoms`, {responseType: 'blob'});
                        const file = new Blob([response.data], {type: 'application/pdf'});

                        setPatientId(patientId);
                        setFile(file);
                    } else {
                        const resp = await axiosInstance.get(`/api/file/${documentId}`);

                        if (resp.data.success) {
                            const respFile = resp.data.file;
                            setFileInfo(respFile);

                            const response = await axiosInstance.get(`/api/file/download/${documentId}`, {responseType: 'blob'});
                            const file = new Blob([response.data], {type: 'application/pdf'});

                            setPatientId(respFile.patientId);
                            setFile(file);
                        } else {
                            setError(resp.data.errorMessage);
                        }
                    }
                }
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message)
                setError(errMsg);
            } finally {
                setLoadingFileInfo(false);
            }
        };

        fetchData();
    }, [documentId]);

    useEffect(() => {
        if (!fileText || searchText.length == 0) {
            setCurrentSearchEl(0);
            setTotalSearchEl(0);
            return;
        }
        let count = 0;
        for (const filePage of fileText.filePages) {
            count += (filePage.text.match(new RegExp(searchText, "ig"), 'i') || []).length
        }
        if (count > 0) {
            const elements = documentContentRef.current.getElementsByTagName("mark");
            if (elements && elements.length > 0) {
                elements[0].scrollIntoView();
            }
        }

        setCurrentSearchEl(count > 0 ? 1 : 0);
        setTotalSearchEl(count);
    }, [searchText]);


    if (loadingFileInfo) {
        return (
            <div className="d-flex justify-content-center p-5">
                <FadeLoader size="5rem" color="#007bff" loading={true}/>
            </div>
        )
    }
    const focusNextOnSearch = () => {
        if (totalSearchEl <= 0) {
            return;
        }
        const elements = documentContentRef.current.getElementsByTagName("mark");
        let nextEl = currentSearchEl + 1;
        if (nextEl > totalSearchEl) {
            nextEl = 1;
        }
        if (elements.length >= nextEl) {
            elements[nextEl - 1].scrollIntoView();
        }
        setCurrentSearchEl(nextEl);
    }

    const focusPreviousOnSearch = () => {
        if (totalSearchEl <= 0) {
            return;
        }
        const elements = documentContentRef.current.getElementsByTagName("mark");
        let nextEl = currentSearchEl - 1;
        if (nextEl < 1) {
            nextEl = totalSearchEl;
        }
        if (elements.length >= nextEl) {
            elements[nextEl - 1].scrollIntoView();
        }
        setCurrentSearchEl(nextEl);
    }

    console.log("fileInfo", fileInfo);
    console.log("file", file);

    return (
        <div>
            <DocumentSharedViewHeader file={fileInfo} patientId={patientId} textViewEnabled={textViewEnabled}
                                      setTextViewEnabled={setTextViewEnabled}
                                      showTextView={documentId && documentId !== 'symptoms-report'}
                                      setSearchText={setSearchText} searchText={searchText}
                                      currentSearchEl={currentSearchEl}
                                      totalSearchEl={totalSearchEl} focusNextOnSearch={focusNextOnSearch}
                                      focusPreviousOnSearch={focusPreviousOnSearch}/>
            {error && <Alert variant="danger">{error}</Alert>}
            <DocumentViewer visible={!textViewEnabled} file={file} pageNumber={pageNumber}/>
            {textViewEnabled &&
                <TextView fileId={fileInfo.id} fileText={fileText} setFileText={setFileText} searchText={searchText}
                          documentContentRef={documentContentRef}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    loadPatientDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfoFullViewer);