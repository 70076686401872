import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useFilters, useSortBy, useTable, useGlobalFilter, useAsyncDebounce} from "react-table";
import BTable from "react-bootstrap/Table";
import isEmpty, {printDate} from "../../utils/helpers";
import moment from "moment";
import {htmlToReact, markText} from "../../utils/render-utils";
import _ from "lodash";
import {getFolderImgSrc} from "../../utils/file-util";
import noOrderSvg from "../../images/no-order.svg";
import orderAscSvg from "../../images/order-asc.svg";
import orderDescSvg from "../../images/order-desc.svg";

const spinnerStyle = {
    width: '5rem',
    height: '5rem',
    marginTop: '5rem',
}

const NO_GROUPING = "NO_GROUPING";

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, cb) => {
        cbRef.current = cb;
        setState(state);
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
}

const Table = ({ columns, data, actions, initialState, selectedRowId, onRowSelect, globalFilter, showFaculty, changeReadUnread }) => {

    const [tableBodyStyle, setTableBodyStyle] = useState();
    const [tableData, setTableData] = useState([]);
    const tableBodyRef = useRef();

    useEffect(() => {
        if (!data) {
            setTableData([]);
            return;
        }

        setTableData(data);

        const searchRecords = data.filter(rec => rec.highlightFragment && rec.searchQuery);
        if (!searchRecords.length) {
            return;
        }

        const promiseArray = [];
        for (const record of searchRecords) {
            const textFragment = record.highlightFragment;
            const searchQuery = record.searchQuery;
            const promise = new Promise((resolve, reject) => {
                markText(textFragment, searchQuery).then(markedText => {
                    const updatedRecord = {
                        ...record,
                        highlightFragment: markedText
                    };

                    resolve(updatedRecord);
                })
            });
            promiseArray.push(promise);
        }

        Promise.all(promiseArray).then((updatedRecords) => {
           setTableData(prevData => {
               if (prevData !== data) {
                   return prevData;
               }

               const updatedRecordMap = new Map();
               for (const updatedRecord of updatedRecords) {
                   updatedRecordMap.set(updatedRecord.id, updatedRecord);
               }

               const processedData = data.map((rec) => {
                   const updatedRecord = updatedRecordMap.get(rec.id);
                   return updatedRecord ? updatedRecord : rec;
               });

               return processedData;
           })
        });
    }, [data]);

    const DefaultColumnFilter = useCallback(({column: {filterValue, preFilteredRows, setFilter, Header}}) => {
        // const count = preFilteredRows.length

        return (
            <input className='form-control'
                   value={filterValue || ''}
                   onChange={e => {
                       setFilter(e.target.value || undefined)
                   }}
                   placeholder={`Search by ${Header}...`}
            />
        )
    }, []);

    const DefaultCell = useCallback((props) => {
        const {column, value} = props;

        if (value === false) {
            return <span>Yes</span>;
        } else if (value === true) {
            return <span>No</span>;
        }

        return <span>{value}</span>;
    }, []);

    const filterTypes = useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .includes(String(filterValue).toLowerCase())
                    : true
            })
        },
    }), []);

    const defaultColumn = useMemo(() => ({
        Cell: DefaultCell,
        Filter: DefaultColumnFilter,
        groupKey: (value) => value,
    }), []);

    const DateCell = useCallback(({value}) => {
        return <span>{printDate(value)}</span>;
    }, []);

    const regexp = '^\s*(.+?)#(.*?)(\.(\w+))?$';
    const tableColumns = useMemo(() => {
        if (columns) {
            return columns;
        }

        let result = [
            {
                Header: 'Date',
                id: 'effectiveDate',
                accessor: (file) => !isEmpty(file.effectiveDate) ? moment.parseZone(file.effectiveDate).valueOf() : null,
                Cell: DateCell,
                disableFilters: true,
                cellTitle: (cell) => printDate(cell.value),
                groupKey: (value) => !isEmpty(value) ? moment.parseZone(value).year() : null,
            },
        ];

        if (showFaculty) {
            result = [{
                Header: 'Type',
                id: 'type',
                accessor: 'folder.name',
                Cell: (props) => <img className="folder-image" src={getFolderImgSrc(props.row.original.icon)}/>,
                disableFilters: true,
                cellTitle: true,
            }, ...result];
            result.push({
                    Header: 'Facility / Subject',
                    accessor: (file) => {
                        if (file.folder.name === 'Advocacy notes') {
                            if (!file.fileName.match(regexp)) {
                                return 'N/A';
                            }
                            let result = file.fileName.match(regexp)[2];
                            return result.indexOf('.') >= 0? result.substring(0, result.lastIndexOf('.')): result;
                        }
                        return !isEmpty(file.facility) ? file.facility : 'N/A';
                    },
                    disableFilters: true,
                    cellTitle: true,
                });
            result.push({
                    Header: 'Care Provider',
                    accessor: (file) => file.folder.name === 'Advocacy notes'? (!isEmpty(file.facility) ? file.facility : 'N/A') : (!isEmpty(file.provider) ? file.provider : 'N/A'),
                    disableFilters: true,
                    cellTitle: true,
                });
        } else {
            result.push({
                Header: 'Name',
                accessor: 'fileName',
                disableFilters: true,
                cellTitle: true,
            });
        }
        return result;
    }, [columns, showFaculty]);

    const tableInitialState = useMemo(() => {
        const tableInitialState = {
            sortBy: [
                {
                    id: 'effectiveDate',
                    desc: true
                }
            ]
        };

        if (tableInitialState) {
            return {...tableInitialState, ...initialState};
        }

        return tableInitialState;

    }, [initialState]);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        visibleColumns,
    } = useTable(
        {
            columns: tableColumns,
            data: tableData,
            defaultColumn,
            filterTypes,
            initialState: tableInitialState,
            actions,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );

    useEffect(() => {
        if (tableBodyRef.current) {
            const tBodyHeight = (window.innerHeight - tableBodyRef.current.getBoundingClientRect().top);
            setTableBodyStyle(({height: tBodyHeight + "px"}));
        }
    }, [tableBodyRef.current]);

    useEffect(() => {
        setGlobalFilter(globalFilter);
    }, [globalFilter]);

    const groups = useMemo(() => {
        const sortedColumn = headerGroups[0].headers.find((column) => column.isSorted);
        rows.forEach(prepareRow);
        if (sortedColumn && sortedColumn.Header !== "Name") {
            const groups = [];
            for (const row of rows) {
                const currentCell = row.cells.find((cell) => cell.column && (cell.column.id === sortedColumn.id));
                const groupKey = currentCell.column.groupKey;

                if (!groupKey) {
                    return [{key: "NO_GROUPING", rows: rows}];
                }

                const cellKey = groupKey(currentCell.value) || "N/A";
                let currentGroup = groups.find(gr => gr.key === cellKey);
                if (!currentGroup || currentGroup.key !== cellKey) {
                    currentGroup = {key: cellKey, rows: []};
                    groups.push(currentGroup);
                }

                currentGroup.rows.push(row);
            }

            return groups;
        }

        return [{key: "NO_GROUPING", rows: rows}];
    }, [rows, headerGroups]);

    if (!data) {
        return (
            <div className="w-100 h-100 text-center">
                <div className="spinner-border text-primary" role="status" style={spinnerStyle}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <BTable hover className="file-group-table scrollbar-thin" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th className="read-actions"></th>
                        {headerGroup.headers.map(column => {
                            if (column.hideHeader) {
                                return null;
                            }

                            const headerTxtProps = {};
                            if (column.titleText) {
                                headerTxtProps.title = column.titleText;
                            }

                            return (
                                <th  className={!showFaculty? "show-faculty": ""} {...column.getHeaderProps()}>
                                    <div {...column.getSortByToggleProps()} className={`header-container ${column.isSorted? " sorted" : ''}`}>
                                        <span {...headerTxtProps} className="header">{column.render('Header')}</span>
                                        <img width={13} height={13} style={{marginTop: "auto", marginBottom: "auto"}} src={column.isSorted? (column.isSortedDesc? orderDescSvg: orderAscSvg): noOrderSvg}/>
                                    </div>
                                    <div>{!column.disableFilters && column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            );
                        })
                        }
                    </tr>
                ))}
                </thead>
                <tbody ref={tableBodyRef} style={tableBodyStyle}>
                {groups.map((group, i) => {
                    return (
                        <>
                        {group.key !== NO_GROUPING && (
                            <tr className="group-title">
                                <td colSpan={visibleColumns.length}>{group.key ? group.key : "N/A"}</td>
                            </tr>
                        )}
                        {group.rows.map((row, i) => {
                            return (
                                <>
                                    <tr {...row.getRowProps({
                                        onClick: () => onRowSelect(row.original),
                                        onMouseEnter: (el) => {
                                            if (!row.original.highlightFragment) {
                                                return;
                                            }
                                            const prevRow = _.get(el, 'currentTarget.nextSibling');
                                            if (prevRow) {
                                                prevRow.classList.add("hover");
                                            }
                                        },
                                        onMouseLeave: (el) => {
                                            if (!row.original.highlightFragment) {
                                                return;
                                            }
                                            const prevRow = _.get(el, 'currentTarget.nextSibling');
                                            if (prevRow) {
                                                prevRow.classList.remove("hover");
                                            }
                                        },
                                        className: 'selectable' + (row.original.id === selectedRowId ? ' selected' : '')
                                            + (row.original.highlightFragment ? ' expanded-parent' : '')
                                            + (row.original.isNew ? ' new-file' : ''),
                                        key: row.original.id
                                    })}>
                                        <td
                                            className={"read-actions" + (row.original.isNew? " new-file": "")}
                                            onClick={(event) => {
                                                changeReadUnread(row.original);
                                                event.stopPropagation();
                                            }}
                                        />
                                        {row.cells.map(cell => {
                                            const cellTitle = !cell.column.cellTitle ? null
                                                : cell.column.cellTitle === true ? cell.value
                                                    : cell.column.cellTitle(cell);

                                            return (
                                                <td className={!showFaculty? "show-faculty": ""} {...cell.getCellProps()} title={cellTitle}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    {row.original.highlightFragment && (
                                        <tr {...row.getRowProps({
                                            onClick: () => onRowSelect(row.original),
                                            onMouseEnter: (el) => {
                                                const prevRow = _.get(el, 'currentTarget.previousSibling');
                                                if (prevRow) {
                                                    prevRow.classList.add("hover");
                                                }
                                            },
                                            onMouseLeave: (el) => {
                                                const prevRow = _.get(el, 'currentTarget.previousSibling');
                                                if (prevRow) {
                                                    prevRow.classList.remove("hover");
                                                }
                                            },
                                            className: 'selectable' + (row.original.id === selectedRowId ? ' selected' : ''),
                                            key: `${row.original.id}-fragment`
                                        })}>
                                            <td colSpan={visibleColumns.length} className="expanded">
                                                {htmlToReact(row.original.highlightFragment)}
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )
                        })}
                        </>
                    );
                })
            }
            </tbody>
            </BTable>
        </>
    )
}

export default Table;