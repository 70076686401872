import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {createLoadingSelector, getPatientsData, getModalData} from "../../../utils/selectors";
import moment from "moment";
import { addPatientCcmNote, loadPatientCcmNotes } from "../../../actions/patients.actions";
import { Form, Formik } from 'formik';

class CCMNotesModal extends Component {

	closeModal = () => {
		this.props.hideModal();
	};

	editNote = () => {
		alert('edit note');
	}

	addNoteToSummary = () => {
		alert('add Note To Summary');
	}

	handleOnSubmit = (data) => {
		this.props.addPatientCcmNote(this.props.modalProps.pid, data.ccmNote).then((response) => {
			this.loadNotes();
		});
	}

	loadNotes = () => {
		this.props.loadPatientCcmNotes(this.props.modalProps.pid);
	}

	render() {
		return (
			<>
				<div className="modal-header">
					<h4 className="modal-title">LA Notes</h4>
					<div className="modal-toolbar">
						<div className="input-group">
							<input type="text" className="form-control" placeholder="Search notes" />
							<div className="input-group-append">
								<button className="btn btn-primary" type="button">
									<i className="fas fa-search" />
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal-body">
				{/*<Formik*/}
                {/*        initialValues={{*/}
                {/*            ccmNote: ''*/}
                {/*        }}*/}
                {/*        */}
                {/*        onSubmit={this.handleOnSubmit}*/}
                {/*        render={({handleSubmit, setFieldValue, values, handleChange}) => (*/}
				{/*				<div className="modal-ccm-notes-add">*/}
				{/*					<form onSubmit={handleSubmit}>*/}
				{/*						<div className="form-row align-items-center">*/}
				{/*							<div className="col-sm-1 my-1">*/}
				{/*								<span className="badge badge-xl badge-pill badge-info">{moment.parseZone().format('MM/DD')}</span>*/}
				{/*							</div>*/}
				{/*							<div className="col-sm-9 my-1">*/}
				{/*								<textarea id="ccmNote" name="ccmNote" value={values.ccmNote} onChange={handleChange} className="form-control form-control-dark" placeholder="Write your note here..." />*/}
				{/*							</div>*/}
				{/*							<div className="col-sm-2 my-1">*/}
				{/*								<button type="submit" className="btn btn-primary btn-round">Add Note</button>*/}
				{/*							</div>*/}
				{/*						</div>*/}
				{/*					</form>*/}
				{/*				</div>*/}
				{/*		)} />*/}
					{/*<Hammer*/}
					{/*	options={swipeOptions}*/}
					{/*	onSwipe={console.log('swipe')}*/}
					{/*>*/}
					<div className="modal-ccm-notes-gestures">
						<span>
							<i className="fas fa-long-arrow-alt-left" />
								Swipe left to edit
							</span>
						<span>
							<i className="fas fa-long-arrow-alt-right" />
								Swipe right to add to disease summary
							</span>
					</div>
					{/*</Hammer>*/}

					<dl className="details details-notes-modal">

						{this.props.patientCcmNotes.map(note => (<>
							<div>
								<dt>{note.time && moment.parseZone(note.time).format("DD-MMM-YYYY")}</dt>
								<dd>{note.details}</dd>
							</div>
						</>))}

					</dl>

					{/*<button type="button" className="btn btn-link">
						Show more <i className="fas fa-angle-down" />
					</button>*/}

				</div>
			</>
		);
	}
}

CCMNotesModal.propTypes = {
	patientCcmNotes: PropTypes.array
}
const patientCcmNotesLoader = createLoadingSelector(['LOAD_PATIENT_CCM_NOTES']);
const mapStateToProps = (state) => {
	const 
	patientCcmNotes = getPatientsData(state).patientCcmNotes,
	loading = patientCcmNotesLoader(state),
	modalProps = getModalData(state).modalProps;

	return {
		patientCcmNotes,
		loading,
		modalProps
	};
};

const mapDispatchToProps = {
	addPatientCcmNote,
	loadPatientCcmNotes
};

export default connect(mapStateToProps, mapDispatchToProps)(CCMNotesModal);
