import React, {useState} from 'react';
import {connect} from 'react-redux';
import PanelTitle from "../PanelTitle";
import {getWindowDimensions, setWindowDimensions} from "../../../utils/helpers";
import {Rnd} from "react-rnd";
import ShareFile from "./ShareFile";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "1px 0 2px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.6)",
    border: "solid 1px #a8a8a8",
    backgroundColor: "#fff",
};

const ShareFilePanel = (props, panelProps) => {
    const {hidePanel} = props;
    const [windowDimension, setWindowDimension] = useState(getWindowDimensions('shareFile', 1100, 800));

    return (
        <Rnd
            style={style}
            size={{ width: windowDimension.width, height: windowDimension.height }}
            position={{ x: windowDimension.x, y: windowDimension.y }}
            onDragStop={(e, d) => {
                windowDimension['x'] = d.x;
                windowDimension['y'] = d.y;
                setWindowDimensions('shareFile', windowDimension);
                setWindowDimension((prevState) => {
                    const newState = {...prevState};
                    newState.x = d.x;
                    newState.y = d.y;
                    return newState;
                });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                windowDimension['width'] = ref.style.width;
                windowDimension['height'] = ref.style.height;
                setWindowDimensions('shareFile', windowDimension);
                setWindowDimension((prevState) => {
                    const newState = {...prevState};
                    newState.width = ref.style.width;
                    newState.height = ref.style.height;
                    return newState;
                });
            }}
        >

            <div className="panel-header">

                <PanelTitle
                    title="Share Files"
                    hidePanel={hidePanel}
                />
            </div>

            <div className={"panel-body"} style={{overflowX: "hidden"}}>
                <ShareFile {...props} />
            </div>

        </Rnd>
    )
}

const mapStateToProps = (state) => {


    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareFilePanel);