import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Bar} from "react-chartjs-2";

class HeartRate extends Component {

    render () {

        let data = {
            labels: ["Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Today"],
            datasets: [{
                data: [[68, 130], [67, 131], [74, 147], [89, 145], [58, 115], [26, 118], [51, 159]],
                backgroundColor: '#f22c72',
                barPercentage: 0.5,
                maxBarThickness: 10,
                borderRadius: 5, // will work in chart.js 3, but that's still not react compatible
            }],
        };

        let options = {
            animation: false,
            spanGaps: true,
            maintainAspectRatio: true,
            responsive: true,
            legend: {display: false},
            tooltips: {enabled: false},
            annotation: {display: false},
            plugins: {datalabels: false},
            scales: {
                xAxes: [{
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999'}
                }],
                yAxes: [{
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999', min: 0, max: 200, stepSize: 100},
                    position: 'right',
                }],
            },
        }

        return  (
            <div className="activity">

                <h5 className="activity-title activity-title-magenta">
                    <i className="fa fa-fw fa-heartbeat" />
                    Heart Rate
                </h5>

                <div className="activity-chart">

                    <Bar data={data} options={options} />

                </div>

                <div className="activity-stats">
                    <h6 className="activity-stats-metric">Range</h6>
                    <p className="activity-stats-value"><span>40-169</span> bpm</p>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HeartRate);