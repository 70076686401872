import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";

export const CLEAN_NOTES = createRequestTypes('CLEAN_NOTES');
export const LOAD_NOTES = createRequestTypes('LOAD_NOTES');
export const CREATE_NOTE = createRequestTypes('CREATE_NOTE');
export const UPDATE_NOTE = createRequestTypes('UPDATE_NOTE');
export const DELETE_NOTE = createRequestTypes('DELETE_NOTE');

export const cleanNotesData = () => async (dispatch) => {
    dispatch(actionCreator.clear(CLEAN_NOTES));
}

export const loadNotesByPid = (pid) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_NOTES));
    try {
        const response = await axiosInstance.get(`/api/note/patient/` + pid);
        dispatch(actionCreator.success(LOAD_NOTES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_NOTES, mockError));
        return err.response;
    }
}

export const createNote = (newNoteRequest) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_NOTE));
    try {
        const response = await axiosInstance.post(`/api/note`, {...newNoteRequest});
        dispatch(actionCreator.success(CREATE_NOTE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(CREATE_NOTE, mockError));
        return err.response;
    }
}

export const updateNote = (id, payload) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_NOTE));
    try {
        const response = await axiosInstance.patch(`/api/note/${id}`, payload);
        dispatch(actionCreator.success(UPDATE_NOTE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(UPDATE_NOTE, mockError));
        return err.response;
    }
}

export const deleteNote = (id) => async (dispatch) => {
        dispatch(actionCreator.request(DELETE_NOTE));
        try {
            const response = await axiosInstance.delete(`/api/note/${id}`);
            dispatch(actionCreator.success(DELETE_NOTE, response.data));
            return response.data;
        } catch (err) {
            let mockError = getErrorMessage(err.response);
            dispatch(actionCreator.failure(DELETE_NOTE, mockError));
            return err.response;
        }
}

export const loadNotesByPidAndContextLike = (pid, context) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_NOTES));
    try {
        const response = await axiosInstance.get(`/api/note/patient/` + pid + `/context/` + context);
        dispatch(actionCreator.success(LOAD_NOTES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_NOTES, mockError));
        return err.response;
    }
}