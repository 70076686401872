import {SHOW_CONFIRM, HIDE_CONFIRM, CLEAR_CONFIRM} from '../actions/confirm.actions';

const initialState = {
    show: false,
    content: null,
    options: {},
}

export const confirmReducer = (state = initialState, action)  => {
    switch(action.type){
        case SHOW_CONFIRM:
            return {
                ...state,
                show: true,
                content: action.content,
                options: action.options,
            };
        case HIDE_CONFIRM:
            return {
                ...state,
                show: false,
            };
        case CLEAR_CONFIRM:
            return initialState;
        default:
            return state;
    }
}