import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {loadPatientCalendar} from "../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../utils/selectors";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarEvent from './Cards/CalendarEvent'
import Loader from 'react-spinners/BeatLoader';
import withRouter from "../../wrappers/withRouter";

class PatientDataCalendar extends Component {

    state = {
        calendarDate: new Date(),
        calendarEvents: [],
        calendarFilters: {
            startDate: null,
            endDate: null,
            includeLab: true,
            includeImaging: true,
            includeIntervention: true,
            includePRO: true,
            includeMDComments: true,
            includeHospitalVisits: true,
            includeDrNotes: true,
        },
        isFiltersVisible: false,
        selectedEvent: null,
    }

    componentDidMount() {

        this.props.loadPatientCalendar(this.props.router.params.id, this.state.calendarFilters).then(() => {
            this.buildEvents();
        });

        // Calendar Popup observer
        window["eventObserver"] = new MutationObserver(function (mutations) {

            mutations.filter(mutation => mutation.type === 'childList').map(mutation => {

                // If events popup has been hidden
                if (mutation.removedNodes[0]) {
                    if (mutation.removedNodes[0].classList) {
                        if (mutation.removedNodes[0].classList.value === "rbc-overlay visible") {

                            // Hide selected event
                            this.setState({selectedEvent: null})
                        }
                    }
                }
            })
        }.bind(this));

        // Connect observer
        window["eventObserver"].observe(document, {childList: true, subtree: true});
    }

    componentWillUnmount() {

        // Disconnect observer
        window["eventObserver"].disconnect();
    }

    buildEvents = () => {
        const {patientEvents} = this.props;

        // Hide selected event
        this.setState({selectedEvent: null})

        let events = [];
        let eventId = 0;
        let eventTime = "";
        let eventDescription = "";

        let combinedEventsType = "";
        let combinedEventsTime = "";
        let combinedEventsInstitution = "";
        let combinedEventsDescription = [];

        let patientEventsSorted = {};
        let patientEventsCombined = {};

        // Categorise source events
        patientEvents.map(event => {

            // Generate event date
            let eventDate = moment(event.time).format("YYYY-MM-DD");

            // Generate event category
            let eventCategory = event.actionName === 'imaging' ? 'imaging' :
                                    event.actionName === 'drug_therapy' ? 'intervention' :
                                        event.actionName === 'radiation_therapy' ? 'intervention' :
                                            event.actionName === 'surgery' ? 'intervention' :
                                                event.actionName === 'blood_test' ? 'lab' :
                                                    event.actionName === 'genomic_information' ? 'lab' :
                                                        event.actionName === 'pathology_report' ? 'lab' :
                                                            event.actionName === 'dr_note' ? "md" :
                                                                event.actionName;

            // Add array for this date if not present
            if (eventDate && !patientEventsSorted.hasOwnProperty(eventDate)) {
                patientEventsSorted[eventDate] = {};
                patientEventsCombined[eventDate] = {};
            }

            // Add array for this category if not present
            if (eventDate && eventCategory && !patientEventsSorted[eventDate].hasOwnProperty(eventCategory)) {
                patientEventsSorted[eventDate][eventCategory] = [];
            }

            // Add events with time to this date & category
            if (event.time) {
                patientEventsSorted[eventDate][eventCategory].push(event);
            }
        });

        // Generate events information
        Object.keys(patientEventsSorted).map((date, i) => {
            Object.keys(patientEventsSorted[date]).map((category, j) => {

                // Process Lab / Intervention categories
                if (category === 'lab' || category === 'intervention') {

                    combinedEventsType = "";
                    combinedEventsTime = "";
                    combinedEventsInstitution = "";
                    combinedEventsDescription = [];

                    patientEventsSorted[date][category].map((event) => {

                        // Combine Blood Test events into one
                        if (event.actionName === 'blood_test') {
                            combinedEventsType = event.actionName;
                            if (combinedEventsTime === "")
                                combinedEventsTime = event.time;
                            if (combinedEventsInstitution === "")
                                combinedEventsInstitution = event.institutionName;

                            combinedEventsDescription.push(event.analyte ? event.analyte : "Blood test");
                        }

                        // Combine Drug Therapy events into one
                        else if (category === 'intervention' && event.actionName === "drug_therapy") {
                            combinedEventsType = event.actionName;
                            if (combinedEventsTime === "")
                                combinedEventsTime = event.time;
                            if (combinedEventsInstitution === "")
                                combinedEventsInstitution = event.institutionName;

                            combinedEventsDescription.push(event.drugName ? event.drugName : "Drug Therapy");
                        }

                        // Combine Genomic Information events into one
                        else if (category === 'lab' && event.actionName === "genomic_information") {

                            combinedEventsType = event.actionName;
                            if (combinedEventsTime === "")
                                combinedEventsTime = event.time;
                            if (combinedEventsInstitution === "")
                                combinedEventsInstitution = event.institution ? event.institution.name : '';

                            combinedEventsDescription.push(
                                eventDescription =
                                    (event.findingsSource ? event.findingsSource : 'Site N/A') +
                                    ", " + (event.analyte ? event.analyte : 'Analyte N/A') +
                                    ", " + (event.value ? event.value : 'Value N/A')
                            );
                        }

                        // Add other events one by one
                        else {

                            if (event.actionName === 'pathology_report') {
                                eventDescription = 'PATH' +
                                    ", [Specimen date: " + (event.specimen_date ? event.specimen_date : 'N/A') + "]" +
                                    ", " + (event.pathologySite ? event.pathologySite : 'Site N/A') +
                                    ", " + (event.procedure ? event.procedure : 'Procedure N/A') +
                                    ", " + (event.diagnosis ? event.diagnosis : 'Diagnosis N/A');
                            }
                            else if (event.actionName === 'genomic_information') {
                                eventDescription = 'GEN' +
                                    ", " + (event.institution ? event.institution.name : 'Institution N/A') +
                                    ", " + (event.findings_source ? event.findings_source : 'Site N/A') +
                                    ", " + (event.analyte ? event.analyte : 'Analyte N/A') +
                                    ", " + (event.value ? event.value : 'Value N/A');
                            }
                            else if (event.actionName === 'radiation_therapy') {
                                eventDescription = event.institutionName || "";
                            }
                            else if (event.actionName === 'surgery') {
                                eventDescription = event.institutionName || "";
                            }

                            // Add event to array
                            events.push({
                                id: eventId,
                                start: event.time,
                                end: event.time,
                                category: category,
                                title:
                                    (event.actionName === 'pathology_report' ? 'Pathology'
                                        : event.actionName === 'genomic_information' ? 'Genomic'
                                            : event.actionName === 'surgery' ? 'Surgery'
                                                : ''),
                                description: eventDescription,
                                originalEvent: event,
                            });

                            // Increment event id
                            eventId++;
                        }
                    });

                    // Add combined event to array
                    events.push({
                        id: eventId,
                        start: combinedEventsTime,
                        end: combinedEventsTime,
                        category: category,
                        title:
                            (combinedEventsType === 'blood_test' ? 'Blood test'
                                : combinedEventsType === 'genomic_information' ? 'Genomic'
                                    : category === 'intervention' ? 'Intervention'
                                        : ''
                            ),
                        description: combinedEventsDescription,
                        institution: combinedEventsInstitution,
                    });

                    // Increment event id
                    eventId++;
                }

                // Process other categories
                else {

                    patientEventsSorted[date][category].map((event) => {

                        // Add event to array
                        events.push({
                            id: eventId,
                            start: event.time,
                            end: event.time,
                            category: category,
                            title:
                                category === 'imaging' ? "Imaging"
                                    : category === 'md' ? "Dr. Appointment"
                                        : event.actionName === 'radiation_therapy' ? (
                                            (event.radiationType ? event.radiationType : "Radiation therapy") +
                                            (event.institutionName ? ", " + event.institutionName : "")
                                        ) :
                                        event.actionName === 'surgery' ? (
                                                (event.type ? event.type : "Surgery") +
                                                (event.institutionName ? ", " + event.institutionName : "")
                                            ) :
                                            '',
                            description:
                                category === 'imaging' ? (
                                        event.institutionName ? event.institutionName : ""
                                    ) :
                                    category === 'md' ? (
                                            (event.institutionName ? event.institutionName : "") +
                                            ((event.institutionName && event.reason) ? ", " : "") +
                                            (event.reason ? event.reason : "")
                                        ) :
                                        event.actionName === 'drug_therapy' ? (
                                                (event.institutionName ? event.institutionName : "") +
                                                ((event.institutionName && event.reason) ? ", " : "") +
                                                (event.reason ? event.reason : "")
                                            ) :
                                            event.actionName === 'radiation_therapy' ? (
                                                    (event.institutionName ? event.institutionName : "") +
                                                    ((event.institutionName && event.reason) ? ", " : "") +
                                                    (event.reason ? event.reason : "")
                                                ) :
                                                event.actionName === 'surgery' ? (
                                                        (event.institutionName ? event.institutionName : "") +
                                                        ((event.institutionName && event.reason) ? ", " : "") +
                                                        (event.reason ? event.reason : "")
                                                    ) :
                                                    '',
                            originalEvent: event,
                        });

                        // Increment event id
                        eventId++;
                    });
                }
            })
        });

        this.setState({calendarEvents: events});
    }

    showHideFilters = (hide) => {
        if (hide === "hide")
            this.setState({selectedEvent: null, isFiltersVisible: false})
        else
            this.setState((prevState) => ({selectedEvent: null, isFiltersVisible: !prevState.isFiltersVisible}))
    }

    handleFilterChange = (filter) => {
        let updatedFilters = this.state.calendarFilters;
        updatedFilters[filter] = !updatedFilters[filter];

        this.setState({selectedEvent: null, calendarFilters: updatedFilters});

        this.props.loadPatientCalendar(this.props.router.params.id, updatedFilters).then(() => {
            this.buildEvents();
        });
    }

    handleChangeDate = (direction) => {
        const {calendarDate} = this.state;

        // Hide selected event
        this.setState({selectedEvent: null})

        // Previous month
        if (direction === "prev") {
            this.setState({calendarDate: moment(calendarDate).add(-1, 'month').toDate()})
        }

        // Next month
        if (direction === "next") {
            this.setState({calendarDate: moment(calendarDate).add(1, 'month').toDate()})
        }
    }

    handleShowHideEvent = (event) => {
        this.setState({selectedEvent: this.state.selectedEvent === event ? null : event})
    }

    handleShowPopup = (events, date) => {

        // Hide selected event
        this.setState({selectedEvent: null})

        // Get popup element
        let popupContaner = document.querySelector(".rbc-overlay");

        // If popup is found
        if (popupContaner) {

            // Get all displayed events for this date
            let displayedEvents = document.querySelectorAll(".rbc-calendar .rbc-event.start-"+moment(events[0].start).format("YYYY-MM-DD"));

            // Remove them from the popup
            displayedEvents.forEach((displayedEvent) => {
                displayedEvent.classList.forEach((className) => {
                    if (className.startsWith('id-')) {

                        let duplicateElement = document.querySelector(".rbc-overlay .rbc-event.id-"+className.substring(3));

                        if (duplicateElement) {
                            duplicateElement.remove()
                        }
                    }
                })
            });

            // Display popup
            popupContaner.classList.add('visible');
        }
        else {

            // Try again
            setTimeout(() => {
                this.handleShowPopup(events, date);
            }, 0)
        }
    }

    render () {

        const {
            calendarDate,
            calendarEvents,
            calendarFilters,
            isFiltersVisible,
            selectedEvent
        } = this.state;

        const {
            patientEvents,
            patientEventsLoading,
            isPanelVisible,
        } = this.props;

        return  (
            <div className={"layout-patient layout-patient-calendar" + (isPanelVisible ? " layout-patient-action-menu-open" : "")}>
                <div className="calendar">

                    <div className="calendar-toolbar">

                        <div>

                            <button type="button" className="btn btn-primary btn-sm btn-round">
                                <i className="far fa-calendar-plus" /> New Event
                            </button>

                            <div className="calendar-dropdown">

                                <button
                                    type="button"
                                    className="btn btn-link btn-sm"
                                    onClick={this.showHideFilters}
                                    onBlur={() => this.showHideFilters('hide')}
                                >
                                    Filters <i className={"fas" + (isFiltersVisible ? " fa-angle-up" : " fa-angle-down")} />
                                </button>

                                {isFiltersVisible &&
                                <div className="calendar-filters">
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeLab ? " btn-purple" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeLab")}
                                    >
                                        Lab
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeImaging ? " btn-success" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeImaging")}
                                    >
                                        Imaging
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeIntervention ? " btn-red" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeIntervention")}
                                    >
                                        Intervention
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includePRO ? " btn-info" : "")}
                                        onMouseDown={() => this.handleFilterChange("includePRO")}
                                    >
                                        PRO
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeMDComments ? " btn-red-dark" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeMDComments")}
                                    >
                                        Dr. Appointment
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeHospitalVisits ? " btn-dark" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeHospitalVisits")}
                                    >
                                        Hospital Visits
                                    </button>
                                    <button type="button"
                                            className={"btn btn-xs btn-round" + (calendarFilters.includeErVisits ? " btn-danger" : "")}
                                            onMouseDown={() => this.handleFilterChange("includeErVisits")}
                                    >
                                        Hospital Visits
                                    </button>
                                    <button type="button"
                                        className={"btn btn-xs btn-round" + (calendarFilters.includeDrNotes ? " btn-red-dark" : "")}
                                        onMouseDown={() => this.handleFilterChange("includeDrNotes")}
                                    >
                                        Doctor Notes
                                    </button>
                                </div>
                                }

                            </div>

                        </div>

                        <div className="calendar-legend">

                            <button
                                className="calendar-legend-button"
                                disabled={patientEventsLoading}
                                onClick={() => this.handleChangeDate("prev")}
                            >
                                <i className="fas fa-angle-left"/>
                            </button>

                            <div className="calendar-legend-date">

                                <h6 className="calendar-legend-month">
                                    {moment(calendarDate).format("MMMM")}
                                </h6>

                                <p className="calendar-legend-year">
                                    {moment(calendarDate).format("YYYY")}
                                </p>

                            </div>

                            <button
                                className="calendar-legend-button"
                                disabled={patientEventsLoading}
                                onClick={() => this.handleChangeDate("next")}
                            >
                                <i className="fas fa-angle-right"/>
                            </button>

                        </div>

                    </div>

                    {patientEventsLoading
                    ?
                    <div className="calendar-loader">
                        <Loader size={10} color={"#32383e"}/>
                    </div>
                    :
                    <div className="calendar-container">

                        <Calendar
                            localizer={momentLocalizer(moment)}
                            date={calendarDate}
                            events={calendarEvents}
                            eventPropGetter={event => ({
                                className: ''
                                    + event.category.toLowerCase()
                                    + (" id-" + event.id)
                                    + (" start-" + moment(event.start).format("DD-MMM-YYYY"))
                                    + (" end-" + moment(event.end).format("DD-MMM-YYYY"))
                                    + (selectedEvent && selectedEvent.id === event.id ? " active" : "")
                            })}
                            toolbar={false}
                            popup={true}
                            views={['month']}
                            drilldownView={false}
                            onShowMore={(patientEvents, date) => {this.handleShowPopup(patientEvents, date)}}

                            // onNavigate={isLoading && this.setState({isLoading: false})}

                            onSelectEvent={event => this.handleShowHideEvent(event)}
                            // onSelectSlot={slotInfo => this.handleSelectDate(slotInfo)}
                            // selectable={true}
                        />

                        {selectedEvent &&
                        <CalendarEvent
                            selectedEvent={selectedEvent}
                            handleShowHideEvent={this.handleShowHideEvent}
                        />
                        }

                    </div>
                    }

                </div>
            </div>
        )
    }
}

const patientEventsLoader = createLoadingSelector(['LOAD_PATIENT_CALENDAR']);

const mapStateToProps = (state) => {

    const
        patientEvents = getPatientsData(state).patientCalendar.actions,
        patientEventsLoading = patientEventsLoader(state);

    return {
        patientEvents,
        patientEventsLoading,
    };
};

const mapDispatchToProps = {
    loadPatientCalendar,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDataCalendar));