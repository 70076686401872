import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const CLEAN_CHAT_DATA = createRequestTypes('CLEAN_CHAT_DATA');
export const LOAD_CHAT_CONTACTS = createRequestTypes('LOAD_CHAT_CONTACTS');
export const LOAD_CONTACT_MESSAGES = createRequestTypes('LOAD_CONTACT_MESSAGES');

export const cleanChatData = () => async (dispatch) => {
    dispatch(actionCreator.clear(CLEAN_CHAT_DATA));
}

export const loadChatContacts = () => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_CHAT_CONTACTS));
    try {
        const response = await axiosInstance.get(`/api/chat/contacts`);
        dispatch(actionCreator.success(LOAD_CHAT_CONTACTS, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_CHAT_CONTACTS, mockError));
        return err.response;
    }
}

export const loadContactMessages = (systemUserId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_CONTACT_MESSAGES));
    try {
        const response = await axiosInstance.get(`/api/chat/messages/${systemUserId}`);
        dispatch(actionCreator.success(LOAD_CONTACT_MESSAGES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_CONTACT_MESSAGES, mockError));
        return err.response;
    }
}
