import React, {useCallback, useEffect, useMemo} from "react";

const CheckboxCell = (props) => {
    const { value, setValue, column, row, error, errors } = props;

    const disabled = useMemo(() => {
        if (typeof column.disabled === 'function') {
            return column.disabled({row, errors});
        }

        return !!column.disabled;
    }, [column, row, errors]);

    const handleOnClick = useCallback(() => {
        if (disabled) {
            return;
        }

        setValue(!value);
    }, [setValue, value, disabled]);

    return (
        <>
            <div className="form-check text-center">
                <input
                    className="custom-control-input cursor-pointer"
                    checked={value}
                    type="checkbox"
                    onClick={handleOnClick}
                    disabled={disabled}
                />
            </div>
            {error && (
                <div className="invalid-feedback text-center">{error}</div>
            )}
        </>
    );
}

export default CheckboxCell;