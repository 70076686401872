
let initialState = {
    forms: [],
};

export const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
