import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const LOAD_FILTERS = createRequestTypes('LOAD_FILTERS');

export const saveTaskFilters = (data) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_FILTERS));
    try {
        dispatch(actionCreator.success(LOAD_FILTERS, data));

    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_FILTERS, mockError));
        return err.response;
    }
}

export const clearTaskFilters = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_FILTERS));
}