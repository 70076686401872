import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {login} from '../../actions/auth.actions';
import {getAuthData, getErrorMessage} from "../../utils/selectors";
import {closeDocuments} from "../../utils/document-utils";
import {useLocation, useNavigate} from "react-router-dom";
import useSteps from "../../hooks/useSteps";
import AuthenticationStep from "./LoginSteps/AuthenticationStep";
import CodeStep from "./LoginSteps/CodeStep";
import {toast} from "react-toastify";
import _ from "lodash";
import axiosInstance from "../../utils/axios-instance";
import MessageStep from "./Common/MessageStep";

const STEP = {
    AUTH: 'AUTH',
    CODE: 'CODE',
    ERROR: 'ERROR',
}

const Login = ({isLoggedIn, login}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [authError, setAuthError] = useState(null);

    const [step, stepState, prevStep, setStep] = useSteps(STEP.AUTH, {
        [STEP.AUTH]: {}
    });

    const handleOnLogin = useCallback(async (username, password) => {
        try {
            const resp = await axiosInstance.post("/api/login", {
                username,
                password,
                source: 'PROFESSIONAL'
            });
            const {message: token, userId, authorities, personalPreferences} = resp.data;

            if (userId) {
                login(userId, authorities, personalPreferences);
            } else {
                setStep(STEP.CODE, {codeToken: token});
            }
        } catch (error) {
            if (error.response) {
                const resp = error.response;
                if (resp.status === 401) {
                    setAuthError(_.get(resp, 'data.errorMessage', 'Invalid username or password'));
                }
            }

            toast.error(error.message);
        }
    }, [login]);

    const handleOnCodeStepSuccess = useCallback(async (authToken) => {
        try {
            const resp = await axiosInstance.post("/api/login", { token: authToken });
            const { userId, authorities , personalPreferences} = resp.data;

            login(userId, authorities, personalPreferences);
        } catch (error) {
            toast.error(error.message);
        }
    }, [login]);

    const handleOnCodeStepError = useCallback((error) => {
        toast.error(error.message);
        setStep(STEP.ERROR, {
            errorMessage: _.get(error, 'response.data.errorMessage', 'Unexpected error'),
        });
    }, []);

    useEffect(() => {
        closeDocuments();

        if (isLoggedIn === true) {
            navigate('/');
        }
    }, [])

    useEffect(() => {
        if (isLoggedIn === true) {
            if (location.state && location.state.from) {
                navigate(location.state.from);
            } else {
                navigate('/');
            }
        }
    }, [isLoggedIn]);

    switch (step) {
        case STEP.AUTH:
            return <AuthenticationStep error={authError} onLogin={handleOnLogin} />;
        case STEP.CODE:
            return <CodeStep codeToken={stepState.codeToken} onError={handleOnCodeStepError} onSuccess={handleOnCodeStepSuccess} />;
        case STEP.ERROR:
            return (
                <MessageStep
                    title="Login"
                    description={
                        <p className="text-center">
                            <span className="text-danger">{stepState.errorMessage}</span><br/>
                            Please contact info@n1x10.com for assistance in login.
                        </p>
                    }
                />
            )
        default:
            return null;
    }
}

const getAuthError = getErrorMessage(['AUTH']);

const mapStateToProps = (state) => {
    const authErrorMessage = getAuthError(state);
    const isLoggedIn = getAuthData(state).isLoggedIn;

    return {
        authErrorMessage,
        isLoggedIn,
    };
};

const mapDispatchToProps = {
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);