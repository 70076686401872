import moment from "moment";
import _ from "lodash";
import cronstrue from "cronstrue";

export const taskTypes = {
    overdue: {
        type: "upcoming",
        label: "OVERDUE",
        text: task => {
            return "Overdue " + moment(task.dueDate).format("D-MMM") + " " + ((task.recurrent && !_.isEmpty(task.recurrentCrontab))?
                cronstrue.toString(task.recurrentCrontab, { verbose: true }) : "")
        },
        transform: tasks => {
            const filteredTasks = [];

            tasks.forEach( task => {
                if (_.isEmpty(task.recurrentTaskId) || !_.find(filteredTasks, {recurrentTaskId: task.recurrentTaskId})) {
                    filteredTasks.push(task);
                }
            });

            return filteredTasks;
        }
    },
    today: {
        type: "upcoming",
        label: "DUE TODAY",
        text: task => "Due today " + ((task.recurrent)? "" : moment(task.dueDate).format("hh:mm aa")) + " " + ((task.recurrent && !_.isEmpty(task.recurrentCrontab))?
            cronstrue.toString(task.recurrentCrontab, { verbose: true }) : "")
    },
    upcoming: {
        type: "upcoming",
        label: "UPCOMING",
        text: task => "Upcoming " + moment(task.dueDate).format("D-MMM") + " " + ((task.recurrent && !_.isEmpty(task.recurrentCrontab))?
            cronstrue.toString(task.recurrentCrontab, { verbose: true }) : ""),
        transform: tasks => _.orderBy(tasks, ["dueDate"], ["asc"])
    },
    done: {
        type: "done",
        value: "",
        text: task => "Completed: " + moment(task.completedDate).format("D-MMM-y"),
        transform: tasks => _.orderBy(tasks, ["completedDate"], ["desc"])
    }
}

export const taskFilters = {
    upcoming: {
        label: "New",
        active: props => props.patientTaskFilters.showOnlyNew,
        filter: (props, tasks) => {
            const filteredTasks = Object.assign({}, tasks);

            Object.entries(filteredTasks)
                .forEach(([key, value]) =>
                    filteredTasks[key] = value.filter(task => task.isNew))

            return filteredTasks;
        },
        handler: props => props.handleShowOnlyNew
    },
    done: {
        label: "Created by Me",
        active: props => props.patientTaskFilters.showCreatedByMe,
        filter: (props, tasks) => {
            const filteredTasks = Object.assign({}, tasks);

            Object.entries(filteredTasks)
                .forEach(([key, value]) =>
                    filteredTasks[key] = value.filter(task => task.completed && task.createdBy.id === props.userId))

            return filteredTasks;
        },
        handler: props => props.handleShowCreatedByMe
    }
}

export const taskAssigneesOrder = {
    PATIENT: 5,
    LA: 4,
    OA: 3,
    BACK_OFFICE: 2,
    PATIENT_MANAGER: 1,
    ADMINISTRATOR: 0
}