import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {loadPatientReport} from "../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../utils/selectors";
import PatientInfo from "./Cards/PatientInfo";
import ReportFilters from "./Cards/ReportFilters";
import ReportItem from "./Cards/ReportItem";
import Loader from "react-spinners/BeatLoader";
import isEmpty from "../../utils/helpers";
import {useParams} from "react-router-dom";

const PatientDataReport = (
    {
        loadPatientReport,
        patientReport,
        patientReportLoading,
        isPanelVisible,
        patientInfo,
    }) => {

    const [filters, setFilters] = useState(
        {
            startDate: null,
                endDate: null,
            includeLab: true,
            includeImaging: true,
            includeIntervention: true,
            includePRO: true,
            includeMDComments: true,
            includeHospitalVisits: true,
            includeErVisits: true,
        }
    );

    const { id: patientId } = useParams();

    useEffect(() => {
        loadPatientReport(patientId, filters);
    }, []);

    const handleFilterChange = useCallback((filter) => {
        const updatedFilters = {...filters}
        updatedFilters[filter] = !updatedFilters[filter];

        setFilters(updatedFilters);
        loadPatientReport(patientId, updatedFilters);
    }, [loadPatientReport, patientId, filters]);

    return  (
        <>
            <div className={"layout-patient layout-patient-timeline" + (isPanelVisible ? " layout-patient-action-menu-open" : "")}>
                <div className="row">
                    <div className="col col-12 col-lg-3">

                        <PatientInfo
                            showDetails={false}
                            patientInfo={patientInfo}
                        />

                    </div>
                    <div className="col col-12 col-lg-9">

                        <ReportFilters
                            isLoading={patientReportLoading}
                            filters={filters}
                            handleFilterChange={handleFilterChange}
                        />

                    </div>
                </div>
                <div className="report">

                    {patientReportLoading || isEmpty(patientReport)
                    ?
                    <div className="report-loader">
                        <Loader size={10} color={"#32383e"}/>
                    </div>
                    :
                    <div className="report-container">

                        {Object.keys(patientReport).map(key => (
                            <ReportItem
                                key={key}
                                date={key}
                                actions={patientReport[key]}
                            />
                        ))}

                    </div>
                    }

                </div>
            </div>
        </>
    )
}

const patientReportLoader = createLoadingSelector(['LOAD_PATIENT_REPORT']);

const mapStateToProps = (state) => {
    const patientReport = getPatientsData(state).patientReport.actionsMap;
    const patientReportLoading = patientReportLoader(state);

    return {
        patientReport,
        patientReportLoading,
    };
};

const mapDispatchToProps = {
    loadPatientReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataReport);