import React, {Component} from 'react';
import isEmpty from "../../../utils/helpers";
import Contact from "./Contact";

class Contacts extends Component {

    render() {

        const {
            type,
            contacts,
        } = this.props;

        return (
            <>

                {!isEmpty(contacts) &&
                <div className="messages-contacts">

                    <h5 className="messages-contacts-type">{type}</h5>

                    {contacts.map(contact =>
                    <Contact
                        key={contact.contactId}
                        contact={contact}
                        {...this.props}
                    />
                    )}

                </div>
                }

            </>
        )
    }
}

export default Contacts;
