import axios from 'axios';
import {API_ROOT} from './api-config';
import store from "../store";
import {logout} from "../actions/auth.actions";

const createAxiosInstance = (props = {}) => {
	return axios.create({
		paramsSerializer(params) {
			const searchParams = new URLSearchParams();
			for (const key of Object.keys(params)) {
				const param = params[key];
				if (Array.isArray(param)) {
					for (const p of param) {
						searchParams.append(key, p);
					}
				} else {
					searchParams.append(key, param);
				}
			}
			return searchParams.toString();
		},
		baseURL: `${API_ROOT}`,
		timeout: 5 * 60 * 1000,
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		...props,
	});
}

const initAxiosInstance = (axiosInstance) => {
	axiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response && (error.response.status === 401 || error.response.status === 403)) {
				store.dispatch(logout());
			}

			return Promise.reject(error);
		}
	);
}

const axiosInstance = createAxiosInstance();
initAxiosInstance(axiosInstance);

const axiosPublicInstance = createAxiosInstance({withCredentials: false});
initAxiosInstance(axiosPublicInstance);

export {
	axiosPublicInstance
}

export default axiosInstance;