import React, {useCallback, useMemo} from "react";
import {connect} from "react-redux";
import * as Yup from 'yup';
import NixioTable, {
    CheckboxCell,
    CreatableSelectCell,
    EditableCell,
    SelectCell,
    SelectColumnFilter
} from "../../Table/NixioTable";
import _ from "lodash";
import {value} from "lodash/seq";
import {getTimezoneIds} from "../../../utils/timezone-utils";
import {isEmpty, removeByIdx} from "../../../utils/helpers";
import {useFormik} from "formik";
import EditablePhoneCell from "../../Table/NixioTable/EditablePhoneCell";
import {isValidPhoneNumber} from "react-phone-number-input";

const CareProvidersEditor = ({careProviders: data, onSave, onCancel, loading}) => {

    const initialValues = useMemo(() => data || [], [data]);

    const handleOnSubmit = useCallback((values, {setSubmitting}) => {
        try {
            if (onSave) {
                onSave(values);
            }
        } catch(e) {
            alert("Unexpected error");
            console.error(e);
        } finally {
            setSubmitting(false);
        }
    }, [onSave]);

    const handleOnCancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: Yup.array()
            .of(
                Yup.object().notRequired().shape({
                    firstName: Yup.string().required("Required"),
                    lastName: Yup.string().required("Required"),
                    type: Yup.string().nullable(),
                    email: Yup.string().nullable().email("Invalid email"),
                    phone: Yup.string().nullable().test('phone-test', 'Invalid phone number', (value) => {
                        if (value === null || value === undefined) {
                            return true;
                        }

                        if (typeof value !== 'string') {
                            return false;
                        }

                        return isValidPhoneNumber(value);
                    }),
                    timezone: Yup.string().nullable(),
                    referringProvider: Yup.boolean().nullable(),
                })
            ),
        onSubmit: handleOnSubmit,
    });

    const careProviders = formik.values;

    const setCareProviders = useCallback((value) => {
        const newCareProviders = typeof value === 'function' ? value(careProviders) : value;
        formik.setValues(newCareProviders);
    }, [formik.setValues, careProviders]);

    const deleteRecord = useCallback((careProvider) => {
        setCareProviders(careProviders => removeByIdx(careProviders, careProvider.rowIdx));
    }, [setCareProviders]);

    const updateCareProviderValue = useCallback(({idx, columnId, value}) => {
        setCareProviders(careProviders => {
            if (!careProviders) {
                return careProviders;
            }

            const newCareProviders = [...careProviders];
            const newCareProvider = {...newCareProviders[idx]};

            newCareProvider[`${columnId}`] = value;
            newCareProviders[idx] = newCareProvider;

            return newCareProviders;
        });
    }, [setCareProviders]);

    const onAddNewCareProviderHandler = useCallback(() => {
        setCareProviders(careProviders => [{referringProvider: false}, ...(careProviders || [])]);
    }, [setCareProviders]);

    const typeSet = useMemo(() => {
        const baseTypes = ["PCP", "Oncologist"];
        const rsSet = new Set();
        for (const type of baseTypes) {
            rsSet.add(type);
        }

        if (careProviders) {
            for (const cp of careProviders) {
                rsSet.add(cp.type);
            }
        }

        return rsSet;
    }, [careProviders]);

    const typeOptions = useMemo(() => {
        const options = typeSet && Array.from(typeSet).map(r => ({value: r, label: r})) || [];
        return _.sortBy(options, opt => opt.value);
    }, [typeSet]);

    const timezoneOptions = useMemo(() => {
        return getTimezoneIds().map(t => ({value: t, label: t}));
    }, []);

    const customSort = useCallback((sortBy, sort) => {
        setCareProviders(cps => sort(cps));
    }, [setCareProviders]);

    const tableInitialState = useMemo(() => {
        return {
            sortBy: [
                {
                    id: 'firstName',
                    desc: false
                }
            ]
        }
    }, []);

    const ActionCell = useCallback(({row}) => {
        return (
            <div className="w-100 text-center">
                <div className="btn-layout-vertical">
                    {<button type="button" className="btn btn-rnd btn-danger " onClick={() => deleteRecord(row.original)}><i className="fas fa-times-circle p-0" aria-hidden="true"/></button>}
                </div>
            </div>
        );
    }, [deleteRecord]);

    const pocColumns = useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: EditableCell,
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: EditableCell,
        },
        {
            Header: 'Role',
            accessor: 'type',
            Cell: CreatableSelectCell,
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: EditableCell,
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            Cell: EditablePhoneCell,
        },
        {
            Header: 'Timezone',
            accessor: 'timezone',
            Cell: SelectCell,
            Filter: SelectColumnFilter,
        },
        {
            Header: 'Referring',
            accessor: 'referringProvider',
            disableFilters: true,
            Cell: CheckboxCell
        },
        {
            Header: 'Action',
            Cell: ActionCell,
            hideHeader: true,
            disableFilters: true,
        },
    ], [ActionCell]);

    return (
        <form>
            <div className="mt-4 mb-3">
                <label htmlFor="btnAddCareProvider">&nbsp;</label>
                <button
                    id="btnAddCareProvider"
                    name="btnAddCareProvider"
                    type="button"
                    className="btn btn-primary btn-rnd btn-save"
                    disabled={loading}
                    onClick={onAddNewCareProviderHandler}>
                    <i className="fas fa-plus"/>New Care Provider
                </button>
            </div>

            <div className="form-group mt-1">
                <NixioTable
                    columns={pocColumns}
                    data={careProviders}
                    initialState={tableInitialState}
                    loading={loading}
                    onPropertyUpdate={updateCareProviderValue}
                    errors={formik.errors}
                    typeOptions={typeOptions}
                    timezoneOptions={timezoneOptions}
                    customSort={customSort}
                />
            </div>

            <div className="text-end">
                <button
                    className="btn btn-secondary btn-rnd btn-cancel"
                    onClick={handleOnCancel}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary btn-rnd btn-save ms-1"
                    onClick={formik.handleSubmit}
                    disabled={isEmpty(careProviders) || formik.isSubmitting || !formik.isValid || loading}
                >
                    Save
                </button>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CareProvidersEditor);