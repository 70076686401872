import React, {useCallback} from 'react';
import MeetingAudioRecording from "../../AudioPlayer/MeetingAudioRecording";
import {getModalData} from "../../../utils/selectors";
import {connect} from "react-redux";
import PanelAction from "../../Panels/PanelAction";

const MeetingAudioRecordingModal = ({url, useAuth, closeModal}) => {
	const handleClose = useCallback(() => {
		closeModal();
	}, [closeModal]);

	return (
		<>
			<div className="modal-body">
				<p>
					<MeetingAudioRecording url={url} useAuth={useAuth} progressClass="progress-small" />
				</p>

				<button
					onClick={handleClose}
					className="btn btn-primary text-end"
				>
					Close
				</button>

			</div>
		</>
	);
}


const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps;

	return {
		...modalProps,
	};
}

const mapDispatchToProps = () => {
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingAudioRecordingModal);
