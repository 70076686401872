import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";
import ReactGA from "react-ga4";

export const LOAD_ALL_PATIENTS = createRequestTypes('LOAD_ALL_PATIENTS');
export const LOAD_POINTS_OF_CONTACT = createRequestTypes('LOAD_POINTS_OF_CONTACT');
export const LOAD_PATIENT_SERVICE = createRequestTypes('LOAD_PATIENT_SERVICE');
export const UPDATE_POINTS_OF_CONTACT = createRequestTypes('UPDATE_POINTS_OF_CONTACT');
export const LOAD_CARE_PROVIDERS = createRequestTypes('LOAD_CARE_PROVIDERS');
export const UPDATE_CARE_PROVIDERS = createRequestTypes('UPDATE_CARE_PROVIDERS');
export const LOAD_PATIENT_INFO = createRequestTypes('LOAD_PATIENT_INFO');
export const LOAD_PATIENT_FILES = createRequestTypes('LOAD_PATIENT_FILES');
export const LOAD_PATIENT_PHOTO = createRequestTypes('LOAD_PATIENT_PHOTO');
export const LOAD_PATIENT_DIAGNOSIS = createRequestTypes('LOAD_PATIENT_DIAGNOSIS');
export const LOAD_PATIENT_DIAGNOSIS_DETAILED = createRequestTypes('LOAD_PATIENT_DIAGNOSIS_DETAILED');
export const LOAD_PATIENT_MEDICAL_ID = createRequestTypes('LOAD_PATIENT_MEDICAL_ID');
export const LOAD_PATIENT_MEDICAL_ID_DETAILED = createRequestTypes('LOAD_PATIENT_MEDICAL_ID_DETAILED');
export const LOAD_PATIENT_PRO_DIALS = createRequestTypes('LOAD_PATIENT_PRO_DIALS');
export const LOAD_PATIENT_DISEASE_SUMMARY = createRequestTypes('LOAD_PATIENT_DISEASE_SUMMARY');
export const LOAD_PATIENT_DISEASE_SUMMARY_DETAILED = createRequestTypes('LOAD_PATIENT_DISEASE_SUMMARY_DETAILED');
export const LOAD_PATIENT_MOLECULAR = createRequestTypes('LOAD_PATIENT_MOLECULAR');
export const LOAD_PATIENT_MOLECULAR_DETAILED = createRequestTypes('LOAD_PATIENT_MOLECULAR_DETAILED');
export const LOAD_PATIENT_INTERVENTION = createRequestTypes('LOAD_PATIENT_INTERVENTION');
export const LOAD_PATIENT_CCM_NOTES = createRequestTypes('LOAD_PATIENT_CCM_NOTES');
export const LOAD_PATIENT_PATHOLOGY = createRequestTypes('LOAD_PATIENT_PATHOLOGY');
export const LOAD_PATIENT_PATHOLOGY_DETAILED = createRequestTypes('LOAD_PATIENT_PATHOLOGY_DETAILED');
export const LOAD_PATIENT_PDL1 = createRequestTypes('LOAD_PATIENT_PDL1');
export const LOAD_PATIENT_TREATMENT_TIMELINE = createRequestTypes('LOAD_PATIENT_TREATMENT_TIMELINE');
export const LOAD_PATIENT_DISEASE_TIMELINE = createRequestTypes('LOAD_PATIENT_DISEASE_TIMELINE');
export const LOAD_PATIENT_CALENDAR = createRequestTypes('LOAD_PATIENT_CALENDAR');
export const LOAD_PATIENT_REPORT = createRequestTypes('LOAD_PATIENT_REPORT');
export const LOAD_PATIENT_DOCUMENT = createRequestTypes('LOAD_PATIENT_DOCUMENT');
export const LOAD_AVAILABLE_TASK_ASSIGNEES = createRequestTypes('LOAD_PATIENT_USERS');
export const ADD_PATIENT_CCM_NOTE = createRequestTypes('ADD_PATIENT_CCM_NOTE');
export const CLEAN_PATIENT_DATA = createRequestTypes('CLEAN_PATIENT_DATA');
export const LOAD_PATIENT_REPORT_COUNT = createRequestTypes('LOAD_PATIENT_REPORT_COUNT');

export const cleanPatientData = () => async (dispatch) => {
    dispatch(actionCreator.clear(CLEAN_PATIENT_DATA));
}

export const loadAllPatients = (serviceId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_ALL_PATIENTS));
    try {
        const params = {};
        if (serviceId) {
            params.serviceId = serviceId;
        }

        const response = await axiosInstance.get('/api/patients', { params });
        dispatch(actionCreator.success(LOAD_ALL_PATIENTS, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_ALL_PATIENTS, mockError));
        throw err;
    }
}

export const clearAllPatients = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_ALL_PATIENTS));
}

export const loadPatientInfo = (patientId, serviceId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_INFO));
    try {
        const params = {app: 'PROFESSIONAL'};

        if (serviceId) {
            params.serviceId = serviceId;
        }

        const response = await axiosInstance.get(`/api/patient/${patientId}`, {params});
        dispatch(actionCreator.success(LOAD_PATIENT_INFO, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_INFO, mockError));
        return err.response;
    }
}

export const loadPatientService = (patientServiceId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_SERVICE));
    try {
        const response = await axiosInstance.get(`/api/patient-service/${patientServiceId}`);
        const patientService = response.data.service;

        dispatch(actionCreator.success(LOAD_PATIENT_SERVICE, patientService));
        return patientService;
    } catch (err) {
        const mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_SERVICE, mockError));
        return err;
    }
}

export const clearPatientService = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_PATIENT_SERVICE));
}

export const clearPointsOfContact = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_POINTS_OF_CONTACT));
}

export const loadPointsOfContact = (patientId) => async (dispatch) => {
    await clearPointsOfContact()(dispatch);

    dispatch(actionCreator.request(LOAD_POINTS_OF_CONTACT));
    try {
        const response = await axiosInstance.get(`/api/patient/${patientId}/points-of-contact`);

        dispatch(actionCreator.success(LOAD_POINTS_OF_CONTACT, response.data.pointsOfContact));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_POINTS_OF_CONTACT, mockError));
        return err.response;
    }
}

export const updatePointsOfContact = (patientId, pointsOfContact) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_POINTS_OF_CONTACT));
    try {
        const response = await axiosInstance.put(`/api/patient/${patientId}/points-of-contact`, pointsOfContact);

        dispatch(actionCreator.success(UPDATE_POINTS_OF_CONTACT, response.data));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err);
        dispatch(actionCreator.failure(UPDATE_POINTS_OF_CONTACT, mockError));
        throw err;
    }
}

export const clearCareProviders = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_CARE_PROVIDERS));
}

export const loadCareProviders = (patientId) => async (dispatch) => {
    await clearCareProviders()(dispatch);

    dispatch(actionCreator.request(LOAD_CARE_PROVIDERS));
    try {
        const response = await axiosInstance.get(`/api/patient/${patientId}/care-providers`);

        dispatch(actionCreator.success(LOAD_CARE_PROVIDERS, response.data.careProviders));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_CARE_PROVIDERS, mockError));
        return err.response;
    }
}

export const updateCareProviders = (patientId, careProviders) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_CARE_PROVIDERS));
    try {
        const response = await axiosInstance.put(`/api/patient/${patientId}/care-providers`, careProviders);

        dispatch(actionCreator.success(UPDATE_CARE_PROVIDERS, response.data));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(UPDATE_CARE_PROVIDERS, mockError));
        throw err;
    }
}

export const loadPatientFiles = (patientId, searchQuery) => async (dispatch) => {
    const params = {}

    if (searchQuery) {
        params.q = searchQuery;
    }

    ReactGA.event({
        category: "files-" + patientId,
        action: "search",
        label: searchQuery
    });
    dispatch(actionCreator.request(LOAD_PATIENT_FILES));
    try {
        const response = await axiosInstance.get(`/api/patient/${patientId}/files`, {params});

        const files = response.data && response.data.files;
        if (files) {
            for (const file of files) {
                file.searchQuery = searchQuery;
            }
        }

        dispatch(actionCreator.success(LOAD_PATIENT_FILES, files));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_FILES, mockError));
        return err.response;
    }
}

export const clearPatientFiles = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_PATIENT_FILES));
}


export const loadPatientPhoto = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_PHOTO));
    try {
        const response = await axiosInstance.get(`/api/patient/image/${patientId}`, {responseType: 'blob'});
        dispatch(actionCreator.success(LOAD_PATIENT_PHOTO, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_PHOTO, mockError));
        return err.response;
    }
}

export const loadPatientDiagnosis = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DIAGNOSIS));
    try {
        const response = await axiosInstance.get(`/api/diagnosis/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_DIAGNOSIS, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DIAGNOSIS, mockError));
        return err.response;
    }
}

export const loadPatientDiagnosisDetailed = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DIAGNOSIS_DETAILED));
    try {
        const response = await axiosInstance.get(`/api/diagnosis/second-layer/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_DIAGNOSIS_DETAILED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DIAGNOSIS_DETAILED, mockError));
        return err.response;
    }
}

export const loadPatientMedicalId = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_MEDICAL_ID));
    try {
        const response = await axiosInstance.get(`/api/medicalid_first/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_MEDICAL_ID, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_MEDICAL_ID, mockError));
        return err.response;
    }
}

export const loadPatientMedicalIdDetailed = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_MEDICAL_ID_DETAILED));
    try {
        const response = await axiosInstance.get(`/api/medicalid_second/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_MEDICAL_ID_DETAILED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_MEDICAL_ID_DETAILED, mockError));
        return err.response;
    }
}

export const loadPatientProDials = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_PRO_DIALS));
    try {
        const response = await axiosInstance.get(`/api/prodials/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_PRO_DIALS, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_PRO_DIALS, mockError));
        return err.response;
    }
}

export const loadPatientDiseaseSummary = (patientId, limit) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DISEASE_SUMMARY));
    try {
        const response = await axiosInstance.get(`/api/diseasesummary/${patientId}?limit=${limit || -1}`);
        dispatch(actionCreator.success(LOAD_PATIENT_DISEASE_SUMMARY, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DISEASE_SUMMARY, mockError));
        return err.response;
    }
}

export const loadPatientDiseaseSummaryDetailed = (patientId, limit) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DISEASE_SUMMARY_DETAILED));
    try {
        const response = await axiosInstance.get(`/api/diseasesummary/${patientId}?limit=-1`);
        dispatch(actionCreator.success(LOAD_PATIENT_DISEASE_SUMMARY_DETAILED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DISEASE_SUMMARY_DETAILED, mockError));
        return err.response;
    }
}

export const loadPatientMolecular = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_MOLECULAR));
    try {
        const response = await axiosInstance.get(`/api/molecularprofile/first-layer/${patientId}`, {params: {limit: 3}});
        dispatch(actionCreator.success(LOAD_PATIENT_MOLECULAR, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_MOLECULAR, mockError));
        return err.response;
    }
}

export const loadPatientMolecularDetailed = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_MOLECULAR_DETAILED));
    try {
        const response = await axiosInstance.get(`/api/molecularprofile/second-layer/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_MOLECULAR_DETAILED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_MOLECULAR_DETAILED, mockError));
        return err.response;
    }
}

export const loadPatientIntervention = (patientId, chemo, radiation, surgery) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_INTERVENTION));
    try {
        const response = await axiosInstance.get(`/api/intervention/${patientId}?chemo=${chemo}&radiation=${radiation}&surgery=${surgery}`);
        dispatch(actionCreator.success(LOAD_PATIENT_INTERVENTION, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_INTERVENTION, mockError));
        return err.response;
    }
}

export const loadPatientCcmNotes = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_CCM_NOTES));
    try {
        const response = await axiosInstance.get(`/api/ccmnotes/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_CCM_NOTES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_CCM_NOTES, mockError));
        return err.response;
    }
}

export const loadPatientPathology = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_PATHOLOGY));
    try {
        const response = await axiosInstance.get(`/api/pathologyreport_first/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_PATHOLOGY, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_PATHOLOGY, mockError));
        return err.response;
    }
}

export const loadPatientPathologyDetailed = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_PATHOLOGY_DETAILED));
    try {
        const response = await axiosInstance.get(`/api/pathologyreport_second/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_PATHOLOGY_DETAILED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_PATHOLOGY_DETAILED, mockError));
        return err.response;
    }
}

export const loadPatientPdl1 = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_PDL1));
    try {
        const response = await axiosInstance.get(`/api/pdl1/${patientId}`);
        dispatch(actionCreator.success(LOAD_PATIENT_PDL1, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_PDL1, mockError));
        return err.response;
    }
}

export const loadPatientTreatmentTimeline = (patientId, values) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_TREATMENT_TIMELINE));
    try {
        const response = await axiosInstance.post(`/api/treatment-timeline/${patientId}`, values);
        dispatch(actionCreator.success(LOAD_PATIENT_TREATMENT_TIMELINE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_TREATMENT_TIMELINE, mockError));
        return err.response;
    }
}

export const loadPatientDiseaseTimeline = (patientId, values) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DISEASE_TIMELINE));
    try {
        const response = await axiosInstance.post(`/api/disease-timeline/${patientId}`, values);
        dispatch(actionCreator.success(LOAD_PATIENT_DISEASE_TIMELINE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DISEASE_TIMELINE, mockError));
        return err.response;
    }
}

export const loadPatientCalendar = (patientId, values) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_CALENDAR));
    try {
        const response = await axiosInstance.post(`/api/calendar/${patientId}`, values);
        dispatch(actionCreator.success(LOAD_PATIENT_CALENDAR, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_CALENDAR, mockError));
        return err.response;
    }
}

export const loadPatientReport = (patientId, values) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_REPORT));
    try {
        const response = await axiosInstance.post(`/api/list/${patientId}`, values);
        dispatch(actionCreator.success(LOAD_PATIENT_REPORT, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_REPORT, mockError));
        return err.response;
    }
}

export const loadPatientDocument = (documentId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DOCUMENT));
    try {
        const response = await axiosInstance.get(`/api/file/download/${documentId}`, {responseType: 'blob'});
        dispatch(actionCreator.success(LOAD_PATIENT_DOCUMENT, response.data));
        return response;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DOCUMENT, mockError));
        return err.response;
    }
}

export const loadPatientDocumentByToken = (token) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_DOCUMENT));
    try {
        const response = await axiosInstance.get(`/api/file/token/${token}/download`, {responseType: 'blob'});
        dispatch(actionCreator.success(LOAD_PATIENT_DOCUMENT, response.data));
        return response;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_DOCUMENT, mockError));
        return err.response;
    }
}

export const addPatientCcmNote = (patientId, note) => async (dispatch) => {
    dispatch(actionCreator.request(ADD_PATIENT_CCM_NOTE));
    try {
        const response = await axiosInstance.post(`/api/ccmnote`, {patientId: patientId, details: note});
        dispatch(actionCreator.success(ADD_PATIENT_CCM_NOTE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(ADD_PATIENT_CCM_NOTE, mockError));
        return err.response;
    }
}

export const loadPatientReportCount = (patientId, startDate, endDate) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PATIENT_REPORT_COUNT));
    try {
        const response = await axiosInstance.get(`/api/report/patient/${patientId}/symptoms-count`, {params: {startDate: startDate.format("MM/DD/YYYY"), endDate: endDate.format("MM/DD/YYYY")}})
        dispatch(actionCreator.success(LOAD_PATIENT_REPORT_COUNT, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PATIENT_REPORT_COUNT, mockError));
        return err.response;
    }
}
