import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthData} from "../../utils/selectors";
import {useLocation, useNavigate} from "react-router-dom";
import _ from "lodash";
import EmailStep from "./ForgotPasswordSteps/EmailStep";
import EmailConfirmationStep from "./ForgotPasswordSteps/EmailConfirmationStep";
import MessageStep from "./Common/MessageStep";
import useSteps from "../../hooks/useSteps";
import {axiosPublicInstance} from "../../utils/axios-instance";

const STEP = {
    EMAIL: "EMAIL",
    EMAIL_CONFIRMATION: "EMAIL_CONFIRMATION",
    ERROR: "ERROR",
}

const ForgotPassword = ({isLoggedIn}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [step, stepState, prevStep, setStep] = useSteps(STEP.EMAIL, {
        [STEP.EMAIL]: {
            email: _.get(location, 'state.email', '')
        }
    });

    const issueForgotPassword = useCallback(async (email) => {
        await axiosPublicInstance.post("/api/forgot-password", {
            email: email,
            source: 'PROFESSIONAL'
        });
    }, []);

    const handleOnEmailStepSuccess = useCallback(async (email) => {
        try {
            await issueForgotPassword(email);

            setStep(STEP.EMAIL_CONFIRMATION, {
                email: email
            });
        } catch (error) {
            const errorCode = _.get(error, 'response.data.errorCode');

            let errorMessage;
            if (errorCode === 23) {
                errorMessage = "The email address you entered is not valid. Please check and try again."
            } else if (errorCode === 24) {
                errorMessage = "The email address you entered was not found in the system. Please check and try again. "
            } else {
                errorMessage = "Unexpected error";
            }
            console.error(error);
            setStep(STEP.ERROR, {error: errorMessage});
        }
    }, [setStep])

    switch (step) {
        case STEP.EMAIL:
            return <EmailStep email={stepState.email} onConfirm={handleOnEmailStepSuccess} />
        case STEP.EMAIL_CONFIRMATION:
            return <EmailConfirmationStep email={stepState.email} onResend={issueForgotPassword} />
        case STEP.ERROR:
            return (
                <MessageStep
                    title="Reset Password"
                    description={
                        <p className="text-center">
                            <span className="text-danger">{stepState.error || 'Unexpected error'}</span><br/>
                            Please contact info@n1x10.com for assistance in login.
                        </p>
                    }
                />
            )
        default:
            return null;
    }
}

ForgotPassword.propTypes = {
    email: PropTypes.string,
};

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;

    return {
        isLoggedIn,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);