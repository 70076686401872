import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import Select from "react-select";
import isEmpty from "../../../utils/helpers";

const SelectCell = (props) => {
    const { value, error, setValue, column } = props;

    const [editing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const inputRef = useRef();

    const options = props[`${column.id}Options`];

    const startEditing = useCallback(() => {
        setEditing(true);
        setEditValue(value);
    }, [value]);

    const stopEditing = useCallback(() => {
        setEditing(false);
    }, []);

    const handleChange = useCallback((option) => {
        const value = option.value;

        setEditValue(value);
        setValue(value);
    }, [setValue]);

    const selectedOption = useMemo(() => {
        return (options || []).find(opt => opt.value === editValue) || null;
    }, [options, editValue]);

    useLayoutEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current, editing]);

    if (editing) {
        return (
            <>
                <Select
                    ref={inputRef}
                    options={options}
                    isLoading={!options}
                    closeMenuOnSelect={true}
                    onChange={handleChange}
                    value={selectedOption}
                    disabled={!options}
                    onBlur={stopEditing}
                />
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </>
        );
    }

    return (
        <>
            <div className="ni-editable-cell" onClick={startEditing}>
                {!isEmpty(value) ? (
                    <div className="cell-value">{value}</div>
                ) : (
                    <div className="cell-empty-value">Enter {column.Header}...</div>
                )}
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </div>
        </>
    );
}

export default SelectCell;