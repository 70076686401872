import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadPatientPhoto} from "../../../actions/patients.actions";
import moment from "moment";
import isEmpty from "../../../utils/helpers";

class Contact extends Component {

    state = {
        // isPhotoLoading: true,
        isPhotoLoading: false,
        contactPhoto: null,
    }

    componentDidMount() {
        // this.props.loadPatientPhoto(this.props.contact.patientId).then(response => {
        //     this.setState({
        //         isPhotoLoading: false,
        //         contactPhoto: isEmpty(response.status) ? response : null,
        //     });
        // });
    }

    render() {

        const {
            isPhotoLoading,
            contactPhoto,
        } = this.state;

        const {
            contact,
            handleShowConversationById,
        } = this.props;

        return (
            <div
                className="messages-contact"
                onClick={() => handleShowConversationById(contact.contactId)}
            >

                {/*{contact.unreadMessages > 0 &&
                <div className="messages-unread">
                    {contact.unreadMessages}
                </div>
                }*/}

                <div className="messages-avatar" onClick={() => handleShowConversationById(contact.contactId)}>
                    {isPhotoLoading
                        ? <i className="fas fa-spinner fa-spin" />
                        : contactPhoto
                            ? <img src={URL.createObjectURL(new Blob([contactPhoto]))} alt={contact.firstName} />
                            : <i className="fa fa-user" />
                    }
                </div>

                <div className="messages-details">

                    <span className="messages-details-name">
                        {contact.firstName}
                    </span>

                    <span className="messages-details-date">
                        {moment(contact.lastReceiveddDate).isAfter(contact.lastSendDate)
                            ? moment(contact.lastReceiveddDate).format("MMM D, HH:mm")
                            : moment(contact.lastSendDate).format("MMM D, HH:mm")
                        }
                    </span>

                    <p className="messages-details-message">
                        {moment(contact.lastReceiveddDate).isAfter(contact.lastSendDate)
                            ? <><span>you: </span> {contact.lastReceivedMessage}</>
                            : <><span>{contact.firstName}: </span> {contact.lastSendMessage}</>
                        }
                    </p>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    loadPatientPhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
