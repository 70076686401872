import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal} from "../../../actions/modal.actions";
import isEmpty from "../../../utils/helpers";
import PanelTitle from "../PanelTitle";
import PanelSearch from "../PanelSearch";
import PanelAction from "../PanelAction";
import Loader from "react-spinners/BeatLoader";

class FormsPanel extends Component {

    state = {
        showCreateNew: false,
    }

    handleSearch = (term) => {
        console.log(term)
    }

    handleCreateNew = () => {
        this.setState({
            showCreateNew: !this.state.showCreateNew,
        });
    }

    handlePopOut = () => {

        this.props.showModal("PANEL_POPOUT_FORMS_MODAL", {
        });
    }

    render() {

        const {
            showCreateNew,
        } = this.state;

        const {
            loadingForms,
            forms,
            hidePanel,
        } = this.props;

        return (
            <>

                <div className="panel-header">

                    <PanelTitle
                        title="Forms"
                        hidePanel={hidePanel}
                    />

                    <div className="panel-toolbar">

                        <PanelAction
                            primary={true}
                            title="Create New"
                            icon="fa fa-plus"
                            handleAction={this.handleCreateNew}
                        />

                        <PanelAction
                            primary={false}
                            title="Pop Out"
                            icon="fa fa-external-link-alt"
                            handleAction={this.handlePopOut}
                        />

                    </div>

                    <PanelSearch
                        placeholder="Search forms"
                        handleSearch={this.handleSearch}
                    />

                </div>

                <div className={"panel-body" + (loadingForms ? " panel-body-loading" : "")}>

                    {loadingForms &&
                    <div className="panel-loader">
                        <Loader size={10} color={"#ccc"} />
                    </div>
                    }

                    {!loadingForms && isEmpty(forms) &&
                    <p className="panel-body-empty">No data found</p>
                    }

                    {!loadingForms && !isEmpty(forms) && <>
                        {/*<Forms />*/}
                    </>}

                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsPanel);