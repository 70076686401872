import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadProCategories, loadProHistory} from "../../../actions/pro.actions";
import {createRecurrentTask, createTask, updateTask} from "../../../actions/tasks.actions";
import {getModalData} from "../../../utils/selectors";
import {Form, Formik} from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Yup from 'yup';
import isEmpty from "../../../utils/helpers";
import CronBuilder from "../../../utils/cron-utils";
import _ from "lodash";
import {TaskUserPhoto} from "../../Panels/TasksPanel/TaskUserPhoto";
import {Modal, ModalBody} from "react-bootstrap";

const VALIDATION = {
    titleMaxLength: 100,
    descriptionMaxLength: 512,
    maxLengthMessage: "You have reached the maximum no. of characters."
}

const WEEK_DAYS = [
    {
        label: "Mon",
        value: 1,
        key: "mon"
    },
    {
        label: "Tue",
        value: 2,
        key: "tue"
    },
    {
        label: "Wed",
        value: 3,
        key: "wed"
    },
    {
        label: "Thu",
        value: 4,
        key: "thu"
    },
    {
        label: "Fri",
        value: 5,
        key: "fri"
    },
    {
        label: "Sat",
        value: 6,
        key: "sat"
    },
    {
        label: "Sun",
        value: 0,
        key: "sun"
    },
]

class PanelPopoutTasksModal extends Component {

    state = {
        showConfirmation: false
    }

    componentDidMount() {
        // this.props.loadProCategories().then(response => {
            // console.log(response.categories)
        // })
    }

    componentWillUnmount() {
        this.props.handleChangeModalTab(1);
        if (this.state.showConfirmation) {
            this.closeStaticModal();
        }
    }

    closeModal = () => {
        this.props.hideModal();
    }

    closeStaticModal = () => {
        this.setState({showConfirmation: false}, this.props.closeStaticModal());
    }

    handleOnSubmit = (values, actions) => {

        const { createTask, updateTask, createRecurrentTask } = this.props;

        const { patientId, getTasks, handleShowTaskDetailsById } = this.props.modalProps;

        const requestData = this.createTaskRequestData(values);

        const requestAction = requestData.id ? updateTask : values.recurrent ? createRecurrentTask : createTask;

        requestAction(patientId, requestData).then(response => {
            actions.setSubmitting(false);
            handleShowTaskDetailsById();
            getTasks();
            this.closeModal();
        })
        actions.setSubmitting(false);
    }

    calcMinTime = (values) => {
        const now = new Date();
        let startDate =  values.dueDate? moment(values.dueDate).toDate(): now;
        const isSelectedDateToday = now.getDate() === startDate.getDate();

        let minTimeHour = now.getHours() + 1;
        if (!isSelectedDateToday) {
            minTimeHour = 0;
        }
        return minTimeHour;
    }

    buildCronExpression = values => {
        const { recurrentRangeValue, recurrentDays } = values;

        const dueDate = moment(values.dueDate);

        return CronBuilder.builder()
            .minutes(dueDate.minutes())
            .hours(dueDate.hours())
            .days(recurrentRangeValue)
            .weekly(recurrentDays)
            .build();
    }

    createTaskRequestData = (values) => {

        const recurrentCrontab = this.buildCronExpression(values);

        const commonData = {
            title: values.title,
            assigneeId: values.assigneeId,
            description: values.description
        };

        if (values.id) {
            commonData.id = values.id;
            commonData.recurrentCrontab = recurrentCrontab;
        }

        let specificData;

        if (values.recurrent) {
            specificData = {
                startDate: values.dueDate,
                endDate: values.endDate,
                recurrentCrontab: recurrentCrontab
            };
        } else {
            specificData = {
                dueDate: values.dueDate,
                recurrent: values.recurrent
            };

        }

        return _.merge(commonData, specificData);
    }

    handleRecurrentDaysChange = (e, handleChange, values, setFieldValue) => {

        handleChange(e);

        const { recurrentDays } = values;

        if (!recurrentDays.delete(parseInt(e.target.value))) {
            recurrentDays.add(parseInt(e.target.value));
        }

        setFieldValue("recurrentDays", recurrentDays);
    }

    calculateMinEndDateHour = (values) => {

        const now = moment();

        const dueDate = values.dueDate? moment(values.dueDate) : now;

        const endDate = values.endDate? moment(values.endDate) : dueDate;

        if(endDate.date() > dueDate.date()) {
            return 0;
        }

        return dueDate.hours() + 1;
    }

    disableSubmit = values => {
        const { title, assigneeId, dueDate } = values;

        return _.isEmpty(title) || _.isEmpty(assigneeId) || _.isEmpty(dueDate);
    }

    handleDatepickerClosed = (values, field, validateForm, setFieldError, setFieldTouched ) => {
        validateForm(values).then( errors =>
            {
                setFieldError(field, errors[field]);
                setFieldTouched(field, true, false);
            }
        )
    }

    render() {

        const { showConfirmation } = this.state;

        const {
            modalProps,
            openStaticModal
        } = this.props;

        const task = modalProps.task || {};
        const isNewTask = isEmpty(task) || isEmpty(task.id);

        const { recurrentRangeKey, recurrentRangeValue, recurrentDays } =
            !isEmpty(task) && !isEmpty(task.recurrentCrontab) ?
                CronBuilder.parseExpression(task.recurrentCrontab) :
                {
                    recurrentRangeKey: "days",
                    recurrentRangeValue: 1,
                    recurrentDays: new Set()
                }

        return (
            <>
                <div className="modal-tabs">
                    <div className="modal-tabs-items">
                        <div
                            className={"modal-tabs-item"}
                        >
                            {isNewTask? 'New' : 'Edit'} Task
                        </div>
                    </div>
                </div>

                <div className="modal-body">

                    <Formik
                        initialValues={{
                            id: !isEmpty(task)? task.id: null,
                            title: !isEmpty(task)? task.title : "",
                            assigneeId:  !isEmpty(task)? task.assignee.id : "",
                            dueDate: !isEmpty(task)? moment(task.dueDate).format("YYYY-MM-DD HH:mm") : "",
                            recurrent: !isEmpty(task) && task.recurrent,
                            notify: !isEmpty(task)? task.notify : false,
                            description: (!isEmpty(task) && task.description) ? task.description : "",
                            endDate: (!isEmpty(task) && task.endDate)? moment(task.endDate).format("YYYY-MM-DD HH:mm") : "",
                            recurrentRangeKey: recurrentRangeKey,
                            recurrentRangeValue: recurrentRangeValue,
                            recurrentDays: recurrentDays
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required('Required').max(VALIDATION.titleMaxLength),
                            assigneeId: Yup.string().required('Required'),
                            dueDate: Yup.string().required('Required')
                                .test("dueDate", function () {
                                    const { dueDate } = this.parent;

                                    if (dueDate && (!_.isEqual(task.dueDate, dueDate) && moment(dueDate).isSameOrBefore(moment()))) {
                                        return this.createError({message: "Due date must be in the future"});
                                    }
                                    return true;
                                }),
                            endDate: Yup.string().nullable()
                                .test("endDate", function () {
                                    const {dueDate, endDate} = this.parent;

                                    const minValue = dueDate? moment(dueDate) : _.max([moment(dueDate), moment()]);

                                    if (endDate && (!_.isEqual(task.endDate, endDate) && moment(endDate).isSameOrBefore(minValue))) {
                                        const message = dueDate?
                                            "End date must be after due date" :
                                            "End date must be in the future";

                                        return this.createError({message: message});
                                    }
                                    return true;
                                }),
                            recurrent: Yup.boolean().required('Required'),
                            recurrentRangeValue: Yup.number()
                                .typeError("Must be a number")
                                .test("Value", "Must be between 1 and 31", val => isNaN(val) || (parseInt(val) > 0 && parseInt(val) < 32))
                            })
                        }
                        onSubmit={this.handleOnSubmit}
                    >
                        {({setFieldTouched, validateForm, setFieldError, touched, errors, handleBlur, handleChange, setFieldValue, values, isSubmitting}) => (
                            <Form className="form">
                                <div className="form-group">
                                    <label htmlFor="title">Task</label>
                                    <input
                                        name="title"
                                        id="title"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values['title']}
                                        maxLength={VALIDATION.titleMaxLength}
                                    />
                                    {values.title.length === VALIDATION.titleMaxLength &&
                                        <div className="validation-feedback">
                                            {VALIDATION.maxLengthMessage}
                                        </div>
                                    }
                                    {errors.title && touched.title && (
                                        <div className="invalid-feedback">{errors.title}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Assign to</label>

                                    <div className="row form-group-assignees justify-content-start">
                                        {!isEmpty(modalProps.assignees) && Object.values(modalProps.assignees).map((assignee, index) =>
                                        <div className={"col-6 form-check" + (modalProps.userId === assignee.id ? " me" : "")}>
                                            <input
                                                name="assigneeId"
                                                className="form-check-input"
                                                type="radio"
                                                id={assignee.id}
                                                checked={values.assigneeId === assignee.id}
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    setFieldValue('assigneeId', assignee.id);
                                                }
                                                }
                                            />
                                            <div className="form-group-photo">
                                                <TaskUserPhoto user={assignee} onClick={() => {
                                                    setFieldValue('assigneeId', assignee.id);
                                                }}/>
                                                <span className="form-check-label" htmlFor={assignee.id}>
                                                    {modalProps.userId === assignee.id ? "me" : assignee.firstName}
                                                </span>
                                            </div>
                                        </div>
                                        )}

                                        {errors.assigneeId && touched.assigneeId && (
                                            <div className="invalid-feedback">{errors.assigneeId}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="col col-md-2">

                                            <div className="form-group">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="recurrent">Recurrent</label>
                                                    <br/>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="recurrent"
                                                        id="recurrent"
                                                        checked={values.recurrent}
                                                        onBlur={handleBlur}
                                                        value={true}
                                                        onChange={handleChange}
                                                        disabled={!isNewTask}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="col col-lg-6">

                                            <div className="form-group">
                                                <label htmlFor="dueDate">
                                                    {values.recurrent ? "Start Date" : "Due Date"}
                                                </label>
                                                <DatePicker
                                                    className="form-control datetimepicker-input"
                                                    name="dueDate"
                                                    id="dueDate"
                                                    autoComplete="off"
                                                    minDate={new Date()}
                                                    minTime={new Date(new Date().setHours(this.calcMinTime(values), 0, 0, 0))}
                                                    maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                                                    showTimeSelect
                                                    dateFormat="MMM d, yyyy @ hh:mm aa"
                                                    timeIntervals={30}
                                                    selected={values.dueDate && moment(values.dueDate).toDate()}
                                                    excludeOutOfBoundsTimes
                                                    onCalendarClose={() => this.handleDatepickerClosed(values, 'dueDate', validateForm, setFieldError, setFieldTouched)}
                                                    onChange={(date, event) => {

                                                        // If we're changing the day
                                                        if (event && event.target && event.target.classList.contains("react-datepicker__day")) {

                                                            // If we've previously selected a time
                                                            if (values.dueDate) {

                                                                // Keep selected time
                                                                date.setHours(moment(values.dueDate).format("HH"), moment(values.dueDate).format("mm"))
                                                            }
                                                            else {

                                                                // Set default time to 12:00 PM
                                                                date.setHours(12, 0)
                                                            }
                                                        }

                                                        // Set new date & time
                                                        setFieldValue('dueDate', moment(date).format("YYYY-MM-DD HH:mm"), false);
                                                    }}
                                                    // ref={(c) => this._calendar = c}
                                                />
                                                {errors.dueDate && touched.dueDate && (
                                                    <div className="invalid-feedback">{errors.dueDate}</div>
                                                )}
                                            </div>
                                        </div>
                                        {values.recurrent &&
                                            <div className="col col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="dueDate">End Date</label>
                                                    <DatePicker
                                                        className="form-control datetimepicker-input"
                                                        name="endDate"
                                                        id="endDate"
                                                        autoComplete="off"
                                                        minDate={values.dueDate? moment(values.dueDate).toDate() : new Date()}
                                                        minTime={new Date(new Date().setHours(this.calculateMinEndDateHour(values), 0, 0, 0))}
                                                        maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                                                        showTimeSelect
                                                        dateFormat="MMM d, yyyy @ hh:mm aa"
                                                        timeIntervals={30}
                                                        selected={values.endDate && moment(values.endDate).toDate()}
                                                        excludeOutOfBoundsTimes
                                                        onCalendarClose={() => this.handleDatepickerClosed(values, 'endDate', validateForm, setFieldError, setFieldTouched)}
                                                        onChange={(date, event) => {

                                                            // If we're changing the day
                                                            if (event && event.target && event.target.classList.contains("react-datepicker__day")) {

                                                                // If we've previously selected a time
                                                                if (values.endDate) {

                                                                    // Keep selected time
                                                                    date.setHours(moment(values.endDate).format("HH"), moment(values.endDate).format("mm"))
                                                                }
                                                                else {

                                                                    // Set default time to 12:00 PM
                                                                    date.setHours(12, 0)
                                                                }
                                                            }

                                                            // Set new date & time
                                                            setFieldValue('endDate', moment(date).format("YYYY-MM-DD HH:mm"));
                                                        }}
                                                        // ref={(c) => this._calendar = c}
                                                    />
                                                    {errors.endDate && touched.endDate && (
                                                        <div className="invalid-feedback">{errors.endDate}</div>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col">

                                        {values.recurrent && <>
                                        <div className="form-group">
                                            <label className="form-label">Repeat<upper>*</upper></label>
                                            <br/>
                                            <div className="form-row">
                                                <div className="col-2">
                                                    <span>Every</span>
                                                </div>
                                                <div className="col-2">
                                                    <input
                                                        name="recurrentRangeValue"
                                                        id="recurrentRangeValue"
                                                        value={values.recurrentRangeValue}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.recurrentRangeKey === "weekly"}
                                                    />
                                                    {errors.recurrentRangeValue && touched.recurrentRangeValue && (
                                                        <div className="invalid-feedback">{errors.recurrentRangeValue}</div>
                                                    )}
                                                </div>
                                                <div className="col-5">
                                                    <select
                                                        name="period"
                                                        className="form-control"
                                                        value={values.recurrentRangeKey}
                                                        onChange={(e) => {
                                                            setFieldValue('recurrentRangeKey', e.target.value)
                                                            setFieldValue('recurrentRangeValue', 1)
                                                            if (e.target.value === "weekly") {
                                                                setFieldValue('recurrentDays', new Set())
                                                            }
                                                        }}
                                                    >
                                                        <option value="days">Days</option>
                                                        <option value="weekly">Weeks</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {values.recurrentRangeKey === "weekly" &&
                                            <div className="form-group">
                                                <div className="form-check-group form-check-group-horizontal">
                                                    <label className="form-label">On</label>
                                                    {WEEK_DAYS.map(day =>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name={day.key}
                                                                id={day.key}
                                                                value={day.value}
                                                                defaultChecked={values.recurrentDays.has(day.value)}
                                                                onChange={ (e) => {
                                                                    this.handleRecurrentDaysChange(e, handleChange, values, setFieldValue);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor={day.key}>{day.label}</label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        </>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">Details</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        id="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values['description']}
                                        maxLength={VALIDATION.descriptionMaxLength}
                                    />
                                    <div className="validation-feedback">
                                        {((values.description.length || 0) + "/" + VALIDATION.descriptionMaxLength)}
                                    </div>
                                </div>

                                <div className="tasks-button-group text-end">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-rnd btn-cancel"
                                        onClick={() => this.setState({showConfirmation: true})}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-rnd btn-save"
                                        disabled={isSubmitting || this.disableSubmit(values)}
                                    >
                                        Save
                                    </button>
                                </div>
                                {showConfirmation &&
                                <Modal
                                    backdrop="static"
                                    keyboard={false}
                                    backdropClassName="stack"
                                    centered={true}
                                    show={showConfirmation}
                                    contentClassName="confirmation-modal"
                                    dialogClassName="confirmation-dialog"
                                    onEnter={openStaticModal}
                                >
                                    <Modal.Header>
                                        <h5>Cancel new task?</h5>
                                    </Modal.Header>
                                    <Modal.Dialog>
                                        <ModalBody>
                                            The changes you made will be discarded.
                                        </ModalBody>
                                    </Modal.Dialog>
                                    <Modal.Footer>
                                        <div className="tasks-button-group">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-rnd btn-cancel"
                                                onClick={this.closeStaticModal}
                                            >
                                                No, save
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-primary btn-rnd btn-save"
                                                onClick={this.closeModal}
                                            >
                                                Yes, cancel
                                            </button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>
                                }
                            </Form>
                            )}
                    </Formik>
                </div>
            </>

        );
    }
}

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;

    return {
        modalProps,
    };
}

const mapDispatchToProps = {
    loadProCategories,
    loadProHistory,
    createTask,
    createRecurrentTask,
    updateTask
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPopoutTasksModal);
