import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment";

class ReportItem extends Component {

    state = {
        actionCategories: [],
        actionTypes: [],
    }

    componentDidMount() {
        this.getActionCategories();
        this.getActionTypes();
    }

    getActionCategories = () => {

        let actionCategories = [];
        let actionCategory = "";

        this.props.actions.map(action => {

            if (["genomic_information", "pathology_report", "blood_test"].includes(action.actionName)) {
                actionCategory = "lab";
            }
            else if (["drug_therapy", "radiation_therapy", "surgery"].includes(action.actionName)) {
                actionCategory = "intervention";
            }
            else {
                actionCategory = action.actionName;
            }

            if (actionCategories.includes(actionCategory) === false) {
                actionCategories.push(actionCategory);
            }

        });

        this.setState({actionCategories: actionCategories})
    }

    getActionTypes = () => {

        let actionTypes = [];
        let actionType = "";

        this.props.actions.map(action => {

            if (actionTypes.includes(action.actionName) === false) {
                actionTypes.push(action.actionName);
            }
        });

        this.setState({actionTypes: actionTypes})
    }

    render () {

        const {
            actionCategories,
            actionTypes,
        } = this.state;

        const {
            date,
            actions
        } = this.props;

        return  (
            <div className="report-item">
                <div>

                    <div className="report-caption">
                        <div className="report-categories">
                            {actionCategories.map((actionType, index) =>
                                <span key={index} className={"report-category report-category-" + actionType} />
                            )}
                        </div>
                        <h4 className="report-date">{moment.parseZone(date).format("DD-MMM-YYYY")}</h4>
                    </div>

                </div>
                <div>

                    {actionCategories.includes("imaging")
                    ?
                        <h5 className="report-title">{actions.filter(action => action.actionName === "imaging")[0].location}</h5>
                    :
                    actionCategories.includes("intervention") &&
                    <>
                        {actionTypes.includes("drug_therapy") && (
                            <h5 className="report-title">
                                Cycle {actions.filter(action => action.actionName === "drug_therapy")[0].cycleNumber}
                            </h5>
                        )}
                        <p className="report-subtitle">
                            {actions.filter(action => action.actionName === "drug_therapy").map((action, index) =>
                                <span key={index}>{action.drugName} {action.doseTakenMg} <br /></span>
                            )}
                        </p>
                    </>
                    }

                    <button className="btn btn-link">Full Report</button>

                </div>

                {actionTypes.includes("blood_test") && <>
                <div>

                    <div className="report-table">
                        <table>
                            <tbody>
                                {actions
                                    .filter(action => action.actionName === "blood_test")
                                    .filter(action => ["CEA", "CA-15-3", "CA-125", "CA-19-9"].includes(action.analyte) === false)
                                    .map((action, index) =>
                                    <tr key={index}>
                                        <td>{action.analyte}</td>
                                        <td>{action.value}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div>

                    <div className="report-table">
                        <table>
                            <tbody>
                                {actions
                                    .filter(action => action.actionName === "blood_test")
                                    .filter(action => ["CEA", "CA-15-3", "CA-125", "CA-19-9"].includes(action.analyte) === true)
                                    .map((action, index) =>
                                    <tr key={index}>
                                        <td>{action.analyte}</td>
                                        <td>{action.value}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
                </> }
                <div>

                    <div className="report-description">

                        {actionTypes.length === 1 && actionTypes.includes("pathology_report") &&
                        actions.filter(action => action.actionName === "pathology_report").map((action, index) =>
                            <p key={index}><strong>{action.cancerType}</strong> - {action.diagnosis}</p>
                        )
                        }

                        {actions.filter(action => action.details !== null).map((action, index) =>
                            <p key={index}>{action.details}</p>
                        )}

                        {actions.filter(action => action.summary !== null).map((action, index) =>
                            <p key={index}>{action.summary}</p>
                        )}

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportItem);