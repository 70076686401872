import React, {useCallback, useMemo} from "react";
import {connect} from "react-redux";
import * as Yup from 'yup';
import NixioTable, {
    CheckboxCell,
    CreatableSelectCell,
    EditableCell,
    SelectCell,
    SelectColumnFilter
} from "../../Table/NixioTable";
import _ from "lodash";
import {value} from "lodash/seq";
import {getTimezoneIds} from "../../../utils/timezone-utils";
import {isEmpty, removeByIdx} from "../../../utils/helpers";
import {useFormik} from "formik";
import EditablePhoneCell from "../../Table/NixioTable/EditablePhoneCell";
import {isValidPhoneNumber} from "react-phone-number-input";


const PointOfContactsEditor = ({pointsOfContact: data, onSave, onCancel, loading}) => {

    const initialValues = useMemo(() => data || [], [data]);

    const handleOnSubmit = useCallback((values, {setSubmitting}) => {
        try {
            if (onSave) {
                onSave(values);
            }
        } catch(e) {
            alert("Unexpected error");
            console.error(e);
        } finally {
            setSubmitting(false);
        }
    }, [onSave]);

    const handleOnCancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: Yup.array()
            .of(
                Yup.object().notRequired().shape({
                    firstName: Yup.string().required("Required"),
                    lastName: Yup.string().required("Required"),
                    relationship: Yup.string().nullable(),
                    email: Yup.string().nullable().email("Invalid email"),
                    phone: Yup.string().nullable().test('phone-test', 'Invalid phone number', (value) => {
                        if (value === null || value === undefined) {
                            return true;
                        }

                        if (typeof value !== 'string') {
                            return false;
                        }

                        return isValidPhoneNumber(value);
                    }),
                    timezone: Yup.string().nullable(),
                })
            ),
        onSubmit: handleOnSubmit,
    });

    const pointsOfContact = formik.values;

    const setPocs = useCallback((value) => {
        const newPocs = typeof value === 'function' ? value(pointsOfContact) : value;
        formik.setValues(newPocs);
    }, [formik.setValues, pointsOfContact]);

    const deleteRecord = useCallback((rec) => {
        setPocs(pocs => removeByIdx(pocs, rec.rowIdx));
    }, [setPocs]);

    const updatePocValue = useCallback(({idx, columnId, value}) => {
        setPocs(pocs => {
            if (!pocs) {
                return pocs;
            }

            const newPocs = [...pocs];
            const newPoc = {...newPocs[idx]};

            newPoc[`${columnId}`] = value;
            newPocs[idx] = newPoc;

            return newPocs;
        });
    }, [setPocs]);

    const onAddNewPocHandler = useCallback(() => {
        setPocs(pocs => [{emailCopy: false}, ...(pocs || [])]);
    }, [setPocs]);

    const tableInitialState = useMemo(() => {
        return {
            sortBy: [
                {
                    id: 'firstName',
                    desc: false
                }
            ]
        }
    }, []);

    const ActionCell = useCallback((props) => {
        const {row} = props;

        return (
            <div className="w-100 text-center">
                <div className="btn-layout-vertical">
                    {<button type="button" className="btn btn-rnd btn-danger " onClick={() => deleteRecord(row.original)}><i className="fas fa-times-circle p-0" aria-hidden="true"/></button>}
                </div>
            </div>
        );
    }, [deleteRecord]);

    const pocColumns = useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: EditableCell,
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: EditableCell,
        },
        {
            Header: 'Relationship',
            accessor: 'relationship',
            Cell: CreatableSelectCell,
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: EditableCell,
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            Cell: EditablePhoneCell,
        },
        {
            Header: 'Timezone',
            accessor: 'timezone',
            Cell: SelectCell,
            Filter: SelectColumnFilter,
        },
        {
            Header: 'Email Copy',
            accessor: 'emailCopy',
            disableFilters: true,
            Cell: CheckboxCell,
            disabled: ({row, errors}) => {
                return !row.values.email || !!errors.email
            },
        },
        {
            Header: 'Action',
            Cell: ActionCell,
            hideHeader: true,
            disableFilters: true,
        },
    ], [ActionCell]);

    const relationshipSet = useMemo(() => {
        const baseRelationships = ["Daughter", "Father", "Friend", "Husband", "Mother", "Son", "Significant Other", "Wife", "Brother", "Family member"];
        const rsSet = new Set();
        for (const relationship of baseRelationships) {
            rsSet.add(relationship);
        }

        if (pointsOfContact) {
            for (const poc of pointsOfContact) {
                rsSet.add(poc.relationship);
            }
        }

        return rsSet;
    }, [pointsOfContact]);

    const relationshipOptions = useMemo(() => {
        const options = relationshipSet && Array.from(relationshipSet).map(r => ({value: r, label: r})) || [];
        return _.sortBy(options, opt => opt.value);
    }, [relationshipSet]);

    const timezoneOptions = useMemo(() => {
        return getTimezoneIds().map(t => ({value: t, label: t}));
    }, []);

    const customSort = useCallback((sortBy, sort) => {
        setPocs(pocs => sort(pocs));
    }, [setPocs]);

    return (
        <form>
            <div className="mt-3 mb-3">
                <label htmlFor="btnAddPoc">&nbsp;</label>
                <button
                    id="btnAddPoc"
                    name="btnAddPoc"
                    type="button"
                    className="btn btn-primary btn-rnd btn-save"
                    onClick={onAddNewPocHandler}
                    disabled={loading}>
                    <i className="fas fa-plus"/>New Point of Contact
                </button>
            </div>

            <div className="mb-3">
                <NixioTable
                    columns={pocColumns}
                    data={pointsOfContact}
                    initialState={tableInitialState}
                    loading={loading}
                    onPropertyUpdate={updatePocValue}
                    errors={formik.errors}
                    relationshipOptions={relationshipOptions}
                    timezoneOptions={timezoneOptions}
                    customSort={customSort}
                />
            </div>

            <div className="text-end">
                <div>
                <button
                    className="btn btn-secondary btn-rnd btn-cancel"
                    onClick={handleOnCancel}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary btn-rnd btn-save ms-1"
                    onClick={formik.handleSubmit}
                    disabled={isEmpty(pointsOfContact) || formik.isSubmitting || !formik.isValid}
                >
                    Save
                </button>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PointOfContactsEditor);