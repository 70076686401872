import {CLEAN_NOTES} from "../actions/notes.actions";
import {LOAD_NOTES} from "../actions/notes.actions";
import {CREATE_NOTE} from "../actions/notes.actions";
import {UPDATE_NOTE} from "../actions/notes.actions";
import {DELETE_NOTE} from "../actions/notes.actions";
import isEmpty from "../utils/helpers";

let initialState = {
    notes: {},
};

export const notesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_NOTES.CLEAR:
            return initialState;
        case LOAD_NOTES.SUCCESS:
            let notes = {};
            notes['private'] = [];
            notes['public'] = [];
            notes['draft'] = null;

            action.payload.notes.map(note => {
                if(note.draft) {
                    notes['draft'] = note;
                } else {
                    if (note.public)
                        notes['public'].push(note);
                    else
                        notes['private'].push(note);
                }
                
            });

            return {
                ...state, notes: notes,
            };
        case CREATE_NOTE.SUCCESS:
        case UPDATE_NOTE.SUCCESS:
            let existingNotes = state.notes;
            if (isEmpty(existingNotes['public'])) {
                existingNotes['public'] = [];
            }
            if (isEmpty(existingNotes['private'])) {
                existingNotes['private'] = [];
            }
            existingNotes['draft'] = null;

            if(action.payload.note.draft) {
                existingNotes['draft'] = action.payload.note;
            } else {
                if (action.payload.note.public) {
                    existingNotes['public'].filter(n => n.id != action.payload.note.id);
                    existingNotes['public'].push(action.payload.note);
                } else {
                    existingNotes['private'].filter(n => n.id != action.payload.note.id);
                    existingNotes['private'].push(action.payload.note);
                }
            }

            return {
                ...state,
                notes: existingNotes
            };
        case DELETE_NOTE.SUCCESS:
            return {
                    ...state
            };
        default:
            return state;
    }
};
