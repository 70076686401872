import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";
import {LOAD_AVAILABLE_TASK_ASSIGNEES} from "./patients.actions";

export const CLEAN_TASKS = createRequestTypes('CLEAN_TASKS');
export const LOAD_TASKS = createRequestTypes('LOAD_TASKS');
export const CREATE_TASK = createRequestTypes('CREATE_TASK');
export const UPDATE_TASK = createRequestTypes('UPDATE_TASK');
export const DELETE_TASK = createRequestTypes('DELETE_TASK');
export const UPDATE_TASK_COMPLETED = createRequestTypes('UPDATE_TASK_COMPLETED');
export const GET_CCM_PHOTO = createRequestTypes('GET_CCM_PHOTO');
export const CREATE_EVENT = createRequestTypes('CREATE_EVENT');
export const LOAD_EVENTS = createRequestTypes('LOAD_EVENTS');

export const cleanTasksData = () => async (dispatch) => {
    dispatch(actionCreator.clear(CLEAN_TASKS));
}

export const loadTasks = (patientId, userId, dueTo, completedFrom) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_TASKS));

    try {
        const params = {dueTo: dueTo, completedFrom: completedFrom}
        const response = await axiosInstance.get(`/api/task/${patientId}`, {params: params});

        dispatch(actionCreator.success(LOAD_TASKS, response.data));

        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_TASKS, mockError));
        return err.response;
    }
}

export const createTask = (patientId, newTaskRequest) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_TASK));
    try {
        const response = await axiosInstance.post(`/api/task/${patientId}`, {...newTaskRequest});
        dispatch(actionCreator.success(CREATE_TASK, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(CREATE_TASK, mockError));
        return err.response;
    }
}

export const createRecurrentTask = (patientId, recurrentTaskRequest) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_TASK));
    try {
        const response = await axiosInstance.post(`/api/task/recurrent/${patientId}`, {...recurrentTaskRequest});
        dispatch(actionCreator.success(CREATE_TASK, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(CREATE_TASK, mockError));
        return err.response;
    }
}

export const updateTask = (patientId, editTaskRequest) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_TASK));
    try {
        const response = await axiosInstance.put(`/api/task/${patientId}`, {...editTaskRequest});
        dispatch(actionCreator.success(UPDATE_TASK, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(UPDATE_TASK, mockError));
        return err.response;
    }
}

export const deleteTask = (patientId, taskId) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_TASK));
    try {
        const response = await axiosInstance.delete(`/api/task/${patientId}/${taskId}`);
        dispatch(actionCreator.success(DELETE_TASK, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(DELETE_TASK, mockError));
        return err.response;
    }
}

export const getCcmPhoto = (systemUserId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_CCM_PHOTO));
    try {
        const response = await axiosInstance.get(`/api/ccm/image/${systemUserId}`);
        dispatch(actionCreator.success(GET_CCM_PHOTO, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(GET_CCM_PHOTO, mockError));
        return err.response;
    }
}

export const createTaskEvent = (patientId, taskId, eventType) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_EVENT));
    // let params = {
    //     search: search || ""
    // };
    try {
        const response = await axiosInstance.post(`/api/task/${patientId}/${taskId}/event`, { eventType });

        dispatch(actionCreator.success(CREATE_EVENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(CREATE_EVENT, mockError));
        return err.response;
    }
}

export const updateTaskCompleted = (patientId, task) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_TASK_COMPLETED));
    try {
        const response = await axiosInstance.patch(`/api/task/${patientId}/${task.id}/completed`, null,{params: {completed: task.completed}});
        dispatch(actionCreator.success(UPDATE_TASK_COMPLETED, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(UPDATE_TASK_COMPLETED, mockError));
        return err.response;
    }
}

export const loadAvailableTaskAssignees = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_AVAILABLE_TASK_ASSIGNEES));
    try {
        const response = await axiosInstance.get(`/api/task/${patientId}/available-assignees`);
        dispatch(actionCreator.success(LOAD_AVAILABLE_TASK_ASSIGNEES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_AVAILABLE_TASK_ASSIGNEES, mockError));
        return err.response;
    }
}