import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {logout} from "../../actions/auth.actions";
import moment, {now} from "moment";
import {closeDocuments} from "../../utils/document-utils";
import {getPatientsData} from "../../utils/selectors";
import _ from "lodash";
import withRouter from "../../wrappers/withRouter";
import infoSvg from "../../images/info.svg"

const style = {
    fontSize: '0.8em',
    height: 'auto',
}

class Header extends Component {
    state = {
        isMenuOpen: false,
        isUserDropdownOpen: false,
    };

    toggleNav = () => {
        this.setState((prevProps) => ({
            isMenuOpen: !prevProps.isMenuOpen,
            isUserDropdownOpen: false
        }));
    };

    toggleDropdown = (hide) => {
        if (hide) {
            this.setState(() => ({
                isUserDropdownOpen: false
            }));
        } else {
            this.setState((prevProps) => ({
                isUserDropdownOpen: !prevProps.isUserDropdownOpen
            }));
        }
    };

    logout = () => {
        this.props.logout(this.props.navigate);
    }

    getEnrollmentWeekText = () => {
        const {patientInfo} = this.props;
        const enrollment = _.get(patientInfo, 'selectedEnrollment');

        if (!enrollment) {
            return null;
        }

        if (!enrollment.startDate) {
            return "Not enrolled";
        }

        const startDate = moment.parseZone(enrollment.startDate);
        const endDate = enrollment.endDate && moment.parseZone(enrollment.endDate);
        const now = moment.parseZone(Date.now());

        if (endDate != null && !endDate.isAfter(now)) {
            return "Completed Program";
        }

        const diff = now.diff(startDate, 'weeks', true);
        const weeks = Math.floor(diff);

        return `Week ${weeks} (Start ${startDate.format("DD-MMM-YYYY")})`;
    }

    renderPatientService = () => {
        const {patientService} = this.props;

        if (!patientService) {
            return null;
        }

        return (
            <div className="mx-auto navbar-brand order-0">
                <div className="row text-center">
                    <div className="col col-12">
                        {patientService.premium && (
                            <i className={"ni ni-star-solid me-3"} />
                        )}
                        {patientService.name}
                    </div>
                    <div className="col col-12" style={style}>
                        {this.getEnrollmentWeekText()}
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const {isMenuOpen, isUserDropdownOpen} = this.state;

        return (
            <header>
                <nav className="navbar navbar-light navbar-expand-md sticky-top">
                    <div className="navbar-brand">
                        <Link to="/search-patients" className="navbar-logo">
                            <img src="/logo.png" alt="Nixio" />
                        </Link>
                        {moment(now()).format("MMMM DD, YYYY")}
                    </div>

                    {this.renderPatientService()}

                    <button
                        className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={isMenuOpen}
                        aria-label="Toggle navigation"
                        onClick={() => this.toggleNav()}
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <ul className={"navbar-nav" + (isMenuOpen ? ' show' : '')} id="navbarNav">
                        <li className="nav-item">
                            <a className="nav-link nav-report" target="_blank" href="https://forms.gle/Mb2r37BBuyuF7nZq6"><img src={infoSvg}/>&nbsp;&nbsp;Report an Issue</a>
                        </li>

                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                to="/"
                                onClick={() => {
                                    closeDocuments();
                                }}
                            >
                                Client List
                            </Link>
                        </li>

                        <li className="nav-item dropdown"
                            onMouseDown={() => this.toggleDropdown(false)}
                            onBlur={() => this.toggleDropdown(true)}
                        >

                            <button
                                className="nav-link dropdown-toggle" id="navbarDropdown"
                                aria-haspopup="true" aria-expanded={isUserDropdownOpen}
                            >
                                User
                            </button>

                            <div className={"dropdown-menu dropdown-menu-right" + (isUserDropdownOpen ? ' show' : '')} aria-labelledby="navbarDropdown">
                                {/*<button className="dropdown-item">Profile</button>*/}
                                {/*<button className="dropdown-item">Settings</button>*/}
                                {/*<div className="dropdown-divider"/>*/}
                                <button
                                    className="dropdown-item"
                                    onMouseDown={() => {
                                        closeDocuments();
                                        this.logout();
                                    }}
                                >
                                    Logout
                                </button>
                            </div>
                        </li>

                    </ul>
                </nav>
            </header>
        );

    }
}

const mapStateToProps = (state) => {
    const { patientService, patientInfo } = getPatientsData(state);

    return {
        patientService,
        patientInfo,
    };
};

const mapDispatchToProps = {
    logout
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
