import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector} from "../../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import HeartRate from "./HeartRate";
import Steps from "./Steps";
import Sleep from "./Sleep";
import {Chart} from "react-chartjs-2";

// Chart.plugins.register({
//     id: 'proChartsBackground',
//     beforeDraw: function(chartInstance, easing) {
//         var ctx = chartInstance.chart.ctx;
//         ctx.fillStyle = '#F7F8F9';
//         var chartArea = chartInstance.chartArea;
//         ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
//     }
// });

class ActivityMonitor extends Component {

    state = {
        viewWeek: true,
        viewMonth: false,
    }

    componentDidMount() {
    }

    changeViewPeriod = () => {
        this.setState({
            viewWeek: !this.state.viewWeek,
            viewMonth: !this.state.viewMonth,
        });
    }

    render () {

        const {
            viewWeek,
            viewMonth,
        } = this.state;

        const {
            loading,
        } = this.props;

        return  (
            <>
                <div className="card">
                    <div className="card-body">

                        {!loading
                            ? <>

                                <div className="card-caption card-caption-activity row">
                                    <div className="col col-md-4 col-xl-5">

                                        <h4 className="card-title card-title-linear">
                                            Activity Monitor
                                            &nbsp;
                                            <span className="small">Linked to iWatch - Jan 4 6:38 AM</span>
                                        </h4>

                                    </div>
                                    <div className="col col-md-4 col-xl-2">

                                        <h4 className="card-date">
                                            Dec 27 - Jan 4
                                        </h4>

                                    </div>
                                    <div className="col col-md-4 col-xl-5">

                                        <div className="btn-group" role="group">
                                            <button
                                                type="button"
                                                className={"btn btn-round btn-opaque" + (viewWeek ? " btn-primary" : " btn-outline-primary")}
                                                onClick={this.changeViewPeriod}
                                                disabled={viewWeek}
                                            >
                                                Week
                                            </button>
                                            <button
                                                type="button"
                                                className={"btn btn-round btn-opaque" + (viewMonth ? " btn-primary" : " btn-outline-primary")}
                                                onClick={this.changeViewPeriod}
                                                disabled={viewMonth}
                                            >
                                                Month
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="card-activity row">
                                    <div className="col-md-4">

                                        <HeartRate/>

                                    </div>
                                    <div className="col-md-4">

                                        <Steps />

                                    </div>
                                    <div className="col-md-4">

                                        <Sleep />

                                    </div>
                                </div>

                            </> : <>

                                <div className="card-caption">
                                    <h4 className="card-title">Activity Monitor</h4>
                                </div>
                                <Loader size={5} color={"#ccc"} />

                            </> }

                    </div>
                </div>
            </>
        )
    }
}

const patientProDialsLoader = createLoadingSelector(['LOAD_PATIENT_PRO_DIALS']);

const mapStateToProps = (state) => {

    const loading = patientProDialsLoader(state);

    return {
        loading,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMonitor);