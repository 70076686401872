import fAdvocacyNotesSvg from "../images/f_advocacynotes.svg";
import fBloodTestsSvg from "../images/f_bloodtests.svg";
import fDrugTherapySvg from "../images/f_drug_therapy.svg";
import fImagingReportsSvg from "../images/f_imaging_reports.svg";
import fInpatientEncountersSvg from "../images/f_inpatient_encounters.svg";
import fMolecularGenomivReportsSvg from "../images/f_molecular_genomic_reports.svg";
import fOperativeReportsSvg from "../images/f_operative_reports.svg";
import fOtherSvg from "../images/f_other.svg";
import fOutpatientEncountersSvg from "../images/f_outpatient_encounters.svg";
import fPathologyReportsSvg from "../images/f_pathology_reports.svg";
import fPerscriptionsSvg from "../images/f_perscriptions.svg";
import fPhysologicalTestsSvg from "../images/f_physiological_tests.svg";
import fRadiationTherapySvg from "../images/f_radiation_therapy.svg";
import fVisitSvg from "../images/f_visit.svg";
import fReasearch from "../images/f_research.svg";

const iconToSvg = {
    f_radiation_therapy: fRadiationTherapySvg,
    f_drug_therapy: fDrugTherapySvg,
    f_physiological_tests: fPhysologicalTestsSvg,
    f_imaging_reports: fImagingReportsSvg,
    f_molecular_genomic_reports: fMolecularGenomivReportsSvg,
    f_bloodtests: fBloodTestsSvg,
    f_outpatient_encounters: fOutpatientEncountersSvg,
    f_pathology_reports: fPathologyReportsSvg,
    f_perscriptions: fPerscriptionsSvg,
    f_inpatient_encounters: fInpatientEncountersSvg,
    f_other: fOtherSvg,
    f_visit: fVisitSvg,
    f_operative_reports: fOperativeReportsSvg,
    f_advocacynotes: fAdvocacyNotesSvg,
    f_research: fReasearch,
};

export const getFolderImgSrc = (icon) => {
    if (!Object.hasOwn(iconToSvg, icon)) {
        return iconToSvg.f_other;
    }

    return iconToSvg[icon];
}