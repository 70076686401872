import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import {TaskUserPhoto} from "./TaskUserPhoto";

class TaskDetails extends Component {

    render() {

        const {
            userId,
            task,
            handleShowTaskDetailsById,
            updatingTask,
            handleMarkAsComplete,
            handleShowDeleteModal,
        } = this.props;

        return (
            <div className="task-details">

                <div className="tasks-header task-details-header">
                    <div className="nav">
                        <button
                            className="btn btn-link"
                            onClick={handleShowTaskDetailsById}
                            disabled={updatingTask === task.id}
                        >
                            <i className="fa fa-chevron-left"/>
                        </button>

                        <h5>
                            Back to Task List
                        </h5>
                    </div>
                    <div className="actions">

                        {(userId === task.assignee.id || userId === task.createdBy.id) && (
                            <button
                                type="button"
                                className="btn btn-rnd btn-primary btn-cancel"
                                onClick={() => handleMarkAsComplete(task)}
                                disabled={updatingTask === task.id}
                            >
                                {updatingTask === task.id
                                    ? <i className="fa fa-spinner fa-spin"/>
                                    : <i className={"fa fa-check-circle" + (task.completed ? " text-success" : "")}/>
                                }

                                <span>{task.completed ? "Mark as not completed" : "Mark as complete"}</span>

                            </button>
                        )}

                        {userId === task.createdBy.id && <>

                            <button
                                type="button"
                                className="btn btn-rnd btn-primary btn-cancel"
                                onClick={() => this.props.handleEdit(task)}
                                disabled={task.completed || updatingTask === task.id}
                            >
                                <i className="fa fa-pencil-alt text-primary"/>
                                <span>Edit</span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-rnd btn-primary btn-del"
                                onClick={() => handleShowDeleteModal(task)}
                                disabled={task.completed || updatingTask === task.id}
                            >
                                <i className="fa fa-trash-alt text-danger"/>
                                <span>Delete</span>
                            </button>

                        </>
                        }
                    </div>

                </div>

                <div className="task-details-content">
                    <TaskUserPhoto user={task.assignee}/>

                    <h5>{task.title}</h5>

                    <div className="task-details-date">
                        Due: {task.dueDate && moment(task.dueDate).format("DD-MMM-YYYY")}
                    </div>

                    {task.description &&
                    <div className="task-details-comments">
                        <h6>Details</h6>
                        <div>
                            {task.description}
                        </div>
                    </div>
                    }

                    <div className="task-details-created">
                        <h6>Created</h6>
                        <TaskUserPhoto user={task.createdBy}/>
                        <p>by {!isEmpty(task.createdBy.firstName) ? task.createdBy.firstName : ""},
                            on {moment(task.createdDate).format("DD-MMM-YYYY")}</p>
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
