import React, {Component, useCallback, useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import { loadPatientMolecular } from "../../../actions/patients.actions";
import { createLoadingSelector, getPatientsData } from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import { showModal } from "../../../actions/modal.actions";
import _ from "lodash";
import BiomarkersOverview from "../../Biomarkers/BiomarkersOverview";
import {useParams} from "react-router-dom";

const Biomarkers = (
    {
        loadPatientMolecular,
        patientMolecular,
        loading,
        showModal,
        match
    }) => {

    const { id: patientId } = useParams();

    const showMolecularModal = useCallback(() => {
        showModal("BIOMARKERS_MODAL", patientId);
    }, [patientId]);

    const hasUntestedBiomarkers = useMemo(() => {
        return patientMolecular && patientMolecular.diagnosisBiomarkers && patientMolecular.diagnosisBiomarkers.find(bm => !bm.tested && bm.type);
    }, [patientMolecular]);

    const hasTests = useMemo(() => {
        return patientMolecular && !_.isEmpty(patientMolecular.tests);
    }, [patientMolecular]);

    useEffect(() => {
        loadPatientMolecular(patientId);
    }, [patientId]);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    {!loading && patientMolecular
                        ? <>
                            <div className="card-caption">
                                <h4 className="card-title">
                                    <button onClick={showMolecularModal}>Biomarkers</button>
                                </h4>
                            </div>

                            {(!hasTests && !hasUntestedBiomarkers) ?
                                (
                                    <span>No available data</span>
                                ) : (
                                    <>
                                        <div className="mb-4 mt-4">
                                            <BiomarkersOverview biomarkers={patientMolecular.diagnosisBiomarkers} />
                                        </div>
                                        {hasTests &&
                                            <div className="table-container">
                                                <table className="biomarkers table table-hover table-responsive-sm table-responsive-md">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-top-0">Date</th>
                                                            <th scope="col" className="border-top-0">Biomarker</th>
                                                            <th scope="col" className="border-top-0">Result</th>
                                                            <th scope="col" className="border-top-0">Site</th>
                                                            <th scope="col" className="border-top-0">Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {patientMolecular.tests.map((test, indexA) => {
                                                            return (test.items.map((item, indexB) => {
                                                                return (<>
                                                                    <tr key={indexA + indexB}>
                                                                        <td>{moment.parseZone(test.date).format("DD-MMM-YYYY")}</td>
                                                                        <td>{item.biomarker}</td>
                                                                        <td>{item.result || '-'}</td>
                                                                        <td>{item.site || '-'}</td>
                                                                        <td>{item.type || (item.isKbBiomarker && '-') || 'Unknown Significance'}</td>
                                                                    </tr>
                                                                </>);
                                                            }));
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </>
                                )
                            }
                        </> : <>

                            <div className="card-caption">
                                <h4 className="card-title">Biomarkers</h4>
                            </div>
                            <Loader size={5} color={"#ccc"} />

                        </>}

                </div>
            </div>
        </>
    )
}

const patientMolecularLoader = createLoadingSelector(['LOAD_PATIENT_MOLECULAR']);

const mapStateToProps = (state) => {

    const
        patientMolecular = getPatientsData(state).patientMolecular,
        loading = patientMolecularLoader(state);

    return {
        patientMolecular,
        loading
    };
};

const mapDispatchToProps = {
    loadPatientMolecular,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Biomarkers);