import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector, getErrorMessage, getModalData, getPatientsData} from "../../../utils/selectors";
import _ from "lodash";
import PointsOfContactEditor from "./PointsOfContactEditor";
import CareProvidersEditor from "./CareProvidersEditor";
import {
	loadCareProviders,
	loadPointsOfContact, updateCareProviders,
	updatePointsOfContact
} from "../../../actions/patients.actions";
import Loader from "react-spinners/BeatLoader";
import {toast} from "react-toastify";
import usersSvg from "../../../images/users.svg";
import doctorSvg from "../../../images/doctor.svg";

const SupportCircleData = (
	{
		patientId,
		loadPointsOfContact,
		loadCareProviders,
		pointsOfContact,
		careProviders,
		loadingPointsOfContact,
		loadingCareProviders,
		pointsOfContactError,
		careProvidersError,
		updatePointsOfContact,
		updateCareProviders,
	}) => {

	const [editingPocs, setEditingPocs] = useState(false);
	const [editingCps, setEditingCps] = useState(false);

	const [savingPointsOfContact, setSavingPointsOfContact] = useState(false);
	const [savingCareProviders, setSavingCareProviders] = useState(false);

	const pointOfContactsTableData = useMemo(() => {
		return _.sortBy((pointsOfContact || []), poc => poc.firstName);
	}, [pointsOfContact]);

	const careProvidersTableData = useMemo(() => {
		return _.sortBy((careProviders || []), cp => cp.firstName);
	}, [careProviders]);

	const handleOnPointsOfContactSave = useCallback((pointsOfContact) => {
		setSavingPointsOfContact(true);
		updatePointsOfContact(patientId, pointsOfContact).then((resp) => {
			if (resp.success) {
				loadPointsOfContact(patientId);
				setEditingPocs(false);
				toast.error("Points of contact have been successfully saved");
			} else {
				toast.error(resp.errorMessage, {autoClose: false});
			}
		}).catch((err) => {
			toast.error(err.message, {autoClose: false});
		}).finally(() => {
			setSavingPointsOfContact(false);
		})
	}, [patientId]);

	const handleOnPointsOfContactCancel = useCallback(() => {
		setEditingPocs(false);
	}, []);

	const handleOnCareProvidersSave = useCallback((careProviders) => {
		setSavingCareProviders(true);
		updateCareProviders(patientId, careProviders).then((resp) => {
			if (resp.success) {
				loadCareProviders(patientId);
				setEditingCps(false);
				toast.error("Care providers have been successfully saved");
			} else {
				toast.error(resp.errorMessage, {autoClose: false});
			}
		}).catch((err) => {
			toast.error(err.message, {autoClose: false});
		}).finally(() => {
			setSavingCareProviders(false);
		});
	}, [patientId]);

	const handleOnCareProvidersCancel = useCallback(() => {
		setEditingCps(false);
	}, []);

	return (
		<div className="ni-support-circle h-75">
			<div className="ni-section-header">
				<img style={{width: 30, height: 30}} className="text-primary" src={usersSvg}/>
				<h4>Points of Contact</h4>
			</div>
			{loadingPointsOfContact ? (
					<Loader size={5} color={"#ccc"}/>
				) : editingPocs ? (
					<PointsOfContactEditor
						pointsOfContact={pointsOfContact}
						loading={savingPointsOfContact}
						onSave={handleOnPointsOfContactSave}
						onCancel={handleOnPointsOfContactCancel} />
				) : pointsOfContactError ? (
					<div className='invalid-feedback'>{pointsOfContactError}</div>
				) : (
					<>
						<div className="text-end">
							<button
								type="button"
								className="btn btn-rnd btn-primary btn-cancel"
								onClick={() => setEditingPocs(true)}
							>
								<i className="fa fa-pencil-alt"/>
								<span>Edit</span>
							</button>
						</div>
						<table className="support-circle-table table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
								<tr>
									<th scope="col" className="border-top-0" title="Name">Name</th>
									<th scope="col" className="border-top-0" title="Relation">Relation</th>
									<th scope="col" className="border-top-0" title="Time Zone">Time Zone</th>
									<th scope="col" className="border-top-0" title="Phone">Phone</th>
									<th scope="col" className="border-top-0" title="Email">Email</th>
									<th scope="col" className="border-top-0" title="Email Copy">Email Copy</th>
								</tr>
							</thead>
							<tbody>
							{pointOfContactsTableData.map(item => {
								const name = [item.firstName, item.lastName].join(" ") || '-';
								const relationship = item.relationship || '-';
								const timezone = item.timezone || '-';
								const phone = item.phone || '-';
								const email = item.email || '-';
								const emailCopy = item.emailCopy  && 'Enabled' || 'Disabled';

								return (
									<tr>
										<td title={name}>{name}</td>
										<td title={relationship}>{relationship}</td>
										<td title={timezone}>{timezone}</td>
										<td title={phone}>{phone}</td>
										<td title={email}>{email}</td>
										<td title={emailCopy}>{emailCopy}</td>
									</tr>
								)
							})}
							</tbody>
						</table>
					</>
			)}

			<div className="ni-section-header">
				<img style={{width: 30, height: 30}} className="text-primary" src={doctorSvg}/>
				<h4>Care Providers</h4>
			</div>
			{loadingCareProviders ? (
				<Loader size={5} color={"#ccc"}/>
			) : editingCps ? (
				<CareProvidersEditor
					careProviders={careProviders}
					loading={savingCareProviders}
					onSave={handleOnCareProvidersSave}
					onCancel={handleOnCareProvidersCancel}
				/>
			) : careProvidersError ? (
				<div className='invalid-feedback'>{careProvidersError}</div>
			) : (
				<>
					<div className="text-end">
						<button
							type="button"
							className="btn btn-rnd btn-primary btn-cancel"
							onClick={() => setEditingCps(true)}
						>
							<i className="fa fa-pencil-alt"/>
							<span>Edit</span>
						</button>
					</div>
					<table className="support-circle-table table table-hover table-subtle table-responsive-sm table-responsive-md">
						<thead>
						<tr>
							<th scope="col" className="border-top-0" title="Name">Name</th>
							<th scope="col" className="border-top-0" title="Role">Role</th>
							<th scope="col" className="border-top-0" title="Time Zone">Time Zone</th>
							<th scope="col" className="border-top-0" title="Phone">Phone</th>
							<th scope="col" className="border-top-0" title="Email">Email</th>
						</tr>
						</thead>
						<tbody>
						{careProvidersTableData.map(item => {
							const name = [item.firstName, item.lastName].join(" ") || '-';
							const type = item.type || '-';
							const timezone = item.timezone || '-';
							const phone = item.phone || '-';
							const email = item.email || '-';

							return (
								<tr>
									<td title={name}>{name}{item.referringProvider &&
										<span className="badge badge-primary ms-2">REF</span>}</td>
									<td title={type}>{type}</td>
									<td title={timezone}>{timezone}</td>
									<td title={phone}>{phone}</td>
									<td title={email}>{email}</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</>
			)}
		</div>
	)
}

const SupportCircleModal = (props) => {

	const { hideModal } = props;

	const closeModal = useCallback(() => {
		hideModal();
	}, []);

	return (
		<>
			<div className="modal-header ni-support-circle">
				<h3 className="modal-title">Support Circle</h3>
			</div>

			<div className="modal-body ni-support-circle">
				<SupportCircleData {...props} />
			</div>
		</>
	);
}

const pointsOfContactLoader = createLoadingSelector(['LOAD_POINTS_OF_CONTACT']);
const careProvidersLoader = createLoadingSelector(['LOAD_CARE_PROVIDERS']);

const getPointsOfContactError = getErrorMessage(['LOAD_POINTS_OF_CONTACT']);
const getCareProvidersError = getErrorMessage(['LOAD_CARE_PROVIDERS']);

const mapStateToProps = (state) => {
	const {patientPointsOfContact, patientCareProviders} = getPatientsData(state);
	const patientId = getModalData(state).modalProps.patientId;

	const loadingPointsOfContact = pointsOfContactLoader(state);
	const loadingCareProviders = careProvidersLoader(state);

	const pointsOfContactError = getPointsOfContactError(state);
	const careProvidersError = getCareProvidersError(state);

	return {
		patientId: patientId,
		pointsOfContact: patientPointsOfContact,
		careProviders: patientCareProviders,
		loadingPointsOfContact,
		loadingCareProviders,
		pointsOfContactError,
		careProvidersError
	};
};

const mapDispatchToProps = {
	loadPointsOfContact,
	loadCareProviders,
	updatePointsOfContact,
	updateCareProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportCircleModal);
