import isEmpty from "./helpers";
import moment from "moment";
import _ from "lodash";
import {getAnalyteValueWithTooltip} from "./chart-utils";

export let analyte;
export let labels;
export const getCategorisedEvents = (patientTimeline) => {

    let categorisedEvents = {};

    if (!isEmpty(patientTimeline) && !isEmpty(patientTimeline.events)) {
        patientTimeline.events.map(event => {

            if (!categorisedEvents.hasOwnProperty(event.type)) {
                categorisedEvents[event.type] = [];
            }

            categorisedEvents[event.type].push(event);
        });
    }

    return categorisedEvents;
}

export const getCategorisedAnalytes = (patientTimeline) => {

    let categorisedAnalytes = {};

    if (!isEmpty(patientTimeline) && !isEmpty(patientTimeline.analytes)) {
        patientTimeline.analytes.map(analyte => {

            if (!categorisedAnalytes.hasOwnProperty(analyte.groupName)) {
                categorisedAnalytes[analyte.groupName] = [];
            }

            categorisedAnalytes[analyte.groupName].push(analyte);
        });
    }

    return categorisedAnalytes;
}


export const getTimelineEventsItems = (patientTimeline) => {
    const topItems = [];
    let itemId = 1000;

    // Add initial event - use a very high ID, so it appears above other items
    if (!isEmpty(patientTimeline.startEvent)) {

        topItems.push({
            id: 9001,
            start: patientTimeline.startEvent.date,
            content: isEmpty(patientTimeline.startEvent.description) ? patientTimeline.startEvent.title : patientTimeline.startEvent.title + "<span>" + patientTimeline.startEvent.description + "</span>",
            className: "diagnosis"
        })
    }

    let timelineEvents = getCategorisedEvents(patientTimeline);

    // Add all events
    Object.keys(timelineEvents).map(type => {
        timelineEvents[type].map(event => {

            itemId++;

            topItems.push({
                id: itemId,
                start: event.date,
                content:
                    (!isEmpty(event.title)
                            ? (!isEmpty(event.documentReference) && !isEmpty(event.documentReference.fileId))
                                ? "<span class='vis-item-link' data-id='" + event.documentReference.fileId + "' data-page='" + event.documentReference.pageNumber + "'>" + event.title + "</span>"
                                : event.title
                            : ""
                    ) +
                    (!isEmpty(event.description)
                            ? "<span>" + event.description + "</span>"
                            : ""
                    ),
                className:
                    event.type === 'LAB' ? "lightish-purple" :
                        event.type === 'IMAGING' ? "algae-green" :
                            event.type === 'INTERVENTION' ? "dusty-orange" :
                                event.type === 'PRO' ? "deep-sky-blue" :
                                    event.type === 'MD_COMMENTS' ? "brownish-grey" :
                                        event.type === 'HOSPITALIZATIONS' ? "dark-indigo" :
                                            event.type === 'ER_VISIT' ? "pinkish-red" :
                                                ""
            })

            // Ranges should have lower IDs than items, so they appear below them
            // {id: 101, start: "2020-09-24", end: "2020-09-28", className: "red", content: "Rt. frontal SRS <span>24-28.09</span>"},

            // Future events should be semi-transparent
            // Expected Results should have very high ID, so it appears above other items
            // {id: 9002, start: "2020-11-28", className: "green transparent", content: "Expected Results"},

        })
    });

    return topItems;
}

export const getTimelineAnalytesGroupsAndItems = (patientTimeline) => {

    const items = [
        // {id: 1, start: "2018-02-07", group: 0, className: "round purple", content: "<i class='far fa-file-alt' />"},
    ];

    const groups = [
        // {id: 0, title: "", content: "", treeLevel: 1},
        // {id: 1, className: "purple main", content: "CBC", treeLevel: 1, nestedGroups: [11, 12, 13, 14]}
    ];

    if (isEmpty(patientTimeline) || isEmpty(patientTimeline.analyteGroups)) {
        return [groups, items];
    }

    let groupId = 10000;
    let itemId = 1000000;

    const groupsMap = new Map();
    groupsMap.set("", {id: 0, title: "", content: "", treeLevel: 1})

    const groupPriorityList = ["Added Analytes", "General", "Markers"];

    const analyteGroups = patientTimeline.analyteGroups.sort((a1, a2) => {
        const groupComp = compareStrings(a1.name, a2.name, groupPriorityList);

        if (groupComp === 0) {
            return compareStrings(a1.name.replace(/[\W_]/g, ""), a2.name.replace(/[\W_]/g, ""));
        }

        return groupComp;
    })

    for (let group of analyteGroups) {

        let analyteTopGroup = {
            id: groupId,
            className: 'lightish-purple main',
            content: group.name,
            treeLevel: 1,
            nestedGroups: []
        };

        groupId++;
        groupsMap.set(group.name, analyteTopGroup);

        if (!group.analytes) {
            continue;
        }

        for (let analyte of group.analytes) {
            let analyteName = analyte.name.replace(/[\W_]/g, "");
            let analyteGroupMapKey = `${group.name} ${analyteName}`.toUpperCase();
            let analyteUnitIsInterpretation = !isEmpty(analyte.unit) && analyte.unit.toUpperCase() === "INTERPRETATION";
            let analyteUnit = "undefined";
            if (analyte.values && analyte.values.length > 0 && analyte.values[0].values[analyte.values[0].values.length - 1].unit) {
                analyteUnit = analyte.values[0].values[analyte.values[0].values.length - 1].unit;
            }
            analyteUnit = "unit-" + analyteUnit.replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '').replace(/\s/g, '-');
            let ranges;
            if (patientTimeline.analyteRanges[analyteName]) {
                ranges = patientTimeline.analyteRanges[analyteName];
            }

            let analyteGroup = {
                id: groupId,
                className: analyteName + " " + analyteUnit,
                content: analyte.name  +  (analyte.values[0].values[analyte.values[0].values.length - 1].unit != null? " - " + analyte.values[0].values[analyte.values[0].values.length - 1].unit: "") +
                    (group.name === 'Added Analytes' ?
                        "<div><i class='delete-icon' data-analyte='" + analyte.name + "' data-unit=" + analyteUnit + "></i></div>"
                        : "") +
                    (!isEmpty(patientTimeline.analyteRanges[analyteName]) && !analyteUnitIsInterpretation ?
                        "<div><i class='fas chart-line' data-analyte='" + analyteName + "' data-unit=" + analyteUnit + "></i></div>"
                        : "") +
                    (!isEmpty(analyte.additionalUnitValues) ?'<div class="visible-only">*Some values are presented on the X axis due to measure differences</div>': '')
                ,
                treeLevel: 2
            };
            groupId++;
            groupsMap.set(analyteGroupMapKey, analyteGroup);

            analyteTopGroup.nestedGroups.push(analyteGroup.id);
            // groupsMap.set(analyte.groupName, analyteTopGroup);
            // Dates that are already filled in
            const filledDates = [];
            for (let analyteValue of analyte.values) {
                const lastValue = analyteValue.values[analyteValue.values.length - 1].value;
                filledDates.push(analyteValue.date);
                items.push({
                    id: itemId,
                    start: analyteValue.date,
                    group: analyteGroup.id,
                    content: getAnalyteValueWithTooltip(analyteValue, analyte.additionalUnitValues, false),
                    className:
                        (isEmpty(lastValue) ? "" : lastValue.split('.').length > 1 ? " decimal" : "") +
                        (!isEmpty(ranges) && ((!_.isNil(ranges.min) && lastValue < ranges.min) || (!_.isNil(ranges.max) && lastValue > ranges.max)) ? " warning" : "")

                    // For modal opening analytes
                    // content: "<i class='far fa-file-alt'>" + analyte.value + "</i>",
                    // className: "round purple",
                });

                itemId++;
            }
            if (analyte.additionalUnitValues) {
                for (let analyteValue of analyte.additionalUnitValues) {
                    if (filledDates.includes(analyteValue.date)) {
                        continue;
                    }
                    const lastValue = analyteValue.values[analyteValue.values.length - 1].value;
                    filledDates.push(analyteValue.date);
                    items.push({
                        id: itemId,
                        start: analyteValue.date,
                        group: analyteGroup.id,
                        content: getAnalyteValueWithTooltip(analyteValue, null, true),
                        className:
                            (isEmpty(lastValue) ? "" : lastValue.split('.').length > 1 ? " decimal" : "") +
                            (!isEmpty(ranges) && ((!_.isNil(ranges.min) && lastValue < ranges.min) || (!_.isNil(ranges.max) && lastValue > ranges.max)) ? " warning" : "")

                        // For modal opening analytes
                        // content: "<i class='far fa-file-alt'>" + analyte.value + "</i>",
                        // className: "round purple",
                    });

                    itemId++;
                }
            }
        }
    }

    // const sortedGroups = _.sortBy(Array.from(groupsMap.values()), gr => gr.groupName);
    // const sortedGroupsIdMap = new Map();
    //
    // for (let i = 0; i < sortedGroups.length; ++i) {
    //     const group = sortedGroups[i];
    //     const newGroupId = 1000 + i;
    //
    //     sortedGroupsIdMap.set(group.id, newGroupId);
    //     group.id = newGroupId;
    // }
    //
    // sortedGroups.forEach(gr => gr.nestedGroups && gr.nestedGroups.map(ng => sortedGroupsIdMap.get(ng.group)).sort());
    //
    // items.forEach(i => i.group = sortedGroupsIdMap.get(i.group));

    return [Array.from(groupsMap.values()), items];
}

export const getEventDates = (patientTimeline) => {

    let timelineDates = [];
    let timelineMarks = {};

    // Get events dates
    if (!isEmpty(patientTimeline) && !isEmpty(patientTimeline.events)) {
        patientTimeline.events.map(event => {
            if (!timelineDates.includes(event.date)) {
                timelineDates.push(event.date);
            }
        });
    }

    // Get analytes dates
    if (!isEmpty(patientTimeline) && !isEmpty(patientTimeline.analyteGroups)) {
        patientTimeline.analyteGroups.map(group => {
            if (group.analytes) {
                group.analytes.map(analyte => {
                    if (analyte.values) {
                        analyte.values.map(value => {
                            if (!timelineDates.includes(value.date)) {
                                timelineDates.push(value.date);
                            }
                        })
                    }
                    if (analyte.additionalUnitValues) {
                        analyte.additionalUnitValues.map(value => {
                            if (!timelineDates.includes(value.date)) {
                                timelineDates.push(value.date);
                            }
                        })
                    }
                });
            }
        });
    }

    // Add Initial Event
    if (!isEmpty(patientTimeline.startEvent) && !timelineDates.includes(patientTimeline.startEvent.date)) {
        if (!timelineDates.includes(patientTimeline.startEvent.date)) {
            timelineDates.push(patientTimeline.startEvent.date);
        }
    }

    // Add Today
    if (!timelineDates.includes(moment().format("YYYY-MM-DD"))) {
        timelineDates.push(moment().format("YYYY-MM-DD"));
    }

    // Sort dates chronologically
    timelineDates.sort();

    // Add marks for all event dates
    timelineDates.map(date => {
        timelineMarks[Object.keys(timelineMarks).length] = date;
    });

    // End one day after last event - to add white space
    timelineMarks[Object.keys(timelineMarks).length] = moment(timelineDates[timelineDates.length - 1]).add(1, 'days').format("YYYY-MM-DD");

    return timelineMarks;
}


export const setGraphPosition = (component) => {
    const {timelineGraphAnalyteName} = component.state;

    let position = {};

    // Get containers
    let timelinePosition = component.timelineAnalytes.current;
    let groupPosition = document.querySelector('.vis-content .vis-foreground div.vis-group.graph-visible');

    // If there is an active graph
    if (!isEmpty(timelineGraphAnalyteName) && timelinePosition && groupPosition) {

        // Calculate positions
        position['left'] = timelinePosition.getElementsByClassName('vis-labelset')[0].clientWidth + 1;
        position['top'] = groupPosition.getBoundingClientRect().top - timelinePosition.getBoundingClientRect().top + 1;

        // Set positions
        component.timelineGraph.current.style.display = "block";
        component.timelineGraph.current.style.left = (position.left) + "px";
        component.timelineGraph.current.style.top = position.top + "px";
    }
}

export const setGraphPoints = (component, bounds) => {
    const {
        patientTimeline,
        rangeOptions,
        timelineGraphOptions,
        timelineGraphAnalyteName,
        timelineGraphAnalyteUnit
    } = component.state;

    // If there is an active graph
    if (!isEmpty(timelineGraphAnalyteName)) {

        let rangeOptionsStart = bounds[0];
        let rangeOptionsEnd = bounds[1];

        // Gather correct analyte
        let hasAdditionalVelues = false;

        for (let group of patientTimeline.analyteGroups) {
            for (let tmpAnalyte of group.analytes) {
                if (tmpAnalyte.name.replace(/[\W_]/g, "") === timelineGraphAnalyteName) {
                    analyte = tmpAnalyte;
                    break;
                }
            }
        }

        labels = [];
        let graphPoints = {
            labels: [], datasets: [
                {
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    pointBorderColor: 'black',
                    backgroundColor: 'black',
                    color: 'red',
                    data: []
                },
                {
                    pointStyle: [],
                    pointRadius: [],
                    pointHoverRadius: [],
                    pointBorderColor: 'black',
                    backgroundColor: 'black',
                    pointRotation: 90,
                    data: []
                }]
        };

        const minAndMax = {min: null, max: null};

        let currentDataset = graphPoints.datasets[0];
        for (let i of _.range(rangeOptionsStart, rangeOptionsEnd)) {
            let analyteValue = analyte.values.find(v => v.date === rangeOptions.marks[i]);
            labels.push(rangeOptions.marks[i]);
            currentDataset.data.push(!isEmpty(analyteValue) ? parseFloat(analyteValue.values[analyteValue.values.length - 1].value) : null);
            let additionalValue = analyte.additionalUnitValues? analyte.additionalUnitValues.find(v => v.date === rangeOptions.marks[i]): null;
            const pointHadAdditionalValues = (additionalValue != null && additionalValue.values != null && additionalValue.values.length > 0);
            hasAdditionalVelues |= pointHadAdditionalValues;

            graphPoints.datasets[1].pointRadius.push(pointHadAdditionalValues? 5: 10);
            graphPoints.datasets[1].pointStyle.push(pointHadAdditionalValues? 'point': 'line');
            graphPoints.datasets[1].pointHoverRadius.push(pointHadAdditionalValues? 5: 5);

            if (isEmpty(analyteValue)) {
                if (!isEmpty(analyte.additionalUnitValues) && !isEmpty(analyte.additionalUnitValues.find(v => v.date === rangeOptions.marks[i]))) {
                    currentDataset = {
                        pointRadius: 5,
                        pointHoverRadius: 5,
                        pointBorderWidth: 0,
                        pointHoverBorderWidth: 0,
                        pointBorderColor: 'black',
                        backgroundColor: 'black',
                        color: 'red',
                        data: []
                    };
                    for (let j = 0; j <= (i - rangeOptionsStart); j++) {
                        currentDataset.data.push(null);
                    }
                    graphPoints.datasets.push(currentDataset);
                }
                continue;
            }
            const floatVelue = parseFloat(analyteValue.values[analyteValue.values.length - 1].value);
            if (minAndMax.min == null) {
                minAndMax.min = floatVelue
            } else if (minAndMax.min > floatVelue) {
                minAndMax.min = floatVelue;
            }

            if (minAndMax.max == null) {
                minAndMax.max = floatVelue
            } else if (minAndMax.max < floatVelue) {
                minAndMax.max = floatVelue;
            }
        }
        graphPoints.labels = labels
        // Object.keys(rangeOptions.marks).map(i => {
        //     if (i >= rangeOptionsStart && i <= rangeOptionsEnd) {
        //     }
        // });

        let graphOptions = timelineGraphOptions;

        graphOptions.plugins.annotation = {annotations: {}};
        graphOptions.scales.y.ticks.min = undefined;
        graphOptions.scales.y.ticks.max = undefined;
        graphOptions.scales.y.ticks.stepSize = undefined;

        // If range areas are defined
        if (patientTimeline.analyteRanges[timelineGraphAnalyteName]) {
            const ranges = patientTimeline.analyteRanges[timelineGraphAnalyteName];

            const pointBackgroundColorFunc = function (context) {
                let value = context.dataset.data[context.dataIndex];
                return ((ranges && ranges.max && value > ranges.max) || (ranges && ranges.min && value < ranges.min)) ? '#D00' : '#5D5E5F';
            };
            for (let i = 0; i < graphPoints.datasets.length; i++) {
                if (i == 1) {
                    continue;
                }
                graphPoints.datasets[i]["pointBackgroundColor"] = pointBackgroundColorFunc;
            }

            if (!isEmpty(ranges.above1)) {
                if (minAndMax.max < ranges.above1.max) {
                    minAndMax.max = ranges.above1.max;
                }
                if (minAndMax.min > ranges.above1.min) {
                    minAndMax.min = ranges.above1.min;
                }
                graphOptions.plugins.annotation.annotations.boxAbove1 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.above1.min,
                    yMax: ranges.above1.max,
                    backgroundColor: '#f3f4f8',
                    borderColor: '#f3f4f8',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.above2)) {
                if (minAndMax.max < ranges.above2.max) {
                    minAndMax.max = ranges.above2.max;
                }
                if (minAndMax.min > ranges.above2.min) {
                    minAndMax.min = ranges.above2.min;
                }
                graphOptions.plugins.annotation.annotations.boxAbove2 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.above2.min,
                    yMax: ranges.above2.max,
                    backgroundColor: '#dadde8',
                    borderColor: '#dadde8',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.above3)) {
                if (minAndMax.max < ranges.above3.max) {
                    minAndMax.max = ranges.above3.max;
                }
                if (minAndMax.min > ranges.above3.min) {
                    minAndMax.min = ranges.above3.min;
                }
                graphOptions.plugins.annotation.annotations.boxAbove3 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.above3.min,
                    yMax: ranges.above3.max,
                    backgroundColor: '#babdcb',
                    borderColor: '#babdcb',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.above4)) {
                if (minAndMax.max < ranges.above4.max) {
                    minAndMax.max = ranges.above4.max;
                }
                if (minAndMax.min > ranges.above4.min) {
                    minAndMax.min = ranges.above4.min;
                }
                graphOptions.plugins.annotation.annotations.boxAbove4 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.above4.min,
                    yMax: ranges.above4.max,
                    backgroundColor: '#999eb0',
                    borderColor: '#999eb0',
                    borderWidth: 0
                };
            }

            // Set area backgrounds
            if (!isEmpty(ranges.below1)) {
                if (minAndMax.max < ranges.below1.max) {
                    minAndMax.max = ranges.below1.max;
                }
                if (minAndMax.min > ranges.below1.min) {
                    minAndMax.min = ranges.below1.min;
                }
                graphOptions.plugins.annotation.annotations.boxBelow1 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.below1.min,
                    yMax: ranges.below1.max,
                    backgroundColor: 'rgba(229, 229, 229,1)',
                    borderColor: 'rgba(229, 229, 229,1)',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.below2)) {
                if (minAndMax.max < ranges.below2.max) {
                    minAndMax.max = ranges.below2.max;
                }
                if (minAndMax.min > ranges.below2.min) {
                    minAndMax.min = ranges.below2.min;
                }
                graphOptions.plugins.annotation.annotations.boxBelow2 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.below2.min,
                    yMax: ranges.below2.max,
                    backgroundColor: 'rgba(206, 206, 206,1)',
                    borderColor: 'rgba(206, 206, 206,1)',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.below3)) {
                if (minAndMax.max < ranges.below3.max) {
                    minAndMax.max = ranges.below3.max;
                }
                if (minAndMax.min > ranges.below3.min) {
                    minAndMax.min = ranges.below3.min;
                }
                graphOptions.plugins.annotation.annotations.boxBelow3 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.below3.min,
                    yMax: ranges.below3.max,
                    backgroundColor: 'rgba(185, 185, 185,1)',
                    borderColor: 'rgba(185, 185, 185,1)',
                    borderWidth: 0
                };
            }
            if (!isEmpty(ranges.below4)) {
                if (minAndMax.max < ranges.below4.max) {
                    minAndMax.max = ranges.below4.max;
                }
                if (minAndMax.min > ranges.below4.min) {
                    minAndMax.min = ranges.below4.min;
                }
                graphOptions.plugins.annotation.annotations.boxBelow4 = {
                    drawTime: 'beforeDraw',
                    type: 'box',
                    yMin: ranges.below4.min,
                    yMax: ranges.below4.max,
                    backgroundColor: 'rgba(166, 166, 166,1)',
                    borderColor: 'rgba(166, 166, 166,1)',
                    borderWidth: 0
                };
            }

            if (!isEmpty(ranges.max)) {
                if (minAndMax.max < ranges.max) {
                    minAndMax.max = ranges.max;
                }
                if (minAndMax.min > ranges.max) {
                    minAndMax.min = ranges.max;
                }
                graphOptions.plugins.annotation.annotations.lineMax = {
                    type: 'line',
                    yMin: parseFloat(ranges.max),
                    yMax: parseFloat(ranges.max),
                    borderColor: 'red',
                    borderWidth: 1,
                    backgroundColor: 'transparent',
                    label: {
                        content: 'Above norm',
                        position: 'end',
                        color: 'red',
                        backgroundColor: 'rgba(0,0,0,0)',
                        padding: {left: 12, bottom: 6},
                        yAdjust: -10,
                        display: true,
                    }
                };
            }

            if (!isEmpty(ranges.min)) {
                if (minAndMax.max < ranges.min) {
                    minAndMax.max = ranges.min;
                }
                if (minAndMax.min > ranges.min) {
                    minAndMax.min = ranges.min;
                }
                graphOptions.plugins.annotation.annotations.lineMin = {
                    type: 'line',
                    yMin: parseFloat(ranges.min),
                    yMax: parseFloat(ranges.min),
                    borderColor: 'red',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    label: {
                        content: 'Below norm',
                        position: 'end',
                        color: 'red',
                        backgroundColor: 'rgba(0,0,0,0)',
                        padding: {left: 12, bottom: 6},
                        yAdjust: 10,
                        display: true,
                    }
                };
            }

            if (minAndMax.max == minAndMax.min) {
                minAndMax.min = minAndMax.min - 1;
                minAndMax.max = minAndMax.max + 1;
            }
            const stepSize = (minAndMax.max - minAndMax.min) / 7;

            let xAxisValue = minAndMax.min - stepSize;
            graphOptions.scales.y.min = minAndMax.min - ((hasAdditionalVelues? 2: 1) * stepSize);
            graphOptions.scales.y.max = minAndMax.max + stepSize;
            // graphOptions.scales.y.ticks.stepSize = 5;
            for (let i of _.range(rangeOptionsStart, rangeOptionsEnd)) {
                graphPoints.datasets[1].data.push(parseFloat(xAxisValue));
            }
        }

        graphOptions.plugins.datalabels.formatter = (value, context) => {
            const date = context.chart.data.labels[context.dataIndex];
            let values = analyte.values.find(v => v.date === date);
            let additionalValues = analyte.additionalUnitValues ? analyte.additionalUnitValues.find(v => v.date === date): null;
            if (context.datasetIndex == 0) {
                return value + (values.values.length > 1? " (+" + (values.values.length - 1) + ")": "");
            }

            if (!additionalValues || !additionalValues.values || additionalValues.values.length == 0) {
                return moment(context.chart.data.labels[context.dataIndex]).format('DD-MMM');
            }

            let textValue = additionalValues.values[additionalValues.values.length - 1].value;
            if (textValue.length > 7) {
                textValue = textValue.substring(0, 7) + '...'
            }
            return [moment(context.chart.data.labels[context.dataIndex]).format('DD-MMM'),
                textValue + (additionalValues.values[additionalValues.values.length - 1].unit != null? " " + additionalValues.values[additionalValues.values.length - 1].unit : "") +
                (additionalValues.values.length > 1? " (+" + (additionalValues.values.length - 1) + ")": "")];
        };

        // graphOptions.plugins.datalabels.listeners = {
        //     enter: function (context, event) {
        //         context.hovered = true
        //         context.chart.tooltip.setActiveElements([{
        //             datasetIndex: context.datasetIndex,
        //             index: context.dataIndex,
        //         }]);
        //         context.chart.update();
        //         return true;
        //     },
        //     leave: function (context, event) {
        //         // Receives `leave` events for any labels of any dataset.
        //         context.hovered = false;
        //         context.chart.tooltip.setActiveElements([]);
        //         context.chart.update();
        //         return true;
        //     }
        // };

        graphPoints.datasets[0].data.push(null);
        graphPoints.datasets[1].data.push(null);
        graphPoints.labels.push("");

        component.setState({timelineGraphOptions: graphOptions, timelineGraphData: graphPoints});
    }
}

// const getMaxAndMin = (analyte) => {
//     // get correct analytes
//     let max = parseFloat(analyte.values[0].values[0].value);
//     let min = parseFloat(analyte.values[0].values[0].value);
//
//     analyte.values.forEach(analyteValue => {
//         analyteValue.values.forEach(val => {
//             const curNumber = parseFloat(val.value);
//             if (curNumber > max) {
//                 max = curNumber;
//             }
//             if (curNumber < min) {
//                 min = curNumber;
//             }
//         })
//     });
//
//     return {max: max, min: min};
// }
//
const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string') {
        return str;
    }

    if (str.length === 0) {
        return str;
    }

    if (str.length === 1) {
        return str.charAt(0).toUpperCase();
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

const compareStrings = (a1, a2, priorityList) => {
    const isA1String = typeof a1 === 'string';
    const isA2String = typeof a2 === 'string';

    if (!isA1String && !isA2String) {
        return 0;
    } else if (!isA1String && isA2String) {
        return -1;
    } else if (isA1String && !isA2String) {
        return 1;
    }

    let str1 = a1;
    let str2 = a2;

    if (a1.length > 0 && a2.length > 0) {
        if (a1.charAt(0) !== a2.charAt(0)) {
            str1 = capitalizeFirstLetter(str1);
            str2 = capitalizeFirstLetter(str2);
        }
    }

    if (priorityList) {
        const revPriorityList = priorityList.reverse();
        const a1Idx = revPriorityList.indexOf(a1);
        const a2Idx = revPriorityList.indexOf(a2);

        if (a1Idx !== -1 || a2Idx !== -1) {
            return a2Idx - a1Idx;
        }
    }

    if (str1 > str2) {
        return 1;
    } else if (str1 < str2) {
        return -1;
    } else {
        return 0;
    }
}

/*const timelineTreatmentAnalytesItems = [
    {id: 1, start: "2020-09-24", group: 0, className: "round purple", content: "<i class='far fa-file-alt' />"},
    {id: 2, start: "2020-10-03", group: 0, className: "round purple", content: "<i class='far fa-file-alt' />"},
    {id: 3, start: "2020-10-10", group: 0, className: "round purple", content: "<i class='far fa-file-alt' />"},
    {id: 4, start: "2020-10-24", group: 0, className: "round purple", content: "<i class='far fa-file-alt' />"},

    {id: 101, start: "2020-09-24", group: 11, content: "13.50"},
    {id: 102, start: "2020-10-03", group: 11, content: "13.00"},
    {id: 103, start: "2020-10-10", group: 11, className: "warning", content: "4.00"},
    {id: 104, start: "2020-10-24", group: 11, content: "13.50"},

    {id: 201, start: "2020-09-24", group: 12, className: "warning", content: "11.90"},
    {id: 202, start: "2020-10-03", group: 12, className: "warning", content: "13.10"},
    {id: 203, start: "2020-10-10", group: 12, className: "warning", content: "12.90"},
    {id: 204, start: "2020-10-24", group: 12, className: "warning", content: "11.90"},

    {id: 301, start: "2020-09-24", group: 13, content: "672.00"},
    {id: 302, start: "2020-10-03", group: 13, content: "504.00"},
    {id: 303, start: "2020-10-10", group: 13, content: "371.00"},
    {id: 304, start: "2020-10-24", group: 13, content: "672.00"},
];*/

// const timelineDiseaseAnalytesGroups = [
//     {id: 0, content: "", treeLevel: 1},
//     {id: 1, content: "Tumor Markers", className: "purple main", treeLevel: 1, nestedGroups: [11, 12, 13, 14]},
//         {id: 11, content: "<strong>CEA</strong> (ng/mL) <strong>(<3)</strong>", treeLevel: 2},
//         {id: 12, content: "<strong>CA-15-3</strong> (U/mL) <strong>(<30)</strong>", treeLevel: 2},
//         {id: 13, content: "<strong>CA-125</strong> (U/mL) <strong>(<35)</strong>", treeLevel: 2},
//         {id: 14, content: "<strong>CA-19-9</strong> (kU/L) <strong>(<37)</strong>", treeLevel: 2},
//     {id: 2, content: "Tumor Size & Spread", className: "green main",treeLevel: 1, nestedGroups: [21, 22, 23]},
//         {id: 21, content: "Head & Neck", className: "grey-1", treeLevel: 2, nestedGroups: [211, 212, 2111], showNested: false},
//             {id: 211, content: "Brain", className: "grey-2", treeLevel: 3, nestedGroups: [2111], showNested: false},
//                 {id: 2111, content: "Right Frontal Cortex (mm)", className: "grey-3", treeLevel: 4},
//             {id: 212, content: "Neck", className: "grey-2", treeLevel: 3},
//         {id: 22, content: "Chest", className: "grey-1", treeLevel: 2},
//         {id: 23, content: "Abdomen & Pelvis", className: "grey-1", treeLevel: 2},
// ];