import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getAuthData} from "../../utils/selectors";
import {useLocation, useNavigate} from "react-router-dom";
import MessageStep from "./Common/MessageStep";
import CodeStep from "./ResetPasswordSteps/CodeStep";
import useSteps from "../../hooks/useSteps";
import NewPasswordStep from "./ResetPasswordSteps/NewPasswordStep";
import _ from "lodash";
import {toast} from "react-toastify";
import {axiosPublicInstance} from "../../utils/axios-instance";

const STEP = {
    CODE: "CODE",
    NEW_PASSWORD: "NEW_PASSWORD",
    CONFIRMATION: "CONFIRMATION",
    ERROR: "ERROR",
    NEW_LINK_SENT: "NEW_LINK_SENT",
}

const ResetPassword = ({isLoggedIn}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [inSending, setInSending] = useState(false);

    const [step, stepState, prevStep, setStep] = useSteps(token ? STEP.CODE : STEP.ERROR, {
        [STEP.CODE]: {
            codeToken: token,
        }
    });

    const handleOnCodeStepSuccess = useCallback(async (passwordToken, code) => {
        setStep(STEP.NEW_PASSWORD, {
            passwordToken: passwordToken,
            code: code,
        })
    }, []);

    const handleOnNewPasswordStepSuccess = useCallback(async (authToken) => {
        setStep(STEP.CONFIRMATION, {
            authToken: authToken
        })
    }, []);

    const handleOnCodeStepError = useCallback((error) => {
        toast.error(error.message);
        setStep(STEP.ERROR, {
            errorMessage: _.get(error, 'response.data.errorMessage', 'Invalid token'),
        });
    }, []);

    const sendNewResetLink = useCallback(async () => {
        try {
            setInSending(true)
            await axiosPublicInstance.post("/api/reset-password/resend-code", {
                email: token,
                source: 'PROFESSIONAL'
            });

            setStep(STEP.NEW_LINK_SENT, {})
        } catch (error) {
            let errorMessage = _.get(error, 'response.data.errorMessage', error.message);

            console.error(error);
            toast.error(errorMessage);
        }
    }, []);

    switch (step) {
        case STEP.CODE:
            return <CodeStep
                codeToken={stepState.codeToken}
                onSuccess={handleOnCodeStepSuccess}
                onError={handleOnCodeStepError}/>
        case STEP.NEW_PASSWORD:
            return <NewPasswordStep
                passwordToken={stepState.passwordToken}
                code={stepState.code}
                onSuccess={handleOnNewPasswordStepSuccess}/>
        case STEP.CONFIRMATION:
            return (
                <MessageStep
                    title="Reset Password"
                    description={
                        <div>
                            <div className="auth-new-password__result">
                                Success
                            </div>
                            <div className="auth-new-password__result-info">
                                You can now login to the app with your new password.
                            </div>
                            <div className="auth-new-password__submit text-center">
                                <button
                                    className="btn btn-lg btn-rnd btn-primary auth-new-password__submit-btn"
                                    type="button"
                                    onClick={() => navigate("/login")}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    }
                />
            )
        case STEP.ERROR:
            if (stepState.errorMessage === "Expired token") {
                return (
                    <MessageStep
                        title="Reset Password"
                        description={
                            <p className="text-center">
                                The reset password link you opened has expired. Click below to get a new link.<br/>
                                <button
                                    className="auth-email-confirmation__resend-btn"
                                    onClick={sendNewResetLink}
                                    disabled={inSending}
                                > Send new reset password link </button>
                            </p>
                        }
                    />
                )
            }
            return (
                <MessageStep
                    title="Reset Password"
                    description={
                        <p className="text-center">
                            <span className="text-danger">{stepState.errorMessage}</span><br/>
                            Please contact info@n1x10.com for assistance in login.
                        </p>
                    }
                />
            )
        case STEP.NEW_LINK_SENT:
            return (
                <MessageStep
                    title="Reset Password"
                    description={
                        <p className="text-center">
                            New reset password link was sent to your e-mail.
                        </p>
                    }
                />
            )
        default:
            return null;
    }
}

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;

    return {
        isLoggedIn,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);