import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment";

class CalendarEvent extends Component {

    state = {
        position: null,
    }

    constructor(props) {
        super(props);

        this.eventContainer = React.createRef();
    }

    componentDidMount() {
        this.calculateEventPosition();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.selectedEvent !== this.props.selectedEvent) {
            this.calculateEventPosition();
        }
    }

    calculateEventPosition = () => {

        let eventPosition = {};

        let calendarContainer = document.querySelector(".calendar-container").getBoundingClientRect();
        let selectedEventContainer = document.querySelector(".rbc-event.rbc-selected").getBoundingClientRect();
        let eventContainer = this.eventContainer.current.getBoundingClientRect();

        // If there is enough space to the right of selected event - position there
        if (calendarContainer.right - selectedEventContainer.right >= eventContainer.width) {
            eventPosition["left"] = selectedEventContainer.right - calendarContainer.left;
        }
        // Otherwise - position event on the left
        else {
            eventPosition["right"] = calendarContainer.right - selectedEventContainer.left;
        }

        // If there is enough space to the bottom of selected event - position there
        if (calendarContainer.bottom - selectedEventContainer.top >= eventContainer.height) {
            eventPosition["top"] = selectedEventContainer.top - calendarContainer.top;
        }
        // Otherwise - position event on the top
        else {
            eventPosition["bottom"] = calendarContainer.bottom - selectedEventContainer.bottom;
        }

        this.setState({position: eventPosition});
    }

    render() {

        const {position} = this.state;

        const {selectedEvent, handleShowHideEvent} = this.props;

        return (
            <>
                <div
                    ref={this.eventContainer}
                    className={"event" + (position ? " event-visible" : "")}
                    style={position && {
                        top: position.top || "",
                        bottom: position.bottom || "",
                        left: position.left || "",
                        right: position.right || "",
                    }}
                >
                    <div className="event-inner">

                        <div className="event-toolbar">
                            <button type="button" className="btn btn-link btn-edit">
                                <i className="fas fa-pencil-alt"/>
                            </button>
                            <button type="button" className="btn btn-link btn-delete">
                                <i className="fas fa-trash-alt"/>
                            </button>
                            <button type="button" className="btn btn-link btn-close" onClick={() => handleShowHideEvent(selectedEvent)}>
                                <i className="fas fa-times"/>
                            </button>
                        </div>

                        <h6 className={"event-title event-title-" + selectedEvent.category}>
                            {selectedEvent.category === 'imaging' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                (selectedEvent.originalEvent.scanType ? selectedEvent.originalEvent.scanType.replace('_', ' ').toUpperCase() : "Imaging") +
                                (selectedEvent.originalEvent.location ? ", " + selectedEvent.originalEvent.location : "")
                            ) : selectedEvent.category === 'md' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                (selectedEvent.originalEvent.reason ? selectedEvent.originalEvent.reason : "") +
                                (selectedEvent.originalEvent.reason && selectedEvent.originalEvent.department && ", " || "") +
                                (selectedEvent.originalEvent.department ? selectedEvent.originalEvent.department : "")
                            ) : selectedEvent.title === 'Blood test' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                selectedEvent.title +
                                (selectedEvent.institution ? " at " + selectedEvent.institution : "")
                            ) : selectedEvent.title === 'Genomic' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                selectedEvent.title +
                                (selectedEvent.institution ? " at " + selectedEvent.institution : "")
                            ) : selectedEvent.title === 'Intervention' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                selectedEvent.title +
                                (selectedEvent.institution ? " at " + selectedEvent.institution : "")
                            ) : selectedEvent.originalEvent && selectedEvent.originalEvent.actionName === 'surgery' ? (
                                (moment.utc(selectedEvent.start).format("Hms") !== "000" ? moment(selectedEvent.start).format("H:mm ") : "") +
                                (selectedEvent.originalEvent.type ? selectedEvent.originalEvent.type : "Surgery")
                            ) :
                            selectedEvent.title
                            }
                        </h6>
                        <div className={"event-caption" + (typeof selectedEvent.description !== 'string' && selectedEvent.description.length > 8 ? ' event-caption-overflowing' : '')}>
                            <p className={"event-description" + (typeof selectedEvent.description !== 'string' && selectedEvent.description.length > 8 ? ' event-description-overflowing' : '')}>
                                {typeof selectedEvent.description === 'string'
                                    ? selectedEvent.description
                                    : selectedEvent.description.map(item => <span>{item}</span>)
                                }
                            </p>
                        </div>

                        <p className="event-date">
                            {moment.utc(selectedEvent.start).format("Hms") !== "000"
                                ? moment(selectedEvent.start).format("dddd, MMMM Do hh:mm A")
                                : moment(selectedEvent.start).format("dddd, MMMM Do")
                            }
                        </p>

                        <p className="event-alarm">
                            <i className="far fa-bell"/> {selectedEvent.alarm || 'No alarm set.'}
                        </p>

                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEvent);