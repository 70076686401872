import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PanelType from './PanelTypes';
import {getPanelData} from "../../utils/selectors";
import {hidePanel, showPanel} from "../../actions/panel.actions";

const PANEL_COMPONENTS = {
    notes: PanelType.NotesPanel,
    tasks: PanelType.TasksPanel,
    calendar: PanelType.CalendarPanel,
    forms: PanelType.FormsPanel,
    messages: PanelType.MessagesPanel,
    upload: PanelType.FileUploadPanel,
    shareFile: PanelType.ShareFilePanel,
};

export class RootPanel extends Component {

    render() {

        const {
            isPanelOpen,
        } = this.props;

        if (!isPanelOpen) {
            return null;
        }

        const CurrentPanel = PANEL_COMPONENTS[isPanelOpen];

        return (
            <>
                <CurrentPanel {...this.props} />
            </>
        )
    }
}

const mapStateToProps = (state) => {

    const isPanelOpen = getPanelData(state).isPanelOpen;
    const panelProps = getPanelData(state).panelProps;

    return {
        isPanelOpen,
        panelProps,
    };
};

const mapDispatchToProps = {
    hidePanel,
    showPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootPanel);
