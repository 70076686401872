import React, {useMemo} from "react";
import _ from "lodash";

const BiomarkersOverview = ({biomarkers}) => {
    const untestedBiomarkers = useMemo(() => {
        return biomarkers && _.sortBy(biomarkers.filter(bm => !bm.tested), bm => bm.marker);
    }, [biomarkers]);

    const untestedNccnRecommendedBiomarkers = useMemo(() => {
        return untestedBiomarkers && untestedBiomarkers.filter(bm => bm.type && bm.type.toLowerCase() === 'NCCN Recommended'.toLowerCase());
    }, [untestedBiomarkers]);

    const untestedAdditionalActionableBiomarkers = useMemo(() => {
        return untestedBiomarkers && untestedBiomarkers.filter(bm => bm.type && bm.type.toLowerCase() === 'Additional actionable'.toLowerCase());
    }, [untestedBiomarkers]);

    const hasUntestedBiomarkers = useMemo(() => {
        return !_.isEmpty(untestedNccnRecommendedBiomarkers) || !_.isEmpty(untestedAdditionalActionableBiomarkers);
    }, [untestedNccnRecommendedBiomarkers, untestedAdditionalActionableBiomarkers]);

    return (
        <div className="biomarkers-overview">
            <div className="row">
                {hasUntestedBiomarkers ? (
                    <>
                        <div className="section col col-md-4">
                            <h5>Untested but relevant</h5>
                            <p>
                                Known to be relevant for client's cancer
                                diagnosis, but test data is unavailable.
                            </p>
                        </div>
                        <div className="section col col-md-4">
                            <h6>NCCN Recommended</h6>
                            <p>
                                {!_.isEmpty(untestedNccnRecommendedBiomarkers)
                                    ? untestedNccnRecommendedBiomarkers.map(bm => bm.marker).join(", ")
                                    : "-"
                                }
                            </p>
                        </div>
                        <div className="section col col-md-4">
                            <h6>Additional Actionable</h6>
                            <p>
                                {!_.isEmpty(untestedAdditionalActionableBiomarkers)
                                    ? untestedAdditionalActionableBiomarkers.map(bm => bm.marker).join(", ")
                                    : "-"
                                }
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="section col">
                        No available <span className="font-weight-500">data for biomarkers known to be relevant</span> for this
                        client's cancer diagnosis,
                        other than those already tested.
                    </div>
                )}
            </div>
        </div>
    );
}

export default BiomarkersOverview;