import React, {Component} from 'react';
import {connect} from 'react-redux';
import PatientInfo from "./Cards/PatientInfo";
import Loader from "react-spinners/BeatLoader";
import Slider, {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import Timeline from 'react-visjs-timeline';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import AnnotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from "moment";
import {loadPatientTreatmentTimeline} from "../../actions/patients.actions";
import isEmpty from "../../utils/helpers";
import {
    getTimelineEventsItems, getTimelineAnalytesGroupsAndItems,
    getEventDates,
    setGraphPosition, setGraphPoints
} from "../../utils/timeline-utils";
import {
    datalabelFont,
    externalTooltipHandler
} from "../../utils/chart-utils";
import TreatmentTimeline from "./Cards/TreatmentTimeline";
import {openDocument} from "../../utils/document-utils";
import withRouter from "../../wrappers/withRouter";
import {TREATMENT_TIMELINE} from "../Modals/Timeline/EditAnalytesModal";
import {showModal} from "../../actions/modal.actions";
import {deleteAnalyteTreatment} from "../../actions/user-settings.actions";
import NixioLink from "../Link/NixioLink";
import {useParams} from "react-router-dom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    AnnotationPlugin,
    ChartDataLabels,
);

class PatientDataTreatmentTimeline extends Component {

    constructor(props) {
        super(props);

        this.rangeSlider = React.createRef();
        this.timelineEvents = React.createRef();
        this.timelineEventsComponent = React.createRef();
        this.timelineAnalytes = React.createRef();
        this.timelineAnalytesComponent = React.createRef();
        this.timelineGraph = React.createRef();
        this.addAnalytesButton = React.createRef();
    }

    state = {
        patientTimeline: {},
        patientTimelineFiltered: {},
        timelineEventsLoading: true,
        timelineAnalytesLoading: true,
        timelineFilters: {
            startDate: null,
            endDate: null,
            includeLab: true,
            includeImaging: true,
            includeIntervention: true,
            includePRO: true,
            includeMDComments: true,
            includeHospitalVisits: true,
            includeErVisits: true,
        },
        rangeBounds: null,
        rangeOptions: null,
        rangeStart: null,
        rangeEnd: null,
        timelineEventsDensity: null,
        timelineEventsItems: null,
        timelineAnalytesItems: null,
        timelineAnalytesGroups: null,
        timelineEventsOptions: null,
        timelineAnalytesOptions: null,
        timelineGraphOptions: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            animation: false, spanGaps: true, maintainAspectRatio: false,
            legend: {display: false},
            layout: {padding: {left: 0, right: 0, top: 20, bottom: 10}},
            elements: {
                // point: {radius: 5, hoverRadius: 5, borderWidth: 0,},
                line: {tension: 0, fill: false, borderWidth: 1, borderColor: '#000'}
            },
            scales: {
                x: {gridLines: {display: false}, ticks: {display: false}, line: {display: false}},
                y: {
                    id: 'y', gridLines: {drawBorder: false}, ticks: {fontSize: 8}, afterFit: function (x) {
                        x.width = 32;
                    }
                }
            },
            annotation: {drawTime: 'beforeDatasetsDraw', annotations: []},
            plugins: {
                datalabels: {
                    align: 'start',
                    offset: 5,
                    padding: 2,
                    font: datalabelFont,
                    color: function(context) {
                        return context.datasetIndex != 1? '#D00': 'black';
                    },
                    backgroundColor: 'transparent',
                    textAlign: 'center',
                },
                legend: {display: false},
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    // custom: function (tooltipModel) {
                    //     console.log('test');
                    // },
                    intersect: false,
                    mode: 'nearest',
                    external: externalTooltipHandler
                },
            },
        },
        timelineGraphData: null,
        timelineGraphAnalyteName: null,
        timelineGraphAnalyteUnit: null,
    }

    componentDidMount() {
        this.reloadTimeline();
        window.addEventListener('click', this.handleTimelineClicks);
    }

    reloadTimeline() {
        const {location, navigate, params} = this.props.router;

        if (this.timelineAnalytes.current !== null) {
            const leftEls = this.timelineAnalytes.current.getElementsByClassName('vis-left');
            if (!isEmpty(leftEls)) {
                leftEls[0].style.width = "";
            }
        }

        this.props.loadPatientTreatmentTimeline(params.id, this.state.timelineFilters).then((response) => {

            let rangeStart = null, rangeEnd = null;

            // If we have a preset range
            if (location && location.state && location.state.range) {
                rangeStart = location.state.range[0];
                rangeEnd = location.state.range[1];
            }

            // Set the range
            this.setState(
                {
                    patientTimeline: response,
                    patientTimelineFiltered: response,
                    rangeStart: rangeStart,
                    rangeEnd: rangeEnd,
                },
                () => {

                    // Build the timelines
                    this.buildTimelines();


                    // Remove history state so we don't adjust scope again
                    navigate(location.search, {replace: true});
                }
            );
        });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleTimelineClicks);
    }

    buildTimelines = () => {
        const {patientTimelineFiltered, rangeStart, rangeEnd} = this.state;

        // Reset active graph
        this.setState({timelineGraphAnalyteName: null, timelineGraphAnalyteUnit: null, timelineGraphData: null});

        // Reset active graph styles
        document.querySelectorAll('.vis-timeline.vis-bottom .graph-visible').forEach(element => {
            element.classList.remove("graph-visible")
        });

        // Create dates for all events
        let rangeOptions = {step: null, dots: false, allowCross: false, pushable: 1, min: 0};
        rangeOptions.marks = getEventDates(patientTimelineFiltered);
        rangeOptions.max = Object.keys(rangeOptions.marks).length - 1;

        // If range has been changed
        if (rangeStart && rangeEnd) {

            // Try to find range start and end among new dates
            let rangeStartIndex = Object.keys(rangeOptions.marks).find(key => rangeOptions.marks[key] === rangeStart) || 0;
            let rangeEndIndex = Object.keys(rangeOptions.marks).find(key => rangeOptions.marks[key] === rangeEnd) || 0;

            // If start index is not found
            if (rangeStartIndex === 0) {

                // Find the closest start date before the current one
                Object.keys(rangeOptions.marks).map(key => {

                    if (moment(rangeOptions.marks[key]).isBefore(rangeStart)) {
                        rangeStartIndex = key;
                    }
                });
            }

            // If end index is not found
            if (rangeEndIndex === 0) {

                // Find the closest end date after the current one
                Object.keys(rangeOptions.marks).map(key => {

                    // Don't invoke additional moment checks if rangeEndIndex has already been found
                    if (rangeEndIndex === 0) {
                        if (moment(rangeOptions.marks[key]).isAfter(rangeEnd)) {
                            rangeEndIndex = key;
                        }
                    }
                });
            }

            rangeOptions.defaultValue = [rangeStartIndex, rangeEndIndex];
        }

            // If range has NOT been changed
            // Event range - first event prior to start of month today - 6 months to today (inclusive)
        // Example - today 15-10-2021 - range - first event before 01-04-2021 to 15-10-2021 (inclusive)
        else {

            let startIndex = null;
            const maxDate = rangeOptions.marks[Object.keys(rangeOptions.marks).length - 1];
            const minDate = moment(maxDate).subtract(6, 'months').startOf('month');

            for (let index = Object.keys(rangeOptions.marks).length - 1; index >= 0; index--) {
                if (moment(rangeOptions.marks[index]).isBefore(minDate)) {
                    startIndex = index;
                    break;
                }
            }

            rangeOptions.defaultValue = [startIndex || 0, Object.keys(rangeOptions.marks).length - 1];
        }

        this.setState({rangeOptions: rangeOptions})

        // Hide all other dates
        let hiddenDates = this.getHiddenDates(rangeOptions.marks);

        // Events Timeline Items
        this.setState({timelineEventsItems: getTimelineEventsItems(patientTimelineFiltered)});

        // Analytes Timeline Groups and Items
        const timelineAnalytesGroupsAndItems = getTimelineAnalytesGroupsAndItems(patientTimelineFiltered);
        this.setState({timelineAnalytesGroups: timelineAnalytesGroupsAndItems[0]});
        this.setState({timelineAnalytesItems: timelineAnalytesGroupsAndItems[1]});

        // Display timeline immediately if no analytes
        if (isEmpty(timelineAnalytesGroupsAndItems[0])) {
            this.setState({timelineAnalytesLoading: false});
        }

        // Order Timeline Items by ID, so order is retained while zooming
        function orderById(a, b) {
            return a.id - b.id;
        }

        // Common Timeline Options
        let timelinesCommonOptions = {
            min: rangeOptions.marks[0] || '0',
            start: rangeOptions.marks[rangeOptions.defaultValue[0]] || '0',
            max: rangeOptions.marks[Object.keys(rangeOptions.marks).length - 1] || '0',
            end: rangeOptions.marks[rangeOptions.defaultValue[1]],
            align: 'center',
            showCurrentTime: false,
            timeAxis: {scale: 'day', step: 1},
            selectable: false,
            moveable: false,
            zoomable: false,
            hiddenDates: hiddenDates
        };

        this.setState({
            rangeBounds: [rangeOptions.defaultValue[0], rangeOptions.defaultValue[1]],
            timelineEventsOptions: {
                ...timelinesCommonOptions,
                order: orderById,
                showMinorLabels: false,
                orientation: {axis: 'top', item: 'bottom'},
                margin: {item: {horizontal: 10, vertical: 10,}},
                format: {majorLabels: {day: 'YYYY MMM'}},
                onInitialDrawComplete: () => {
                    this.setState({timelineEventsLoading: false});
                    this.syncTimelinesMenuWidth();
                    this.timelineEventsComponent.current.$el._redraw();
                    setTimeout(() => this.calculateEventsDensity(), 500);
                }
            },
            timelineAnalytesOptions: {
                ...timelinesCommonOptions,
                showMajorLabels: false,
                orientation: 'top',
                stack: false,
                margin: {item: {horizontal: 5, vertical: 5,}},
                format: {minorLabels: {day: 'DD-MMM'}},
                onInitialDrawComplete: () => {
                    this.setState({timelineAnalytesLoading: false});
                    this.syncTimelinesMenuWidth();
                    this.calculateEventsDensity();
                }
            },
        }, () => {

            // Set events timeline range
            if (this.timelineEventsComponent.current) {
                this.timelineEventsComponent.current.$el.setWindow(
                    rangeOptions.marks[rangeStart],
                    rangeOptions.marks[rangeEnd],
                    {animation: false}
                );
                this.timelineEventsComponent.current.$el._redraw();
            }

            // Set analytes timeline range
            if (this.timelineAnalytesComponent.current) {
                this.timelineAnalytesComponent.current.$el.setWindow(
                    rangeOptions.marks[rangeStart],
                    rangeOptions.marks[rangeEnd],
                    {animation: false}
                );
                this.timelineAnalytesComponent.current.$el._redraw();
            }
        });
    }

    calculateEventsDensity = (bounds) => {
        // if (bounds) {
        //     this.setState({
        //         timelineEventsDensity: Math.floor((bounds[1] - bounds[0]) / 20)
        //     });
        // }
    }

    handleFilterChange = (filter) => {
        const {patientTimeline, patientTimelineFiltered, timelineFilters} = this.state;

        this.setState({isLoading: true}, () => {

            setTimeout(() => {
                let updatedFilters = timelineFilters;
                updatedFilters[filter] = !updatedFilters[filter];

                let updatedEvents = patientTimeline.events;

                if (timelineFilters.includeLab === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "LAB");
                if (timelineFilters.includeImaging === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "IMAGING");
                if (timelineFilters.includeIntervention === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "INTERVENTION");
                if (timelineFilters.includePRO === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "PRO");
                if (timelineFilters.includeMDComments === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "MD_COMMENTS");
                if (timelineFilters.includeHospitalVisits === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "HOSPITALIZATIONS");
                if (timelineFilters.includeErVisits === false)
                    updatedEvents = updatedEvents.filter(event => event.type !== "ER_VISIT");

                this.setState(
                    {
                        patientTimelineFiltered: {
                            ...patientTimelineFiltered,
                            events: updatedEvents,
                        },
                        timelineFilters: updatedFilters,
                    },
                    () => {
                        this.buildTimelines();
                        this.setState({isLoading: false});
                    }
                );
            }, 0)
        });
    }

    handleChangeRange = () => {
        const {rangeOptions, timelineGraphAnalyteName} = this.state;

        return (bounds) => {
            this.setState({rangeBounds: bounds}, () => {
                setTimeout(() => {

                    if (this.timelineEventsComponent.current) {

                        // Get timeline range
                        let timelineStart = rangeOptions.marks[bounds[0]];
                        let timelineEnd = rangeOptions.marks[bounds[1]];
                        let timelineAnimation = {animation: false};

                        // Store timeline range
                        this.setState({
                            rangeStart: timelineStart,
                            rangeEnd: timelineEnd,
                        });

                        // Set events timeline range
                        this.timelineEventsComponent.current.$el.setWindow(timelineStart, timelineEnd, timelineAnimation);

                        // Set analytes timeline range
                        if (this.timelineAnalytesComponent.current) {
                            this.timelineAnalytesComponent.current.$el.setWindow(timelineStart, timelineEnd, timelineAnimation);
                        }

                        // Set graph points
                        if (!isEmpty(timelineGraphAnalyteName)) {
                            setGraphPoints(this, bounds);
                        }

                        // Recalculate events density
                        this.calculateEventsDensity(bounds);
                    }
                }, 0);
            })
        }
    }

    syncTimelinesMenuWidth = () => {
        const {timelineGraphAnalyteName} = this.state;

        let menuWidth;

        // Get menu size of analytes timeline
        if (this.timelineAnalytes.current !== null) {
            menuWidth = this.timelineAnalytes.current.getElementsByClassName('vis-labelset')[0].parentNode.clientWidth;
            if (menuWidth < 255) {
                menuWidth = 255;
            }
            const leftEl = this.timelineAnalytes.current.getElementsByClassName('vis-left')[0];
            if (leftEl.style.width.indexOf("px") < 0) {
                leftEl.style.width = (menuWidth + 25) + "px";
                if (this.timelineAnalytes.current.$el != undefined) {
                    this.timelineAnalytes.current.$el._redraw();
                }
            }
        }
        // Otherwise use an arbitrary value for spacing
        else {
            menuWidth = 64;
        }

        // Set menu size of events timeline
        if (this.timelineEvents.current !== null) {
            this.timelineEvents.current.getElementsByClassName('vis-labelset')[0].style.width = menuWidth + "px";
        }

        if (this.addAnalytesButton.current !== null) {
            this.addAnalytesButton.current.style.width = (menuWidth-30) + "px";
        }

        // Redraw events timeline
        if (this.timelineEvents.current !== null && this.timelineEvents.current.$el !== undefined) {
            this.timelineEventsComponent.current.$el._redraw();
        }

        // Set graph position
        if (!isEmpty(timelineGraphAnalyteName)) {
            setGraphPosition(this);
        }
    }

    getHiddenDates = (dates) => {

        let hiddenDates = [];

        if (!isEmpty(dates)) {

            // Hide labels for dates with no events
            Object.keys(dates).map(i => {
                if (i != 0 && i != (Object.keys(dates).length - 1)) {

                    hiddenDates.push({
                        start: moment(dates[i - 1]).add('1', 'days').format('YYYY-MM-DD') + ' 00:00:00',
                        end: dates[i] + ' 00:00:00'
                    })
                }
            });
        }

        return hiddenDates;
    }

    handleTimelineClicks = (event) => {

        // Handle document links
        if (event.target.classList && event.target.classList.value === 'vis-item-link') {
            this.handleDocumentOpen(event);
        } else if (event.target.classList && event.target.classList.value === 'delete-icon')  {
            this.handleAnalyteDelete(event);
        } else {
            this.handleGraphShowHide(event);
        }
    }

    handleAnalyteDelete = (event) => {
        let analyteName = event.target.getAttribute('data-analyte');
        this.props.showModal("GENERIC_CONFIRM_MODAL", {
            title: 'Delete',
            message: `Are you sure you want to delete <b>${analyteName}</b> from the analytes list?`,
            rejectText: 'No, save',
            rejectHandler: () => {
            },
            confirmText: 'Yes, Delete',
            confirmHandler: () => {
                const {params} = this.props.router;
                this.props.deleteAnalyteTreatment(params.id, analyteName)
                    .then((response) => {
                        this.reloadTimeline();
                    });
            },
        });
    }

    handleGraphShowHide = (event) => {
        const {timelineGraphAnalyteName, timelineGraphAnalyteUnit} = this.state;

        let analyteName = event.target.getAttribute('data-analyte');
        let analyteUnit = event.target.getAttribute('data-unit');

        if (analyteUnit === 'undefined') analyteUnit = undefined;

        // If clicked element was a group
        if ((event.target.classList && event.target.classList.contains("vis-nesting-group")) ||
            (event.target.parentNode.classList && event.target.parentNode.classList.contains("vis-nesting-group"))) {

            // If there is an active graph
            if (!isEmpty(timelineGraphAnalyteName)) {

                // If it belonged to a group that was collapsed
                if (document.querySelector('i[data-analyte="' + timelineGraphAnalyteName + '"]') === null) {

                    // Reset active graph
                    this.setState({
                        timelineGraphAnalyteName: null,
                        timelineGraphAnalyteUnit: null,
                        timelineGraphData: null
                    });
                } else {

                    // Otherwise reapply graph style that Timeline.redraw() might have removed
                    document.querySelector('i[data-analyte="' + timelineGraphAnalyteName + '"][data-unit="' + timelineGraphAnalyteUnit + '"]').classList.add("graph-visible");
                }
            }
        }

        // If clicked element was a graph toggle
        if (!isEmpty(analyteName)) {

            let analyteToggle = event.target.classList.value;

            // Reset active graph
            this.setState({timelineGraphAnalyteName: null, timelineGraphAnalyteUnit: null, timelineGraphData: null});

            // Reset active graph styles
            document.querySelectorAll('.vis-timeline.vis-bottom .graph-visible').forEach(element => {
                element.classList.remove("graph-visible")
            });

            // Activate current graph
            if (analyteToggle !== 'fas chart-line graph-visible') {

                document.querySelector('i.fas[data-analyte="' + analyteName + '"][data-unit="' + analyteUnit + '"]').classList.add("graph-visible");
                document.querySelector('.vis-labelset .vis-nested-group.' + analyteName + '.' + analyteUnit).classList.add("graph-visible");
                document.querySelector('.vis-content .vis-background div.vis-group.' + analyteName + '.' + analyteUnit).classList.add("graph-visible");
                document.querySelector('.vis-content .vis-foreground div.vis-group.' + analyteName + '.' + analyteUnit).classList.add("graph-visible");

                // Set active analyte
                this.setState({timelineGraphAnalyteName: analyteName, timelineGraphAnalyteUnit: analyteUnit}, () => {

                    // Set graph position
                    setGraphPosition(this);

                    // Set graph points
                    setGraphPoints(this, this.state.rangeBounds);

                });
            } else {
                this.timelineGraph.current.style.display = 'none';
            }

            // Update Timeline Height
            this.timelineAnalytesComponent.current.$el.redraw();
        }
    }

    handleDocumentOpen = (event) => {
        this.handleViewFullReport(event.target.dataset.id, event.target.dataset.page)
    }

    handleViewFullReport = (documentId, pageNumber) => {
        openDocument(this.props.router.params.id, documentId, pageNumber);
    }

    showAddAnalytes = () => {
        this.props.showModal("EDIT_ANALYTES_MODAL", {analytesType: TREATMENT_TIMELINE, reloadView: this});
    }

    render() {

        const {
            isLoading,
            timelineEventsLoading,
            timelineAnalytesLoading,
            timelineFilters,
            rangeOptions,
            timelineEventsDensity,
            timelineEventsItems,
            timelineAnalytesItems,
            timelineAnalytesGroups,
            timelineEventsOptions,
            timelineAnalytesOptions,
            timelineGraphOptions,
            timelineGraphData,
            timelineGraphAnalyteName,
            rangeStart,
            rangeEnd,
        } = this.state;

        const {
            isPanelVisible,
        } = this.props;

        // Open respective modals/graphs
        function clickHandler(props) {
            let isClickable = props.event.target.parentNode.className.includes("clickable");
            let isItem = props.what === "item";
            let itemId = props.item;

            if (isItem && isClickable && itemId !== null) {
                console.log('open detailed view')
            }
        }

        return (
            <>
                <div
                    className={"layout-patient layout-patient-timeline" + (isPanelVisible ? " layout-patient-action-menu-open" : "")}>
                    <div className="row">
                        <div className="col col-12 col-lg-3">

                            <PatientInfo
                                showDetails={false}
                                {...this.props}
                            />

                        </div>
                        <TreatmentTimeline
                            isLoading={isLoading}
                            timelineFilters={timelineFilters}
                            handleFilterChange={this.handleFilterChange}
                            rangeStart={rangeStart}
                            rangeEnd={rangeEnd}
                            {...this.props}
                        />
                    </div>
                    <div className="timeline" data-density={timelineEventsDensity}>

                        {isLoading || timelineEventsLoading || timelineAnalytesLoading
                            ?
                            <div className="timeline-loader">
                                <Loader size={10} color={"#32383e"}/>
                            </div>
                            :
                            <div className="timeline-range">
                                <Slider
                                    range
                                    ref={this.rangeSlider}
                                    min={rangeOptions.min}
                                    max={rangeOptions.max}
                                    defaultValue={rangeOptions.defaultValue}
                                    step={rangeOptions.step}
                                    dots={rangeOptions.dots}
                                    allowCross={rangeOptions.allowCross}
                                    pushable={rangeOptions.pushable}
                                    marks={rangeOptions.marks}
                                    onChange={this.handleChangeRange()}
                                    draggableTrack={true}
                                />
                            </div>
                        }

                        {!isEmpty(rangeOptions) &&
                            <div className="timeline-container">

                                {!isEmpty(timelineEventsOptions) &&
                                    <div className="timeline-events" ref={this.timelineEvents}>
                                        <Timeline
                                            ref={this.timelineEventsComponent}
                                            options={timelineEventsOptions}
                                            items={timelineEventsItems}
                                            clickHandler={clickHandler}
                                        />
                                    </div>
                                }

                                {!isEmpty(timelineAnalytesOptions) &&
                                    <div className="timeline-analytes" ref={this.timelineAnalytes}>
                                        <div className="add-analytes-button" ref={this.addAnalytesButton}>
                                            <button
                                                className="btn-timeline-inactive left"
                                                type="button"
                                                disabled
                                            >
                                                Treatment
                                            </button>
                                            <NixioLink
                                                className="btn-timeline-active right"
                                                path={`/patient/${this.props.router.params.id}/disease-timeline`}
                                                state={{range: [rangeStart, rangeEnd]}}
                                            >
                                                Disease
                                            </NixioLink>
                                            <i className="ni-plus" title="Add analytes" onClick={this.showAddAnalytes}/>
                                        </div>

                                        <Timeline
                                            ref={this.timelineAnalytesComponent}
                                            options={timelineAnalytesOptions}
                                            items={timelineAnalytesItems}
                                            groups={timelineAnalytesGroups}
                                            changedHandler={this.syncTimelinesMenuWidth}
                                        />

                                        <div className="graph" ref={this.timelineGraph}>
                                            {!isEmpty(timelineGraphAnalyteName) && !isEmpty(timelineGraphData) &&
                                                <Line
                                                    data={timelineGraphData}
                                                    plugins={[ChartDataLabels]}
                                                    options={timelineGraphOptions}
                                                />
                                            }
                                        </div>

                                    </div>
                                }

                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {

    return {}
}

const mapDispatchToProps = {
    loadPatientTreatmentTimeline,
    showModal,
    deleteAnalyteTreatment,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDataTreatmentTimeline));