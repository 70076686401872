import React from 'react'
import {connect} from "react-redux";
import {Container} from "react-bootstrap";
import BasicHeader from "../BasicHeader";

const SharedViewLayout = (props) => {
    return (
        <>
            <BasicHeader {...props} />
            <main className="p-0">
                <Container className="p-0" fluid>
                    {props.children}
                </Container>
            </main>
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedViewLayout);
