import {LOAD_PRO_HISTORY} from "../actions/pro.actions";
import {DECREASE_UNREAD_FILES, LOAD_UNREAD_FILES} from "../actions/file.actions";

let initialState = {
    unreadFilesCount: 0,
};

export const fileReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_UNREAD_FILES.SUCCESS:
            return {
                ...state,
                unreadFilesCount: action.payload.data,
            };
        case DECREASE_UNREAD_FILES.SUCCESS:
            return {
                ...state,
                unreadFilesCount: (state.unreadFilesCount - action.payload),
            };
        default:
            return state;
    }
};
