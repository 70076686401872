import React, {Component} from 'react';

class PanelSearch extends Component {

    state = {
        searchTerm: undefined,
    }

    handleSearchTerm = (event) => {
        this.setState({
            searchTerm: event.target.value,
        })
    }

    render() {

        const {
            searchTerm,
        } = this.state;

        const {
            placeholder,
            handleSearch,
            onKeyPress
        } = this.props;

        return (
            <div className="panel-search">
                <div className="input-group input-group-sm">

                    <input
                        type="text"
                        className="form-control"
                        value={searchTerm}
                        placeholder={placeholder}
                        onChange={(e) => this.handleSearchTerm(e)}
                        onKeyPress={onKeyPress ? (e) => onKeyPress(e, searchTerm) : () => {}}
                    />

                    <div className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleSearch(searchTerm)}
                        >
                            <i className="fas fa-search"/>
                        </button>
                    </div>

                </div>
            </div>
        )
    }
}

export default PanelSearch;
