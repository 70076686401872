import React, {Component} from 'react';
import {connect} from 'react-redux';
import ActivityMonitor from "./Cards/ActivityMonitor/ActivityMonitor";
import PatientReportedOutcomes from "./Cards/PatientReportedOutcomes";
import {createLoadingSelector, getProData} from "../../utils/selectors";
import {loadProCategories, loadProHistory} from "../../actions/pro.actions";

class PatientDataPro extends Component {

    componentDidMount() {
        this.props.loadProCategories();
    }

    componentWillUnmount() {
    }

    render () {

        const {
            proCategoriesLoading,
            proHistoryLoading,
            proCategories,
            proHistory,
            isPanelVisible,
        } = this.props;

        return (
            <>
                <div className={"layout-patient layout-patient-home" + (isPanelVisible ? " layout-patient-action-menu-open" : "")}>

                    <ActivityMonitor {...this.props} />

                    <PatientReportedOutcomes {...this.props} />

                </div>
            </>
        )
    }
}

const proCategoriesLoader = createLoadingSelector(['LOAD_PRO_CATEGORIES']);
const proHistoryLoader = createLoadingSelector(['LOAD_PRO_HISTORY']);

const mapStateToProps = (state) => {

    const proCategoriesLoading = proCategoriesLoader(state);
    const proHistoryLoading = proHistoryLoader(state);
    const proCategories = getProData(state).proCategories;
    const proHistory = getProData(state).proHistory;

    return {
        proCategoriesLoading,
        proHistoryLoading,
        proCategories,
        proHistory,
    };
};

const mapDispatchToProps = {
    loadProCategories,
    loadProHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataPro);