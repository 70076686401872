
let initialState = {
    calendar: [],
};

export const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
