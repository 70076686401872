import React, {Component} from 'react';
import {isEmpty} from "../../utils/helpers";
import {debounce} from "lodash"
import Loader from "react-spinners/BeatLoader";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PropTypes from "prop-types";


class DocumentViewer extends Component {
    state = {
        isLoaded: false,
        isInitialised: false,
        fileError: false,
        fileContent: null,
        documentWidth: null,
        pageOffset: null,
        pagesRendered: 0,
        pagesTotal: null,
        pageNumber: null,
        pages: [],
        textLayersRendered: 0,
        textLayers: null,
        searchInitialised: false,
        searchDisplayed: false,
        searchTerm: '',
        searchResults: null,
        searchResultHighlighted: null,
        zoomLevel: 1,
        contentStyle: null,
    }

    constructor(props) {
        super(props);

        this.documentToolbar = React.createRef();
        this.documentContent = React.createRef();
        this.searchInput = React.createRef();
        this.pdfContainer = React.createRef();
    }

    loadDocument = (file, pageNumber) => {
        // If Document ID found
        if (file) {
            let fileContent;
            if (window.navigator.msSaveOrOpenBlob || navigator.userAgent.match('FxiOS') || navigator.userAgent.match('CriOS')) {
                // Show error message
                this.setState({isLoaded: true, fileError: true});
            } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                fileContent = window.URL.createObjectURL(file);
            } else {
                fileContent = URL.createObjectURL(file);
            }

            // Store file for displaying
            this.setState({isLoaded: true, pageNumber: pageNumber ? parseInt(pageNumber) : 1, fileContent: fileContent});

            // Set width to fill screen on resize
            window.addEventListener("resize", debounce(this.setDocumentWidth, 500));

            // Capture find shortcut
            window.addEventListener("keydown", (event) => this.captureFindShortcut(event));
        } else {
            this.setState({isLoaded: false, fileError: false});
        }
    }

    componentDidMount() {
        this.loadDocument(this.props.file, this.props.pageNumber);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.file !== this.props.file) {
            this.loadDocument(this.props.file, this.props.pageNumber);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", debounce(this.setDocumentWidth, 500));
        window.removeEventListener("keydown", (event) => this.captureFindShortcut(event));
    }

    handleDocumentError = () => {
        // Indicate Document is initialised
        this.setState({isInitialised: true});
    }

    handlePageRender = () => {
        this.setState({pagesRendered: this.state.pagesRendered + 1}, () => {
            if (!this.state.isInitialised && this.state.pagesRendered === this.state.pagesTotal) {
                this.initialiseDocument();
            }
        });
    }

    handleTextRender = () => {
        this.setState({textLayersRendered: this.state.textLayersRendered + 1}, () => {
            if (this.state.searchInitialised && !this.state.searchDisplayed && this.state.textLayersRendered === this.state.pagesTotal) {
                this.displaySearchResults(false, false, true);
            }
        });
    }

    captureFindShortcut = (event) => {
        if (event.keyCode === 114 || (event.ctrlKey && event.keyCode === 70) || (event.metaKey && event.keyCode === 70)) {
            event.preventDefault();
            if (this.searchInput.current) {
                this.searchInput.current.focus();
            }
        }
    }

    setDocumentWidth = () => {
        if (this.pdfContainer.current) {
            const pdfContainer = this.pdfContainer.current;

            this.setState({
                textLayersRendered: 0,
                searchDisplayed: false,
                documentWidth: pdfContainer.getBoundingClientRect().width,
            });
        }

        // Set text layer size
        this.setPageWidth();
    }

    setPageWidth = () => {
        let firstPage = document.querySelector('[data-page-number="1"] .react-pdf__Page__canvas');

        if (firstPage) {
            Array.from(document.querySelectorAll('[data-page-number]')).map(page => {
                page.style.width = firstPage.style.width;
            });
        }
    }

    handleDocumentSuccess = ({numPages}) => {
        const pages = Array.from(new Array(numPages)).map((el, index) => ({
            ref: `page_${index + 1}`,
            key: `page_${index + 1}`,
            pageNumber: index + 1,
            loading: "",
            onRenderSuccess: () => this.handlePageRender(),
            onGetTextSuccess: () => this.handleTextRender(index),
            renderTextLayer: true,
            renderAnnotationLayer: false,
        }));

        const contentHeight = (window.innerHeight - this.documentContent.current.getBoundingClientRect().top + this.documentToolbar.current.getBoundingClientRect().height);

        this.setState({pagesTotal: numPages, isInitialised: true, pages: pages, contentStyle: {height: contentHeight + 'px'}});
    }

    setPageNumber = (newPageNumber, scrollToPage) => {
        // If number is valid
        if (newPageNumber && parseInt(newPageNumber) > 0 && parseInt(newPageNumber) <= this.state.pagesTotal) {

            // Store page number
            this.setState({pageNumber: parseInt(newPageNumber)});

            // Go to page now if requested
            if (scrollToPage) {
                let pageElement = document.querySelector('[data-page-number="'+newPageNumber+'"]');

                if (pageElement) {
                    pageElement.scrollIntoView({behavior: "auto", block: "start"});
                }
            }
        }
    }

    watchPagePosition = () => {
        // Declare intersection observer
        let observer = new IntersectionObserver((pages, observer) => {
            pages.forEach(page => {
                window.requestAnimationFrame(() => {
                    // Don't look at current page
                    if (page.target.dataset.pageNumber !== this.state.pageNumber) {
                        // Calculate how much of the screen the new page covers
                        if (page.boundingClientRect.height * page.intersectionRatio / page.rootBounds.height > 0.5) {
                            this.setPageNumber(page.target.dataset.pageNumber);
                        }
                    }
                })
            });
        }, {
            root: document.querySelector('.document'),
            threshold: [
                0.0, 0.025, 0.05, 0.075,
                0.1, 0.125, 0.15, 0.175,
                0.2, 0.225, 0.25, 0.275,
                0.3, 0.325, 0.35, 0.375,
                0.4, 0.425, 0.45, 0.475,
                0.5, 0.525, 0.55, 0.575,
                0.6, 0.625, 0.65, 0.675,
                0.7, 0.725, 0.75, 0.775,
                0.8, 0.825, 0.85, 0.875,
                0.9, 0.925, 0.95, 0.975,
            ],
        });

        // Fire observer
        document.querySelectorAll('[data-page-number]').forEach(page => observer.observe(page));
    }

    initialiseDocument = () => {
        // Wait for rendering
        setTimeout(() => {

            // Set width to fill screen
            this.setDocumentWidth();

            // Get page top offset
            let firstPage = document.querySelector('[data-page-number="1"]');

            // Store page offset
            this.setState({pageOffset: firstPage ? firstPage.offsetTop : ""});

            // Go to the page indicated in DB
            this.setPageNumber(this.state.pageNumber, true);

            // Collect text layers
            this.setState({textLayers: document.getElementsByClassName("react-pdf__Page__textContent")});

            // Start page position observer
            this.watchPagePosition();

            // Indicate Document is initialised
            this.setState({isInitialised: true});

        }, 0);
    }

    setSearchTerm = (searchTerm) => {
        const newSearchTerm = searchTerm;

        // If search term has changed
        if (newSearchTerm !== this.state.searchTerm) {
            // Store new search term
            this.setState({searchTerm: newSearchTerm});

            // Clear current search results
            this.clearSearchResults();
        }
    }

    displaySearchResults = (highlightResult, highlightPrevious, disableAnimation) => {
        const {searchTerm, searchResultHighlighted, textLayers} = this.state;

        let searchResultContainers = [];
        Array.from(textLayers).map(page => {
                Array.from(page.children).filter(child => child.innerHTML.toLowerCase().includes(searchTerm.toLowerCase())).map(child => {
                    searchResultContainers.push(child);
                });
            }
        );

        // If results found
        if (searchResultContainers.length > 0) {
            // Generate highlights
            searchResultContainers.map((result, index) => {
                result.innerHTML = result.innerHTML.replace(new RegExp(searchTerm, 'gi'),
                    '<span class="' +
                    (searchResultHighlighted === index ? "document-search-result document-search-result-current" : "document-search-result") +
                    (disableAnimation ? " document-search-result-noanimation" : "") +
                    '">$&</span>'
                );
            });
        }

        // Set text layer size
        this.setPageWidth();

        // Indicate search initialised and store results
        this.setState({
            searchInitialised: true,
            searchDisplayed: true,
            searchResults: Array.from(document.getElementsByClassName("document-search-result")),
        }, () => {

            if (highlightResult) {
                this.goToSearchResult(highlightPrevious);
            }
        });
    }

    goToSearchResult = (highlightPrevious) => {
        const {searchResults, searchResultHighlighted} = this.state;

        // If search has not been initialised
        if (!this.state.searchInitialised) {

            // Display search results first
            this.displaySearchResults(true, highlightPrevious);
        }
        else {

            // Get search results
            this.setState({searchResults: Array.from(document.getElementsByClassName("document-search-result"))}, () => {

                if (!isEmpty(searchResults)) {

                    let resultIndex;

                    // Get index of previous result to highlight
                    if (highlightPrevious) {
                        resultIndex = searchResultHighlighted === null
                            ? (searchResults.length - 1)
                            : (searchResultHighlighted === 0)
                                ? (searchResults.length - 1)
                                : searchResultHighlighted - 1;
                    }
                    // Get index of next result to highlight
                    else {
                        resultIndex = searchResultHighlighted === null
                            ? 0
                            : (searchResultHighlighted + 1 === searchResults.length)
                                ? 0
                                : searchResultHighlighted + 1;
                    }

                    // Remove current highlight
                    this.state.searchResults.filter(result => result.classList.contains("document-search-result-current")).map(result => {
                        result.classList.remove("document-search-result-current");
                    });

                    // Scroll next result into view
                    this.state.searchResults[resultIndex].scrollIntoView({behavior: "auto", block: "center"});

                    // Add next result highlight
                    this.state.searchResults[resultIndex].classList.add("document-search-result-current");

                    // Indicate index of highlighted result
                    this.setState({searchResultHighlighted: resultIndex});
                }
            });
        }
    }

    clearSearchResults = () => {
        // Clear highlights
        Array.from(document.getElementsByClassName('document-search-result')).map(element => {
            element.replaceWith(element.innerText);
        })

        // Reset state
        this.setState({
            searchInitialised: false,
            searchDisplayed: false,
            searchResults: null,
            searchResultHighlighted: null,
        })
    }

    setZoomLevel = (direction) => {
        this.setState({
            textLayersRendered: 0,
            searchDisplayed: false,
            zoomLevel:
                direction === "down" ? Math.round((this.state.zoomLevel - 0.1) * 10) / 10
                : direction === "up" ? Math.round((this.state.zoomLevel + 0.1) * 10) / 10
                    : 1
        });
    }

    render() {
        const {
            isLoaded,
            isInitialised,
            fileError,
            fileContent,
            documentWidth,
            pageNumber,
            pagesTotal,
            searchTerm,
            searchResults,
            searchResultHighlighted,
            zoomLevel,
        } = this.state;

        const elemStyle = this.props.visible? {}: {display: "none"};

        return (
            <div style={elemStyle} className={"document" + (zoomLevel !== 1 ? " document-scaled" : "") + (!isInitialised ? " document-loading" : "")} ref={this.pdfContainer}>
                {isLoaded && fileError &&
                <div className="container-fluid text-center pt-3">
                    <h6>This browser does not support PDFs.</h6>
                </div>
                }

                {isLoaded && !fileError && !isEmpty(fileContent) && <>

                <Document
                    ref={"document"}
                    file={fileContent}
                    renderMode={"canvas"}
                    externalLinkTarget={"_blank"}
                    loading={""}
                    noData={"No data found."}
                    onLoadSuccess={this.handleDocumentSuccess}
                    onLoadError={this.handleDocumentError}
                    onSourceError={this.handleDocumentError}
                >

                    <div className="document-toolbar" ref={this.documentToolbar}>
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-4">
                                    <div className="document-toolbar-zoom">
                                        <div className="input-group">

                                            <div className="input-group-prepend">
                                                <button
                                                    type="button"
                                                    title="Previous Page"
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => this.setZoomLevel("down")}
                                                    disabled={zoomLevel <= 0.5}
                                                >
                                                    <i className="fa fa-minus" />
                                                </button>
                                            </div>

                                            <button
                                                type="button"
                                                title="Previous Page"
                                                className="btn btn-sm btn-light"
                                                onClick={() => this.setZoomLevel()}
                                                disabled={zoomLevel === 1}
                                            >
                                                <i className="fa fa-arrows-alt-h" />
                                            </button>

                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    title="Next Page"
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => this.setZoomLevel("up")}
                                                    disabled={zoomLevel >= 2}
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="document-toolbar-pagination">
                                        <div className="input-group">

                                            <div className="input-group-prepend">
                                                <button
                                                    type="button"
                                                    title="Previous Page"
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => this.setPageNumber(pageNumber - 1, true)}
                                                    disabled={pageNumber === 1}
                                                >
                                                    <i className="fa fa-chevron-up" />
                                                </button>
                                            </div>

                                            <input
                                                type="number"
                                                title="Current Page"
                                                className="document-toolbar-input document-toolbar-input-page form-control form-control-sm"
                                                autoComplete="off"
                                                min="1"
                                                max={pagesTotal}
                                                value={pageNumber}
                                                onFocus={event => event.target.select()}
                                                onChange={(event) => this.setPageNumber(event.target.value)}
                                                onKeyPress={(event) => {if (event.key === 'Enter') event.target.blur()}}
                                                onBlur={(event) => this.setPageNumber(event.target.value, true)}
                                            />

                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    title="Next Page"
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => this.setPageNumber(pageNumber + 1, true)}
                                                    disabled={pageNumber === pagesTotal}
                                                >
                                                    <i className="fa fa-chevron-down" />
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="document-toolbar-search">

                                        <div className="document-toolbar-search-value">

                                            <input
                                                ref={this.searchInput}
                                                type="text"
                                                title="Search Phrase"
                                                className={"document-toolbar-input document-toolbar-input-search form-control form-control-sm" +
                                                    (searchResults && searchResults.length === 0 ? " form-control-invalid" : "")
                                                }
                                                autoComplete="off"
                                                value={searchTerm}
                                                placeholder="Find..."
                                                onChange={(event) => this.setSearchTerm(event.target.value)}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') this.goToSearchResult()
                                                }}
                                            />

                                            {searchResults && searchResults.length > 0 &&
                                            <span className="document-toolbar-search-counter">
                                                {searchResultHighlighted + 1 + "/" + searchResults.length}
                                            </span>
                                            }

                                        </div>

                                        <button
                                            type="button"
                                            title="Previous Result"
                                            className="btn btn-sm btn-light"
                                            onClick={() => this.goToSearchResult(true)}
                                        >
                                            <i className="fa fa-chevron-up" />
                                        </button>

                                        <button
                                            type="button"
                                            title="Next Result"
                                            className="btn btn-sm btn-light"
                                            onClick={() => this.goToSearchResult()}
                                        >
                                            <i className="fa fa-chevron-down" />
                                        </button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {(!isInitialised || !isLoaded) &&
                        <div className="container-fluid text-center pt-3">
                            <Loader size={10} color={"#32383e"}/>
                        </div>
                    }

                    <div className="document-content" ref={this.documentContent} style={this.state.contentStyle}>
                        {this.state.pages.map((page) => {
                            return (
                                <Page
                                    className={zoomLevel > 1 ? "react-pdf__Page--overzoom" : ""}
                                    ref={page.ref}
                                    key={page.key}
                                    width={documentWidth}
                                    pageNumber={page.pageNumber}
                                    loading={page.loading}
                                    onRenderSuccess={page.onRenderSuccess}
                                    onGetTextSuccess={page.onGetTextSuccess}
                                    renderTextLayer={page.renderTextLayer}
                                    renderAnnotationLayer={page.renderAnnotationLayer}
                                    scale={zoomLevel}
                                />
                            )
                        })}
                    </div>
                </Document>
                </>}
            </div>
        )
    }
}

DocumentViewer.propTypes = {
    file: PropTypes.object,
    pageNumber: PropTypes.number,
};

export default DocumentViewer;