import React, {useCallback, useMemo} from "react";
import Select from "react-select";

export const SelectColumnFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}) => {
    const isArray = preFilteredRows.map(row => row.values[id]).find(Array.isArray);

    const options = useMemo(() => {
        const options = new Set();
        options.add('All');
        preFilteredRows.forEach(row => {
            let value = row.values[id];

            if (value === null || typeof value === 'undefined') {
                return;
            }

            if (isArray) {
                for (const v of value) {
                    options.add(v);
                }
                return;
            }

            options.add(value);
        })
        return [...options.values()]
            .map(v => ({
                value: (v === 'All' ? undefined : v),
                label: typeof v === 'boolean' ? (v ? 'Yes' : 'No') : v
            }))
    }, [id, preFilteredRows]);

    const onSelectHandler = useCallback(({value}) => {
        setFilter(value);
    }, []);

    const selectedOption = useMemo(() => {
        return options.filter(op => op.value === filterValue);
    }, [filterValue, options]);

    return (
        <Select
            options={options}
            closeMenuOnSelect={true}
            onChange={onSelectHandler}
            value={selectedOption}
            styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
            }}
        />
    )
}

export default SelectColumnFilter;