import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector, getModalData, getUserSettings, getPatientsData} from "../../../utils/selectors";
import {
    LOAD_PERSONAL_PREFERENCES, LOAD_ANALYTES_DISEASE, LOAD_ANALYTES_TREATMENT,
    loadPersonalPreferences, loadAnalytesDisease, loadAnalytesTreatment, saveAnalytes
} from "../../../actions/user-settings.actions";
import {useParams} from "react-router-dom";
import isEmpty from "../../../utils/helpers";
import deleteSvg from "../../../images/delete.svg";
import lastFirstSvg from "../../../images/last-first-01.svg";

export const DISEASE_TIMELINE = "DISEASE_TIMELINE";
export const TREATMENT_TIMELINE = "TREATMENT_TIMELINE";

const EditAnalytesModal = ({
                               loadPersonalPreferences,
                               loadAnalytesDisease,
                               loadAnalytesTreatment,
                               patientInfo,
                               loading,
                               hideModal,
                               patientPreferences,
                               modalProps,
                               availableAnalytes,
                               saveAnalytes
                           }) => {
    const [addedList, setAddedList] = useState([]);
    const [analytes, setAnalytes] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        loadPersonalPreferences(patientInfo.id);
        if (modalProps.analytesType === DISEASE_TIMELINE) {
            loadAnalytesDisease(patientInfo.id);
        } else {
            loadAnalytesTreatment(patientInfo.id);
        }
    }, [patientInfo.id]);

    useEffect(() => {
        if (isEmpty(patientPreferences)) {
            return;
        }
        setAddedList(modalProps.analytesType === DISEASE_TIMELINE ? patientPreferences.diseaseAnalytes : patientPreferences.treatmentAnalytes);
    }, [patientPreferences])

    useEffect(() => {
        if (isEmpty(availableAnalytes)) {
            return;
        }
        setAnalytes(availableAnalytes.sort());
    }, [availableAnalytes])

    useEffect(() => {
        setAnalytes(availableAnalytes.filter((o) => o.toUpperCase().indexOf(searchValue.toUpperCase()) >= 0));
    }, [searchValue])

    const closeModal = useCallback(() => {
        hideModal();
    }, []);

    const deleteAnalyte = useCallback((analyte) => {
        setAddedList((o) => o.filter((value) => !(value === analyte)));
    }, []);

    const addDeleteAnalyte = useCallback((analyte) => {
        setAddedList((addedList) => {
            if (addedList.includes(analyte)) {
                return addedList.filter((value) => !(value === analyte));
            }
            return [...addedList, analyte].sort();
        });
    }, []);

    const searchAnalytes = useCallback((value) => {
        const txt = value.target.value;
        setSearchValue(txt);
    }, []);

    const saveAnalytesPressed = useCallback(() => {
        if (modalProps.analytesType === DISEASE_TIMELINE) {
            patientPreferences.diseaseAnalytes = addedList;
        } else {
            patientPreferences.treatmentAnalytes = addedList;
        }

        saveAnalytes(patientPreferences.patientId, patientPreferences)
            .then((result) => {
               hideModal();
               modalProps.reloadView.reloadTimeline();
            });
    }, [saveAnalytes, patientPreferences, addedList, modalProps, hideModal]);

    return (
        <>
            <div className="modal-header p-4 add-analytes-title modal-title">
                Add Analytes
            </div>

            <div className="modal-body p-4">
                <div className="row">
                    <div className="section col col-md-6">
                        <div className="row m-1">
                            <input className="bg-opacity-10 bg-black border-0" placeholder="Search Analytes"
                                   type="text" value={searchValue} onChange={searchAnalytes}/>
                        </div>
                        <div className="row m-1 overflow-scroll analytes-list">
                            {analytes.map((analyte) => {
                                return <div className="row">
                                    <div className="col-1"><input type="checkbox"
                                                                  onClick={() => addDeleteAnalyte(analyte)}
                                                                  checked={addedList.includes(analyte)}/></div>
                                    <div className="col-11 analyte-text"
                                         onClick={() => addDeleteAnalyte(analyte)}>{analyte}</div>
                                </div>;
                            })}
                        </div>
                    </div>
                    <div className="section col col-md-6">
                        <div className="row m-1">
                            <div className="analytes-list-title">Selected Analytes</div>
                        </div>
                        <div className="row m-1 overflow-scroll analytes-list border-0 container">
                            {addedList.map((analyte) => {
                                return <div className="row added-analyte">
                                    <div className="col-10">{analyte}</div>
                                    <div className="col-2 delete-analyte"><a onClick={() => deleteAnalyte(analyte)}><img
                                        style={{width: 23, height: 22}} src={deleteSvg}/></a></div>
                                </div>;
                            })}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-md-auto">
                        <button onClick={hideModal} className="analytes-button cancel-analytes">Cancel</button>
                    </div>
                    <div className="col-md-auto">
                        <button className="analytes-button save-analytes" onClick={saveAnalytesPressed}>Save</button>
                    </div>
                </div>
            </div>
        </>
    );
}

EditAnalytesModal.propTypes = {
    // patientMolecularDetailed: PropTypes.array
}

const personalPreferencesLoader = createLoadingSelector(['LOAD_PERSONAL_PREFERENCES']);
const loadAnalytesDiseaseLoader = createLoadingSelector(['LOAD_ANALYTES_DISEASE']);
const loadAnalytesTreatmentLoader = createLoadingSelector(['LOAD_ANALYTES_TREATMENT']);

const mapStateToProps = (state) => {

    const
        patientPreferences = getUserSettings(state).patientPreferences,
        availableAnalytes = getUserSettings(state).availableAnalytes,
        loading = personalPreferencesLoader(state) | loadAnalytesDiseaseLoader(state) | loadAnalytesTreatmentLoader(state),
        patientInfo = getPatientsData(state).patientInfo,
        modalProps = getModalData(state).modalProps;

    return {
        patientInfo,
        patientPreferences,
        loading,
        modalProps,
        availableAnalytes,
    }
};

const mapDispatchToProps = {
    loadPersonalPreferences,
    loadAnalytesDisease,
    loadAnalytesTreatment,
    saveAnalytes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAnalytesModal);
