import React, {Component} from 'react';
import moment from "moment";

class Message extends Component {

    render() {

        const {
            message,
            contact,
        } = this.props;

        return (
            <div className="messages-message">

                <div className="messages-message-title">
                    <span className="messages-avatar"><i className="fa fa-user" /></span>
                    <span className="messages-message-name">{message.received ? contact.firstName : "me"}</span>
                    <span className="messages-message-date">{moment(message.date).format("HH:mm")}</span>
                    {/*<span className="messages-attachment"><i className="fa fa-paperclip" /></span>*/}
                </div>

                {message.message}

            </div>
        )
    }
}

export default Message;
