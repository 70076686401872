import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCcmPhoto} from "../../../actions/tasks.actions";
import Task from "./Task";
import TaskDetails from "./TaskDetails";
import isEmpty from "../../../utils/helpers";
import PanelAction from "../PanelAction";
import {TaskUserPhoto} from "./TaskUserPhoto";
import {getTaskNotificationData, getTasksData} from "../../../utils/selectors";
import _ from "lodash";
import {taskFilters, taskTypes} from "../../../constants/task-constants";
import {findAssignees, isTaskListEmpty} from "../../../utils/task-utils";

const notificationCountStyle = {
    position: 'absolute',
    top: '-0.1rem',
    right: '-0.1rem',
    borderRadius: '100%',
    backgroundColor: "#f51842",
    fontSize: '0.7rem'
}


class Tasks extends Component {

    assigneeActive = (assigneeId = "", actualAssignees = new Set()) => {
        const { showTasksByAssignee } = this.props.patientTaskFilters;

        return actualAssignees.has(assigneeId) && (showTasksByAssignee.has(assigneeId) || _.isEmpty(showTasksByAssignee));
    }

    filterByAssignees = (tasks = {}) => {
        const { showTasksByAssignee } = this.props.patientTaskFilters;

        if (_.isEmpty(showTasksByAssignee)) {
            return tasks;
        }

        const filteredTasks = {...tasks}

        if (!_.isEmpty(showTasksByAssignee)) {
            Object.entries(filteredTasks).forEach(([key, value]) =>
                filteredTasks[key] = value.filter(task => showTasksByAssignee.has(task.assignee.id))
            )
        }

        return filteredTasks;
    }

    noTasksToDisplay = (tasks = {}) => {
        return _.isEmpty(_.flatten(Object.values(tasks)));
    }

    render() {

        const {
            userId,
            assignees,
            tasks,
            handleShowTasksByAssignee,
            showTaskDetailsById,
            taskNotificationCount,
            taskType,
            taskHeadings,
            handleChangeTaskType
        } = this.props;

        const filteredByAssignees = this.filterByAssignees(tasks[taskType]);

        const filteredTasks = taskFilters[taskType] ?
            taskFilters[taskType].filter(this.props, filteredByAssignees) : [];

        const displayTasks = taskFilters[taskType].active(this.props) ?
            filteredTasks : filteredByAssignees;

        const actualAssignees = findAssignees(displayTasks);

        const noTasksToDisplay = this.noTasksToDisplay(displayTasks);

        return (
            <>
                {isEmpty(showTaskDetailsById) && <>

                    <div className="clearfix tasks-query">
                        <div className="tasks-query-assignees">
                            {!isEmpty(assignees)  &&
                            <>
                                <div className="assignees-heading">
                                    Assigned to
                                </div>
                                <div className="assignees-container">
                                {Object.keys(assignees).map(assigneeId =>
                                    <button
                                        className={
                                            "tasks-query-assignees-assignee" +
                                            (this.assigneeActive(assigneeId, actualAssignees) ? " active" : "") +
                                            (userId === assigneeId ? " tasks-query-assignees-assignee-me" : "")
                                        }
                                        onClick={() => handleShowTasksByAssignee(assigneeId)}
                                        // disabled={!this.assigneeEnabled(assigneeId)}
                                    >
                                        <TaskUserPhoto user={assignees[assigneeId]}/>
                                    </button>
                                )}
                                </div>
                            </>
                            }
                        </div>

                        <div className="tasks-filter">
                                <button
                                    type="button"
                                    className={"btn btn-rnd btn-filter" +  (taskFilters[taskType].active(this.props) ?
                                    " clicked" : "")}
                                    onClick={taskFilters[taskType].handler(this.props)}
                                    disabled={isTaskListEmpty(filteredTasks)  && !taskFilters[taskType].active(this.props)}
                                >
                                    <span>{taskFilters[taskType].label}</span>
                                </button>
                            </div>
                        <div className="tasks-query-actions" style={{position: "relative"}}>

                                <button type="button" className="panel-toolbar-notification btn"
                                        disabled={!taskNotificationCount}
                                        onClick={this.props.toggleTaskNotifications}
                                >
                                    <i className="fa fa-bell"/>

                                    {taskNotificationCount > 0 &&
                                    <span className="badge badge-success" style={notificationCountStyle}>
                                    {taskNotificationCount}
                                    </span>
                                    }
                                </button>

                                <button
                                    type="button"
                                    title="Create"
                                    className="btn btn-primary btn-rnd btn-cancel"
                                    onClick={this.props.handleCreateNew}
                                    disabled={false}
                                >
                                    <i className="fa fa-plus"/>
                                    <span>Create</span>
                                </button>
                        </div>

                    </div>

                    <div className="tasks-heading">
                        {taskHeadings.map(heading =>
                            <div className={"tasks-heading-title" + (heading === taskType ? " active" : "")}
                                onClick={() => handleChangeTaskType(heading)}
                            >
                                <span>{_.capitalize(heading)}</span>
                            </div>
                        )}
                    </div>

                    {noTasksToDisplay &&
                    <p className="panel-body-empty">No data found</p>
                    }

                    {!noTasksToDisplay && Object.entries(displayTasks).map(([key, value]) => {
                            const type = taskTypes[key];

                            return (!_.isEmpty(value) && (
                                <div className="tasks-list">
                                    <h6 className="tasks-list-title">
                                        {type.label}
                                    </h6>

                                    {value.map((task, i) =>
                                            <Task
                                                key={i}
                                                task={task}
                                                assignee={task.assignee && task.assignee.id ? assignees[task.assignee.id] : ""}
                                                due={type.text(task)}
                                                typeKey={key}
                                                taskType={taskType}
                                                taskPhoto={null}
                                                {...this.props}
                                            />
                                        )
                                    }
                                </div>
                            ));
                        })
                    }
                </>
                }

                {!isEmpty(showTaskDetailsById) &&
                <TaskDetails
                    task={tasks[showTaskDetailsById.type][showTaskDetailsById.due].filter(task => task.id === showTaskDetailsById.id)[0]}
                    {...this.props}
                />
                }
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    const taskNotificationCount = getTaskNotificationData(state).notificationCount;

    return {
        taskNotificationCount,
    };
};

const mapDispatchToProps = {
    getCcmPhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
