import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import {getAuthData} from "../../../utils/selectors";
import {toast} from "react-toastify";

const EmailStep = ({email, onConfirm}) => {
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            email: Yup.string().required("Required").email("The email address you entered is not valid. Please check and try again."),
        });
    }, []);

    const handleOnSubmit = useCallback( ({email}, {setSubmitting}) => {
        Promise.resolve(onConfirm(email))
            .catch((error) => {
                console.error(error);
                toast.error("Unexpected error")
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            email: email || '',
        },
        validationSchema: validationSchema,
        onSubmit: handleOnSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit} className="auth-email">
            <div className="auth-email__title">Reset Password</div>
            <div className="auth-email__description">Please enter your email address</div>
            <div className="auth-email__email">
                <input className="form-control form-control-rounded"
                       type="text"
                       placeholder="Email*"
                       name="email"
                       aria-label="Email"
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                )}
            </div>

            <div className="auth-email__confirmation">
                <button
                    className="btn btn-lg btn-rnd btn-primary auth-email__confirmation-btn"
                    type="submit"
                    onClick={formik.handleSubmit}
                >
                    Next
                </button>
            </div>
        </form>
    );
}

EmailStep.propTypes = {
    email: PropTypes.string,
    onConfirm: PropTypes.func,
};

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;

    return {
        isLoggedIn,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailStep);