import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getAuthData, getPanelData, getPatientsData, getTaskNotificationData} from "../../utils/selectors";
import {showPanel, hidePanel} from "../../actions/panel.actions";
import {loadTasks} from "../../actions/tasks.actions";
import moment from "moment";
import {clearTaskNotifications, loadTaskNotifications} from "../../actions/task-notification.actions";
import {useParams} from "react-router-dom";
import actionNotesSvg from "../../images/action-notes.svg";
import tasksSvg from "../../images/tasks.svg";
import uploadFilesSvg from "../../images/upload-files.svg";

const notificationCountStyle = {
    position: 'absolute',
    top: '0.1rem',
    right: '0.1rem',
    borderRadius: '100%',
    backgroundColor: "#f51842",
    fontSize: '0.5rem',
}

const PatientActionMenu = ({
        clearTaskNotifications,
        userId,
        hidePanel,
        isPanelOpen,
        showPanel,
        taskNotificationCount,
        patientAccessTime,
        patientLastAccessTime,
        loadTasks,
       loadTaskNotifications,
    }) => {

    const {id: patientId} = useParams();

    useEffect(() => {
        clearTaskNotifications();

        return hidePanel;
    }, []);

    useEffect(() => {
        const dueToDate = moment().add(31, 'days').startOf('day').format();
        const completedFromDate = moment().subtract(30, 'days').startOf('day').format();

        loadTasks(patientId, userId, dueToDate, completedFromDate).then(resp => {
            const tasks = resp.tasks || [];
            loadTaskNotifications({tasks, userId, patientLastAccessTime, patientAccessTime});
        })
    }, [patientLastAccessTime]);

    return (
        <nav className={"action-menu"}>

            <button
                className={"btn action-menu-button" + (isPanelOpen === 'notes' ? ' action-menu-button-active' : '')}
                onClick={() => showPanel(isPanelOpen, 'notes', {patientId: patientId})}
                data-panel="notes"
            >
                <img style={{width: 30, height: 30}} src={actionNotesSvg}/>
            </button>

            <button
                className={"btn action-menu-button" + (isPanelOpen === 'tasks' ? ' action-menu-button-active' : '')}
                onClick={() => showPanel(isPanelOpen, 'tasks', {patientId: patientId})}
                data-panel="tasks"
            >
                <img style={{width: 30, height: 30}} src={tasksSvg}/>
                {taskNotificationCount > 0 &&
                    <span className="badge badge-success" style={notificationCountStyle}>
                    {taskNotificationCount}
                    </span>
                }
            </button>

            <button
                className={"btn action-menu-button" + (isPanelOpen === 'upload' ? ' action-menu-button-active' : '')}
                onClick={() => showPanel(isPanelOpen, 'upload', {patientId: patientId})}
                data-panel="upload"
            >
                <img style={{width: 30, height: 30}} src={uploadFilesSvg}/>
            </button>

            {/*<button*/}
            {/*    className={"btn action-menu-button" + (isPanelOpen === 'calendar' ? ' action-menu-button-active' : '')}*/}
            {/*    onClick={() => showPanel(isPanelOpen, 'calendar', {patientId: patientId})}*/}
            {/*    data-panel="calendar"*/}
            {/*>*/}
            {/*    <i className="fa fa-calendar-alt" />*/}
            {/*</button>*/}

            {/*<button*/}
            {/*    className={"btn action-menu-button" + (isPanelOpen === 'forms' ? ' action-menu-button-active' : '')}*/}
            {/*    onClick={() => showPanel(isPanelOpen, 'forms', {patientId: patientId})}*/}
            {/*    data-panel="forms"*/}
            {/*>*/}
            {/*    <i className="fa fa-file" />*/}
            {/*</button>*/}

            {/*<button*/}
            {/*    className={"btn action-menu-button" + (isPanelOpen === 'messages' ? ' action-menu-button-active' : '')}*/}
            {/*    onClick={() => showPanel(isPanelOpen, 'messages', {patientId: patientId})}*/}
            {/*    data-panel="messages"*/}
            {/*>*/}
            {/*    <i className="fa fa-comment-dots" />*/}
            {/*    /!*<span className="action-menu-button-badge">2</span>*!/*/}
            {/*</button>*/}

        </nav>
    )
}

const mapStateToProps = (state, props) => {
    const isPanelOpen = getPanelData(state).isPanelOpen;
    const {patientInfo} = getPatientsData(state);
    const userId = getAuthData(state).userId;
    const patientLastAccessTime = patientInfo.lastAccessed;
    const patientAccessTime = patientInfo.accessTime;
    const taskNotificationCount = getTaskNotificationData(state).notificationCount;

    return {
        isPanelOpen,
        userId,
        patientLastAccessTime,
        patientAccessTime,
        taskNotificationCount
    };
};

const mapDispatchToProps = {
    showPanel,
    hidePanel,
    loadTasks,
    loadTaskNotifications,
    clearTaskNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientActionMenu);
