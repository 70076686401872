import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Bar} from "react-chartjs-2";

class Sleep extends Component {

    render () {

        let data = {
            labels: ["Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Today"],

            // create one dataset for each data item
            // data[0] = labels[0] (data for first bar - 'Wed')
            // data[1] = labels[1] (data for second bar - 'Thu')
            // etc...
            // put 0 if there is no data for the particular bar

            datasets: [{
                data: [[12, 16], [11, 16], [12, 16], [11, 16], [12, 16], [11, 16], [12, 16]],
                backgroundColor: '#06C9FF', barPercentage: 0.5, maxBarThickness: 10, borderRadius: 5,
            },{
                data: [0, [18, 20], 0, [18, 20], 0, [18, 20], 0],
                backgroundColor: '#06C9FF', barPercentage: 0.5, maxBarThickness: 10, borderRadius: 5,
            },{
                data: [[8, 12], [7, 11], [8, 12], [7, 11], [8, 12], [7, 11], [8, 12]],
                backgroundColor: '#D2F2FA', barPercentage: 0.5, maxBarThickness: 10, borderRadius: 5,
            },{
                data: [[16, 19], [16, 18], [16, 19], [16, 18], [16, 19], [16, 18], [16, 19]],
                backgroundColor: '#D2F2FA', barPercentage: 0.5, maxBarThickness: 10, borderRadius: 5,
            }],
        };

        let options = {
            animation: false,
            spanGaps: true,
            maintainAspectRatio: true,
            responsive: true,
            legend: {display: false},
            tooltips: {enabled: false},
            annotation: {display: false},
            plugins: {datalabels: false},
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999'}
                }],
                yAxes: [{
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999', min: 0, max: 24, stepSize: 6,
                        callback: function(value, index, values) {
                            if (index === 0) return '12 AM'
                            if (index === 1) return '6 PM'
                            if (index === 2) return '12 PM'
                            if (index === 3) return '6 AM'
                            if (index === 4) return '12 AM'
                        }
                    },
                    position: 'right',
                }],
            },
        }

        return  (
            <div className="activity">

                <h5 className="activity-title activity-title-blue">
                    <i className="fa fa-fw fa-bed" />
                    Sleep
                </h5>

                <div className="activity-chart">

                    <Bar data={data} options={options} />

                </div>

                <div className="activity-stats row">
                    <div className="col col-6">
                        <h6 className="activity-stats-metric activity-stats-metric-badge activity-stats-metric-badge-sky-light">
                            Average time in bed
                        </h6>
                        <p className="activity-stats-value">
                            <span>7</span>hrs <span>45</span>mins
                        </p>
                    </div>
                    <div className="col col-6">
                        <h6 className="activity-stats-metric activity-stats-metric-badge activity-stats-metric-badge-sky">
                            Average time asleep
                        </h6>
                        <p className="activity-stats-value">
                            <span>7</span>hrs <span>21</span> mins
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Sleep);