import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import AuthCode from "react-auth-code-input";
import {Spinner} from "react-bootstrap";
import classNames from "classnames";
import {axiosPublicInstance} from "../../../utils/axios-instance";
import _ from "lodash";
import Countdown from "react-countdown";
import {rendererCountdown} from "../../../utils/helpers";

const CodeStep = ({codeToken: codeIssuerToken, onSuccess, onError}) => {

    const [codeToken, setCodeToken] = useState(null);
    const [loadingCodeToken, setLoadingCodeToken] = useState(false);
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState("");

    const isValidCode = useMemo(() => {
        return code && code.length === 4;
    }, [code]);

    const issueLoginCode = useCallback(async () => {
        try {
            setLoadingCodeToken(true);
            const resp = await axiosPublicInstance.post("/api/reset-password/code", {token: codeIssuerToken});
            const { token, phone } = resp.data;

            setCodeToken(token);
            setPhone(phone);
        } catch (error) {
            console.error(error);
            if (onError) {
                onError(error);
            }
        } finally {
            setLoadingCodeToken(false);
        }
    }, [codeIssuerToken]);

    const verifyLoginCode = useCallback(async () => {
        try {
            const resp = await axiosPublicInstance.post("/api/reset-password/verify-code", {token: codeToken, code: code});
            const { token } = resp.data;

            onSuccess(token, code);
        } catch (error) {
            const errorCode = _.get(error, 'response.data.errorCode');
            let errorMessage = _.get(error, 'response.data.errorMessage', error.message);
            if (errorCode === 22) {
                errorMessage = "The verification code you entered is incorrect. Please try again."
            }

            console.error(error);
            toast.error(errorMessage);
        }
    }, [codeToken, code]);

    useEffect(() => {
        issueLoginCode();
    }, []);

    return (
        <div className="code-verification">
            <div className="code-verification__title">Reset Password</div>
            <div className="code-verification__description">
                Enter verification code
            </div>
            <div className="code-verification__info">
                A security code was sent to your mobile phone {phone ? phone : "(No Phone)"}
            </div>
            <div className="code-verification__code-input">
                <AuthCode inputClassName="auth-code-input" allowedCharacters='numeric' length={4} onChange={setCode} />
            </div>
            <div className="code-verification__expire-info">
                The code will expire within <Countdown date={Date.now() + 360000} renderer={rendererCountdown} />
            </div>
            <div className="code-verification__resend">
                Didn't receive an email?
                <button
                    className={classNames(
                        'code-verification__resend-btn',
                        {
                            'code-verification__resend-btn--disabled': loadingCodeToken,
                        }
                    )}
                    disabled={loadingCodeToken}
                    onClick={issueLoginCode}>
                    Resend code
                </button>
                {loadingCodeToken && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                        variant="primary"
                    />
                )}
            </div>
            <div className="code-verification__verify">
                <button
                    className="btn btn-lg btn-rnd btn-primary
                        code-verification__verify-btn"
                    disabled={!isValidCode}
                    onClick={verifyLoginCode}
                >
                    Verify
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeStep);