import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadPatientMedicalIdDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import Arrays from "lodash";

class MedicalIdModal extends Component {

	state = {
		patientMedicalId: {},
	}

	constructor(props) {
		super(props);
		this.sectionRefs = [];
	}

	scrollToSection = () => {
		if (!this.props.scrollToSection) {
			return;
		}

		if (this.sectionRefs[this.props.scrollToSection]) {
			this.sectionRefs[this.props.scrollToSection].scrollIntoView({behavior: 'smooth'});
		}
	}

	componentDidMount() {
		this.props.loadPatientMedicalIdDetailed(this.props.patientId).then((response) => {
			this.orderMedicalId(response);
		});
	}

	compareMedicalId = (a, b) => {
		if (a.time < b.time)
			return 1;
		else if (a.time > b.time)
			return -1;
		else
			return 0;
	}

	orderMedicalId = (response) => {
		let patientMedicalId = response;

		if (!isEmpty(patientMedicalId)) {

			Object.keys(patientMedicalId).map(key => {

				if (Arrays.isArray(patientMedicalId[key]) && !isEmpty(patientMedicalId[key])) {
					patientMedicalId[key] = patientMedicalId[key].sort(this.compareMedicalId)
					this.setState({patientMedicalId: patientMedicalId}, this.scrollToSection)
				}
			})
		}
	}

	closeModal = () => {
		this.props.hideModal();
	}

	render() {
		const { patientMedicalId } = this.state;
		const { loading } = this.props;

		return (
			<>
				<div className="modal-header">
					<h5 className="modal-title">Medical ID</h5>
				</div>

				<div className="modal-body modal-body-medical-id">

					{!loading
					?
					<>
						<h6 ref={(ref) => this.sectionRefs['smoking'] = ref}>
							Smoking
						</h6>

						{!isEmpty(patientMedicalId.smoking) ?
							<p>{patientMedicalId.smoking}</p>
							: <p>N/A</p> }


						<h6 ref={(ref) => this.sectionRefs['medicalHistory'] = ref}>
							Medical history
						</h6>

						{!isEmpty(patientMedicalId.medicalHistory) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
								<tr>
									<th scope="col">Date</th>
									<th scope="col">Summary</th>
								</tr>
							</thead>
							<tbody>
							{patientMedicalId.medicalHistory.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.summary}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6 ref={(ref) => this.sectionRefs['familyHistory'] = ref}>
							Family history
						</h6>

						{!isEmpty(patientMedicalId.familyHistory) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Summary</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.familyHistory.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.summary}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6 ref={(ref) => this.sectionRefs['allergies'] = ref}>
							Allergies
						</h6>

						{!isEmpty(patientMedicalId.allergies) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Summary</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.allergies.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.summary}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6 ref={(ref) => this.sectionRefs['cancerDiagnosis'] = ref}>
							Cancer associated diagnosis
						</h6>

						{!isEmpty(patientMedicalId.cancerDiagnosis) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Summary</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.cancerDiagnosis.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.summary}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6 ref={(ref) => this.sectionRefs['medications'] = ref}>
							Current Medications
						</h6>

						{!isEmpty(patientMedicalId.currentMedications) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Medication</th>
								<th scope="col">Dosage</th>
								<th scope="col">Duration</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.currentMedications.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.drug}</td>
								<td>
									{item.doseTakenMg ? item.doseTakenMg: 'Dose NA'}
									{item.unit && ' ' + item.unit}
									{item.frequency && ( <>
										{' / ' + item.frequency + ' X '}
										{item.frequencyUnit ? item.frequencyUnit: 'NA'}
									</>)}
								</td>
								<td>{item.duration}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6>
							Discontinued Medications
						</h6>

						{!isEmpty(patientMedicalId.medications) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Medication</th>
								<th scope="col">Dosage</th>
								<th scope="col">Duration</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.medications.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.drug}</td>
								<td>
									{item.doseTakenMg ? item.doseTakenMg: 'Dose NA'}
									{item.unit && ' ' + item.unit}
									{item.frequency && ( <>
										{' / ' + item.frequency + ' X '}
										{item.frequencyUnit ? item.frequencyUnit: 'NA'}
									</>)}
								</td>
								<td>{item.duration}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6 ref={(ref) => this.sectionRefs['supplements'] = ref}>
							Current Supplements
						</h6>

						{!isEmpty(patientMedicalId.currentSupplements) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Medication</th>
								<th scope="col">Dosage</th>
								<th scope="col">Duration</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.currentSupplements.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.drug}</td>
								<td>
									{item.doseTakenMg ? item.doseTakenMg: 'Dose NA'}
									{item.unit && ' ' + item.unit}
									{item.frequency && ( <>
										{' / ' + item.frequency + ' X '}
										{item.frequencyUnit ? item.frequencyUnit: 'NA'}
									</>)}
								</td>
								<td>{item.duration}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

						<h6>
							Discontinued Supplements
						</h6>

						{!isEmpty(patientMedicalId.supplements) ?
						<table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
							<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Medication</th>
								<th scope="col">Dosage</th>
								<th scope="col">Duration</th>
							</tr>
							</thead>
							<tbody>
							{patientMedicalId.supplements.map(item => <tr>
								<td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
								<td>{item.drug}</td>
								<td>
									{item.doseTakenMg ? item.doseTakenMg: 'Dose NA'}
									{item.unit && ' ' + item.unit}
									{item.frequency && ( <>
										{' / ' + item.frequency + ' X '}
										{item.frequencyUnit ? item.frequencyUnit: 'NA'}
									</>)}
								</td>
								<td>{item.duration}</td>
							</tr> )}
							</tbody>
						</table>
						: <p>None</p> }

					</>
					:
					<Loader size={10} color={"#ccc"}/>
					}

				</div>
			</>
		);
	}
}

const patientMedicalIdDetailedLoader = createLoadingSelector(['LOAD_PATIENT_MEDICAL_ID_DETAILED']);

const mapStateToProps = (state) => {

	const
		patientId = getModalData(state).modalProps.patientId,
		scrollToSection = getModalData(state).modalProps.scrollToSection,
		loading = patientMedicalIdDetailedLoader(state);

	return {
		patientId,
		scrollToSection,
		loading
	}
}

const mapDispatchToProps = {
	loadPatientMedicalIdDetailed
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalIdModal);
