import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DocumentViewer from "./DocumentViewer";
import isEmpty, {printDate} from "../../utils/helpers";
import {useParams} from "react-router-dom";
import MeetingAudioRecording from "../AudioPlayer/MeetingAudioRecording";
import {FadeLoader} from "react-spinners";
import {Alert} from "react-bootstrap";
import _ from 'lodash';
import {toast} from "react-toastify";
import moment from "moment/moment";
import Loader from "react-spinners/BeatLoader";
import {axiosPublicInstance} from "../../utils/axios-instance";
import {TextView} from "./TextView";
import hourglassClockLightSvg from "../../images/hourglass-clock-light.svg";

const get = (obj, path, defaultValue) => {
    if (typeof path === 'string') {
        path = path.split('.');
    }

    let current = obj;

    for (let i = 0; i < path.length; i++) {
        if (current === null || current === undefined) {
            return defaultValue;
        }
        current = current[path[i]];
    }

    return (current === undefined || current === null) ? defaultValue : current;
}

const printError = (error) => {
    toast.error(error);
    console.error(error);
}

const DocumentSharedViewHeader = ({file, token, textViewEnabled, setTextViewEnabled}) => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [patientInfo, setPatientInfo] = useState(null);
    const [loadingPatientInfo, setLoadingPatientInfo] = useState(true);
    const [patientPhoto, setPatientPhoto] = useState(null);
    const [loadingPatientPhoto, setLoadingPatientPhoto] = useState(true);
    const [downloadingFile, setDownloadingFile] = useState(false);

    const fileProps = useMemo(() => [
        {
            name: 'Name',
            value: get(file, 'fileName', 'N/A'),
        }, {
            name: 'Date',
            value: (file && printDate(file.effectiveDate)) || 'N/A',
        }, {
            name: 'Facility',
            value: get(file, 'facility', 'N/A'),
        }, {
            name: 'Type',
            value: get(file, 'folder.name', 'N/A'),
        },
    ], [file]);

    useEffect(() => {
        console.log(file);
        console.log(file?.fileAttachments);
        if (file && file.fileAttachments && token) {
            const audioFile = file.fileAttachments.find((attachment) => attachment.tags && attachment.tags.includes("meeting_audio"));
            if (audioFile) {
                setAudioUrl(`/api/shared/files/${token}/attachment/${audioFile.id}`);
                return;
            }
        }

        setAudioUrl(null);
    }, [file, token]);

    useEffect(() => {
        if (file) {
            const fetchData = async () => {
                setLoadingPatientInfo(true);
                setPatientInfo(null);

                try {
                    if (token) {
                        const resp = await axiosPublicInstance.get(`/api/shared/patients/${token}`);

                        if (resp.data.success) {
                            setPatientInfo(resp.data.patient);
                        } else {
                            printError("Fetching patient info: " + resp.data.errorMessage)
                        }
                    }
                } catch (err) {
                    const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
                    printError("Fetching patient info: " + errMsg);
                } finally {
                    setLoadingPatientInfo(false);
                }
            };

            fetchData();
        } else {
            setPatientInfo(null);
            setLoadingPatientInfo(false);
        }
    }, [file, token]);

    useEffect(() => {
        if (patientInfo) {
            const fetchData = async () => {
                setLoadingPatientPhoto(true);
                setPatientPhoto(null);

                try {
                    if (token) {
                        const resp = await axiosPublicInstance.get(`/api/shared/patients/${token}/photo`, {responseType: 'blob'});
                        setPatientPhoto(resp.data);
                    }
                } catch (err) {
                    if (_.get(err, 'response.status') !== 404) {
                        const errMsg = _.get(err, 'response.data.errorMessage', err.message);
                        printError("Fetching patient photo: " + errMsg);
                    }
                } finally {
                    setLoadingPatientPhoto(false);
                }
            };

            fetchData();
        } else {
            setPatientPhoto(null);
            setLoadingPatientPhoto(false);
        }
    }, [patientInfo, token]);

    const downloadDocument = useCallback(() => {
        const downloadPdf = async (file, token) => {
            try {
                if (token) {
                    setDownloadingFile(true);

                    const resp = await axiosPublicInstance.get(`/api/shared/files/${token}/content`, {responseType: 'blob'});
                    const blob = new Blob([resp.data], {type: 'application/pdf'});
                    const link = document.createElement('a');

                    if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                        link.href = window.URL.createObjectURL(blob);
                    } else {
                        link.href = URL.createObjectURL(blob);
                    }

                    link.target = "_blank";
                    link.setAttribute(
                        'download',
                        file.fileName,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                }
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
                printError("Download error: " + errMsg);
            } finally {
                setDownloadingFile(false);
            }
        };

        if (file && token && !downloadingFile) {
            downloadPdf(file, token);
        }
    }, [file, token]);

    return (
        <div className="document-view-header">
            <div className="header-info">
                <div className="document-info-toolbar flex-grow-1">
                    <div className="info-left">
                        <div className="patient-menu-info no-background">
                            {!loadingPatientInfo && patientInfo
                                ? (<>
                                    {loadingPatientPhoto ? (
                                        <div className="patient-menu-loader">
                                            <Loader size={5} color={"#000"}/>
                                        </div>
                                    ) : (
                                        patientPhoto ? (
                                            <img className="patient-menu-image"
                                                 src={URL.createObjectURL(new Blob([patientPhoto]))}
                                                 alt="Patient Photo"/>
                                        ) : (
                                            <i className="patient-menu-image ni ni-avatar"/>
                                        )
                                    )}
                                    <div className="patient-menu-details">
                                        <div className="row">
                                            <div className="col">
                                                <span
                                                    className="patient-menu-detail patient-menu-detail-full">{patientInfo.firstName + " " + patientInfo.lastName}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span
                                                    className="patient-menu-detail">{patientInfo.birthdate && moment(patientInfo.birthdate).format("DD-MMM-YYYY")}</span>
                                                <span
                                                    className="patient-menu-detail">{patientInfo.gender && patientInfo.gender}</span>
                                                <span
                                                    className="patient-menu-detail">{!isEmpty(patientInfo.crmCases) && patientInfo.crmCases[0].crmCaseId}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>) : (
                                    <div className="patient-menu-loader">
                                        <Loader size={5} color={"#000"}/>
                                    </div>
                                )
                            }
                        </div>
                        <div className="vertical-separator"/>
                        {fileProps.map(prop =>
                            <div className="prop" key={prop.name}>
                                <div className="name">
                                    {prop.name}
                                </div>
                                <div className="value" title={prop.value}>
                                    {prop.value}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="document-info-toolbar info-controls">
                    <div className="info-left">
                        {audioUrl && (
                            <MeetingAudioRecording url={audioUrl} useAuth={true} progressClass="progress-big"/>
                        )}
                    </div>
                    <div className="info-right">
                        <div><label htmlFor="textViewEnabled" className="form-check-label">Text View</label></div>
                        <div className="form-check form-switch d-inline-block">
                            <input
                                id="textViewEnabled"
                                name="textViewEnabled"
                                type="checkbox"
                                className="form-check-input cursor-pointer"
                                onChange={() => setTextViewEnabled(!textViewEnabled)}
                                checked={textViewEnabled}
                            />
                        </div>
                        <button
                            type="button"
                            className={"btn btn-rnd btn-outline-purple"}
                            disabled={!file || !token || downloadingFile}
                            onClick={downloadDocument}
                        >
                            {!downloadingFile ? (
                                <i className="fas fa-lg fa-fw fa-download"/>
                            ) : (
                                <div className="spinner-border text-primary spinner-border-sm me-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const DocumentInfoSharedViewer = () => {
    const {token} = useParams();

    const [fileInfo, setFileInfo] = useState(null);
    const [file, setFile] = useState(null);
    const [loadingFileInfo, setLoadingFileInfo] = useState(true);
    const [error, setError] = useState(null);
    const [textViewEnabled, setTextViewEnabled] = useState(false);
    const [fileText, setFileText] = useState(null);
    const [goodLink, setGoodLink] = useState(false);
    const documentContentRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setFileInfo(null);
            setFile(null);

            if (!token) {
                setLoadingFileInfo(false);
                return;
            }

            setLoadingFileInfo(true);

            try {
                const resp = await axiosPublicInstance.get(`/api/shared/files/${token}`);

                if (resp.data.success) {
                    setFileInfo(resp.data.file);
                    const response = await axiosPublicInstance.get(`/api/shared/files/${token}/content`, {responseType: 'blob'});
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    setFile(file);
                    setGoodLink(true);
                } else {
                    setError(resp.data.errorMessage);
                }
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message)
                setError(errMsg);
            } finally {
                setLoadingFileInfo(false);
            }
        };

        fetchData();
    }, [token]);

    if (loadingFileInfo) {
        return (
            <div className="d-flex justify-content-center p-5">
                <FadeLoader size="5rem" color="#007bff" loading={true}/>
            </div>
        )
    }

    if (!goodLink) {
        return (
            <div className="row" style={{marginTop: "150px"}}>
                <img width={154} height={137} src={hourglassClockLightSvg}/>
                <span className="text-center" style={{"margin-top": "46px", "font-size": "36px", "font-weight": "500"}}>This link has expired.</span>
                <span className="text-center" style={{"margin-top": "35px", "font-size": "20px"}}>
                        This link cannot be used anymore.<br/>
For assistance send a message to <a href="mailto: info@n1x10.com">info@n1x10.com</a>.</span>
            </div>
        );
    }
    return (
        <div>
            <DocumentSharedViewHeader file={fileInfo} token={token} textViewEnabled={textViewEnabled}
                                      setTextViewEnabled={setTextViewEnabled} showTextView={false}/>
            {error && <Alert variant="danger">{error}</Alert>}
            <DocumentViewer visible={!textViewEnabled} file={file} pageNumber={1}/>
            {textViewEnabled && <TextView fileId={fileInfo.id} fileText={fileText} setFileText={setFileText} documentContentRef={documentContentRef}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfoSharedViewer);