export const SHOW_CONFIRM = 'confirm/SHOW_CONFIRM';
export const HIDE_CONFIRM = 'confirm/HIDE_CONFIRM';
export const CLEAR_CONFIRM = 'confirm/CLEAR_CONFIRM';

export const showConfirm = (content, options = {}) => {
	return {
		type: SHOW_CONFIRM,
		content,
		options,
	};
};

export const hideConfirm = () => {
	return {
		type: HIDE_CONFIRM,
	};
};

export const clearConfirm = () => {
	return {
		type: CLEAR_CONFIRM,
	};
};