import {LOAD_FILTERS} from "../actions/task-filter.actions";
import _ from "lodash";

const initialState = {}


export const taskFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_FILTERS.SUCCESS:

            const { patientId, filters } = action.payload
            const newState = _.set({}, patientId, filters);

            return {
                ...state,
                ...newState
            };
        case LOAD_FILTERS.CLEAR:
            return initialState;
        default:
            return state;
    }
};