import { SHOW_PANEL, HIDE_PANEL} from '../actions/panel.actions';

const initialState = {
    isPanelOpen: false,
    panelProps: {},
}

export const panelReducer = (state = initialState, action)  => {
    switch(action.type) {
        case SHOW_PANEL:
            return {
                ...state,
                isPanelOpen: action.panelType,
                panelProps: action.panelProps
            } 
            
        case HIDE_PANEL:
            return {
                ...state,
                isPanelOpen: false,
                panelProps: {}
            }
        default:
            return state;
    }
}