import React, {useEffect, useRef, useState} from "react";
import axiosInstance from "../../utils/axios-instance";
import _ from "lodash";
import {htmlToReact} from "../../utils/render-utils";
import {toast} from "react-toastify";
import Highlight from "react-highlighter";

const printError = (error) => {
    toast.error(error);
    console.error(error);
}

export const TextView = ({fileId, visible, searchText, fileText, setFileText, documentContentRef}) => {
    const [documentStyle, setDocumentStyle] = useState({overflow: "auto"});

    useEffect(() => {
        const fetchData = async (fileId) => {
            if (!fileId) {
                return;
            }

            try {
                const resp = await axiosInstance.get(`/api/file/download/${fileId}/text`);

                if (resp.data) {
                    setFileText(resp.data);
                } else {
                    printError("Fetching file info: " + resp.data.errorMessage)
                }
            } catch (err) {
                const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
                printError("Fetching file info: " + errMsg);
            }
        };

        fetchData(fileId);
    }, [fileId]);

    useEffect(() => {
        const contentHeight = (window.innerHeight - documentContentRef.current.getBoundingClientRect().top);
        setDocumentStyle({overflow: 'auto', height: contentHeight + 'px'});
    }, [])

    const elemStyle = visible ? {} : {display: "none"};

    return <div style={elemStyle} ref={documentContentRef} style={documentStyle} className="document-content">
            {fileText && fileText.filePages.map((filePage, index) =>
                <div className="form-group mt-4 left-right-margin-100 bg-white">
                    <div className="horizontal-separator">Page {index + 1}</div>
                    {filePage.text.split("\n").map(text => {
                        return <>
                            <Highlight search={searchText || ""} caseSensitive={false}>{text}</Highlight>
                            <br/>
                        </>;
                    })}
                    <br/>
                </div>
            )}
        </div>

}
