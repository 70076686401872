import _ from "lodash";
import dompurify from 'dompurify';
import parse from 'html-react-parser';
import Mark from "mark.js";

export const markText = (text, markText, sanitize = true) => {
    return new Promise(function(resolve, reject) {
        const sanitizedText = sanitize ? dompurify.sanitize(text) : text;

        if (!_.isEmpty(markText)) {
            const tmpDiv = document.createElement('div');
            tmpDiv.innerHTML = sanitizedText;

            const marker = new Mark(tmpDiv);
            marker.mark(markText, {
                "separateWordSearch": false,
                "acrossElements": true,
                "done": (counter) => {
                    resolve("<span>" + tmpDiv.innerHTML + "</span>");
                }
            });
        } else {
            resolve(sanitizedText);
        }
    });
}

export const htmlToReact = (html, sanitize = true) => {
    const sanitizedHtml = sanitize ? dompurify.sanitize(html) : html;
    return parse(sanitizedHtml);
}