import axios from 'axios';
import isEmpty, {
    actionCreator,
    createRequestTypes,
    getLocalStorageUserData
} from '../utils/helpers';
import {API_ROOT} from "../utils/api-config";
import _ from "lodash";

export const AUTH = createRequestTypes('AUTH');
export const AUTH_LOGOUT = createRequestTypes('AUTH_LOGOUT');

export const login = (userId, authorities, personalPreferences) => async (dispatch) => {
    dispatch(actionCreator.request(AUTH));

    try {
        const loggedInTime = Date.now();
        dispatch(actionCreator.success(AUTH, { userId, authorities, loggedInTime, personalPreferences}));
    } catch (err) {
        const mockError = {
            message: _.get(err, 'response.data.errorMessage','Invalid username or password')
        };
        dispatch(actionCreator.failure(AUTH, mockError));

        return err;
    }
};

export const logout = (navigate) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            url: `${API_ROOT}api/logout`,
        };

        axios(headers).catch(() => {
            // ignoring
        }).finally(() => {
            dispatch(actionCreator.success(AUTH_LOGOUT));

            if (navigate) {
                navigate('/login')
            }
        })
    };
};

export const checkAuthStatus = (navigate) => {
    return (dispatch) => {
        const userData = getLocalStorageUserData();
        if (isEmpty(userData)) {
            logout(navigate)(dispatch);
        }
    };
};
