import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import {TaskUserPhoto} from "./TaskUserPhoto";
import {taskTypes} from "../../../constants/task-constants";
import _ from "lodash";

class Task extends Component {

    state = {
        isEditing: false,
    }

    handleShowTaskDetails = () => {
        const {task, typeKey} = this.props;

        const taskType =taskTypes[typeKey].type;

        // Mark as viewed
        if (task.isNew) {
            this.props.handleMarkAsViewed(task);
        }

        // Show task details
        this.props.handleShowTaskDetailsById(taskType, typeKey, task.id);
    }

    render() {

        const {
            assignee,
            due,
            task,
            updatingTask,
            handleMarkAsComplete,
            typeKey,
            userId
        } = this.props;

        return (
            <div className={"task" + (task.isNew ? "" : " task-viewed")}>

                <TaskUserPhoto user={assignee} onClick={this.handleShowTaskDetails} />
                <div className="task-heading">
                    <div
                        className={"task-heading-title" + (task.isNew ? " task-heading-title-viewed" : "")}
                        onClick={this.handleShowTaskDetails}
                    >
                        {task.title}
                    </div>
                    <div
                        className={"task-heading-date" + (typeKey === "overdue" ? " task-heading-date-overdue" : "")}
                        onClick={this.handleShowTaskDetails}
                    >
                        {taskTypes[typeKey].text(task)}
                    </div>
                    <div className="task-heading-actions">
                        {(userId === task.assignee.id || userId === task.createdBy.id) && (
                            <button
                                className="task-button task-heading-actions-element"
                                onClick={() => handleMarkAsComplete(task)}
                                disabled={!isEmpty(updatingTask)}
                            >
                                {updatingTask === task.id
                                    ? <i className="fa fa-spinner fa-spin"/>
                                    : <i className={"fa fa-check-circle" + (task.completed ? " text-success" : "")}/>
                                }
                            </button>
                        )}
                    </div>
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
