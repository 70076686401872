import {
    LOAD_PERSONAL_PREFERENCES,
    LOAD_ANALYTES_DISEASE,
    LOAD_ANALYTES_TREATMENT
} from "../actions/user-settings.actions";


let initialState = {
    patientPreferences: {},
    availableAnalytes:[],
};

export const userSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PERSONAL_PREFERENCES.CLEAR:
            return {
                ...initialState,
                patientPreferences: {},
            };
        case LOAD_PERSONAL_PREFERENCES.SUCCESS:
            return {
                ...state,
                patientPreferences: action.payload.patientPreferences,
            };
        case LOAD_ANALYTES_DISEASE.CLEAR:
        case LOAD_ANALYTES_TREATMENT.CLEAR:
            return {
                ...initialState,
                availableAnalytes: [],
            };
        case LOAD_ANALYTES_DISEASE.SUCCESS:
        case LOAD_ANALYTES_TREATMENT.SUCCESS:
            return {
                ...state,
                availableAnalytes: action.payload.bloodTestAnalytes,
            };
        default:
            return state;
    }
};
