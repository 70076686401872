import {combineReducers} from 'redux';
import {patientsReducer} from "./patients.reducer";
import {loadingReducer} from "./loading.reducer";
import {modalReducer} from "./modal.reducer";
import {panelReducer} from "./panel.reducer";
import {proReducer} from "./pro.reducer";
import {notesReducer} from "./notes.reducer";
import {tasksReducer} from "./tasks.reducer";
import {calendarReducer} from "./calendar.reducer";
import {formsReducer} from "./forms.reducer";
import {chatReducer} from "./chat.reducer";
import {authReducer} from "./auth.reducer";
import {errorReducer} from "./error.reducer";
import {AUTH, AUTH_LOGOUT} from "../actions/auth.actions";
import {taskNotificationReducer} from "./task-notification.reducer";
import {taskFilterReducer} from "./task-filter.reducer";
import {confirmReducer} from "./confirm.reducer";
import {userSettingsReducer} from "./user-settings.reducer";
import {fileReducer} from "./file.reducer";

const appReducer = combineReducers({
    patients: patientsReducer,
    loading: loadingReducer,
    modals: modalReducer,
    panels: panelReducer,
    pro: proReducer,
    notes: notesReducer,
    tasks: tasksReducer,
    calendar: calendarReducer,
    forms: formsReducer,
    chat: chatReducer,
    auth: authReducer,
    errors: errorReducer,
    taskNotifications: taskNotificationReducer,
    patientTaskFilters: taskFilterReducer,
    confirm: confirmReducer,
    userSettings: userSettingsReducer,
    file: fileReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT.SUCCESS) {
        state = undefined;
    }

    // This prevent race conditions when user log-out and log-in with other account
    if (action.type === AUTH.SUCCESS) {
        const { auth } = state;
        state = { auth };
    }

    return appReducer(state, action);
};

