import React, {Component} from 'react';
import {connect} from 'react-redux';

class ReportFilters extends Component {

    render () {

        const {
            isLoading,
            filters,
            handleFilterChange,
        } = this.props;

        return  (
            <>
                <div className="card">
                    <div className="card-body">

                        <div className="card-caption card-caption-center">

                            <h6>Report Filter</h6>

                        </div>

                        <div className="card-toolbar">
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeLab ? " btn-purple" : "")}
                                onClick={() => handleFilterChange("includeLab")}
                            >
                                Lab
                            </button>
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeImaging ? " btn-success" : "")}
                                onClick={() => handleFilterChange("includeImaging")}
                            >
                                Imaging
                            </button>
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeIntervention ? " btn-red" : "")}
                                onClick={() => handleFilterChange("includeIntervention")}
                            >
                                Intervention
                            </button>
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includePRO ? " btn-info" : "")}
                                onClick={() => handleFilterChange("includePRO")}
                            >
                                PRO
                            </button>
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeMDComments ? " btn-secondary" : "")}
                                onClick={() => handleFilterChange("includeMDComments")}
                            >
                                MD Comments
                            </button>
                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeHospitalVisits ? " btn-dark" : "")}
                                onClick={() => handleFilterChange("includeHospitalVisits")}
                            >
                                Hospital Visits
                            </button>

                            <button
                                type="button" disabled={isLoading}
                                className={"btn btn-xs btn-round" + (filters.includeErVisits ? " btn-danger" : "")}
                                onClick={() => handleFilterChange("includeErVisits")}
                            >
                                ER Visits
                            </button>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilters);