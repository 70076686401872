import {useLocation, useNavigate} from "react-router-dom";
import {Fragment, useEffect} from "react";
import {logout} from "../actions/auth.actions";
import {connect} from "react-redux";
import {getAuthData} from "./selectors";

const ManagedRoute = ({ route, isLoggedIn, logout }) => {
    const { component: Component, layout: Layout = Fragment, public: isPublic } = route;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isPublic && !isLoggedIn) {
            logout(navigate);
            navigate('/login', {
                state: { from: location },
            });
        }
    }, [isPublic, isLoggedIn, navigate, location, logout]);

    if (!isPublic && !isLoggedIn) {
        return null;
    }

    return (
        <Layout>
            <Component />
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    isLoggedIn: getAuthData(state).isLoggedIn,
});

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagedRoute);