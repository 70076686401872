import React from 'react';
import AudioPlayer from "./AudioPlayer";

const MeetingAudioRecording = ({ url, useAuth, progressClass }) => {

    return (
        <div className="meeting-recording">
            <span className="audio-icon" />
            <span className="audio-label"></span>
            <AudioPlayer url={url} useAuth={useAuth} progressClass={progressClass} />
        </div>
    )
}

export default MeetingAudioRecording;