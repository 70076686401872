import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import isEmpty from "../../../utils/helpers";
import moment from "moment";
import {loadPatientIntervention} from "../../../actions/patients.actions";
import Loader from "react-spinners/BeatLoader";
import * as _ from "lodash";

import systemicSvg from "../../../images/systemic.svg";
import radiationSvg from "../../../images/radiation.svg";
import surgerySvg from "../../../images/surgery.svg";
import lastFirstSvg from "../../../images/last-first-01.svg";
import firstLastSvg from "../../../images/first-last-02.svg";

class InterventionModal extends Component {

    state = {
        activeFilters: true,
        showChemo: true,
        showRadiation: true,
        showSurgery: true,
        interventionActions: {},
        sortOrder: 'asc',
    }

    componentDidMount() {
        this.props.loadPatientIntervention(this.props.patientId, this.state.showChemo, this.state.showRadiation, this.state.showSurgery).then((response) => {
            this.orderIntervention(response.actions)
        });
    }

    closeModal = () => {
        this.props.hideModal();
    }

    handleFilter = () => {

        if (!this.state.showChemo && !this.state.showRadiation && !this.state.showSurgery) {
            this.setState({activeFilters: false});
        } else {
            this.setState({activeFilters: true});
        }

        this.props.loadPatientIntervention(this.props.patientId, this.state.showChemo, this.state.showRadiation, this.state.showSurgery).then((response) => {
            this.orderIntervention(response.actions)
        });
    }

    compareIntervention = (a, b) => {
        if (a.beginDate < b.beginDate || a.time < b.beginDate || a.beginDate < b.time || a.time < b.time)
            return 1;
        else if (a.beginDate > b.beginDate || a.time > b.beginDate || a.beginDate > b.time || a.time > b.time)
            return -1;
        else
            return 0;
    }

    orderIntervention = (actions) => {
        const orderedActions = actions ? actions.sort(this.compareIntervention) : [];
        this.setState({interventionActions: orderedActions});
    }

    sortByColumn = (column) => {
        let data;
        if (this.state.sortOrder === 'desc') {
            data = _.orderBy(this.state.interventionActions, ['time'], ['desc']);
            this.setState({
                sortOrder: 'asc'
            });
        } else {
            data = _.orderBy(this.state.interventionActions, ['time'], ['asc']);
            this.setState({
                sortOrder: 'desc'
            });
        }
        this.setState({
            interventionActions: data
        });
    }

    render() {

        const {
            showChemo,
            showRadiation,
            showSurgery,
            interventionActions,
        } = this.state;

        const {
            loading,
        } = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Intervention</h5>
                    <div className="modal-toolbar">
                        <button
                            type="button"
                            className={"btn btn-xs btn-round" + (showChemo ? " btn-red" : "")}
                            onClick={() => {
                                this.setState((prevState) => ({showChemo: !prevState.showChemo}), () => {
                                    this.handleFilter();
                                })
                            }}
                        >
                            Systemic
                        </button>
                        <button
                            type="button"
                            className={"btn btn-xs btn-round" + (showRadiation ? " btn-red" : "")}
                            onClick={() => {
                                this.setState((prevState) => ({showRadiation: !prevState.showRadiation}), () => {
                                    this.handleFilter();
                                })
                            }}
                        >
                            Radiation
                        </button>
                        <button
                            type="button"
                            className={"btn btn-xs btn-round" + (showSurgery ? " btn-red" : "")}
                            onClick={() => {
                                this.setState((prevState) => ({showSurgery: !prevState.showSurgery}), () => {
                                    this.handleFilter();
                                })
                            }}
                        >
                            Surgery
                        </button>
                    </div>
                </div>

                <div className="modal-body">
                    {loading === true ? (
                        <Loader size={5} color={"#ccc"}/>
                    ) : (
                        <table
                            className="table table-hover table-subtle table-responsive-sm table-responsive-md">
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" role="columnheader">
                                    <div className="header-container sorted" style={{cursor: "pointer"}} onClick={() => this.sortByColumn('time')}>
                                        Date
                                        {this.state.sortOrder === 'desc'
                                            ? <i className={"ni ni-arrow-down ni-xs ms-1"} />
                                            : <i className={"ni ni-arrow-up ni-xs ms-1"} />
                                        }
                                    </div>
                                </th>
                                <th scope="col">Type</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Details</th>
                                <th scope="col">Comments</th>
                            </tr>
                            </thead>
                            <tbody>

                            {!isEmpty(interventionActions) &&
                            interventionActions.map((item, i) => (
                                <tr>
                                    <td className="color-red">
                                        <img src={
                                            item.actionName === "treatment"
                                                ? (
                                                    item.treatmentType === "drug"
                                                        ? systemicSvg
                                                        : radiationSvg
                                                )
                                                : surgerySvg
                                        } style={{height: '1.25rem'}} />
                                    </td>
                                    <td className={item.beginDate && !item.endDate ? "font-weight-bold" : ""}>
                                        {item.beginDate ? (<>
                                            {moment.parseZone(item.beginDate).format('DD-MMM-YYYY')}&nbsp;-&nbsp;
                                            {item.endDate ? (moment.parseZone(item.endDate).format('DD-MMM-YYYY')) : 'Ongoing'}

                                        </>) : (moment.parseZone(item.time).format('DD-MMM-YYYY'))}
                                    </td>
                                    <td>{item.typeName || item.type || "N/A"}</td>
                                    <td>
                                        {item.dose ? (<>
                                            {item.dose}
                                            {!isEmpty(item.doseUnit) && '\u00A0' + item.doseUnit}
                                            {!isEmpty(item.frequency) && " × " + item.frequency}
                                            {!isEmpty(item.boost) && ', ' + (item.boost === "no" ? 'no\u00A0boost' : 'boost')}
                                        </>) : "N/A"}
                                    </td>
                                    <td>
                                        {item.treatmentType === "radiation" ? (<>
                                            {item.siteTargeted ? item.siteTargeted : 'Site N/A'}
                                            {item.numberCourses ? ' ' + item.numberCourses + ' courses' : ' Courses N/A'}
                                        </>) : (<>
                                            {item.details || "N/A"}
                                        </>)}
                                        {item.actionName === 'surgery' && (<>
                                            {item.procedureSite ? item.procedureSite : 'Procedure Site N/A'}
                                            , {item.type ? item.type : 'Type N/A'}
                                            , {item.complications ? item.complications : 'Complications N/A'}
                                            {item.comments && ', ' + item.comments}
                                        </>)}
                                    </td>
                                    <td>
                                        {item.sideEffects && item.sideEffects + "; "}
                                        {item.comments ? item.comments : (item.sideEffects ? "" : "N/A")}
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    )}

                </div>
            </>
        );
    }

}

InterventionModal.propTypes = {
    patientInterventionChemo: PropTypes.array,
    patientInterventionRadiation: PropTypes.array,
    patientInterventionSurgery: PropTypes.array,
}

const patientInterventionDetailedLoader = createLoadingSelector(['LOAD_PATIENT_INTERVENTION']);

const mapStateToProps = (state) => {

    const
        patientId = getModalData(state).modalProps,
        loading = patientInterventionDetailedLoader(state);

    return {
        patientId,
        loading
    };
};

const mapDispatchToProps =
    {
        loadPatientIntervention
    };

export default connect(mapStateToProps, mapDispatchToProps)(InterventionModal);