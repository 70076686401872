export const NA_TEXT = "N/A";

export const formatContentType = (contentType) => {
    const ct = contentType.substr(contentType.indexOf("/") + 1);
    switch (ct) {
        case 'pdf':
            return 'PDF';
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'DOCX';
        case 'jpg':
        case 'jpeg':
            return 'JPEG';
        default:
            switch (contentType) {
                case "text/plain":
                    return "text";
                default:
                    return contentType;
            }
    }
}