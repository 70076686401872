import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import AuthBaseLayout from "../Common/AuthBaseLayout";
import AuthCode from "react-auth-code-input";
import {Spinner} from "react-bootstrap";
import classNames from "classnames";
import {showConfirm} from "../../../actions/confirm.actions";
import axiosInstance, {axiosPublicInstance} from "../../../utils/axios-instance";
import _ from "lodash";
import Countdown from "react-countdown";
import {rendererCountdown} from "../../../utils/helpers";

const CodeStep = ({codeToken: codeIssuerToken, onSuccess, onError, showConfirm}) => {

    const [codeToken, setCodeToken] = useState(null);
    const [loadingCodeToken, setLoadingCodeToken] = useState(false);
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState("");
    const [trustThisDevice, setTrustThisDevice] = useState(false);

    const isValidCode = useMemo(() => {
        return code && code.length === 4;
    }, [code]);

    const issueLoginCode = useCallback(async () => {
        try {
            setLoadingCodeToken(true);
            const resp = await axiosPublicInstance.post("/api/login/code", {token: codeIssuerToken});
            const { token, phone } = resp.data;

            setCodeToken(token);
            setPhone(phone);
        } catch (error) {
            console.error(error);
            if (onError) {
                onError(error);
            }
        } finally {
            setLoadingCodeToken(false);
        }
    }, [codeIssuerToken]);

    const verifyLoginCode = useCallback(async () => {
        try {
            const resp = await axiosInstance.post(
                "/api/login/verify-code", {
                    token: codeToken,
                    code: code,
                    trustThisDevice: trustThisDevice
                });

            const {token, userId, authorities, personalPreferences} = resp.data;

            onSuccess(token);
        } catch (error) {
            const errorCode = _.get(error, 'response.data.errorCode');
            let errorMessage = _.get(error, 'response.data.errorMessage', error.message);
            if (errorCode === 22) {
                errorMessage = "The verification code you entered is incorrect. Please try again."
            }

            console.error(error);
            toast.error(errorMessage);
        }
    }, [codeToken, code, trustThisDevice]);

    const handleOnTrustDeviceClick = useCallback((event) => {
        if (!trustThisDevice) {
            showConfirm(
                <div className="alert alert-warning" role="alert">
                    Please note that this option should only be selected when using your personal device,
                    and it should not be chosen when accessing your account from a public or someone else's device.
                    By clicking "OK," you are acknowledging and confirming that the device you are using is your own personal device.
                    It is important to exercise caution and ensure the security of your login information and personal data when using any device.
                </div>,
                {
                    confirmText: "Ok",
                    cancelText: "Cancel",
                    onConfirm: () => setTrustThisDevice(true),
                }
            );
        } else {
            setTrustThisDevice(false);
        }

    }, [trustThisDevice]);

    useEffect(() => {
        issueLoginCode();
    }, []);

    return (
        <AuthBaseLayout>
            <div className="code-verification">
                <div className="code-verification__title">Login</div>
                <div className="code-verification__description">
                    Enter verification code
                </div>
                <div className="code-verification__info">
                    A security code was sent to your mobile phone {phone ? phone : "(No Phone)"}
                </div>
                <div className="code-verification__code-input">
                    <AuthCode inputClassName="auth-code-input" allowedCharacters='numeric' length={4} onChange={setCode} />
                </div>
                <div className="code-verification__expire-info">
                    The code will expire within <Countdown date={Date.now() + 360000} renderer={rendererCountdown} />
                </div>
                <div className="code-verification__trust-this-device">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            checked={trustThisDevice}
                            className="custom-control-input"
                            id="trust-this-device"
                            onClick={handleOnTrustDeviceClick}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="trust-this-device"
                        >
                            Trust this device
                        </label>
                    </div>
                </div>
                <div className="code-verification__resend">
                    Didn't receive an email?
                    <button
                        className={classNames(
                            'code-verification__resend-btn',
                            {
                                'code-verification__resend-btn--disabled': loadingCodeToken,
                            }
                        )}
                        disabled={loadingCodeToken}
                        onClick={issueLoginCode}>
                        Resend
                    </button>
                    {loadingCodeToken && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-1"
                            variant="primary"
                        />
                    )}
                </div>
                <div className="code-verification__verify">
                    <button
                        className="btn btn-primary btn-rnd btn-save ms-1
                            code-verification__verify-btn"
                        disabled={!isValidCode}
                        onClick={verifyLoginCode}
                    >
                        Verify
                    </button>
                </div>
            </div>
        </AuthBaseLayout>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    showConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeStep);