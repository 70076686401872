import {useEffect, useRef, useState} from "react";
import axiosInstance from "../utils/axios-instance";

const useFile = (url, axiosInst = axiosInstance) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const fileUrlRef = useRef();

    useEffect(() => {
        const prevFileUrl = fileUrlRef.current;

        if (prevFileUrl && prevFileUrl !== fileUrl) {
            URL.revokeObjectURL(prevFileUrl);
        }

        fileUrlRef.current = fileUrl;
    }, [fileUrl])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                if (!url) {
                    setFileUrl(null);
                    return;
                }

                const response = await axiosInst.get(url, {
                    responseType: 'blob',
                });

                const objectUrl = URL.createObjectURL(response.data);
                setFileUrl(objectUrl);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            fileUrl && URL.revokeObjectURL(fileUrl);
        }
    }, [url]);

    return [fileUrl, error, loading];
};

export default useFile;