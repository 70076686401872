// Initialise documents object
import axiosInstance from "./axios-instance";

window.documentTabs = {};

// Open or focus document
export const openDocument = (patientId, documentId, pageNumber) => {

    // Construct tab address
    const address = '/patient/'+patientId+'/document' +
        (documentId ? '?documentId='+documentId : '') +
        (pageNumber ? '&pageNumber='+pageNumber : '');

    // Focus if already open
    if (window.documentTabs.hasOwnProperty(address) && !window.documentTabs[address].closed) {
        window.documentTabs[address].focus();
    }

    // Otherwise open new tab
    else {
        window.documentTabs[address] = window.open(address);
    }

    // Alert the user of blocked popup
    /*if (!window.documentTabs[address] || window.documentTabs[address].closed || typeof window.documentTabs[address].closed == 'undefined') {

        let browser = 'Your web browser';

        if (navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) browser = 'Chrome';
        else if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) browser = 'Safari';
        else if (navigator.userAgent.toLowerCase().indexOf('firefox/') > -1) browser = 'Firefox';

        alert(browser + ' blocked the document from opening. Allow pop ups and try again.');
    }*/

}

// Close all documents
export const closeDocuments = () => {
    if (window.documentTabs) {
        Object.keys(window.documentTabs).map(key => {
            window.documentTabs[key].close();
            delete window.documentTabs[key];
        });
    }
    // return true;
}

export const initWindowEvents = () => {
    window.onbeforeunload = (event) => {
        if (window.documentTabs && Object.values(window.documentTabs).some(tab => !tab.closed)) {
            event.preventDefault();
            event.returnValue = "";
        }
    };

    window.onunload = (event) => {
        if (window.documentTabs && Object.values(window.documentTabs).some(tab => !tab.closed)) {
            closeDocuments();
        }
    }
}

export const openPdfRaw = (url) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url, {responseType: 'arraybuffer'})
            .then((response) => {
                return new Blob([response.data], {type: 'application/pdf'});
            })
            .then((blob) => {
                // Create blob link to download
                let url;

                if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                    url = window.URL.createObjectURL(blob);
                }
                else {
                    url = URL.createObjectURL(blob);
                }

                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .finally(() => {
                resolve();
            })
    });
}