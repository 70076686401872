import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal} from "../../../actions/modal.actions";
import {loadAvailableTaskAssignees} from "../../../actions/tasks.actions";
import {
    cleanTasksData,
    loadTasks,
    createTask,
    updateTask,
    deleteTask,
    updateTaskCompleted,
    createTaskEvent
} from "../../../actions/tasks.actions";
import {
    createLoadingSelector,
    getAuthData,
    getPatientsData,
    getPatientTaskFilters,
    getTasksData
} from "../../../utils/selectors";
import isEmpty, {getWindowDimensions, setWindowDimensions} from "../../../utils/helpers";
import PanelTitle from "../PanelTitle";
import Loader from "react-spinners/BeatLoader";
import Tasks from "./Tasks";
import {Rnd} from "react-rnd";
import TaskNotificationModal from "./TaskNotificationModal";
import _ from "lodash";
import {taskTypes} from "../../../constants/task-constants";
import moment from "moment";
import {loadTaskNotifications} from "../../../actions/task-notification.actions";
import {dueTaskComparator, getAssigneeOrdinal, getTaskType} from "../../../utils/task-utils";
import {saveTaskFilters} from "../../../actions/task-filter.actions";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "1px 0 2px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.6)",
    border: "solid 1px #a8a8a8",
    backgroundColor: "#fff",
};

class TasksPanel extends Component {

    state = {
        isAssigneesLoaded: false,
        assignees: null,
        isTasksLoaded: false,
        tasks: null,
        showTaskDetailsById: {},
        updatingTask: null,
        taskType: "upcoming",
        windowDimension: getWindowDimensions('tasks'),
        showNotificationModal: false,
        notificationsSeen: false
    }

    componentDidMount() {
        this.getUsers();
        this.getTasks();
    }

    toggleTaskNotifications = () => {
        this.setState(prevState => ({
            notificationsSeen: true,
            showNotificationModal: !prevState.showNotificationModal,
        }));
    }

    closeNotificationModal = () => {
        this.setState(prevState => ({
            showNotificationModal: false,
        }));
    }

    getUsers = () => {
        this.props.loadAvailableTaskAssignees(this.props.patientId).then(response => {

            let assignees = {};

            if (!isEmpty(response.systemUsers)) {

                const sortedUsers = this.sortAssignees(response.systemUsers);

                sortedUsers.map(systemUser => {

                    // Collect assignee info for display and id for filtering
                    if (!assignees.hasOwnProperty(systemUser.id) && !systemUser.roles.includes('GUEST') && !systemUser.roles.includes('DATA_STUDIO')) {
                        assignees[systemUser.id] = systemUser;
                    }

                });
            }

            // Store assignees
            this.setState({
                isAssigneesLoaded: true,
                assignees: assignees,
            });
        })
    }

    getTasks = (source) => {
        const { showTaskDetailsById, taskType } =  this.state;
        const { patientId, userId, patientLastAccessTime, patientAccessTime } = this.props;

        const dueToDate = moment().add(31, 'days').startOf('day').format();
        const completedFromDate = moment().subtract(30, 'days').startOf('day').format();

        this.props.loadTasks(patientId, userId, dueToDate, completedFromDate).then(response => {

            let tasks = [];
            let structuredTasks = {};

            Object.entries(taskTypes).forEach(([key, type]) => {
                _.set(structuredTasks, `${type.type}.${key}`, [])
            });

            if (response && response.tasks) {
                tasks = response.tasks;
            }

            this.props.loadTaskNotifications({tasks, userId, patientLastAccessTime, patientAccessTime, source});

            tasks.forEach(task => {
                const taskKey = getTaskType(task);
                const taskHeading = taskTypes[taskKey];

                _.get(structuredTasks,`${taskHeading.type}.${taskKey}`).push(task);

                if (task.id === showTaskDetailsById.id) {
                    showTaskDetailsById.type = taskHeading.type;
                    showTaskDetailsById.due = taskKey;
                }
            });

            this.loadPatientTaskFilters();

            Object.entries(taskTypes).forEach(([key, type]) => {
                if (type.transform) {
                    structuredTasks[type.type][key] = type.transform(structuredTasks[type.type][key]);
                }

                if (type === taskTypes.overdue) {
                    const overdueTasks = structuredTasks[type.type][key];
                    structuredTasks[type.type][key] = overdueTasks.sort(dueTaskComparator);
                }
            });

            // Store tasks
            this.setState({
                isTasksLoaded: true,
                tasks: structuredTasks,
                showTaskDetailsById: showTaskDetailsById
            });
        });
    }

    componentWillUnmount() {
        this.props.cleanTasksData();
    }

    handleCreateNew = () => {
        this.props.showModal("PANEL_POPOUT_TASKS_MODAL", {
            userId: this.props.userId,
            patientId: this.props.patientId,
            assignees: this.state.assignees,
            getTasks: this.getTasks,
            handleShowTaskDetailsById: this.handleShowTaskDetailsById
        });
    }

    handleEdit = (task) => {
        this.props.showModal("PANEL_POPOUT_TASKS_MODAL", {
            userId: this.props.userId,
            patientId: this.props.patientId,
            assignees: this.state.assignees,
            getTasks: this.getTasks,
            task: task,
            handleShowTaskDetailsById: this.handleShowTaskDetailsById
        });
    }

    handlePopOut = () => {
        this.handleCreateNew();
    }

    handleShowCreatedByMe = () => {
        const { patientId, patientTaskFilters, saveTaskFilters} = this.props;
        patientTaskFilters.showCreatedByMe = !patientTaskFilters.showCreatedByMe;

        saveTaskFilters({
            patientId: patientId,
            filters: {...patientTaskFilters}
        });
    }

    handleShowOnlyNew = () => {
        const { patientId, patientTaskFilters, saveTaskFilters } = this.props;
        patientTaskFilters.showOnlyNew = !patientTaskFilters.showOnlyNew;

        saveTaskFilters({
            patientId: patientId,
            filters: {...patientTaskFilters}
        });
    }

    handleShowTasksByAssignee = (id = "") => {
        const { patientId, patientTaskFilters, saveTaskFilters } = this.props;
        const { showTasksByAssignee } = patientTaskFilters;

        if (!showTasksByAssignee.delete(id)) {
            showTasksByAssignee.add(id);
        }

        saveTaskFilters({
            patientId: patientId,
            showTasksByAssignee: new Set(showTasksByAssignee),
            filters: {...patientTaskFilters}
        });
    }

    handleShowTaskDetailsById = (type, due, id) => {
        this.setState({
            showTaskDetailsById: id ? {type: type, due: due, id: id} : {},
        });
    }

    handleMarkAsViewed = (task, source) => {
        this.props.createTaskEvent(this.props.patientId, task.id, 'VIEW').then(() => {
            this.getTasks(source)
        });
    }

    handleMarkAsComplete = (task) => {
        let updatedTask = {
            id: task.id,
            completed: !task.completed,
        };

        this.setState({updatingTask: task.id}, () => {
            this.props.updateTaskCompleted(this.props.patientId, updatedTask).then(() => {
                this.setState({updatingTask: null}, this.getTasks);
            });
        })
    }

    handleShowDeleteModal = (task) => {

        this.props.showModal("GENERIC_CONFIRM_MODAL", {
            title: 'Are you sure you want to erase this task',
            message: task.title,
            confirmHandler: () => {
                this.handleDeleteTask(task.id);
            },
        });
    }

    handleDeleteTask = (taskId) => {
        this.handleShowTaskDetailsById();
        this.props.deleteTask(this.props.patientId, taskId).then(response => {
            this.getTasks();
        })
    }

    handleChangeTaskType = (taskType = "") => {
        this.setState({
            taskType: taskType
        });
    }

    loadPatientTaskFilters = () => {
        const { userId, patientId, patientTaskFilters, saveTaskFilters, authorities } = this.props;

        if (_.isEmpty(patientTaskFilters)) {
            const defaultFilters = {
                patientId: patientId,
                filters: {
                    showCreatedByMe: false,
                    showOnlyNew: false,
                    showTasksByAssignee: authorities.includes("OA") ? new Set() : new Set([userId])
                }}

            saveTaskFilters(defaultFilters);
        }
    }

    render() {

        const {
            isAssigneesLoaded,
            assignees,
            isTasksLoaded,
            tasks,
            showTaskDetailsById,
            updatingTask,
            windowDimension,
            taskType,
        } = this.state;

        const {
            userId,
            hidePanel,
            allTasks,
            patientLastAccessTime,
            patientAccessTime,
            patientTaskFilters,
        } = this.props;

        const taskHeadings = _.chain(Object.values(taskTypes)).map(item => item.type).uniq().value();

        return (
            <Rnd
                style={style}
                size={{ width: windowDimension.width, height: windowDimension.height }}
                position={{ x: windowDimension.x, y: windowDimension.y }}
                onDragStop={(e, d) => {
                    windowDimension['x'] = d.x;
                    windowDimension['y'] = d.y;
                    setWindowDimensions('tasks', windowDimension);
                    this.setState({windowDimension: windowDimension});
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    windowDimension['width'] = ref.style.width;
                    windowDimension['height'] = ref.style.height;
                    setWindowDimensions('tasks', windowDimension);
                    this.setState({windowDimension: windowDimension});
                }}
            >

                <div className="panel-header">

                    <PanelTitle
                        title="Tasks"
                        hidePanel={hidePanel}
                    />

                </div>

                <div className={"panel-body" + ((!isTasksLoaded && isEmpty(showTaskDetailsById)) ? " panel-body-loading" : "")}>

                    <div className="task-notification-pivot">
                        {this.state.showNotificationModal &&
                        <TaskNotificationModal
                            userId={userId}
                            patientId={this.props.patientId}
                            tasks={allTasks}
                            patientLastAccessTime={patientLastAccessTime}
                            patientAccessTime={patientAccessTime}
                            handleCreateNew={this.handleCreateNew}
                            handleShowCreatedByMe={this.handleShowCreatedByMe}
                            showTaskDetailsById={showTaskDetailsById}
                            handleShowTaskDetailsById={this.handleShowTaskDetailsById}
                            updatingTask={updatingTask}
                            handleMarkAsComplete={this.handleMarkAsComplete}
                            handleMarkAsViewed={this.handleMarkAsViewed}
                            handleShowDeleteModal={this.handleShowDeleteModal}
                            handleDeleteTask={this.handleDeleteTask}
                            handleEdit={this.handleEdit}
                            toggleTaskNotifications={this.toggleTaskNotifications}
                            onClose={this.toggleTaskNotifications}  />
                        }
                    </div>

                    {(!isTasksLoaded && isEmpty(showTaskDetailsById) || _.isEmpty(patientTaskFilters)) &&
                    <div className="panel-loader">
                        <Loader size={10} color={"#ccc"} />
                    </div>
                    }

                    {isTasksLoaded && isEmpty(tasks) &&
                    <p className="panel-body-empty">No data found</p>
                    }

                    {(isAssigneesLoaded && isTasksLoaded && !isEmpty(tasks) && !_.isEmpty(patientTaskFilters)) &&
                    <div className="tasks">
                        <Tasks
                            userId={userId}
                            patientId={this.props.patientId}
                            assignees={assignees}
                            tasks={tasks}
                            getTasks={this.getTasks}
                            handleCreateNew={this.handleCreateNew}
                            handleShowTasksByAssignee={this.handleShowTasksByAssignee}
                            handleShowCreatedByMe={this.handleShowCreatedByMe}
                            handleShowOnlyNew={this.handleShowOnlyNew}
                            showTaskDetailsById={showTaskDetailsById}
                            handleShowTaskDetailsById={this.handleShowTaskDetailsById}
                            updatingTask={updatingTask}
                            handleMarkAsComplete={this.handleMarkAsComplete}
                            handleMarkAsViewed={this.handleMarkAsViewed}
                            handleShowDeleteModal={this.handleShowDeleteModal}
                            handleDeleteTask={this.handleDeleteTask}
                            handleEdit={this.handleEdit}
                            taskType={taskType}
                            taskHeadings={taskHeadings}
                            handleChangeTaskType={this.handleChangeTaskType}
                            toggleTaskNotifications={this.toggleTaskNotifications}
                            patientTaskFilters={patientTaskFilters}
                        />
                    </div>
                    }
                </div>

            </Rnd>
        )
    }

    sortAssignees(assignees = []) {
        return [...assignees].sort((assignee, other) => getAssigneeOrdinal(assignee) - getAssigneeOrdinal(other));
    }
}

const tasksLoader = createLoadingSelector(['LOAD_TASKS']);
const usersLoader = createLoadingSelector(['LOAD_PATIENT_USERS']);

const mapStateToProps = (state, props) => {
    const patientId = props.panelProps.patientId;
    const loadingTasks = tasksLoader(state);
    const tasks = getTasksData(state).tasks;
    const loadingUsers = usersLoader(state);
    const { users, patientInfo } = getPatientsData(state);
    const userId = getAuthData(state).userId;
    const patientLastAccessTime = patientInfo.lastAccessed;
    const patientAccessTime = patientInfo.accessTime;
    const allTasks = getTasksData(state).tasks;
    const authorities = getAuthData(state).authorities || [];
    const patientTaskFilters = getPatientTaskFilters(state, patientId);

    return {
        allTasks,
        loadingTasks,
        tasks,
        loadingUsers,
        users,
        userId,
        patientId,
        patientLastAccessTime,
        patientAccessTime,
        authorities,
        patientTaskFilters
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    loadAvailableTaskAssignees,
    cleanTasksData,
    loadTasks,
    createTask,
    updateTask,
    updateTaskCompleted,
    deleteTask,
    createTaskEvent,
    loadTaskNotifications,
    saveTaskFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksPanel);