import {AUTH, AUTH_LOGOUT} from '../actions/auth.actions';
import {clearLocalStorage, getLocalStorageUserData, setLocalStorageUserData} from "../utils/helpers";

const getInitialData = () => {
    const { userId, loggedInTime, authorities } = (getLocalStorageUserData() || {});
    const isLoggedIn = !!userId;

    return {
        isLoggedIn: isLoggedIn,
        userId: userId,
        loggedInTime: isLoggedIn && loggedInTime || null,
        authorities: authorities,
    }
}

const initialState = getInitialData();

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH.SUCCESS:
            const userData = {
                userId: action.payload.userId,
                loggedInTime: action.payload.loggedInTime,
                authorities: action.payload.authorities,
                personalPreferences: action.payload.personalPreferences,
            }

            setLocalStorageUserData(userData);

            return {
                ...state,
                isLoggedIn: true,
                ...userData,
            };
        case AUTH_LOGOUT.SUCCESS:
            clearLocalStorage();

            return {
                ...state,
                isLoggedIn: false,
                userId: null,
                authorities: null,
            };
        default:
            return state;
    }
};