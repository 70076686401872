import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from "react-spinners/BeatLoader";
import isEmpty from "../../../utils/helpers";

class PatientReportedOutcomes extends Component {

    componentDidMount() {
    }

    render () {

        let loading = false;

        const {
        } = this.props;

        return  (
            <>
                <div className="card">
                    <div className="card-body">

                        {!loading
                        ? <>

                            <div className="card-caption">
                                <h4 className="card-title">Patient Reported Outcomes</h4>
                            </div>

                        </> : <>

                            <div className="card-caption">
                                <h4 className="card-title">Patient Reported Outcomes</h4>
                            </div>
                            <Loader size={5} color={"#ccc"} />

                        </> }

                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientReportedOutcomes);