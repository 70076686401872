import React from 'react';
import {connect} from 'react-redux';

const EmailConfirmationStep = ({title, description}) => {

    return (
        <div className="auth-message">
            <div className="auth-message__title">{title}</div>
            <div className="auth-message__description">
                {description}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationStep);