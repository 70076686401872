import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getModalData} from "../../../utils/selectors";
import isEmpty from "../../../utils/helpers";
import Notes from "../../Panels/NotesPanel/Notes";
import PanelAction from "../../Panels/PanelAction";
import {getNotesData} from "../../../utils/selectors";
import {cleanNotesData, createNote, updateNote, loadNotesByPid, loadNotesByPidAndContextLike} from "../../../actions/notes.actions";

class PanelPopoutNotesModal extends Component {

	state = {
		showCreateNew: false,
		showNotesByCategory: this.props.modalProps.showNotesByCategory,
	}

	handleCreateNew = () => {
		this.setState({
			showCreateNew: !this.state.showCreateNew,
		});
	}

	closeCreateNew = () => {
		this.setState({
			showCreateNew:false
		});
	}

	handleShowNotesByCategory = (category) => {
		this.setState({
			showNotesByCategory: category,
		});
	}

	closeModal = () => {
		this.props.hideModal();
	}

	render() {
	
		const {
			showCreateNew,
			showNotesByCategory,
		} = this.state;
	
		const {
			modalProps,
			notes
		} = this.props;

		return (
			<>
				<div className="modal-header">
					<h5 className="modal-title">
						Notes
						<PanelAction
							primary={true}
							title="Create New"
							icon="fa fa-plus"
							handleAction={this.handleCreateNew}
							disabled={showCreateNew}
						/>
					</h5>
				</div>

				<div className="modal-body">


					{!isEmpty(notes) && <>
						<Notes
							notes={notes}
							showCreateNew={showCreateNew}
							showNotesByCategory={showNotesByCategory}
							handleCreateNew={this.handleCreateNew}
							closeCreateNew={this.closeCreateNew}
							handleShowNotesByCategory={this.handleShowNotesByCategory}
							loadNotesByPid={this.props.modalProps.loadNotesByPid}
                        	patientId={this.props.modalProps.patientId}
						/>
					</>}

				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {

	const modalProps = getModalData(state).modalProps;
	const notes = getNotesData(state).notes;
	return {
		modalProps,
		notes
	};
}

const mapDispatchToProps = {
    cleanNotesData,
    createNote,
    updateNote,
    loadNotesByPid,
    loadNotesByPidAndContextLike
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPopoutNotesModal);
