import React from "react";
import {getDefaultRole, getDefaultRoleObject} from "../../../utils/role-utils";

export const TaskUserPhoto = ({user, onClick}) => {
    const role = user && getDefaultRole(user.roles) || '';
    const roleObject = user && getDefaultRoleObject(user.roleObjects) || {name: "", description: ""};

    return (
        <div className="task-photo-holder" title={roleObject.description}>
            <div
                className={"task-photo " + role.toLowerCase()}
                onClick={onClick}
            >
                {/*{isPhotoLoading*/}
                {/*    ? <i className="fas fa-spinner fa-spin" />*/}
                {/*    : taskPhoto*/}
                {/*        ? <img src={URL.createObjectURL(new Blob([taskPhoto]))} alt={contact.firstName} />*/}
                {/*:*/} {user && ((user.firstName? user.firstName.charAt(0) : "") + (user.lastName? user.lastName.charAt(0): ""))}
                {/*}*/}
            </div>
        </div>
    );
}