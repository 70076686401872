import React, {Component, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {loadPatientPathology} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../../utils/selectors";
import {showModal} from "../../../actions/modal.actions";
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import {useParams} from "react-router-dom";

const Pathology = (
    {
        patientPathology,
        loadPatientPathology,
        loading,
        showModal
    }) => {

    const { id: patientId } = useParams();

    useEffect(() => {
        loadPatientPathology(patientId);
    }, [patientId]);

    const showPathologyModal = useCallback(() => {
        showModal("PATHOLOGY_MODAL", patientId);
    }, [showModal, patientId]);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    {!loading && patientPathology ? <>
                        <div className="card-caption">
                            <h4 className="card-title">
                                <button onClick={showPathologyModal}>Pathology</button>
                            </h4>
                            <span className="card-subtitle">
                                {/*{patientPathology[0] && moment.parseZone(patientPathology[0].recentDiagnosis.time).format('DD-MMM-YYYY')}*/}
                            </span>
                        </div>
                        {patientPathology.map(item => (<>
                            <p>{item.diagnosis + ' , ' + moment.parseZone(item.recentDiagnosis.time).format('DD-MMM-YYYY')}</p>
                        </>))}
                        {/*<p>{patientPathology.dh && (
                            <>
                                {patientPathology.dh.diseaseName ? patientPathology.dh.diseaseName: 'Name N/A'},&nbsp;
                                {patientPathology.dh.t ? 'T ' + patientPathology.dh.t: 'Tx'},&nbsp;
                                {patientPathology.dh.n ? 'N ' + patientPathology.dh.n: 'Nx'},&nbsp;
                                {patientPathology.dh.m ? 'M ' + patientPathology.dh.m: 'Mx'},&nbsp;
                                {patientPathology.dh.stage ? 'Stage ' + patientPathology.dh.stage: 'Stage N/A'},&nbsp;
                                {patientPathology.dh.diseaseLocations ? patientPathology.dh.diseaseLocations: 'Sites N/A'}
                            </>
                        )}</p>*/}

                    </> : <>

                        <div className="card-caption">
                            <h4 className="card-title">Pathology</h4>
                        </div>
                        <Loader size={5} color={"#ccc"}/>

                    </>}

                </div>
            </div>
        </>
    )
}

const patientPathologyLoader = createLoadingSelector(['LOAD_PATIENT_PATHOLOGY']);
const mapStateToProps = (state) => {
    const
        patientPathology = getPatientsData(state).patientPathology,
        loading = patientPathologyLoader(state);
    return {
        patientPathology,
        loading
    };
};
const mapDispatchToProps = {
    loadPatientPathology,
    showModal
};
export default connect(mapStateToProps, mapDispatchToProps)(Pathology);