import React, { Suspense } from 'react';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import './index.css';

import './resources/styles/bootstrap.scss';
import './resources/styles/fontawesome.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './resources/styles/styles.scss';

import 'typeface-roboto';
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

const rootDocument = createRoot(document.getElementById('root'));

rootDocument.render(
    <Suspense fallback={(<div>loading...</div>)}>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
