import {LOAD_NOTIFICATIONS} from "../actions/task-notification.actions";
import moment from "moment";
import {dueTaskComparator, endDateTaskComparator, isOverdueTask} from "../utils/task-utils";
import _ from "lodash";

const initialState = {
    overdueTasksAssignedToMe: [],
    newTasksAssignedToMe: [],
    completedTasksCreatedByMe: [],
    overdueTasksCreatedByMe: [],
    recurrentTasksCreatedByMe: [],
    notificationCount: 0,
};

export const taskNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_NOTIFICATIONS.SUCCESS:

            return {
                ...state,
                ...getTaskNotifications(state, action.payload),
            };
        case LOAD_NOTIFICATIONS.CLEAR:
            return initialState;
        default:
            return state;
    }
};

const getTaskNotifications = (state, {tasks = [], userId, patientLastAccessTime, patientAccessTime, source}) => {
    const lastAccessTime = moment(patientLastAccessTime);
    const accessTime = moment(patientAccessTime);
    const momentNow = moment();

    const overdueTasksAssignedToMe
        = tasks.filter(task =>
            isOverdueTask(task)
            && task.assignee.id === userId
            && lastAccessTime.isBefore(task.dueDate))
        .sort(dueTaskComparator);

    let newTasksAssignedToMe = tasks.filter(task => {
        const createdMoment = moment.utc(task.createdDate);

        return task.isNew
            && task.assignee.id === userId
            && lastAccessTime.isBefore(createdMoment)
            && (task.createdBy.id === userId ? accessTime.isAfter(createdMoment) : true);
    });

    const completedTasksCreatedByMe = tasks.filter(task => {
        const completeMoment = moment(task.completedDate);

        return task.completedDate
            && task.completed
            && task.createdBy.id === userId
            && lastAccessTime.isBefore(completeMoment)
            && accessTime.isAfter(completeMoment);
    });

    const overdueTasksCreatedByMe
        = tasks.filter(task =>
            isOverdueTask(task)
            && task.createdBy.id === userId
            && lastAccessTime.isBefore(task.dueDate))
        .sort(dueTaskComparator);

    const recurrentTasksCreatedByMe
        = tasks.filter(task =>
            task.recurrent
            && task.createdBy.id === userId
            && task.endDate && moment(task.endDate).diff(momentNow, 'days') < 7)
        .sort(dueTaskComparator);

    if (source === 'TaskNotificationModal') {
        const mNewTasksAssignedToMe = state.newTasksAssignedToMe.map(oTask => {
            for (const task of tasks) {
                if (oTask.id === task.id) {
                    return  task;
                }
            }
            return oTask;
        })

        for (const nTask of newTasksAssignedToMe) {
            if (!mNewTasksAssignedToMe.find(mTask => mTask.id === nTask.id)) {
                mNewTasksAssignedToMe.push(nTask);
            }
        }

        newTasksAssignedToMe = mNewTasksAssignedToMe;
    }

    const notificationCount
        = _.uniq(
            _.map(
                _.flatten(
                    [
                        overdueTasksAssignedToMe,
                        newTasksAssignedToMe,
                        completedTasksCreatedByMe,
                        overdueTasksCreatedByMe,
                        recurrentTasksCreatedByMe
                    ]),
                task => task.id)
        ).length;

    return {
        overdueTasksAssignedToMe,
        newTasksAssignedToMe,
        completedTasksCreatedByMe,
        overdueTasksCreatedByMe,
        recurrentTasksCreatedByMe,
        notificationCount,
    };
}
