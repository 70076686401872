import React, {useCallback, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {loadCareProviders, loadPointsOfContact} from "../../../actions/patients.actions";
import {showModal} from "../../../actions/modal.actions";
import {createLoadingSelector, getErrorMessage, getModalData, getPatientsData} from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import {useParams} from "react-router-dom";
import usersSvg from "../../../images/users.svg";
import doctorSvg from "../../../images/doctor.svg";
import isEmpty from "../../../utils/helpers";

const SupportCircle = ({
        showModal,
        loadPointsOfContact,
        loadCareProviders,
        pointsOfContact,
        careProviders,
        loadingPointsOfContact,
        loadingCareProviders,
        pointsOfContactError,
        careProvidersError,
    }) => {

    const {id: patientId} = useParams();

    const showSupportCircleModal = useCallback((modalProps = {}) => {
        showModal("SUPPORT_CIRCLE_MODAL", { patientId: patientId , ...modalProps });
    }, [showModal, patientId]);

    useEffect(() => {
        if (patientId) {
            loadPointsOfContact(patientId);
            loadCareProviders(patientId);
        }
    }, [patientId]);

    const pointOfContactsText = useMemo(() => {
        return (pointsOfContact || []).map(poc => [poc.firstName, poc.lastName].join(" ")).sort().join(", ");
    }, [pointsOfContact]);

    const careProvidersText = useMemo(() => {
        return (careProviders || []).map(cp => [cp.firstName, cp.lastName].join(" ")).sort().join(", ");
    }, [careProviders]);

    const hasContacts = useMemo(() => {
        return !(isEmpty(careProviders) && isEmpty(careProviders)) || loadingCareProviders || loadingPointsOfContact;
    }, [careProviders, pointsOfContact, loadingCareProviders, loadingPointsOfContact]);

    return (
        <>
            <div className="card sc-card">
                <div className="card-body">
                    <div className="card-caption">
                        <h4 className="card-title">
                            <button
                                onClick={showSupportCircleModal}
                            >
                                Support Circle
                            </button>
                        </h4>
                    </div>
                    {hasContacts? (
                        <>
                            {loadingPointsOfContact ? (
                                <Loader size={5} color={"#ccc"}/>
                            ) : (
                                <div className="sc-section" title="Point of Contact">
                                    <img style={{width: 30, height: 30}} className="text-primary" src={usersSvg}/>
                                    <div className="sc-text">
                                        <span>{pointOfContactsText}</span>
                                    </div>
                                    {pointsOfContactError && (
                                        <div className='invalid-feedback d-block'>{pointsOfContactError}</div>
                                    )}
                                </div>
                            )
                            }

                            {loadingCareProviders ? (
                                <Loader size={5} color={"#ccc"}/>
                            ) : (
                                <div className="sc-section" title="Care Provider">
                                    <img style={{width: 30, height: 30}} className="text-primary" src={doctorSvg}/>
                                    <div className="sc-text">
                                        <span>{careProvidersText}</span>
                                    </div>
                                    {careProvidersError && (
                                        <div className='invalid-feedback d-block'>{careProvidersError}</div>
                                    )}
                                </div>
                            )
                            }
                        </>
                    ): (
                        <div className="sc-text">
                            <span>Support circle information has not been uploaded to the system yet</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

const pointsOfContactLoader = createLoadingSelector(['LOAD_POINTS_OF_CONTACT']);
const careProvidersLoader = createLoadingSelector(['LOAD_CARE_PROVIDERS']);

const getPointsOfContactError = getErrorMessage(['LOAD_POINTS_OF_CONTACT']);
const getCareProvidersError = getErrorMessage(['LOAD_CARE_PROVIDERS']);

const mapStateToProps = (state, props) => {
    const {patientPointsOfContact, patientCareProviders} = getPatientsData(state);

    const loadingPointsOfContact = pointsOfContactLoader(state);
    const loadingCareProviders = careProvidersLoader(state);

    const pointsOfContactError = getPointsOfContactError(state);
    const careProvidersError = getCareProvidersError(state);

    return {
        pointsOfContact: patientPointsOfContact,
        careProviders: patientCareProviders,
        loadingPointsOfContact,
        loadingCareProviders,
        pointsOfContactError,
        careProvidersError
    };
};

const mapDispatchToProps = {
    showModal,
    loadPointsOfContact,
    loadCareProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportCircle);