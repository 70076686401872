import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createNote, loadNotesByPid} from "../../../actions/notes.actions";
import Note from "./Note";
import isEmpty from "../../../utils/helpers";
import {Form, Formik} from "formik";
import _ from 'lodash';
import {createLoadingSelector, getModalData, getNotesData} from "../../../utils/selectors";
import SunEditor, {buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import PanelAction from "../PanelAction";
import Mark from "mark.js";
import * as Yup from "yup";

const isEmptyContext = ctx => {
    if (_.isEmpty(ctx)) {
        return true;
    }

    const ctxDiv = document.createElement('div');
    ctxDiv.innerHTML = ctx;

    if (isEmpty(ctxDiv.innerText)) {
        return true;
    }

    return false;
}

const noteEditorValidationSchema = Yup.object().shape({
    context: Yup.string().test(
        'empty-context-check',
        'Body cannot be empty',
        ctx => !isEmptyContext(ctx),
    ),
})

class Notes extends Component {
    state = {
        patientId: this.props.patientId,
        isDraft: this.props.draft != null,
        debouncedSubmit: null,
        note: {
            title: this.props.notes["draft"] == null ? "" : this.props.notes["draft"].title,
            context: this.props.notes["draft"] == null ? "" : this.props.notes["draft"].context,
            id: this.props.notes["draft"] == null ? null : this.props.notes["draft"].id
        },
        filterText: "",
        filteredNotes: [],
    }

    constructor(props) {
        super(props);
        this.filterNotesDebounce = _.debounce(() => this.filterNotes(), 300);
    }

    componentDidMount() {
        this.setState({filterText: this.props.filterText}, this.filterNotes);
    }

    componentWillUnmount() {
        this.filterNotesDebounce.cancel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.showNotesByCategory !== this.props.showNotesByCategory
            || prevProps.filterText !== this.props.filterText
            || !_.isEqual(prevProps.notes, this.props.notes)) {

            this.filterNotesDebounce();
        }
    }

    handleOnSubmit = (values, actions) => {
        let newNoteRequest = {
            patientId: this.state.patientId,
            title: values.title,
            context: values.context,
            isDraft: false,
            isPublic: this.props.showNotesByCategory === 'public',
            id: values.id
        }

        this.props.createNote(newNoteRequest).then((response) => {
            this.props.closeCreateNew();
            this.props.loadNotesByPid(this.state.patientId);
            actions.resetForm();
        });
    }

    handleDebouncedSubmit = (setFieldValue, value, values) => {
        setFieldValue("context", value);
        this.setState({
            note: {
                ...this.state.note,
                context: value,
            }
        });

        let newNoteRequest = {
            patientId: this.props.patientId,
            context: value,
            isDraft: true,
            isPublic: this.props.showNotesByCategory === 'public',
            id: this.state.note["id"],
            title: this.state.note["title"]
        }

        // function for POST request
        let sendRequest = () => {
            this.props.createNote(newNoteRequest).then(response => {
                setFieldValue("id", response.note.id);
                this.setState({
                    note: {
                        ...this.state.note,
                        id: response.note.id,
                    }
                });
            });
        }
        // cancel the previous debounce
        if (this.state.debouncedSubmit) {
            this.state.debouncedSubmit.cancel();
        }
        let debounce_fun = _.debounce(sendRequest, 5000);
        debounce_fun();
        this.setState({debouncedSubmit: debounce_fun})
    }

    handleOnFilterTextChange = (filterText) => {
        this.setState({filterText}, () => this.props.onFilterTextChange(filterText));
    }

    hasFilter = (filterText) => {
        return filterText && filterText !== "";
    }

    filterNotes = () => {
        const { showNotesByCategory, filterText } = this.props;
        const hasFilter = this.hasFilter(filterText);

        this.setState(prevState => {
            const filteredNotes = {...this.props.notes};
            const categoryNotes = [...filteredNotes[showNotesByCategory]]
            filteredNotes[showNotesByCategory] = categoryNotes.map(note => ({...note, found: !hasFilter}));

            return {
                filteredNotes
            }
        }, () => {
            if (!hasFilter) {
                return;
            }

            // Process note.title
            const filteredCategoryNotes = this.state.filteredNotes[showNotesByCategory];
            filteredCategoryNotes.forEach((note, idx) => {
                const titleTmpDiv = document.createElement('div');
                titleTmpDiv.innerHTML = note.title;

                const titleMark = new Mark(titleTmpDiv);
                titleMark.mark(filterText, {
                    "separateWordSearch": false,
                    "acrossElements": true,
                    "done": (counter) => {
                        if (counter < 1) {
                            return;
                        }

                        this.setState(prevState => {
                            const sFilteredNotes = {...prevState.filteredNotes};
                            const sFilteredCategoryNotes = [...sFilteredNotes[showNotesByCategory]];

                            if (idx < sFilteredCategoryNotes.length) {
                                const sNote = sFilteredCategoryNotes[idx];

                                if (sNote.id === note.id) {
                                    sFilteredCategoryNotes[idx] = {...sNote, found: true, titleView: titleTmpDiv.innerHTML}
                                    sFilteredNotes[showNotesByCategory] = sFilteredCategoryNotes;
                                    return {
                                        filteredNotes: sFilteredNotes
                                    }
                                }
                            }

                            return {};
                        })
                    }
                });

                // Process note.context
                const ctxTmpDiv = document.createElement('div');
                ctxTmpDiv.innerHTML = note.context;

                const ctxMark = new Mark(ctxTmpDiv);
                ctxMark.mark(filterText, {
                    "separateWordSearch": false,
                    "acrossElements": true,
                    "done": (counter) => {
                        if (counter < 1) {
                            return;
                        }

                        this.setState(prevState => {
                            const sFilteredNotes = {...prevState.filteredNotes};
                            const sFilteredCategoryNotes = [...sFilteredNotes[showNotesByCategory]];

                            if (idx < sFilteredCategoryNotes.length) {
                                const sNote = sFilteredCategoryNotes[idx];

                                if (sNote.id === note.id) {
                                    sFilteredCategoryNotes[idx] = {...sNote, found: true, contextView: ctxTmpDiv.innerHTML}
                                    sFilteredNotes[showNotesByCategory] = sFilteredCategoryNotes;
                                    return {
                                        filteredNotes: sFilteredNotes
                                    }
                                }
                            }

                            return {};
                        })
                    }
                });
            });
        });
    }

    getFilteredNotes = () => {
        const { showNotesByCategory } = this.props;
        const { filteredNotes } = this.state;

        const notesRes = filteredNotes || [];

        return (notesRes[showNotesByCategory] || []).filter(note => note.found === true);
    }

    render() {

        const {
            notes,
            showCreateNew,
            showNotesByCategory,
            closeCreateNew,
            handleShowNotesByCategory,
            loadingNotes,
            isModalOpen,
        } = this.props;

        const filteredNotes = this.getFilteredNotes();

        if (isModalOpen) {
            return null;
        }
        return (
            <div className="notes">

                <div className="notes-categories">
                    <button
                        className={"notes-category notes-category-private" + (showNotesByCategory === 'private' ? " active" : "")}
                        onClick={() => handleShowNotesByCategory('private')}
                    >
                        <i className="fa fa-user"/> Private
                    </button>
                    <button
                        className={"notes-category notes-category-public" + (showNotesByCategory === 'public' ? " active" : "")}
                        onClick={() => handleShowNotesByCategory('public')}
                    >
                        <i className="fa fa-users"/> Public
                    </button>

                    <div className="panel-toolbar notes-category">

                        <PanelAction
                            primary={true}
                            title="Create New"
                            icon="fa fa-plus"
                            handleAction={this.props.handleCreateNew}
                            disabled={showCreateNew || loadingNotes}
                        />
                    </div>
                </div>
                <div className="notes-list">

                    {(showCreateNew || !isEmpty(notes["draft"])) &&
                    <Formik
                        initialValues={{
                            title: notes["draft"] == null ? "" : notes["draft"].title,
                            context: notes["draft"] == null ? "" : notes["draft"].context,
                            id: notes["draft"] == null ? null : notes["draft"].id
                        }}
                        validationSchema={noteEditorValidationSchema}
                        // validationSchema={!notes["draft"] && Yup.object().shape({context: Yup.string().required('Required')})}
                        onSubmit={(values, actions) => this.handleOnSubmit(values, actions)}
                        render={({
                                     handleBlur,
                                     setValues,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     handleChange,
                                     setFieldValue,
                                 }) => (

                            /*
                           <form className={"notes-new notes-new-" + showNotesByCategory}>
                               <button
                                   className="pull-right btn btn-link"
                                   onClick={handleCreateNew}
                               >
                                   <i className="fa fa-times" />
                               </button>
                           </form>
                           * */

                            <Form className={"notes-new notes-new-" + showNotesByCategory}>
                                <button
                                    className="note-close btn btn-link"
                                    onClick={closeCreateNew}
                                >
                                    <i className="fa fa-times"/>
                                </button>
                                {!isEmpty(notes["draft"]) && (
                                    <p className="text-center text-success">
                                        Draft (autosaved)
                                    </p>
                                )}
                                <input
                                    style={{width: '100%', marginBottom: '10px'}}
                                    value={values['title']}
                                    type="text"
                                    id="title"
                                    placeholder="Title"
                                    name="title"
                                    aria-label="Title"
                                    onChange={(event => {
                                        setFieldValue("title", event.target.value);
                                        this.setState({
                                            note: {
                                                ...this.state.note,
                                                title: event.target.value,
                                            }
                                        });
                                    })}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                />
                                <SunEditor
                                    defaultValue={values.context}
                                    placeholder={showNotesByCategory === 'private'
                                        ? "Write a new private note..."
                                        : "Write a new public note..."
                                    }
                                    setOptions={{
                                        buttonList: [
                                            ["undo", "redo"],
                                            ["font", "fontSize", "fontColor", "formatBlock"],
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            "/",
                                            ["align", "horizontalRule", "list", "table"],
                                            ["removeFormat"],
                                            ["outdent", "indent"],
                                        ]
                                    }}
                                    onChange={(value) => {
                                        this.handleDebouncedSubmit(setFieldValue, value, values);
                                    }}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                />
                                {errors.context && touched.context && (
                                    <p className="invalid-feedback">{errors.context}</p>
                                )}
                                <button
                                    style={{marginTop: '10px'}}
                                    type="submit"
                                    onClick={() => {
                                        this.state.debouncedSubmit && this.state.debouncedSubmit.cancel();
                                    }
                                    }
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                >
                                    {!isEmpty(notes["draft"]) ? 'Save Note' : 'Add Note'}
                                </button>
                            </Form>
                        )}
                    />
                    }

                    <div className="notes-search row no-gutters">
                        <div className="col">
                            <input
                                type="text"
                                placeholder="Search"
                                value={this.state.filterText}
                                onMouseDown={e => e.stopPropagation()}
                                onChange={event => this.handleOnFilterTextChange(event.target.value)}
                            />
                            <span className="underline"/>
                        </div>
                        <div className="col-auto">
                            <button className="btn" type="button" onClick={() => this.handleOnFilterTextChange("")}>
                                <i className="fa fa-times-circle cancel"/>
                            </button>
                        </div>
                        {loadingNotes && (
                            <div className="col-auto">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        )}
                        {/*<div className="col-auto">*/}
                        {/*    <button className="btn" type="button">*/}
                        {/*        <i className="fa fa-search search"/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                    {this.hasFilter(this.props.filterText) && <span>{filteredNotes.length} search result{filteredNotes.length === 1? '' : 's'}</span>}

                    {isEmpty(filteredNotes)
                        ?
                        <p className="panel-body-empty">No data found</p>
                        :
                        <>
                            {filteredNotes.map(note => {
                                return (
                                    <Note
                                        key={note.id}
                                        patientId={this.props.patientId}
                                        note={note}
                                        showNotesByCategory={showNotesByCategory}
                                    />
                                );
                            })}
                        </>
                    }
                </div>

            </div>
        )
    }
}

const notesLoader = createLoadingSelector(['LOAD_NOTES']);
const mapStateToProps = (state) => {
    const draft = getNotesData(state).notes.draft;
    const loadingNotes = notesLoader(state);
    const isModalOpen = getModalData(state).showModal;

    return {
        draft,
        loadingNotes,
        isModalOpen,
    };
};

const mapDispatchToProps = {
    createNote,
    loadNotesByPid
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
