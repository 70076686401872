import React, {Component} from 'react';
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import Message from "./Message";

class Messages extends Component {

    render() {

        const {
            loadingMessages,
            contactMessages,
            contact,
            handleShowConversationById,
        } = this.props;

        return (
            <div className="messages-messages">

                {loadingMessages &&
                <div className="panel-loader">
                    <Loader size={10} color={"#ccc"} />
                </div>
                }

                {!loadingMessages && !isEmpty(contactMessages) &&
                <>

                    <div className="messages-messages-title">
                        <button
                            className="btn btn-link"
                            onClick={() => handleShowConversationById()}
                        >
                            <i className="fas fa-chevron-left" />
                        </button>
                        <h5>{contact.firstName}</h5>
                    </div>

                    {contactMessages.map((message) =>
                    <Message
                        key={message.id}
                        message={message}
                        contact={contact}
                    />
                    )}

                </>
                }

            </div>
        )
    }
}

export default Messages;
