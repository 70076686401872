import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {loadPatientDiseaseSummary} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../../utils/selectors";
import {showModal} from "../../../actions/modal.actions";
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import {useParams} from "react-router-dom";

const DiseaseSummary = (
    {
        loadPatientDiseaseSummary,
        showModal,
        patientDiseaseSummary,
        loading
    }) => {

    const { id: patientId } = useParams();

    useEffect(() => {
        loadPatientDiseaseSummary(patientId, 5);
    }, [loadPatientDiseaseSummary]);

    const showDiseaseSummaryModal = useCallback(() => {
        showModal("DISEASE_SUMMARY_MODAL", patientId);
    }, [showModal]);

    return  (
        <>
            <div className="card">
                <div className="card-body">

                    {!loading && patientDiseaseSummary
                    ? <>
                        <div className="card-caption">
                            <h4 className="card-title">
                                <button onClick={showDiseaseSummaryModal}>Disease Summary</button>
                                <span className="small">For full view click “disease summary”</span>
                            </h4>
                        </div>

                        {patientDiseaseSummary.actions &&
                        <dl className="details details-disease-summary">

                            {patientDiseaseSummary.actions.map((item, index) =>
                                <div key={index}>
                                    <dt>
                                        {item.beginDate ? (<>
                                            {moment.parseZone(item.beginDate).format('DD-MMM-YYYY')}<br/>
                                            {item.endDate ? (moment.parseZone(item.endDate).format('DD-MMM-YYYY')) : 'Ongoing'}

                                        </>) : (item.dateIn ? (<>
                                            {moment.parseZone(item.dateIn).format('DD-MMM-YYYY')}<br/>
                                            {item.dateOut ? (moment.parseZone(item.dateOut).format('DD-MMM-YYYY')) : 'Ongoing'}

                                        </>) : (item.time ? moment.parseZone(item.time).format('DD-MMM-YYYY') : 'Date unknown'))}
                                    </dt>
                                    <dd>

                                        {item.actionName === 'dr_note' && (<>
                                            {'OP-EN'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            {item.summary && ', '+item.summary}
                                        </>)}

                                        {item.actionName === 'er_visit' && (<>
                                            {'ER'}
                                            , {item.institution ? item.institution.name : 'Institution N/A'}
                                            {item.summary && ', '+item.summary}
                                        </>)}

                                        {item.actionName === 'drug_therapy' && (<>
                                            {'DRUGTHERAPY'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.type_name ? item.type_name : 'Type N/A'}
                                            , {item.number_courses ? item.number_courses : 'N/A'} cycles
                                            {item.comments && ', '+item.comments}
                                        </>)}

                                        {item.actionName === 'radiation_therapy' && (<>
                                            {'RT'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.site_targeted ? item.site_targeted : 'Site N/A'}
                                            , {item.radiation_type ? item.radiation_type : 'Type N/A'}
                                            {item.comments && ', '+item.comments}
                                        </>)}

                                        {item.actionName === 'surgery' && (<>
                                            {'SURGERY'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.procedureSite ? item.procedureSite : 'Procedure Site N/A'}
                                            , {item.type ? item.type : 'Type N/A'}
                                            , {item.complications ? item.complications : 'Complications N/A'}
                                            {item.comments && ', '+item.comments}
                                        </>)}

                                        {item.actionName === 'pathology_report' && (<>
                                            {'PATH'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , [Specimen date:  {!isEmpty(item.specimenDate) ? moment.parseZone(item.specimenDate).format('DD-MMM-YYYY') : 'N/A'}]
                                            , {item.pathologySite ? item.pathologySite : 'Site N/A'}
                                            , {item.procedure ? item.procedure : 'Procedure N/A'}
                                            , {item.diagnosis ? item.diagnosis : 'Diagnosis N/A'}
                                        </>)}

                                        {item.actionName === 'genomic_information' && (<>
                                            {'GEN'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.institution ? item.institution.name : 'Institution N/A'}
                                            , {item.findings_source ? item.findings_source : 'Site N/A'}
                                            , {item.analyte ? item.analyte : 'Analyte N/A'}
                                            {/* , {item.value ? item.value : 'Value N/A'} */}
                                        </>)}

                                        {item.actionName === 'imaging' && (<>
                                            {item.scanType? item.scanType.toUpperCase(): "IMAGING"}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.location ? item.location : 'Location N/A'}
                                            : {item.summary ? item.summary : 'Summary N/A'}
                                        </>)}

                                        {item.actionName === 'hospital_visit' && (<>
                                            {'IP-EN, '}
                                            {item.institution ? item.institution.name : 'Institution N/A'}
                                            {item.doctor && ', ' + item.doctor}
                                            , {item.summary ? item.summary : 'Summary N/A'}
                                        </>)}

                                        {item.actionName === 'treatment' && (<>
                                            {item.treatmentType === 'radiation' && (<>
                                                RT&nbsp;
                                                {item.doctor && ', ' + item.doctor}
                                                {item.department && ', ' + item.department}
                                                {item.siteTargeted? item.siteTargeted: 'Site targeted N/A'},&nbsp;
                                                {item.typeName? item.typeName: 'Radiation type N/A'}
                                                {item.comments && (', ' + item.comments)}
                                            </>)}
                                            {item.treatmentType === 'drug' && (<>
                                                DRUGTHERAPY&nbsp;
                                                {item.doctor && ', ' + item.doctor}
                                                {item.department && ', ' + item.department}
                                                {item.typeName? item.typeName: 'Drug N/A'},&nbsp;
                                                {item.numberCourses? item.numberCourses + ' cycles': 'Cycles N/A'}
                                                {item.comments && (', ' + item.comments)}
                                            </>)}
                                        </>)}

                                        {(['ct', 'mri_brain', 'pet_ct', 'mri'].includes(item.actionName)) && (<>
                                            {item.actionName.toUpperCase()}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.location ? item.location+': ' : 'N/A: '}
                                            {item.summary ? item.summary : 'N/A'}
                                        </>)}

                                        {(['dr_note', 'drug_therapy', 'radiation_therapy', 'surgery', 'pathology_report', 'genomic_information', 'ct', 'mri_brain', 'pet_ct', 'mri', 'imaging', 'treatment'].includes(item.actionName) === false) && (<>
                                            {item.actionName === 'ccm_note' ? "LA" : item.actionName.toUpperCase()}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            {item.summary && ', '+item.summary}
                                            {item.comments && ', '+item.comments}
                                        </>)}

                                    </dd>
                                </div>
                            )}
                        </dl>
                        }

                    </> : <>

                        <div className="card-caption">
                            <h4 className="card-title">Disease Summary</h4>
                        </div>
                        <Loader size={5} color={"#ccc"}/>

                    </> }

                </div>
            </div>
        </>
    )
}

const patientDiseaseSummaryLoader = createLoadingSelector(['LOAD_PATIENT_DISEASE_SUMMARY']);

const mapStateToProps = (state) => {
    const patientDiseaseSummary = getPatientsData(state).patientDiseaseSummary,
        loading = patientDiseaseSummaryLoader(state);

    return {
        patientDiseaseSummary,
        loading
    };
};

const mapDispatchToProps = {
    loadPatientDiseaseSummary,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseSummary);