import React, {useCallback, useMemo} from "react";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";

const CreatableSingleSelect = ({options, onChange, value, defaultValue, ref, ...props}) => {
    const handleChange = useCallback((newValue, actionMeta) => {
        if ((actionMeta.action === 'select-option' || actionMeta.action === 'create-option') && typeof onChange === 'function') {
            onChange(_.get(newValue, 'value', newValue));
        }
    }, [onChange]);

    const defValue = useMemo(() => {
        if (options) {
            return options.find(opt => opt.value === defaultValue);
        }
    }, [defaultValue, options]);

    return (
        <CreatableSelect
            isClearable
            {...props}
            forwardedRef={ref}
            defaultValue={defValue}
            onChange={handleChange}
            options={options}
        />
    );
}

export default CreatableSingleSelect;