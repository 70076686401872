export const getPasswordStrength = (password) => {
    let score = 0;

    if (typeof password === 'string') {
        if (password.length >= 8) {
            score += 1;
        }

        if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
            score += 1;
        }

        if (/[\!\@\#\?\]]/.test(password)) {
            score += 1;
        }
    }

    return score;
};