import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadPatientDiseaseSummaryDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import * as _ from "lodash";
import {openDocument} from "../../../utils/document-utils";

import lastFirstSvg from "../../../images/last-first-01.svg";
import firstLastSvg from "../../../images/first-last-02.svg";
import fullReportSvg from "../../../images/full_report.svg";

class DiseaseSummaryModal extends Component {

    state = {
        patientDiseaseSummaryDetailed: {},
        sortOrder: 'asc',
    }

    componentDidMount() {
        this.props.loadPatientDiseaseSummaryDetailed(this.props.patientId).then((response) => {
            this.orderDiseaseSummary(response.actions)
        });
    }

    handleViewFullReport = (documentId, pageNumber) => {
        openDocument(this.props.patientId, documentId, pageNumber);
    }

    compareDiseaseSummary = (a, b) => {
        if (a.beginDate < b.beginDate || a.time < b.beginDate || a.beginDate < b.time || a.time < b.time)
            return 1;
        else if (a.beginDate > b.beginDate || a.time > b.beginDate || a.beginDate > b.time || a.time > b.time)
            return -1;
        else
            return 0;
    }

    orderDiseaseSummary = (actions) => {
        if (!isEmpty(actions)) {
            this.setState({patientDiseaseSummaryDetailed: actions.sort(this.compareDiseaseSummary)})
        }
    }

    closeModal = () => {
        this.props.hideModal();
    };

    sortByColumn = (column) => {
        let data;
        if (this.state.sortOrder === 'desc') {
            data = _.orderBy(this.state.patientDiseaseSummaryDetailed, [column], ['desc']);
            this.setState({
                sortOrder: 'asc'
            });
        } else {
            data = _.orderBy(this.state.patientDiseaseSummaryDetailed, [column], ['asc']);
            this.setState({
                sortOrder: 'desc'
            });
        }
        this.setState({
            patientDiseaseSummaryDetailed: data
        });
    }

    render() {
        const {patientDiseaseSummaryDetailed} = this.state;
        const {loading} = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Disease Summary</h5>
                </div>

                <div className="modal-body">

                    {loading &&
                        <Loader size={10} color={"#ccc"}/>
                    }

                    {!loading && isEmpty(patientDiseaseSummaryDetailed) &&
                        <p>No data found.</p>
                    }

                    {!loading && !isEmpty(patientDiseaseSummaryDetailed) &&
                        <table className="table table-hover table-subtle table-align-top table-responsive-sm table-responsive-md">
                            <thead>
                            <tr>
                                <th scope="col" role="columnheader" width="15%">
                                    <div className="header-container sorted" style={{cursor: "pointer"}} onClick={() => this.sortByColumn('time')}>
                                        Date
                                        {this.state.sortOrder === 'desc'
                                            ? <i className={"ni ni-arrow-down ni-xs ms-1"} />
                                            : <i className={"ni ni-arrow-up ni-xs ms-1"} />
                                        }
                                    </div>
                                </th>
                                <th scope="col">Summary</th>
                                <th scope="col">View File</th>
                            </tr>
                            </thead>
                            <tbody>
                            {patientDiseaseSummaryDetailed && patientDiseaseSummaryDetailed.map(item => (<>
                                <tr>
                                    <td>
                                        {item.beginDate ? (<>
                                            {moment.parseZone(item.beginDate).format('DD-MMM-YYYY')}<br/>
                                            {item.endDate ? (moment.parseZone(item.endDate).format('DD-MMM-YYYY')) : 'Ongoing'}

                                        </>) : (item.dateIn ? (<>
                                                {moment.parseZone(item.dateIn).format('DD-MMM-YYYY')}<br/>
                                                {item.dateOut ? (moment.parseZone(item.dateOut).format('DD-MMM-YYYY')) : 'Ongoing'}

                                            </>) : (item.time ? moment.parseZone(item.time).format('DD-MMM-YYYY') : 'Date unknown'))}
                                    </td>
                                    <td>

                                        {item.actionName === 'dr_note' && (<>
                                            {'OP-EN'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            {item.summary && ', ' + item.summary}
                                        </>)}

                                        {item.actionName === 'er_visit' && (<>
                                            {'ER'}
                                            , {item.institution ? item.institution.name : 'Institution N/A'}
                                            {item.summary && ', '+item.summary}
                                        </>)}

                                        {item.actionName === 'drug_therapy' && (<>
                                            {'DRUGTHERAPY'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.type_name ? item.type_name : 'Type N/A'}
                                            , {item.number_courses ? item.number_courses : 'N/A'} cycles
                                            {item.comments && ', ' + item.comments}
                                        </>)}

                                        {item.actionName === 'radiation_therapy' && (<>
                                            {'RT'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.site_targeted ? item.site_targeted : 'Site N/A'}
                                            , {item.radiation_type ? item.radiation_type : 'Type N/A'}
                                            {item.comments && ', ' + item.comments}
                                        </>)}

                                        {item.actionName === 'surgery' && (<>
                                            {'SURGERY'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.procedureSite ? item.procedureSite : 'Procedure Site N/A'}
                                            , {item.type ? item.type : 'Type N/A'}
                                            , {item.complications ? item.complications : 'Complications N/A'}
                                            {item.comments && ', ' + item.comments}
                                        </>)}

                                        {item.actionName === 'pathology_report' && (<>
                                            {'PATH'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , [Specimen date: {!isEmpty(item.specimenDate) ? moment.parseZone(item.specimenDate).format('DD-MMM-YYYY') : 'N/A'}]
                                            , {item.pathologySite ? item.pathologySite : 'Site N/A'}
                                            , {item.procedure ? item.procedure : 'Procedure N/A'}
                                            , {item.diagnosis ? item.diagnosis : 'Diagnosis N/A'}
                                        </>)}

                                        {item.actionName === 'genomic_information' && (<>
                                            {'GEN'}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.institution ? item.institution.name : 'Institution N/A'}
                                            , {item.findingsSource ? item.findingsSource : 'Site N/A'}
                                            , {item.analyte ? item.analyte : 'Analyte N/A'}
                                            {/* , {item.value ? item.value : 'Value N/A'} */}
                                        </>)}

                                        {item.actionName === 'imaging' && (<>
                                            {item.scanType ? item.scanType.toUpperCase() : "IMAGING"}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.location ? item.location : 'Location N/A'}
                                            : {item.summary ? item.summary : 'Summary N/A'}
                                        </>)}

                                        {item.actionName === 'hospital_visit' && (<>
                                            {'IP-EN, '}
                                            {item.institution ? item.institution.name : 'Institution N/A'}
                                            {item.doctor && ', ' + item.doctor}
                                            , {item.summary ? item.summary : 'Summary N/A'}
                                        </>)}

                                        {item.actionName === 'treatment' && (<>
                                            {item.treatmentType === 'radiation' && (<>
                                                RT&nbsp;
                                                {item.doctor && ', ' + item.doctor}
                                                {item.department && ', ' + item.department}
                                                {item.siteTargeted ? item.siteTargeted : 'Site targeted N/A'},&nbsp;
                                                {item.typeName ? item.typeName : 'Radiation type N/A'}
                                                {item.comments && (', ' + item.comments)}
                                            </>)}
                                            {item.treatmentType === 'drug' && (<>
                                                DRUGTHERAPY&nbsp;
                                                {item.doctor && ', ' + item.doctor}
                                                {item.department && ', ' + item.department}
                                                {item.typeName ? item.typeName : 'Drug N/A'},&nbsp;
                                                {item.numberCourses ? item.numberCourses + ' cycles' : 'Cycles N/A'}
                                                {item.comments && (', ' + item.comments)}
                                            </>)}
                                        </>)}

                                        {(['ct', 'mri_brain', 'pet_ct', 'mri'].includes(item.actionName)) && (<>
                                            {item.actionName.toUpperCase()}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            , {item.location ? item.location + ': ' : 'N/A: '}
                                            {item.summary ? item.summary : 'N/A'}
                                        </>)}

                                        {(['dr_note', 'drug_therapy', 'radiation_therapy', 'surgery', 'pathology_report', 'genomic_information', 'ct', 'mri_brain', 'pet_ct', 'mri', 'imaging', 'treatment', 'hospital_visit'].includes(item.actionName) === false) && (<>
                                            {item.actionName.toUpperCase()}
                                            {item.doctor && ', ' + item.doctor}
                                            {item.department && ', ' + item.department}
                                            {item.summary && ', ' + item.summary}
                                            {item.comments && ', ' + item.comments}
                                        </>)}

                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        {item.documentReference && item.documentReference.fileId &&
                                        <button
                                            className="btn btn-link"
                                            onClick={() => {
                                                this.handleViewFullReport(item.documentReference.fileId, item.documentReference.pageNumber)
                                            }}
                                        >
                                            <img alt="Full Report" style={{width: 30, height: 30}} src={fullReportSvg}/>
                                        </button>
                                        }
                                    </td>
                                </tr>
                            </>))}
                            </tbody>
                        </table>
                    }

                </div>
            </>
        );
    }
}

const patientDiseaseSummaryDetailedLoader = createLoadingSelector(['LOAD_PATIENT_DISEASE_SUMMARY_DETAILED']);

const mapStateToProps = (state) => {

    const
        patientId = getModalData(state).modalProps,
        loading = patientDiseaseSummaryDetailedLoader(state);

    return {
        patientId,
        loading
    }
}

const mapDispatchToProps = {
    loadPatientDiseaseSummaryDetailed
}

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseSummaryModal);
