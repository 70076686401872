import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {clearPatientFiles, loadPatientFiles} from "../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../utils/selectors";
import {Form, FormCheck} from "react-bootstrap";
import {useAsyncDebounce} from "react-table";
import {openDocument, openPdfRaw} from "../../utils/document-utils";
import _ from "lodash";
import {getLocalStorageUserData, isEmpty, printDate, setLocalStorageUserData} from "../../utils/helpers";
import GroupingTable from "../Table/GroupingTable";
import DocumentViewer from "../PatientDocument/DocumentViewer";
import PatientDocumentInfoViewer from "../PatientDocument/PatientDocumentInfoViewer";
import {useLocation, useParams} from "react-router-dom";
import axiosInstance, {axiosPublicInstance} from "../../utils/axios-instance";
import ReactGA from "react-ga4";
import {getFolderImgSrc} from "../../utils/file-util";
import {decreaseUnreadFiles} from "../../actions/file.actions";

const spinnerStyle = {
    width: '5rem',
    height: '5rem',
    marginTop: '5rem',
}

const PatientFiles = (
    {
        match,
        loadPatientFiles,
        clearPatientFiles,
        patientFilesLoading,
        patientFiles,
        isPanelVisible,
        decreaseUnreadFiles
    }) => {

    const {id: patientId} = useParams();

    const userData = getLocalStorageUserData();

    const [groupByType, setGroupByType] = useState(userData.personalPreferences ? userData.personalPreferences.filesGroupByType : true);
    const [selectedGroup, setSelectedGroup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [generatingOutline, setGeneratingOutline] = useState(false);
    const [pendingFilesSelected, setPendingFilesSelected] = useState(false);
    const [filesCount, setFilesCount] = useState(0);
    const [newFilesCount, setNewFilesCount] = useState(0);
    const [newFilesPendingCount, setNewFilesPendingCount] = useState(0);
    const [filesPendingCount, setFilesPendingCount] = useState(0);
    const [isRead, setIsRead] = useState(true);
    const [showUnread, setShowUnread] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);

    const hasSearchQuery = !_.isEmpty(searchQuery);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pending = searchParams.get('pending');
    const showFile = searchParams.get('showFile');

    useEffect(() => {
        if (pending === "true") {
            setShowUnread(true);
        }
    }, [pending]);

    useEffect(() => {
        if (isEmpty(showFile) || isEmpty(patientFiles)) {
            return;
        }

        const selectedFile = patientFiles.find(f => f.id === showFile);
        if (isEmpty(selectedFile)) {
            return;
        }

        setPendingFilesSelected(selectedFile.status.name === 'NEW');
        setGroupByType(false);

        onFileSelect(selectedFile);
    }, [showFile, patientFiles]);

    const countNewFiles = () => {
        let newFiles = 0;
        let newFilesPending = 0;
        let files = 0;
        let filesPending = 0;

        for (const file of (patientFiles || [])) {
            if (!file.isNew) {
                if (file.status.name === 'NEW') {
                    filesPending++;
                } else {
                    files++;
                }
            } else {
                if (file.status.name === 'NEW') {
                    newFilesPending++;
                } else {
                    newFiles++;
                }
            }
        }

        setNewFilesPendingCount(newFilesPending);
        setNewFilesCount(newFiles);
        setFilesPendingCount(filesPending);
        setFilesCount(files);
    }

    const prepareFileGroups = (pending) => {
        const typeCountMap = new Map();
        const typeIconMap = new Map();
        const orderMap = new Map();

        for (const file of (patientFiles || [])) {
            if ((!pending && file.status.name === 'NEW') || (pending && file.status.name !== 'NEW')) {
                continue;
            }
            if (showUnread && !file.isNew) {
                continue;
            }
            const type = file.folder.name;
            const typeCount = typeCountMap.get(type) || 0;

            typeCountMap.set(type, typeCount + 1);
            typeIconMap.set(type, file.icon);
            orderMap.set(type, file.folder.displayOrder);
        }

        const result = [...typeCountMap.keys()].map((type) => ({
            type: type,
            count: typeCountMap.get(type),
            icon: typeIconMap.get(type),
            displayOrder: orderMap.get(type),
        }));

        return result.sort((o1, o2) => o1.displayOrder - o2.displayOrder);
    }
    const fileGroups = useMemo(() => {
        return prepareFileGroups(false);
    }, [patientFiles, showUnread]);

    const fileGroupsPending = useMemo(() => {
        return prepareFileGroups(true);
    }, [patientFiles, showUnread]);

    useEffect(() => {
        countNewFiles();
    }, [patientFiles]);

    const stateInfoText = useMemo(() => {
        if (hasSearchQuery) {
            return null;
        } else if (!groupByType) {
            return <div className="col-sm-auto col-no-right-border">All files&nbsp;</div>;
        } else if (!selectedGroup) {
            return <div className="col-sm-auto col-no-right-border">Files by Type&nbsp;</div>;
        } else {
            return (
                <>
                    <div className="cursor-pointer col-sm-auto col-no-right-border"
                         onClick={() => setSelectedGroup(false)}>Files by Type&nbsp;</div>
                    <div
                        className="cursor-pointer col-sm-auto col-no-left-border col-no-right-border">> {selectedGroup}&nbsp;</div>
                </>
            );
        }

    }, [groupByType, selectedGroup, hasSearchQuery]);

    const tableData = useMemo(() => {
        const isGroupSelected = !_.isEmpty(selectedGroup);
        if (patientFiles && !hasSearchQuery && isGroupSelected) {
            return patientFiles.filter(file =>
                (!showUnread || file.isNew) &&
                ((file.folder.name === selectedGroup && ((pendingFilesSelected && file.status.name === 'NEW')
                    || (!pendingFilesSelected && file.status.name !== 'NEW')))));
        }

        if (patientFiles) {
            return patientFiles.filter(file =>
                (!showUnread || file.isNew === true) &&
                ((pendingFilesSelected && file.status.name === 'NEW')
                    || (!pendingFilesSelected && file.status.name !== 'NEW')));
        }

        return patientFiles;
    }, [patientFiles, selectedGroup, hasSearchQuery, pendingFilesSelected, showUnread])

    const onFileSelect = useAsyncDebounce((file) => {
        setSelectedFile(file);
        setSelectedFileId(file && file.id);

        if (file.isNew === true) {
            file.isNew = false;
            setIsRead(true);
            decreaseUnreadFiles(1);
        }
        countNewFiles();
    }, 300);

    const changeReadUnread = useCallback((file) => {
        axiosInstance.get(`/api/file/${file.id}/change-read-unread`);
        file.isNew = !file.isNew;
        if (file.id === selectedFileId) {
            setIsRead(!isRead);
        }
        decreaseUnreadFiles(file.isNew? -1: 1);
        countNewFiles();
    }, [setIsRead, decreaseUnreadFiles, countNewFiles, selectedFileId]);

    const searchFile = useAsyncDebounce((patientId, value) => {
        loadPatientFiles(patientId, value);
    }, [500]);

    const onFileSearch = useCallback((e) => {
        setSearchQuery(e.target.value);
    }, [searchFile]);

    const generateOutline = useCallback(() => {
        setGeneratingOutline(true);
        openPdfRaw(`/api/report/patient/${patientId}/files-outline`).finally(() => setGeneratingOutline(false));
    }, [patientId]);

    const toggleGroupByType = useCallback(async () => {
        if (searchQuery) {
            return;
        }

        if (!groupByType) {
            setSelectedFile(null);
        }

        ReactGA.event({
            category: "files-" + patientId,
            action: "toggleGrouped",
            label: "" + (!groupByType)
        });

        userData.personalPreferences.filesGroupByType = !groupByType;
        setLocalStorageUserData(userData);
        // update system user preferences
        await axiosInstance.post("/api/system-user/user-preferences", {filesGroupByType: userData.personalPreferences.filesGroupByType});

        setSelectedGroup(false);
        setGroupByType(!groupByType);
    }, [groupByType, searchQuery]);

    const onSelectFileGroup = useCallback((type) => {
        setSelectedGroup(type);
    }, []);

    const DocumentView = useCallback(() => {
        return (
            <div style={{
                minHeight: '100%',
                width: '100%',
                backgroundColor: '#d8d8d8',
                padding: '5px',
                paddingRight: '15px'
            }}>
                {selectedFile && (
                    <>
                        <PatientDocumentInfoViewer fileInfo={selectedFile} patientId={patientId} changeReadUnread={changeReadUnread}
                        isRead={isRead} setIsRead={setIsRead}/>
                    </>
                ) || (
                    <h3 className="text-center pt-5">No files selected</h3>
                )}
            </div>
        );
    }, [selectedFile]);

    useEffect((e) => {
        searchFile(patientId, searchQuery);
    }, [searchQuery, searchFile]);

    useEffect(() => {
        clearPatientFiles();
    }, []);

    const clearSearch = useCallback((e) => {
        setSearchQuery("");
        clearPatientFiles();
    }, []);

    const actualFileGroups = pendingFilesSelected? fileGroupsPending: fileGroups;

    const fileChangedChannel = new BroadcastChannel('internal_file_status_changed');
    useEffect(() => {
        fileChangedChannel.onmessage = ev => {
            if (patientId === ev.data) {
                searchFile(patientId, searchQuery);
            }
        };
        return () => {
            fileChangedChannel.close();
        };
    }, [fileChangedChannel]);

    return (
        <>
            <div className="bg-white p-3 patient-files-container">
                <div className="row">
                    <div className="col col-12 col-lg-4">
                        <div className="form-group-flex">
                            <div className="ni-search">
                                <input type="text" className="form-control rounded-1" placeholder="Search Documents"
                                       value={searchQuery} onChange={onFileSearch}/>
                                {!hasSearchQuery ?
                                    <i className="fas fa-search text-secondary"/>
                                    : <i className="fas fa-times text-secondary cursor-pointer" onClick={clearSearch}/>
                                }
                            </div>
                            <div hidden={hasSearchQuery} className="col text-end" style={{paddingRight: "0px"}}>
                                <button
                                    type="button"
                                    className={"btn btn-rnd btn-sm btn-opaque btn-white font-weight-500"}
                                    onClick={generateOutline}
                                >
                                    {generatingOutline && (
                                        <span className="spinner-grow spinner-grow-sm text-info me-2" role="status"
                                              aria-hidden="true"/>
                                    ) || <i className="far fa-file-alt"/>}
                                    Outline
                                </button>
                            </div>
                        </div>
                        <div className="nixio-tabs">
                            <div className="tabs-row">
                                <div className="tab-column">
                                    <button
                                        className="btn btn-tab btn-with-badge"
                                        disabled={!pendingFilesSelected}
                                        onClick={() => setPendingFilesSelected(false)}
                                    >
                                        Processed{hasSearchQuery && (<> ({filesCount})</>)}
                                        {!hasSearchQuery && newFilesCount > 0 && (<span className="badge badge-success badge-red">{newFilesCount}</span>)}
                                    </button>
                                </div>
                                <div className="tab-column">
                                    <button
                                        className="btn btn-tab btn-with-badge"
                                        disabled={pendingFilesSelected}
                                        onClick={() => setPendingFilesSelected(true)}
                                    >
                                        Pending{hasSearchQuery && (<> ({filesPendingCount})</>)}
                                        {!hasSearchQuery && newFilesPendingCount > 0 && (<span className="badge badge-success badge-red">{newFilesPendingCount}</span>)}
                                    </button>
                                </div>
                            </div>
                            <div className="tabs-row">
                                <div className={"divider" + (!pendingFilesSelected? " selected": "")}/>
                                <div className={"divider" + (pendingFilesSelected? " selected": "")}/>
                            </div>

                        </div>

                        <div className="row pt-2 justify-content-between">
                        {(!hasSearchQuery && !pendingFilesSelected) && (
                                <div className="col">
                                    <Form.Check type='switch' className='font-weight-500'>
                                        <Form.Check.Input className="form-check-input cursor-pointer"
                                                          onClick={toggleGroupByType}
                                                          checked={groupByType}/>
                                        <Form.Check.Label className="cursor-pointer" onClick={toggleGroupByType}>
                                            {`Group by Type`}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </div>
                        )}
                            <div className="col text-end">
                                <button
                                    className={"btn btn-round heigh-30 btn-deep-sky-blue-border" + (showUnread? " selected": "")}
                                    onClick={() => setShowUnread(!showUnread)}
                                >Unread</button>
                            </div>
                        </div>
                        {patientFiles && (
                            <div className="row text-secondary small text-nowrap">
                                {stateInfoText}
                                <div
                                    className="col-sm-auto col-no-left-border"> ({tableData.length} {tableData.length === 1 ? 'file' : 'files'})
                                </div>
                            </div>
                        )}
                        {!patientFiles && (
                            <div className="w-100 h-100 text-center">
                                <div className="spinner-border text-primary" role="status" style={spinnerStyle}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) || (pendingFilesSelected || hasSearchQuery || (!groupByType || selectedGroup)) && (
                            <>
                                <GroupingTable data={tableData} onRowSelect={onFileSelect} changeReadUnread={changeReadUnread}
                                               selectedRowId={selectedFileId} showFaculty={!pendingFilesSelected}/>
                            </>
                        ) || (
                            <ul className="file-group-list">
                                {actualFileGroups.map(group => (
                                    <li key={group.type} onClick={() => onSelectFileGroup(group.type)}>
                                        <img className="folder-image" src={getFolderImgSrc(group.icon)}/>
                                        <span className="file-group-name">{group.type}</span>
                                        <span
                                            className="file-group-count">{group.count} {group.count === 1 ? 'item' : 'items'}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="col col-12 col-lg-8">
                        <div style={{
                            minHeight: '100%',
                            width: '100%',
                            backgroundColor: '#d8d8d8',
                            padding: '5px',
                            paddingRight: '15px'
                        }}>
                            {selectedFile && (
                                <>
                                    <PatientDocumentInfoViewer fileInfo={selectedFile} patientId={patientId} changeReadUnread={changeReadUnread}
                                                               isRead={isRead} setIsRead={setIsRead}/>
                                </>
                            ) || (
                                <h3 className="text-center pt-5">No files selected</h3>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const patientFilesLoader = createLoadingSelector(['LOAD_PATIENT_FILES']);

const mapStateToProps = (state, props) => {

    const patientFiles = getPatientsData(state).patientFiles;
    const patientFilesLoading = patientFilesLoader(state);

    return {
        patientFiles,
        patientFilesLoading,
    };
};

const mapDispatchToProps = {
    loadPatientFiles,
    clearPatientFiles,
    decreaseUnreadFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientFiles);