export const SHOW_PANEL = 'SHOW_PANEL';
export const HIDE_PANEL = 'HIDE_PANEL';

export const showPanel = (currentPanelType, clickedPanelType, panelProps) => {

	let panelType = currentPanelType === clickedPanelType ? false : clickedPanelType;

	return {
		type: SHOW_PANEL,
		panelType,
		panelProps,
	}
};

export const hidePanel = () => {
	return {
		type: HIDE_PANEL
	};
};
