import {Link} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import {getPatientServiceIdParam} from "../../utils/helpers";
import withRouter from "../../wrappers/withRouter";

const NixioLink = ({className, path, params, state, children, patientServiceId}) => {

    const to = {
        pathname: path,
    };

    if (state) {
        to.state = {...state}
    }

    // assign query params
    const queryParams = {};
    if (patientServiceId) {
        queryParams.ps = patientServiceId
    }

    Object.assign(queryParams, params);
    to.search = new URLSearchParams(queryParams).toString();

    return (
        <Link className={className} to={to}>
            {children}
        </Link>
    )
}

const mapStateToProps = (state, props) => {
    const patientServiceId = getPatientServiceIdParam(props);

    return {
        patientServiceId,
    };
};

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NixioLink));