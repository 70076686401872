import React, {Component} from "react";
import Task from "./Task";
import {connect} from "react-redux";
import {Rnd} from "react-rnd";
import {getTaskType} from "../../../utils/task-utils";
import {getTaskNotificationData} from "../../../utils/selectors";
import {loadTaskNotifications} from "../../../actions/task-notification.actions";

const topLineStyle = {
    margin: "0 1rem 0 1rem",
    border: "solid 1px #d8d8d8",
}

const tasksLineStyle = {
    width: "100%",
    margin: "3rem 0 1.2rem 0",
    border: "solid 1px #d8d8d8",
}

class TaskNotificationModal extends Component {

    handleMarkAsViewed = (task) => {
        this.props.handleMarkAsViewed(task, 'TaskNotificationModal');
    }

    componentWillUnmount() {
        const {tasks, userId, patientLastAccessTime, patientAccessTime} = this.props;
        this.props.loadTaskNotifications({tasks, userId, patientLastAccessTime, patientAccessTime});
    }

    closeModal = () => {
        this.props.onClose();
    }

    render() {
        return (
            <Rnd className="task-notification-modal" onMouseDown={e => e.stopPropagation()}>
                <button className="btn btn-close" type="button" onMouseOver={e => e.stopPropagation()} onClick={this.closeModal}>
                    <i className="fa fa-times-circle fa-2x cancel"/>
                </button>
                <div className="header">
                    <h4>Notifications</h4>
                    <p>Since last time you viewed this patient</p>
                </div>
                <div style={topLineStyle}/>
                <div className="tasks">
                    <div className="body">
                        {this.renderTasksAssignedToMe()}
                        {this.renderTasksSpacer()}
                        {this.renderTasksCreatedByMe()}
                    </div>
                </div>
            </Rnd>
        )
    }

    renderTasksAssignedToMe = () => {
        const { overdueTasksAssignedToMe, newTasksAssignedToMe } = this.props;

        if (newTasksAssignedToMe.length < 1 && overdueTasksAssignedToMe.length < 1) {
            return null;
        }

        return <>
            <h5>Assigned to me</h5>
            {overdueTasksAssignedToMe.length > 0 &&
            <div className="tasks-list">
                <h6 className="tasks-list-title">
                    Overdue
                </h6>
                {overdueTasksAssignedToMe.map((task, i) =>
                    <Task
                        key={i}
                        task={task}
                        assignee={task.assignee}
                        typeKey={getTaskType(task)}
                        {...this.props}
                        handleMarkAsViewed={this.handleMarkAsViewed}
                    />
                )
                }
            </div>
            }
            {newTasksAssignedToMe.length > 0 &&
            <div className="tasks-list">
                <h6 className="tasks-list-title">
                    New
                </h6>
                {newTasksAssignedToMe.map((task, i) =>
                    <Task
                        key={i}
                        task={task}
                        assignee={task.assignee}
                        typeKey={getTaskType(task)}
                        {...this.props}
                        handleMarkAsViewed={this.handleMarkAsViewed}
                    />
                )
                }
            </div>
            }
        </>
    }

    renderTasksCreatedByMe = () => {
        const { completedTasksCreatedByMe, overdueTasksCreatedByMe, recurrentTasksCreatedByMe } = this.props;

        if (completedTasksCreatedByMe.length < 1 && overdueTasksCreatedByMe.length < 1 && recurrentTasksCreatedByMe.length < 1) {
            return null;
        }

        return <>
            <h5>Created by me</h5>
            {completedTasksCreatedByMe.length > 0 &&
            <div className="tasks-list">
                <h6 className="tasks-list-title">
                    Completed
                </h6>
                {completedTasksCreatedByMe.map((task, i) =>
                    <Task
                        key={i}
                        task={task}
                        assignee={task.assignee}
                        typeKey={getTaskType(task)}
                        {...this.props}
                        handleMarkAsViewed={this.handleMarkAsViewed}
                    />
                )
                }
            </div>
            }
            {overdueTasksCreatedByMe.length > 0 &&
            <div className="tasks-list">
                <h6 className="tasks-list-title">
                    Overdue
                </h6>
                {overdueTasksCreatedByMe.map((task, i) =>
                    <Task
                        key={i}
                        task={task}
                        assignee={task.assignee}
                        typeKey={getTaskType(task)}
                        {...this.props}
                        handleMarkAsViewed={this.handleMarkAsViewed}
                    />
                )
                }
            </div>
            }
            {recurrentTasksCreatedByMe.length > 0 &&
            <div className="tasks-list">
                <h6 className="tasks-list-title">
                    End date coming up
                </h6>
                {recurrentTasksCreatedByMe.map((task, i) =>
                    <Task
                        key={i}
                        task={task}
                        assignee={task.assignee}
                        typeKey={getTaskType(task)}
                        {...this.props}
                        handleMarkAsViewed={this.handleMarkAsViewed}
                    />
                )
                }
            </div>
            }
        </>
    }

    renderTasksSpacer = () => {
        const {
            overdueTasksAssignedToMe,
            newTasksAssignedToMe,
            completedTasksCreatedByMe,
            overdueTasksCreatedByMe,
        } = this.props;

        if ((overdueTasksAssignedToMe.length || newTasksAssignedToMe.length)
            && (completedTasksCreatedByMe.length || overdueTasksCreatedByMe.length)) {
            return <div style={tasksLineStyle}/>
        }

        return null;
    }

}


const mapStateToProps = (state) => {
    const taskNotifications = getTaskNotificationData(state);

    return {
        ...taskNotifications,
    };
};

const mapDispatchToProps = {
    loadTaskNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskNotificationModal);