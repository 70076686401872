import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadPatientDiagnosisDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import moment from "moment";
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import {openDocument} from "../../../utils/document-utils";
import * as _ from "lodash";

import lastFirstSvg from "../../../images/last-first-01.svg";
import firstLastSvg from "../../../images/first-last-02.svg";
import fullReportSvg from "../../../images/full_report.svg";
import {htmlToReact} from "../../../utils/render-utils";

class PatientInfoModal extends Component {

    render() {
        const {modalProps} = this.props;
        let terapyGoal = modalProps.therapyGoal || "-";
        terapyGoal = terapyGoal.replaceAll("\n", "<br>");
        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Patient Preferences</h5>
                </div>

                <div className="modal-body">
                    <p>{htmlToReact(terapyGoal)}</p>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {

    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoModal);
