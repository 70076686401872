import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTable, useFilters, useSortBy, useExpanded} from "react-table";
import BTable from "react-bootstrap/Table";
import DefaultCell from "./DefaultCell";
import DefaultColumnFilter from "./DefaultColumnFilter";
import _ from "lodash";
import isEmpty from "../../../utils/helpers";

const Table = (props) => {
    const {
        columns,
        data,
        initialState,
        loading,
        customSort,
        onPropertyUpdate: updateProp,
        errors,
        renderRowSubComponent,
        className: customClassName,
        stickyHeader,
        ...additionalProps
    } = props;

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const tableData = (data || []).map((rec, index) => ({...rec, rowIdx: index}));
        setTableData(tableData);
    }, [data]);

    const debounce = useCallback(_.debounce((callable) => {
        if (typeof callable === 'function') {
            callable();
        }
    }, 300), []);

    const filterTypes = useMemo(() => {
        return  {
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];

                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .includes(String(filterValue).toLowerCase())
                        : true;
                })
            },
        }
    }, []);

    const defaultColumn = useMemo(() => ({
        Cell: DefaultCell,
        Filter: DefaultColumnFilter,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { sortBy, expanded }
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            filterTypes,
            initialState: initialState,
            manualSortBy: !!customSort,
            ...additionalProps,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    const prevSortByRef = useRef(null);
    const prevDataRef = useRef(null);
    useEffect(() => {
        const prevSortBy = prevSortByRef.current;
        prevSortByRef.current = sortBy;

        const prevData = prevDataRef.current;
        prevDataRef.current = data;

        if (customSort && !_.isEmpty(data) && (prevSortBy !== sortBy || _.isEmpty(prevData) && !_.isEmpty(data))) {
            const fieldNames = sortBy.map(sb => sb.id);
            const orders = sortBy.map(sb => sb.desc ? 'desc' : 'asc');
            const sortFunc = (items) => {
                if (items) {
                    return _.orderBy(items, fieldNames, orders);
                }

                return items;
            }

            customSort(sortBy, sortFunc);
        }
    }, [sortBy, data]);

    if (loading) {
        return (
            <div className="w-100 h-100 text-center">
                <div className="spinner-border ni-spinner text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div><span>{rows.length} entries</span></div>
            <BTable striped bordered hover size="sm" {...getTableProps({className: customClassName})}>
                <thead style={stickyHeader? {position: "sticky", top: "0px"}: {}}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            if (column.hideHeader) {
                                return null;
                            }

                            const headerTxtProps = {};
                            if (column.titleText) {
                                headerTxtProps.title = column.titleText;
                            }

                            const headerProps = {style: {minWidth: '8rem'}};
                            if (column.headerProps) {
                                Object.assign(headerProps, column.headerProps);
                            }

                            return (
                                <th {...column.getHeaderProps(headerProps)} className="align-top">
                                    <div {...column.getSortByToggleProps()}>
                                        <span {...headerTxtProps}>{column.render('Header')}</span>
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                            </span>
                                    </div>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            );
                        })
                        }
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);

                    const rowProps = {};
                    const expandedRowProps = {};

                    if (row.isExpanded) {
                        rowProps.className = 'expanded-parent'
                        expandedRowProps.className = 'expanded-child';
                    }

                    return (
                        <>
                            <tr {...row.getRowProps(rowProps)}>
                                {row.cells.map(cell => {
                                    const rowIdx = row.original.rowIdx;
                                    const columnId = cell.column.id;

                                    const setProperty = (name, value) => {
                                        if (updateProp) {
                                            updateProp({idx: rowIdx, columnId: name, value});
                                        }
                                    }

                                    const setValueDebounce = (value) => debounce(() => {
                                        setProperty(columnId, value);
                                    });

                                    const setValue = (value) => {
                                        setProperty(columnId, value);
                                    }

                                    const mapError = cell.column.errorAccessor;
                                    const rowErrors = _.get(errors, `[${rowIdx}]`, {});
                                    const error = (typeof mapError === "function") ? mapError(rowErrors)
                                        : _.get(rowErrors, `${columnId}`);

                                    return (
                                        <td {...cell.getCellProps({className: `${isEmpty(error) ? '' : 'error'}`})}>
                                            {cell.render('Cell', {error, errors: rowErrors, setValue, setValueDebounce, setProperty})}
                                        </td>
                                    )
                                })}
                            </tr>
                            {row.isExpanded ? (
                                <tr {...row.getRowProps(expandedRowProps)}>
                                    <td colSpan={visibleColumns.length}>
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null}
                        </>
                    )
                })}
                </tbody>
            </BTable>
        </>
    )
}

export default Table;