import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from "../../../actions/modal.actions";
import { cleanNotesData, createNote, updateNote, loadNotesByPid, loadNotesByPidAndContextLike } from "../../../actions/notes.actions";
import { createLoadingSelector, getNotesData } from "../../../utils/selectors";
import isEmpty, {getWindowDimensions, setWindowDimensions} from "../../../utils/helpers";
import PanelTitle from "../PanelTitle";
import PanelSearch from "../PanelSearch";
import PanelAction from "../PanelAction";
import Loader from "react-spinners/BeatLoader";
import Notes from "./Notes";
import {Rnd} from "react-rnd";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "1px 0 2px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.6)",
    border: "solid 1px #a8a8a8",
    backgroundColor: "#fff",
};

class NotesPanel extends Component {

    state = {
        notes: this.props.notes,
        showCreateNew: this.props.notes["draft"] != null ? true : false,
        showNotesByCategory: 'public',
        windowDimension: getWindowDimensions('notes'),
        filterText: "",
    }

    componentDidMount() {
        this.loadAllNotesForPatient();
    }

    loadAllNotesForPatient() {
        this.props.loadNotesByPid(this.props.panelProps.patientId).then(result => {
            this.setState({ notes: this.props.notes });
        });
    }

    componentWillUnmount() {
        this.props.cleanNotesData();
    }

    handleSearch = (term) => {
        if (term) {
            this.props.loadNotesByPidAndContextLike(this.props.panelProps.patientId, term);
        } else {
            this.props.loadNotesByPid(this.props.panelProps.patientId);
        }
    }

    handleKeyPress = (event, term) => {
        if (event.key === 'Enter') {
            this.handleSearch(term);
        }
    }

    handleCreateNew = () => {
        this.setState({
            showCreateNew: !this.state.showCreateNew,
        });
    }

    closeCreateNew = () => {
        this.setState({
            showCreateNew: false
        });
    }

    openCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleShowNotesByCategory = (category) => {
        this.setState({
            showNotesByCategory: category,
        });
    }

    handleFilterTextChange = (filterText) => {
        this.setState({filterText});
    }

    handlePopOut = () => {

        const {
            showCreateNew,
            showNotesByCategory,
        } = this.state;

        this.props.showModal("PANEL_POPOUT_NOTES_MODAL", {
            patientId: this.props.panelProps.patientId,
            notes: this.props.notes,
            showCreateNew: showCreateNew,
            showNotesByCategory: showNotesByCategory,
            loadAllNotesForPatient: this.loadAllNotesForPatient,
            handleCreateNew: this.handleCreateNew,
            closeCreateNew: this.closeCreateNew,
            handleShowNotesByCategory: this.handleShowNotesByCategory,
            loadNotesByPid: this.props.loadNotesByPid
        });
    }

    render() {
        const {
            showCreateNew,
            showNotesByCategory,
            windowDimension
        } = this.state;

        const {
            loadingNotes,
            hidePanel,
            notes
        } = this.props;

        return (
            <Rnd
                style={style}
                size={{ width: windowDimension.width, height: windowDimension.height }}
                position={{ x: windowDimension.x, y: windowDimension.y }}
                onDragStop={(e, d) => {
                    windowDimension['x'] = d.x;
                    windowDimension['y'] = d.y;
                    setWindowDimensions('notes', windowDimension);
                    this.setState({windowDimension: windowDimension});
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    windowDimension['width'] = ref.style.width;
                    windowDimension['height'] = ref.style.height;
                    setWindowDimensions('notes', windowDimension);
                    this.setState({windowDimension: windowDimension});
                }}
            >

                <div className="panel-header">

                    <PanelTitle
                        title="Notes"
                        hidePanel={hidePanel}
                    />

                    {/*<div className="panel-toolbar">*/}

                    {/*    <PanelAction*/}
                    {/*        primary={true}*/}
                    {/*        title="Create New"*/}
                    {/*        icon="fa fa-plus"*/}
                    {/*        handleAction={this.handleCreateNew}*/}
                    {/*        disabled={showCreateNew || loadingNotes}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<PanelSearch*/}
                    {/*    placeholder="Search notes"*/}
                    {/*    handleSearch={this.handleSearch}*/}
                    {/*    onKeyPress={this.handleKeyPress}*/}
                    {/*/>*/}

                </div>

                    {loadingNotes &&
                        <div className="panel-loader">
                            <Loader size={10} color={"#ccc"} />
                        </div>
                    }

                    {!loadingNotes && isEmpty(notes) &&
                        <p className="panel-body-empty">No data found</p>
                    }

                    {!loadingNotes && !isEmpty(notes) && <>
                        <Notes
                            notes={notes}
                            showCreateNew={showCreateNew}
                            showNotesByCategory={showNotesByCategory}
                            filterText={this.state.filterText}
                            handleCreateNew={this.handleCreateNew}
                            closeCreateNew={this.closeCreateNew}
                            openCreateNew={this.openCreateNew}
                            handleShowNotesByCategory={this.handleShowNotesByCategory}
                            loadNotesByPid={this.props.loadNotesByPid}
                            patientId={this.props.panelProps.patientId}
                            onFilterTextChange={this.handleFilterTextChange}
                        />
                    </>}

            </Rnd>
        )
    }
}

const notesLoader = createLoadingSelector(['LOAD_NOTES']);

const mapStateToProps = (state) => {

    const loadingNotes = notesLoader(state);
    const notes = getNotesData(state).notes;
    
    return {
        loadingNotes,
        notes,
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    cleanNotesData,
    createNote,
    updateNote,
    loadNotesByPid,
    loadNotesByPidAndContextLike
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesPanel);