import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {loadPatientMedicalId} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData} from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import moment from "moment";
import isEmpty from '../../../utils/helpers';
import {showModal} from "../../../actions/modal.actions";
import {useParams} from "react-router-dom";

const MedicalId = (
    {
        loadPatientMedicalId,
        patientMedicalId,
        patientInfo,
        showModal,
        loading
    }) => {

    const { id: patientId } = useParams();

    useEffect(() => {
        loadPatientMedicalId(patientId);
    }, [patientId]);

    const showMedicalIdModal = useCallback((modalProps = {}) => {
        showModal("MEDICAL_ID_MODAL", { patientId: patientId , ...modalProps });
    }, []);


    const medications = [];

    if (!isEmpty(patientMedicalId.medications)) {
        patientMedicalId.medications.forEach(medication => {
            if (medication.duration === 'chronic' || moment().diff(moment(medication.time), 'days') <= 30) {
                if (!medications.includes(medication.drug)) medications.push(medication.drug)
            }
        });
    }

    let supplements = [];

    if (!isEmpty(patientMedicalId.supplements)) {

        patientMedicalId.supplements.forEach(supplement => {
            if (supplement.duration === 'chronic' || moment().diff(moment(supplement.time), 'days') <= 30) {
                if (!supplements.includes(supplement.drug)) supplements.push(supplement.drug)
            }
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-body">

                    {!loading && patientMedicalId
                        ? <>
                            <div className="card-caption">
                                <h4 className="card-title">
                                    <button onClick={showMedicalIdModal}>Medical ID</button>
                                </h4>
                            </div>

                            <div className="card-details">
                                <span>
                                    {patientInfo.birthdate ? moment().diff(patientInfo.birthdate, "years") : '-'} YO
                                </span>
                                <span /*className="card-link"*/>
                                    {patientInfo.height ? (patientInfo.height / 100).toFixed(2) : '-'} M
                                </span>
                                <span /*className="card-link"*/>
                                    {patientInfo.weight ? patientInfo.weight : '-'} KG
                                </span>
                                <span /*className="card-link"*/>
                                    {(patientInfo.height && patientInfo.weight)
                                        ? (0.007184 * Math.pow(patientInfo.height, 0.725) * Math.pow(patientInfo.weight, 0.425)).toFixed(2)
                                        : '-'} BSA
                                </span>
                            </div>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "smoking"})}>Smoking</button>
                            </h6>
                            <p>{!isEmpty(patientMedicalId.smoking) ? (<>
                                {patientMedicalId.smoking}
                            </>) : "N/A"}</p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "medicalHistory"})}>Medical history</button>
                            </h6>
                            <p>{!isEmpty(patientMedicalId.medicalHistory) ? (<>
                                {patientMedicalId.medicalHistory[0] && patientMedicalId.medicalHistory[0].summary}
                                {patientMedicalId.medicalHistory[0] && patientMedicalId.medicalHistory[0].summary && ', '}
                                {patientMedicalId.medicalHistory[1] && patientMedicalId.medicalHistory[1].summary}
                                {patientMedicalId.medicalHistory[1] && patientMedicalId.medicalHistory[2] && ', '}
                                {patientMedicalId.medicalHistory[2] && '...'}
                            </>) : "None"}</p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "familyHistory"})}>Family history</button>
                            </h6>
                            <p>{!isEmpty(patientMedicalId.familyHistory) ? (<>
                                {patientMedicalId.familyHistory[0] && patientMedicalId.familyHistory[0].summary}
                                {patientMedicalId.familyHistory[1] && ', ' + patientMedicalId.familyHistory[1].summary}
                                {patientMedicalId.familyHistory[2] && ', ...'}
                            </>) : "None"}</p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "allergies"})}>Allergies</button>
                            </h6>
                            <p>{!isEmpty(patientMedicalId.allergies) ? (<>
                                {patientMedicalId.allergies[0] && patientMedicalId.allergies[0].summary}
                                {patientMedicalId.allergies[1] && ', ' + patientMedicalId.allergies[1].summary}
                                {patientMedicalId.allergies[2] && ', ...'}
                            </>) : "None"}</p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "cancerDiagnosis"})}>Cancer associated diagnosis</button>
                            </h6>
                            <p>{!isEmpty(patientMedicalId.cancerDiagnosis) ? (<>
                                {patientMedicalId.cancerDiagnosis[0] && patientMedicalId.cancerDiagnosis[0].summary}
                                {patientMedicalId.cancerDiagnosis[1] && ', ' + patientMedicalId.cancerDiagnosis[1].summary}
                                {patientMedicalId.cancerDiagnosis[2] && ', ...'}
                            </>) : "None"}</p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "medications"})}>Medications</button>
                            </h6>
                            <p>
                                {!isEmpty(medications)
                                    ? medications.map((medication, index) => (medication + (index + 1 < medications.length ? ', ' : '')))
                                    : 'No current medications'}
                            </p>

                            <h6 className="card-title">
                                <button onClick={() => showMedicalIdModal({scrollToSection: "supplements"})}>Supplements</button>
                            </h6>
                            <p>
                                {!isEmpty(supplements)
                                    ? supplements.map((supplement, index) => (supplement + (index + 1 < supplements.length ? ', ' : '')))
                                    : 'No current supplements'}
                            </p>

                        </> : <>
                            <div className="card-caption">
                                <h4 className="card-title">Medical ID</h4>
                            </div>
                            <Loader size={5} color={"#ccc"}/>
                        </>}

                </div>
            </div>
        </>
    )
}

const patientMedicalIdLoader = createLoadingSelector(['LOAD_PATIENT_MEDICAL_ID']);

const mapStateToProps = (state) => {
    const
        patientMedicalId = getPatientsData(state).patientMedicalId,
        patientInfo = getPatientsData(state).patientInfo,
        loading = patientMedicalIdLoader(state);

    return {
        patientMedicalId,
        patientInfo,
        loading
    };
};

const mapDispatchToProps = {
    loadPatientMedicalId,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalId);