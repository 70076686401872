import moment from "moment";
import _ from "lodash";
import {taskAssigneesOrder} from "../constants/task-constants";
import {getDefaultRole} from "./role-utils";

export const isOverdueTask = (task) => {
    return !task.completed && moment().isAfter(task.dueDate);
}

export const isDueTodayTask = (task) => {
    const momentNow = moment();
    const momentDue = moment(task.dueDate);

    return !task.completed && momentDue.isSame(momentNow, 'day') && momentDue.isAfter(momentNow);
}

export const isUpcomingTask = (task) => {
    return !task.completed && moment(task.dueDate).isAfter(moment(), "day");
}

export const getTaskType = (task) => {
    if (task.completed) {
        return "done";
    }
    // Collect overdue tasks
    else if (isOverdueTask(task)) {
        return "overdue";
    }
    // Collect tasks due today
    else if (isDueTodayTask(task)) {
        return "today";
    }

    return "upcoming";
}

export const isTaskListEmpty = (tasks) => {
    const flattenedTasks = _.flatMapDeep(tasks,type => _.values(type));
    return _.isEmpty(flattenedTasks);
}

export const getAssigneeOrdinal = (assignee = {}) => {
    const role = getDefaultRole(assignee.roles);

    if (_.isEmpty(role)) {
        return -1;
    }
    return taskAssigneesOrder[role] || -1;
}

export const findAssignees = (tasks = {}) => {
   const assignees = new Set();
   Object.values(tasks).forEach(taskArray => taskArray.forEach(task => assignees.add(task.assignee.id)));
   return assignees;
}

export const dueTaskComparator = (t1, t2) => moment(t1.dueDate).diff(t2.dueDate);