import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {hidePanel} from "../../../actions/panel.actions";
import axiosInstance from "../../../utils/axios-instance";
import {toast} from "react-toastify";
import isEmpty from "../../../utils/helpers";
import {ReactMultiEmail} from "react-multi-email";
import 'react-multi-email/dist/style.css';
import closeButtonSvg from "../../../images/close-button.svg";
import linkIconSvg from "../../../images/i_link.svg";
import linkIconWhiteSvg from "../../../images/i_link_white.svg";

const ShareFile = ({panelProps, hidePanel}) => {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [message, setMessgage] = useState('');
    const [linkFileIcon, setLinkFileIcon] = useState(linkIconSvg);
    const patientId = panelProps.patientId;
    const fileId = panelProps.fileId;

    const copyTextToClipboard = (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    useEffect(() => {
        // load assigned users
        axiosInstance.get(`/api/system-user/enrolled-in-case/${patientId}`).then((resp) => {
            if (resp.data.success) {
                setAssignedUsers(resp.data.systemUsers);
            } else {
                toast.error(resp.data.errorMessage);
            }
        }).catch((err) => {
            toast.error("Unable to fetch patient assigned users: " + err.message);
        });
    }, [patientId]);

    const selectUser = useCallback((user) => {
        setSelectedUsers(prevState => {
            const newState = [...prevState];
            const index = newState.indexOf(user.id);
            if (index >= 0) {
                newState.splice(index, 1);
            } else {
                newState.push(user.id);
            }
            return newState;
        });
    }, [assignedUsers]);

    const updateMessage = useCallback((event) => {
        setMessgage(event.target.value);
    }, []);

    const sendClicked = useCallback(() => {
        const requst = {
            fileId: fileId,
            crmCaseId: patientId,
            internalUsers: selectedUsers,
            emails: emails,
            message: message,
        };

        axiosInstance.post(`/api/file/share`, requst).then((resp) => {
            toast.success('The file was shared');
            hidePanel();
        }).catch((err) => {
            toast.error("Unable to share the file: " + err.message);
        });
    }, [selectedUsers, emails, message, fileId, patientId]);

    const copyClicked = useCallback(() => {
        axiosInstance.get(`/api/file/${fileId}/link`).then((resp) => {
            copyTextToClipboard(resp.data.message);
            toast.success('The link is copied to clipboard.');
        }).catch((err) => {
            toast.error("Unable to share the file: " + err.message);
        });
    }, [selectedUsers, emails, message, fileId, patientId]);
    return (
        <div className="share-file-dialog">
            <div className="share-file-headline">
                Share with assigned users, external people via emails or copy share link.
            </div>
            <div className="assigned-users">
                <div className="assigned-title">Assigned Users</div>
                <div className="assigned-users-container">
                    {isEmpty(assignedUsers) && (
                        <div>No assigned users to this case</div>
                    )}
                    {assignedUsers.map((user) => (
                        <div className={"assigned-user" + (selectedUsers.includes(user.id) ? " selected" : "")}
                             onClick={() => selectUser(user)}>
                            <div className="avatar">
                                <div
                                    className="avatar-text">{user.firstName ? user.firstName.substring(0, 1).toUpperCase() : ""}
                                    {user.lastName ? user.lastName.substring(0, 1).toUpperCase() : ""}</div>
                            </div>
                            <div className="name-container">
                                <div className="name">{user.firstName ? (user.firstName + " ") : ""}
                                    {user.lastName ? user.lastName : ""}</div>
                                <div
                                    className="position">{!isEmpty(user.roleObjects) ? user.roleObjects[0].description : "N/A"}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="assigned-form-input">
                <div className="assigned-title">Email</div>
                <ReactMultiEmail
                    placeholder=""
                    emails={emails}
                    onChange={(emails) => {
                        setEmails(emails);
                    }}
                    getLabel={(
                        email,
                        index,
                        removeEmail
                    ) => {
                        return (
                            <div className="added-email" key={index}>
                                <div className="email-text">{email}</div>
                                <span className="delete-email" onClick={() => removeEmail(index)}><img
                                    src={closeButtonSvg}/> </span>
                            </div>
                        );
                    }}
                />
            </div>
            <div className="assigned-form-input">
                <div className="assigned-title">Message</div>
                <textarea value={message} onChange={updateMessage}/>
            </div>
            <div className="assigned-actions">
                <button className="btn radius-6 btn-white-invert" onClick={copyClicked}
                        onMouseEnter={() => setLinkFileIcon(linkIconWhiteSvg)}
                        onMouseLeave={() => setLinkFileIcon(linkIconSvg)}
                ><img src={linkFileIcon}/> Copy File Link
                </button>
                <button className="btn radius-6 btn-purple" onClick={sendClicked}>Send</button>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    hidePanel
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareFile);
