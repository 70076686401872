import React, { Component } from 'react';
import {Container} from "react-bootstrap";

class NotFound extends Component {

    componentDidMount() {

    }

    render() {

        return (

            <Container fluid>
                Not found
            </Container>

        );
    }
}

export default NotFound;
