// const slideIn =  {
//     days: CronBuilder.days,
//     weeks: (value, builder) => builder.weeks(value),
//     months: (value, builder) => builder.months(value)
// }

import _ from "lodash";

const cronConstants = {
    any: "*",
    separator: "/",
    space: " ",
    comma: ","
}

class CronBuilder {

    constructor(minutes = 0, hours = 0) {
        this.cronMinutes = minutes;
        this.cronHours = hours;
        this.cronDays = 1;
        this.cronMonths = 1;
        this.cronWeekDays = [];
    }

    minutes = (minutes = 0) => {
        if (minutes < 0 || minutes > 59) {
            minutes = 0;
        }
        this.cronMinutes = minutes;
        return this;
    }

    hours = (hours = 0) => {
        if (hours < 0 || hours > 23) {
            hours = 0;
        }
        this.cronHours = hours;
        return this;
    }

    days = (days = 1) => {
        if (days < 1 || days > 31) {
            days = 1;
        }
        this.cronDays = days;
        return this;
    }

    months = (months = 1) => {
        if (months < 1 || months > 12) {
            months = 1;
        }
        this.cronMonths = months;
        return this;
    }

    weekly = (weekDays = []) => {
        if (this.cronDays !== 1) {
            weekDays = [];
        }
        this.cronWeekDays = [...weekDays];
        return this;
    }

    interval = (interval = "days", value = 1) => {
        if(!this[interval]) {
            interval = "days";
        }
        return this[interval](value);
    }

    build = () => {

        const days = this.cronDays === 1 ? cronConstants.any : `*/${this.cronDays}`

        const months = this.cronMonths === 1 ? cronConstants.any : `*/${this.cronMonths}`

        const weekdays = this.cronDays !== 1 ?
            cronConstants.any : this.cronWeekDays.join(",") || cronConstants.any;

        return `0 ${this.cronMinutes} ${this.cronHours} ${days} ${months} ${weekdays}`
    }

    static builder = (minutes = 0, hours = 0) => {
        return new CronBuilder(minutes, hours);
    }

    static parseExpression = (expression = "") => {
        const elements = expression.split(cronConstants.space);

        if (elements.length !== 6) {
            return {
                recurrentRangeKey: "days",
                recurrentRangeValue: 1,
                recurrentDays: new Set()
            }
        }

        const daySeparatorIndex = elements[3].indexOf(cronConstants.separator);
        const days = daySeparatorIndex !== -1 ? elements[3].substring(daySeparatorIndex) : 1;

        const weekDays = elements[5] === cronConstants.any ?
            [] : elements[5].split(cronConstants.comma).map(value => parseInt(value));

        return {
            recurrentRangeKey: _.isEmpty(weekDays) ? "days" : "weekly",
            recurrentRangeValue: days,
            recurrentDays: new Set(weekDays)
        };
    }

}

export default CronBuilder;