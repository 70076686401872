import {LOAD_PRO_CATEGORIES} from "../actions/pro.actions";
import {LOAD_PRO_HISTORY} from "../actions/pro.actions";

let initialState = {
    proCategories: [],
    proHistory: [],
};

export const proReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRO_CATEGORIES.SUCCESS:
            return {
                ...state,
                proCategories: action.payload,
            };
        case LOAD_PRO_HISTORY.SUCCESS:
            return {
                ...state,
                proHistory: action.payload,
            };
        default:
            return state;
    }
};
