import React, {useState, useRef, useEffect} from 'react';
import {ProgressBar} from "react-bootstrap";
import useFile from "../../hooks/useFile";

import {BarLoader} from "react-spinners";
import axiosInstance, {axiosPublicInstance} from "../../utils/axios-instance";

const AudioPlayer = ({ url, useAuth = true, progressClass = null }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [audioUrl, error, loading] = useFile(url, useAuth ? axiosInstance : axiosPublicInstance);
    const [seeking, setSeeking] = useState(false);
    const [seekTime, setSeekTime] = useState(0);
    const audioRef = useRef(null);

    const handleSeek = (e) => {
        const progressBarRect = e.target.getBoundingClientRect();
        const clickPosition = e.clientX - progressBarRect.left;
        const seekPosition = (clickPosition / progressBarRect.width) * duration;

        setSeekTime(seekPosition);
        audioRef.current.currentTime = seekPosition;
    };

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        setMuted(muted => !muted);
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = muted;
        }
    }, [audioRef.current, muted]);


    useEffect(() => {
        const progress = ((currentTime / duration) * 100).toFixed();
        setProgress(progress);
    }, [currentTime, duration]);

    const handleProgress = (e) => {
        setCurrentTime(e.target.currentTime);
    };

    const handleLoadedMetadata = (e) => {
        setDuration(e.target.duration);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="audio-player">
            {loading ? (
                    <BarLoader width="100" color="#4A2ECF"/>
                ) : error ? (
                    <div className="invalid-feedback">{error}</div>
                ) : (
                <>
                    <div className="audio-player-progress">
                        <span className="audio-player-time">{formatTime(currentTime)}</span>
                        <ProgressBar
                            now={progress}
                            label={`${progress}%`}
                            srOnly
                            onClick={handleSeek}
                            onMouseDown={() => setSeeking(true)}
                            onMouseUp={() => setSeeking(false)}
                            onMouseMove={(e) => seeking && handleSeek(e)}
                            className={progressClass}
                        />
                        <span className="audio-player-time">{formatTime(duration)}</span>
                    </div>

                    <div className="play-btn">
                        <button onClick={togglePlay} title="Play meeting recording" className={`${isPlaying ? 'pause' : 'play'}`} />
                    </div>
                </>
            )}
            {audioUrl && (
                <audio
                    ref={audioRef}
                    src={audioUrl}
                    onTimeUpdate={handleProgress}
                    onLoadedMetadata={handleLoadedMetadata}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                />
            )}
        </div>
    )
}

export default AudioPlayer;