import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const LOAD_PERSONAL_PREFERENCES = createRequestTypes('LOAD_PERSONAL_PREFERENCES');
export const LOAD_ANALYTES_DISEASE = createRequestTypes('LOAD_ANALYTES_DISEASE');
export const LOAD_ANALYTES_TREATMENT = createRequestTypes('LOAD_ANALYTES_TREATMENT');
export const SAVE_ANALYTES = createRequestTypes('SAVE_ANALYTES');
export const DELETE_ANALYTE_TREATMENT = createRequestTypes('DELETE_ANALYTE_TREATMENT');
export const DELETE_ANALYTE_DISEASE = createRequestTypes('DELETE_ANALYTE_DISEASE');

export const loadPersonalPreferences = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PERSONAL_PREFERENCES));
    try {
        const response = await axiosInstance.get(`/api/personal-prefs/${patientId}`);
        dispatch(actionCreator.success(LOAD_PERSONAL_PREFERENCES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PERSONAL_PREFERENCES, mockError));
        throw err;
    }
}

export const clearPersonalPreferences = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_PERSONAL_PREFERENCES));
}

export const loadAnalytesDisease = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_ANALYTES_DISEASE));
    try {
        const response = await axiosInstance.get(`/api/personal-prefs/${patientId}/disease/analytes`);
        dispatch(actionCreator.success(LOAD_ANALYTES_DISEASE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_ANALYTES_DISEASE, mockError));
        throw err;
    }
}

export const clearAnalytes = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_ANALYTES_DISEASE));
}

export const loadAnalytesTreatment = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_ANALYTES_TREATMENT));
    try {
        const response = await axiosInstance.get(`/api/personal-prefs/${patientId}/treatment/analytes`);
        dispatch(actionCreator.success(LOAD_ANALYTES_TREATMENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_ANALYTES_TREATMENT, mockError));
        throw err;
    }
}
export const saveAnalytes = (patientId, analytes) => async (dispatch) => {
    dispatch(actionCreator.request(SAVE_ANALYTES));
    try {
        const response = await axiosInstance.post(`/api/personal-prefs/${patientId}`, analytes);
        dispatch(actionCreator.success(SAVE_ANALYTES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(SAVE_ANALYTES, mockError));
        throw err;
    }
}

export const deleteAnalyteTreatment = (patientId, analyteName) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_ANALYTE_TREATMENT));
    try {
        const response = await axiosInstance.delete(`/api/personal-prefs/${patientId}/treatment`, {params: {analyte: analyteName}});
        dispatch(actionCreator.success(DELETE_ANALYTE_TREATMENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(DELETE_ANALYTE_TREATMENT, mockError));
        throw err;
    }
}

export const deleteAnalyteDisease = (patientId, analyteName) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_ANALYTE_DISEASE));
    try {
        const response = await axiosInstance.delete(`/api/personal-prefs/${patientId}/disease`, {params: {analyte: analyteName}});
        dispatch(actionCreator.success(DELETE_ANALYTE_DISEASE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(DELETE_ANALYTE_DISEASE, mockError));
        throw err;
    }
}
