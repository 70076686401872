import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loadPatientPathologyDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getModalData} from "../../../utils/selectors";
import isEmpty from "../../../utils/helpers";
import moment from "moment";
import Loader from "react-spinners/BeatLoader";
import * as _ from "lodash";
import {openDocument} from "../../../utils/document-utils";

import lastFirstSvg from "../../../images/last-first-01.svg";
import firstLastSvg from "../../../images/first-last-02.svg";
import fullReportSvg from "../../../images/full_report.svg";

class PathologyModal extends Component {

    state = {
        patientPathologyData: {},
        sortOrder: 'asc',
    }

    componentDidMount() {
        const {loadPatientPathologyDetailed, patientId} = this.props;
        loadPatientPathologyDetailed(patientId).then((response) => {
            response = _.orderBy(response, ['time'], ['desc']);
            this.setState({
                patientPathologyData: response
            });
        });
    }

    handleViewFullReport = (documentId, pageNumber) => {
        openDocument(this.props.patientId, documentId, pageNumber);
    }

    closeModal = () => {
        this.props.hideModal();
    };

    sortByColumn = (column) => {
        let data;
        if (this.state.sortOrder === 'desc') {
            data = _.orderBy(this.state.patientPathologyData, ['time'], ['desc']);
            this.setState({
                sortOrder: 'asc'
            });
        } else {
            data = _.orderBy(this.state.patientPathologyData, ['time'], ['asc']);
            this.setState({
                sortOrder: 'desc'
            });
        }
        this.setState({
            patientPathologyData: data
        });
    }

    render() {
        const {patientPathologyData} = this.state;
        const {loading} = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Pathology</h5>
                </div>

                <div className="modal-body">

                    {loading &&
                    <Loader size={10} color={"#ccc"}/>
                    }

                    {!loading && isEmpty(patientPathologyData) &&
                    <p>No data found.</p>
                    }

                    {!loading && !isEmpty(patientPathologyData) &&
                    <table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
                        <thead>
                        <tr>
                            <th scope="col" role="columnheader">
                                <div className="header-container sorted" style={{cursor: "pointer"}} onClick={() => this.sortByColumn('time')}>
                                    Result Date
                                    {this.state.sortOrder === 'desc'
                                        ? <i className={"ni ni-arrow-down ni-xs ms-1"} />
                                        : <i className={"ni ni-arrow-up ni-xs ms-1"} />
                                    }
                                </div>
                            </th>
                            <th scope="col">Specimen Date</th>
                            <th scope="col">Site</th>
                            <th scope="col">Method</th>
                            <th scope="col">Finding</th>
                            <th scope="col">View File</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.patientPathologyData.map(item => (<>
                            <tr>
                                <td>{item.time ? moment.parseZone(item.time).format('DD-MMM-YYYY') : "N/A"}</td>
                                <td>{item.specimenDate ? moment.parseZone(item.specimenDate).format('DD-MMM-YYYY') : "N/A"}</td>
                                <td className="font-weight-bold">{item.pathologySite || "N/A"}</td>
                                <td>{item.procedure || "N/A"}</td>
                                <td>{item.diagnosis || "N/A"}</td>
                                <td style={{textAlign:"center"}}>
                                    {item.documentReference && item.documentReference.fileId &&
                                    <button
                                        className="btn btn-link"
                                        onClick={() => {
                                            this.handleViewFullReport(item.documentReference.fileId, item.documentReference.pageNumber)
                                        }}
                                    >
                                        <img alt="Full Report" style={{width: 30, height: 30}} src={fullReportSvg}/>
                                    </button>
                                    }
                                </td>
                            </tr>
                            {/*{item.isPositive && <>*/}
                            {/*    <tr>*/}
                            {/*        <td>{item.time ? moment.parseZone(item.time).format('MM/DD/YYYY') : "N/A"}</td>*/}
                            {/*        <td>{item.specimenDate ? moment.parseZone(item.specimenDate).format('MM/DD/YYYY') : "N/A"}</td>*/}
                            {/*        <td className="font-weight-bold">{item.pathologySite || "N/A"}</td>*/}
                            {/*        <td>{item.procedure || "N/A"}</td>*/}
                            {/*        <td>{item.diagnosis || "N/A"}</td>*/}
                            {/*        <td>*/}
                            {/*            {item.documentReference && item.documentReference.fileId &&*/}
                            {/*            <button*/}
                            {/*                className="btn btn-link"*/}
                            {/*                onClick={() => {*/}
                            {/*                    this.handleViewFullReport(item.documentReference.fileId, item.documentReference.pageNumber)*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                Full Report*/}
                            {/*            </button>*/}
                            {/*            }*/}
                            {/*        </td>*/}
                            {/*    </tr>*/}
                            {/*</>}*/}
                        </>))}
                        </tbody>
                    </table>
                    }

                </div>
            </>
        );
    }
}

PathologyModal.propTypes = {
    patientPathology: PropTypes.array
}

const patientPathologyDetailedLoader = createLoadingSelector(['LOAD_PATIENT_PATHOLOGY_DETAILED']);
const mapStateToProps = (state) => {
    const
        patientId = getModalData(state).modalProps,
        // patientPathology = getPatientsData(state).patientPathologyDetailed,
        loading = patientPathologyDetailedLoader(state);
    return {
        patientId,
        // patientPathology,
        loading
    }
}
const mapDispatchToProps = {
    loadPatientPathologyDetailed,
}
export default connect(mapStateToProps, mapDispatchToProps)(PathologyModal);
