import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal} from "../../../actions/modal.actions";
import {loadChatContacts, loadContactMessages, cleanChatData} from "../../../actions/chat.actions";
import {createLoadingSelector, getChatData} from "../../../utils/selectors";
import isEmpty from "../../../utils/helpers";
import Contacts from "./Contacts";
import Messages from "./Messages";
import PanelTitle from "../PanelTitle";
import PanelSearch from "../PanelSearch";
import PanelAction from "../PanelAction";
import Loader from "react-spinners/BeatLoader";

class MessagesPanel extends Component {

    state = {
        showConversationById: null,
    }

    componentDidMount() {
        this.props.loadChatContacts();
    }

    componentDidUpdate(prevProps) {

        // if (this.props.isPanelVisible === 'chat' && this.props.isPanelVisible !== prevProps.isPanelVisible) {
        //     this.props.loadChatContacts();
        // }
    }

    componentWillUnmount() {
        this.props.cleanChatData();
    }

    handleShowConversationById = (contactId) => {

        // Load/reload messages if opening conversation view
        if (contactId) {
            this.props.loadContactMessages(contactId);
        }
        // Reload contacts if going back to contact view
        else {
            this.props.loadChatContacts();
        }

        // Toggle between conversation/chat view
        this.setState({
            showConversationById: contactId || null,
        });
    }

    handleSearch = (term) => {
        console.log(term)
    }

    handleCreateNew = () => {
        console.log('create new')
    }

    handlePopOut = () => {

        this.props.showModal("PANEL_POPOUT_MESSAGES_MODAL", {
        });
    }

    render() {

        const {
            showConversationById,
        } = this.state;

        const {
            loadingContacts,
            loadingMessages,
            contacts,
            contactMessages,
            hidePanel,
        } = this.props;

        return (
            <>

                <div className="panel-header">

                    <PanelTitle
                        title="Messages"
                        hidePanel={hidePanel}
                    />

                    <div className="panel-toolbar">

                        <PanelAction
                            primary={true}
                            title="Create New"
                            icon="fa fa-plus"
                            handleAction={this.handleCreateNew}
                        />

                        <PanelAction
                            primary={false}
                            title="Pop Out"
                            icon="fa fa-external-link-alt"
                            handleAction={this.handlePopOut}
                        />

                    </div>

                    <PanelSearch
                        placeholder="Search messages"
                        handleSearch={this.handleSearch}
                    />

                </div>

                <div className={"panel-body" + (loadingContacts ? " panel-body-loading" : "")}>

                    {loadingContacts &&
                    <div className="panel-loader">
                        <Loader size={10} color={"#ccc"} />
                    </div>
                    }

                    {!loadingContacts && isEmpty(contacts) &&
                    <p className="panel-body-empty">No data found</p>
                    }

                    {!loadingContacts && !isEmpty(contacts) && isEmpty(showConversationById) && <>

                        {isEmpty(showConversationById) &&
                        <Contacts
                            type="New"
                            contacts={contacts.filter(contact => contact.unreadMessages > 0)}
                            handleShowConversationById={this.handleShowConversationById}
                        />
                        }

                        {isEmpty(showConversationById) &&
                        <Contacts
                            type="Older"
                            contacts={contacts.filter(contact => contact.unreadMessages === 0)}
                            handleShowConversationById={this.handleShowConversationById}
                        />
                        }

                    </>}

                    {!isEmpty(showConversationById) &&
                    <Messages
                        loadingMessages={loadingMessages}
                        contact={contacts.filter(contact => contact.contactId === showConversationById)[0]}
                        contactMessages={contactMessages}
                        handleShowConversationById={this.handleShowConversationById}
                    />
                    }

                </div>

            </>
        )
    }
}

const contactsLoader = createLoadingSelector(['LOAD_CHAT_CONTACTS']);
const contactMessagesLoader = createLoadingSelector(['LOAD_CONTACT_MESSAGES']);

const mapStateToProps = (state) => {

    const loadingContacts = contactsLoader(state);
    const loadingMessages = contactMessagesLoader(state);
    const contacts = getChatData(state).chatContacts;
    const contactMessages = getChatData(state).contactMessages;

    return {
        loadingContacts,
        loadingMessages,
        contacts,
        contactMessages,
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    loadChatContacts,
    loadContactMessages,
    cleanChatData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPanel);