import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const LOAD_UNREAD_FILES = createRequestTypes('LOAD_UNREAD_FILES');
export const DECREASE_UNREAD_FILES = createRequestTypes('DECREASE_UNREAD_FILES');


export const loadUnreadFiles = (patientId) => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_UNREAD_FILES));
    try {
        const response = await axiosInstance.get(`/api/patient/${patientId}/unread-files`);
        dispatch(actionCreator.success(LOAD_UNREAD_FILES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_UNREAD_FILES, mockError));
        return err.response;
    }
}

export const decreaseUnreadFiles = (count) => async (dispatch) => {
    dispatch(actionCreator.success(DECREASE_UNREAD_FILES, count));
}

