import React, {Component} from 'react';
import {getModalData} from "../../utils/selectors";
import {connect} from 'react-redux';
import * as ModalType from './ModalTypes';
import {Button} from "react-bootstrap";
import {hideModal, showModal} from "../../actions/modal.actions";
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactGA from "react-ga4";

const MODAL_COMPONENTS = {
    DIAGNOSIS_MODAL: ModalType.DiagnosisModal,
    MEDICAL_ID_MODAL: ModalType.MedicalIdModal,
    DISEASE_SUMMARY_MODAL: ModalType.DiseaseSummaryModal,
    BIOMARKERS_MODAL: ModalType.BiomarkersModal,
    INTERVENTION_MODAL: ModalType.InterventionModal,
    CCM_NOTES_MODAL: ModalType.CCMNotesModal,
    PATHOLOGY_MODAL: ModalType.PathologyModal,
    CBC_REPORT_MODAL: ModalType.CBCReportModal,
    CBC_TIMELINE_REPORT_MODAL: ModalType.CBCTimelineReportModal,
    PET_CT_SCAN_MODAL: ModalType.PETCTScanModal,
    PANEL_POPOUT_NOTES_MODAL: ModalType.PanelPopoutNotesModal,
    PANEL_POPOUT_TASKS_MODAL: ModalType.PanelPopoutTasksModal,
    PANEL_POPOUT_CALENDAR_MODAL: ModalType.PanelPopoutCalendarModal,
    PANEL_POPOUT_FORMS_MODAL: ModalType.PanelPopoutFormsModal,
    PANEL_POPOUT_MESSAGES_MODAL: ModalType.PanelPopoutMessagesModal,
    GENERIC_CONFIRM_MODAL: ModalType.GenericConfirmModal,
    SUPPORT_CIRCLE_MODAL: ModalType.SupportCircleModal,
    MEETING_AUDIO_RECORDING_MODAL: ModalType.MeetingAudioRecordingModal,
    EDIT_ANALYTES_MODAL: ModalType.EditAnalytesModal,
    PATIENT_INFO_MODAL: ModalType.PatientInfoModal,
};

function resolveModalClass(modalType) {
    switch (modalType) {
        case "MEETING_AUDIO_RECORDING_MODAL": {
            return 'audio-recording-modal';
        }
        case "GENERIC_CONFIRM_MODAL": {
            return 'modal-dialog-small';
        }
        case "EDIT_ANALYTES_MODAL": {
            return 'modal-dialog-medium';
        }
    }
}

class RootModal extends Component {

    modalRef = React.createRef();

    state = {
        isModalShowing: false,
        activeModalTab: 1,
        staticModalOpened: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isModalShowing && !prevState.isModalShowing)  {
            ReactGA.event({
                category: "modal",
                action: "show",
                label: this.props.modalType
            });
            document.addEventListener("keyup", this.closeOnEvent);
            document.addEventListener("click", this.closeOnEvent);
        }
    }

    closeModal = () => {
        ReactGA.event({
            category: "modal",
            action: "close",
            label: this.props.modalType
        });

        document.removeEventListener("keyup", this.closeOnEvent);
        document.removeEventListener("click", this.closeOnEvent);

        this.setState({isModalShowing: 'closing'});

        setTimeout(() => {
            this.props.hideModal();
            this.setState({isModalShowing: false});
        }, 500);
    }

    closeOnEvent = (event) => {

        if (this.state.staticModalOpened) {
            return;
        }

        if (event.code && (event.code === "Esc" || event.code === "Escape")) {
            this.closeModal();
        }

        if (event.target && (event.target.classList && event.target.classList.contains('modal'))) {
            this.closeModal();
        }
    }

    handleChangeModalTab = (id) => {
        this.setState({activeModalTab: id})
    }

    openStaticModal = () => {
        this.setState({staticModalOpened: true});
    }

    closeStaticModal = () => {
        this.setState({staticModalOpened: false});
    }

    render() {

        const {
            isModalShowing,
            activeModalTab,
        } = this.state;

        const {
            isModalOpen,
            modalType,
            modalProps
        } = this.props;

        if (!modalType) {
            return null;
        }

        if (isModalOpen && !isModalShowing) {

            setTimeout(() => {
                this.setState({isModalShowing: true});
            }, 0);
        }

        const customModalDialogClass = resolveModalClass(modalType);
        const modalDialogClass = "modal-dialog modal-dialog-centered" + (customModalDialogClass ? " " + customModalDialogClass : "");

        const CurrentModal = MODAL_COMPONENTS[modalType];

        return (
            <>
                <div className={"modal root-modal fade" + (isModalShowing && isModalShowing !== 'closing' ? ' show' : '')} tabIndex="-1" role="dialog">
                    <div
                        role="document"
                        className={modalDialogClass}
                    >
                        <div ref={this.modalRef} className="modal-content">

                            <Button className="modal-close" onClick={this.closeModal}><i className="fa fa-times"/></Button>

                            <CurrentModal
                                activeModalTab={activeModalTab}
                                handleChangeModalTab={this.handleChangeModalTab}
                                closeModal={this.closeModal}
                                openStaticModal={this.openStaticModal}
                                closeStaticModal={this.closeStaticModal}
                                {...this.props}
                            />

                        </div>
                    </div>
                </div>

                <div className={"modal-backdrop fade" + (isModalShowing && isModalShowing !== 'closing' ? ' show' : '')}/>
            </>
        );
    }
}

RootModal.propTypes = {
    modalType: PropTypes.string
}

const mapStateToProps = (state) => {
    const isModalOpen = getModalData(state).showModal;
    const modalType = getModalData(state).modalType;
    const modalProps = getModalData(state).modalProps;

    return {
        isModalOpen,
        modalType,
        modalProps,
    };
};

const mapDispatchToProps = {
    hideModal,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
