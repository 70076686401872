import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getModalData} from "../../../utils/selectors";
import {htmlToReact} from "../../../utils/render-utils";

class GenericConfirmModal extends Component {

    handleReject = () => {

        if (this.props.modalProps.rejectHandler) {
            this.props.modalProps.rejectHandler()
        }
        this.closeModal();
    }

    handleConfirm = () => {

        if (this.props.modalProps.confirmHandler) {
            this.props.modalProps.confirmHandler();
        }
        this.closeModal();
    }

    closeModal = () => {
        this.props.closeModal();
    }

    render() {

        const {
            modalProps,
        } = this.props;

        return (
            <>

                <div className="modal-header">

                    <h5 className="modal-title">
                        {modalProps.title}
                    </h5>

                </div>

                <div className="modal-body">

                    <p>
                        {htmlToReact(modalProps.message)}
                    </p>

                    <div className="row justify-content-center">
                        <div className="col-5 mx-auto">
                            <button
                                type="submit"
                                onClick={this.handleReject}
                                className="btn btn-secondary"
                            >
                                {modalProps.rejectText || "No"}
                            </button>
                        </div>

                        <div className="col-5 ms-auto">
                            <button
                                onClick={this.handleConfirm}
                                className="btn btn-primary text-end"
                            >
                                {modalProps.confirmText || "Yes"}
                            </button>
                        </div>
                    </div>

                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;

    return {
        modalProps,
    };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericConfirmModal);
