import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateNote, deleteNote, loadNotesByPid} from "../../../actions/notes.actions";
import {hideModal, showModal} from "../../../actions/modal.actions";
import {getNotesData} from "../../../utils/selectors";
import {getUserId} from "../../../utils/helpers";
import moment from "moment";
const parse = require('html-react-parser');

class Note extends Component {

    state = {
        isEditing: false,
        readMore: false,
    }

    handleEditNote = () => {

        if (this.props.draft) {

            this.props.showModal("GENERIC_CONFIRM_MODAL", {
                title: 'Another draft exists',
                message: 'You have unsaved draft, please save or delete it before proceeding with editing',
                rejectText: 'Delete Note',
                rejectHandler: () => {
                    this.props.deleteNote(this.props.draft.id).then((response) => {
                        this.props.updateNote(this.props.note.id, {isDraft: true}).then((response) => {
                            this.props.loadNotesByPid(this.props.patientId);
                        });
                    });
                },
                confirmText: 'Save Note',
                confirmHandler: () => {
                    this.props.updateNote(this.props.draft.id, {isDraft: false}).then((response) => {
                        this.props.updateNote(this.props.note.id, {isDraft: true}).then((response) => {
                            this.props.loadNotesByPid(this.props.patientId);
                        });
                    });
                },
            });
        }
        else {

            // set selected as draft
            const payload = {
                "isDraft": true
            }

            this.props.updateNote(this.props.note.id, payload).then((response) => {
                this.props.loadNotesByPid(this.props.patientId);
            });
        }
    }

    handleSubmitNote = (id) => {
        this.setState({isEditing: true});
    }

    handleDeleteNote = () => {

        this.props.showModal("GENERIC_CONFIRM_MODAL", {
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this note?',
            confirmHandler: () => {
                this.props.deleteNote(this.props.note.id).then((response) => {
                    this.props.loadNotesByPid(this.props.patientId);
                });
            },
        });
    }

    render() {

        const {
            note,
            showNotesByCategory,
        } = this.props;

        const userId = getUserId();
        return (
            <div className="note">

                <div className="note-heading">
                    <span className="note-title">
                      <span className="note-title-date">{moment(note.updateDate).format('DD-MMM-YYYY')}</span><br/>
                        {(note.titleView? parse(note.titleView) : (note.title? note.title : '(no subject)'))}
                    </span>
                    {showNotesByCategory === 'public' &&
                    <span className={"note-author"}>{note.createdBy.firstName}</span>
                    }
                    {showNotesByCategory === 'private' &&
                    <span className={"note-author"}></span>
                    }
                    {userId === note.createdBy.id && (<>
                        <button
                            className="btn btn-link note-edit"
                            onClick={this.handleEditNote}
                        >
                            <i className="fa fa-pencil-alt"/>
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-link note-edit"
                            onClick={this.handleDeleteNote}
                        >
                            <i className="fa fa-trash"/>
                        </button>
                    </>)}
                </div>
                <div className={"sun-editor-editable" + (this.state.readMore ? "" : " show-less")}>
                    {note.contextView? parse(note.contextView) : parse(note.context)}
                </div>
                <a className="note-read-more" onClick={() => {
                    this.setState({readMore: !this.state.readMore})
                }}>
                    {this.state.readMore ? "Read less" : "Read more"}
                </a>
                <div className="line"/>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const draft = getNotesData(state).notes.draft;

    return {
        draft
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    updateNote,
    deleteNote,
    loadNotesByPid
};

export default connect(mapStateToProps, mapDispatchToProps)(Note);
