import {
    CLEAN_TASKS,
    LOAD_TASK_NOTIFICATIONS,
    UPDATE_TASK_COMPLETED } from "../actions/tasks.actions";
import {LOAD_TASKS} from "../actions/tasks.actions";
import {CREATE_TASK} from "../actions/tasks.actions";
import {UPDATE_TASK} from "../actions/tasks.actions";

let initialState = {
    tasks: [],
};

export const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_TASKS.CLEAR:
            return {
                initialState,
            }
        case LOAD_TASKS.SUCCESS:
            return {
                ...state,
                tasks: action.payload.tasks,
            };
        case CREATE_TASK.SUCCESS:
            return {
                // ...state, tasks: action.payload.tasks,
            };
        case UPDATE_TASK.SUCCESS:
            return {
                // ...state, tasks: [],
            };
        case UPDATE_TASK_COMPLETED.SUCCESS:
            return {
                // ...state, tasks: [],
            };
        default:
            return state;
    }
};
