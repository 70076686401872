import {analyte, labels} from "./timeline-utils";
import isEmpty from "./helpers";
import moment from "moment";

const getValue = (context) => {
    let value;
    if (context.datasetIndex == 0) {
        value = analyte.values.find(v => v.date === labels[context.dataIndex]);
    } else if (analyte.additionalUnitValues) {
        value = analyte.additionalUnitValues.find(v => v.date === labels[context.dataIndex]);
    }

    return value
}

const getTooltipValue = (tooltip) => {
    const activeElements = tooltip.getActiveElements();
    if (activeElements == null || activeElements.length == 0) {
        return null;
    }

    const activeElement = activeElements[0];

    let value;
    if (activeElement.datasetIndex == 0) {
        value = analyte.values.find(v => v.date === labels[activeElement.index]);
    } else if (analyte.additionalUnitValues) {
        value = analyte.additionalUnitValues.find(v => v.date === labels[activeElement.index]);
    }

    return value;
}

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, -120%)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

export const externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    const activeElements = chart.getActiveElements();
    const value = getTooltipValue(tooltip);
    if (tooltip.opacity === 0 || value == null) {
        tooltipEl.style.opacity = 0;
        return;

    }

    // Set Text
    // const dateFormater = moment(additionalUnitValue.date);
    if (tooltip.body) {
        const tableBody = document.createElement('tbody');
        value.values.forEach((v, i) => {

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const tdTime = document.createElement('td');
            const tdValue = document.createElement('td');
            tdTime.style.borderWidth = 0;
            tdValue.style.borderWidth = 0;

            const text = document.createTextNode(v.time + ", ");
            const b = document.createElement('b');
            b.textContent = v.value + (v.unit != null? ' ' + v.unit: '');

            tdTime.style.verticalAlign = 'top';
            tdValue.style.verticalAlign = 'top';
            tdTime.appendChild(text);
            tdValue.appendChild(b);
            tr.appendChild(tdTime);
            tr.appendChild(tdValue);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.classList.add("tooltiptext");

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        tooltipEl.style.background = 'white';
        tooltipEl.style.borderWidth = '1px';
        tooltipEl.style.borderColor = 'black';
        tooltipEl.style.borderStyle = 'solid';
        tooltipEl.style.boxShadow = '5px 5px 5px rgba(0,0,0,0.7)';
        tableRoot.style.backgroundColor = 'white';
        tableRoot.style.color = 'black';
        // Add new children
        // tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export const datalabelFont = (context) => {
    let fontValue = {size: 10};

    const value = getValue(context);

    if (value && value.values && value.values.length > (context.datasetIndex == 0? 1: 0)) {
        fontValue.weight = 'bold';
    }
    return fontValue;
}

export const getAnalyteValueWithTooltip = (analyteValue, additionalUnitValues, isAdditionalValue) => {
    let printValue = analyteValue.values[analyteValue.values.length - 1].value;
    if (isAdditionalValue) {
        printValue += !isEmpty(analyteValue.values[analyteValue.values.length - 1].unit)? " " + analyteValue.values[analyteValue.values.length - 1].unit: "";
    }
    if (isEmpty(printValue)) {
        printValue = "";
    }
    let additionalUnitValue = null;
    if (additionalUnitValues) {
        additionalUnitValue = additionalUnitValues.find(v => v.date === analyteValue.date)
    }

    const numberOfValues = analyteValue.values.length + (additionalUnitValue && additionalUnitValue.values? additionalUnitValue.values.length: 0);

    if (numberOfValues < 2 && printValue.length < 8) {
        return '<div class="nixiotooltip">' + printValue + '</div>';
    }

    if (printValue.length > 10) {
        printValue = printValue.substring(0, 10) + '<div class="tooltip-pointer">...</div>';
    }

    let trStr = "";
    const dateFormater = moment(analyteValue.date);
    analyteValue.values.forEach(v  => {
        trStr += '<tr><td vertical-align="top"><div class="nixiotooltip-time">' +
            dateFormater.format('DD-MMM-yyyy') + ' ' + v.time + ',</div></td><td vertical-align="top" align="left"><b>' +
            v.value +
            (v.unit != null? ' ' + v.unit: '') +
            '</b></td></tr>';
    });
    if (additionalUnitValue && additionalUnitValue.values) {
        const dateFormater = moment(additionalUnitValue.date);
        additionalUnitValue.values.forEach(v  => {
            trStr += '<tr vertical-align="top"><td vertical-align="top"><div class="nixiotooltip-time">' +
                dateFormater.format('DD-MMM-yyyy') + ' ' + v.time + ',</div></td><td vertical-align="top" align="left"><b>' +
                v.value +
                (v.unit != null? ' ' + v.unit: '') +
                '</b></td></tr>';
        });
    }
    return '<div class="nixiotooltip">' + printValue + (numberOfValues > 1?' <div class="tooltip-pointer">(+' + (numberOfValues - 1) + ')</div>': '') +
        '<div class="tooltiptext"><table>' + trStr +
        '</table></div></div>';
}