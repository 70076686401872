import React, {Component, useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";
import Loader from "react-spinners/BeatLoader";
import {createLoadingSelector, getFileData, getPatientsData} from "../../utils/selectors";
import {loadPatientInfo, loadPatientPhoto} from "../../actions/patients.actions";
import NixioLink from "../Link/NixioLink";
import {useLocation, useParams} from "react-router-dom";
import axiosInstance from "../../utils/axios-instance";
import {loadUnreadFiles} from "../../actions/file.actions";
import isEmpty from "../../utils/helpers";

const PatientDataMenu = ({patientServiceId, patientInfo, loadPatientInfo, loadingInfo, patientPhoto, loadPatientPhoto, loadingPhoto, loadUnreadFiles, unreadFilesCount }) => {
    const {pathname: path} = useLocation();
    const {id: patientId} = useParams();

    const fileChangedChannel = new BroadcastChannel('file_status_changed');
    const internalFileChangedChannel = new BroadcastChannel('internal_file_status_changed');

    useEffect(() => {
        loadPatientInfo(patientId, patientServiceId);
        loadPatientPhoto(patientId);
        loadUnreadFiles(patientId);
    }, [patientId]);

    useEffect(() => {
        fileChangedChannel.onmessage = ev => {
            if (patientId === ev.data) {
                loadUnreadFiles(patientId);
                internalFileChangedChannel.postMessage(patientId);
            }
        };

        return () => {
            fileChangedChannel.close();
        };
    }, [fileChangedChannel]);

    return (
        <nav className="patient-menu">
            <div className="container-fluid">
                <div className="row">

                    <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                        <div className="patient-menu-info">
                        {!loadingInfo && !loadingPhoto && patientInfo
                            ? <>
                                {patientPhoto
                                    ? <img className="patient-menu-image" src={URL.createObjectURL(new Blob([patientPhoto]))} alt="Patient Photo"/>
                                    : <i className="patient-menu-image ni ni-avatar" />
                                }
                                <div className="patient-menu-details">
                                    <span className="patient-menu-detail patient-menu-detail-full"><h5>{patientInfo.firstName + " " + patientInfo.lastName}</h5></span>
                                    <span className="patient-menu-detail">{patientInfo.birthdate && moment(patientInfo.birthdate).format("DD-MMM-YYYY")}</span>
                                    <span className="patient-menu-detail">{patientInfo.gender && patientInfo.gender}</span>
                                    <span className="patient-menu-detail">{!isEmpty(patientInfo.crmCases) && patientInfo.crmCases[0].crmCaseId}</span>
                                </div>
                            </>
                            :
                            <div className="patient-menu-loader">
                                <Loader size={5} color={"#000"}/>
                            </div>
                        }
                        </div>
                    </div>

                    <div className="col-9 center-block">
                        <ul className="patient-menu-items">
                            <li className="patient-menu-item">
                                <NixioLink className={path.endsWith("home") ? "active" : null} path={`/patient/${patientId}/home`}>
                                    Home
                                </NixioLink>
                            </li>
                            <li className="patient-menu-item">
                                <NixioLink className={path.endsWith("timeline") ? "active" : null} path={`/patient/${patientId}/treatment-timeline`}>
                                    Timeline
                                </NixioLink>
                            </li>
                            <li className="patient-menu-item">
                                <NixioLink className={path.endsWith("files") ? "active" : null} path={`/patient/${patientId}/files`}>
                                    Files
                                    {unreadFilesCount > 0 && (
                                        <span className="badge badge-success badge-red">{unreadFilesCount}</span>
                                    )}
                                </NixioLink>
                            </li>
                            {/*<li className="patient-menu-item">*/}
                            {/*    <NixioLink className={path.endsWith("report") ? "active" : null} path={`/patient/${patientId}/report`}>*/}
                            {/*        Report*/}
                            {/*    </NixioLink>*/}
                            {/*</li>*/}
                            {/*<li className="patient-menu-item">*/}
                            {/*    <NixioLink className={path.endsWith("nccn") ? "active" : null} path={`/patient/${patientId}/nccn`}>*/}
                            {/*        NCCN*/}
                            {/*    </NixioLink>*/}
                            {/*</li>*/}
                            {/*<li className="patient-menu-item">*/}
                            {/*    <NixioLink className={path.endsWith("calendar") ? "active" : null} path={`/patient/${patientId}/calendar`}>*/}
                            {/*        Calendar*/}
                            {/*    </NixioLink>*/}
                            {/*</li>*/}
                            {/*<li className="patient-menu-item">*/}
                            {/*    <Link className={this.props.match.path.endsWith("pro") ? "active" : null} to={`/patient/${patientId}/pro`}>*/}
                            {/*        PRO*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </ul>
                    </div>

                </div>
            </div>
        </nav>
    );
}

const patientInfoLoader = createLoadingSelector(['LOAD_PATIENT_INFO']);
const patientPhotoLoader = createLoadingSelector(['LOAD_PATIENT_PHOTO']);

const mapStateToProps = (state, props) => {

    const {patientInfo, patientPhoto} = getPatientsData(state);
    const {unreadFilesCount} = getFileData(state);

    const loadingInfo = patientInfoLoader(state);
    const loadingPhoto = patientPhotoLoader(state);

    return {
        patientInfo,
        patientPhoto,
        loadingInfo,
        loadingPhoto,
        unreadFilesCount,
    };
};

const mapDispatchToProps = {
    loadPatientInfo,
    loadPatientPhoto,
    loadUnreadFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataMenu);
