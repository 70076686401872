import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getModalData} from "../../../utils/selectors";

class PanelPopoutMessagesModal extends Component {

    closeModal = () => {
        this.props.hideModal();
    }

    render() {

        const {
            modalProps,
        } = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Messages</h5>
                </div>

                <div className="modal-body">

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;

    return {
        modalProps,
    };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPopoutMessagesModal);
