import axiosInstance from "../utils/axios-instance";
import {actionCreator, createRequestTypes, getErrorMessage} from "../utils/helpers";
import React from "react";

export const LOAD_PRO_CATEGORIES = createRequestTypes('LOAD_PRO_CATEGORIES');
export const LOAD_PRO_HISTORY = createRequestTypes('LOAD_PRO_HISTORY');

export const loadProCategories = () => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PRO_CATEGORIES));
    try {
        const response = await axiosInstance.get('/api/pro/categories');
        dispatch(actionCreator.success(LOAD_PRO_CATEGORIES, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PRO_CATEGORIES, mockError));
        return err.response;
    }
}

export const loadProHistory = () => async (dispatch) => {
    dispatch(actionCreator.request(LOAD_PRO_HISTORY));
    try {
        const response = await axiosInstance.get(`/api/pro/history`);
        dispatch(actionCreator.success(LOAD_PRO_HISTORY, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(LOAD_PRO_HISTORY, mockError));
        return err.response;
    }
}
