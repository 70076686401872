import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Bar} from "react-chartjs-2";

class Steps extends Component {

    render () {

        let data = {
            labels: ["Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Today"],
            datasets: [{
                data: [3000, 5000, 5500, 5100, 3000, 3750, 1750],
                backgroundColor: '#35c95b',
                barPercentage: 0.5,
                maxBarThickness: 10,
                borderRadius: 5, // will work in chart.js 3, but that's still not react compatible
            }],
        };

        let options = {
            animation: false,
            spanGaps: true,
            maintainAspectRatio: true,
            responsive: true,
            legend: {display: false},
            tooltips: {enabled: false},
            annotation: {display: false},
            plugins: {datalabels: false},
            scales: {
                xAxes: [{
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999'}
                }],
                yAxes: [{
                    gridLines: {color: "#E0E0E0", zeroLineColor: "#E0E0E0", lineWidth: 2, zeroLineWidth: 2, drawTicks: false},
                    ticks: {padding: 5, fontSize: 12, fontColor: '#999', min: 0, max: 6000, stepSize: 2000},
                    position: 'right',
                }],
            },
        }

        return  (
            <div className="activity">

                <h5 className="activity-title activity-title-green">
                    <i className="fa fa-fw fa-shoe-prints" />
                    Steps
                </h5>

                <div className="activity-chart">

                    <Bar data={data} options={options} />

                </div>

                <div className="activity-stats">
                    <h6 className="activity-stats-metric">Average</h6>
                    <p className="activity-stats-value"><span>3,962</span> Steps</p>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);