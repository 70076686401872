import SearchPatientLayout from "./SearchPatientLayout";
import SearchPatient from "../SearchPatient/SearchPatient";
import PatientDataLayout from "./PatientDataLayout";
import PatientDataHome from "../PatientData/PatientDataHome";
import PatientDataTreatmentTimeline from "../PatientData/PatientDataTreatmentTimeline";
import PatientDataDiseaseTimeline from "../PatientData/PatientDataDiseaseTimeline";
import PatientDataReport from "../PatientData/PatientDataReport";
import PatientDataNccn from "../PatientData/PatientDataNccn";
import PatientDataCalendar from "../PatientData/PatientDataCalendar";
import PatientDataPro from "../PatientData/PatientDataPro";
import PatientFiles from "../PatientData/PatientFiles";
import DocumentInfoFullViewer from "../PatientDocument/DocumentInfoFullViewer";
import DocumentInfoSharedViewer from "../PatientDocument/DocumentInfoSharedViewer";
import ForgotPassword from "../Authentication/ForgotPassword";
import SharedViewLayout from "./Public/SharedViewLayout";
import React from "react";
import AuthBaseLayout from "../Authentication/Common/AuthBaseLayout";
import ResetPassword from "../Authentication/ResetPassword";

const components = {
    searchPatients: {
        url: '/search-patients',
        component: SearchPatient,
        layout: SearchPatientLayout
    },
    patientDataHome: {
        url: '/patient/:id/home',
        component: PatientDataHome,
        layout: PatientDataLayout
    },
    patientDataTimeline: {
        url: '/patient/:id/treatment-timeline',
        component: PatientDataTreatmentTimeline,
        layout: PatientDataLayout
    },
    patientDataDiseaseTimeline: {
        url: '/patient/:id/disease-timeline',
        component: PatientDataDiseaseTimeline,
        layout: PatientDataLayout
    },
    patientDataReport: {
        url: '/patient/:id/report',
        component: PatientDataReport,
        layout: PatientDataLayout
    },
    patientDataNccn: {
        url: '/patient/:id/nccn',
        component: PatientDataNccn,
        layout: PatientDataLayout
    },
    patientFiles: {
        url: '/patient/:id/files',
        component: PatientFiles,
        layout: PatientDataLayout
    },
    patientDataCalendar: {
        url: '/patient/:id/calendar',
        component: PatientDataCalendar,
        layout: PatientDataLayout
    },
    patientDataPro: {
        url: '/patient/:id/pro',
        component: PatientDataPro,
        layout: PatientDataLayout
    },
    patientDocument: {
        url: '/patient/:id/document',
        component: DocumentInfoFullViewer,
    },
    document: {
        url: '/shared/document/:token',
        component: DocumentInfoSharedViewer,
        layout: SharedViewLayout,
        public: true,
    },
    forgotPassword: {
        url: '/forgot-password',
        component: ForgotPassword,
        layout: AuthBaseLayout,
        public: true,
    },
    resetPassword: {
        url: '/reset-password',
        component: ResetPassword,
        layout: AuthBaseLayout,
        public: true,
    },
};

export const routes = [ ...Object.values(components) ];