import React, {Component} from 'react';
import {connect} from 'react-redux';
import PatientInfo from "./Cards/PatientInfo";
import Diagnosis from "./Cards/Diagnosis";
import MedicalId from "./Cards/MedicalId";
import PRODials from "./Cards/PRODials";
import DiseaseSummary from "./Cards/DiseaseSummary";
import Biomarkers from "./Cards/Biomarkers";
import Intervention from "./Cards/Intervention";
import Pathology from "./Cards/Pathology";
import {cleanPatientData} from "../../actions/patients.actions";
import {hideModal} from "../../actions/modal.actions";
import SupportCircle from "./Cards/SupportCircle";

class PatientDataHome extends Component {

    componentWillUnmount() {
        this.props.hideModal();
    }

    render () {

        const {
            isPanelVisible,
        } = this.props;

        return (
            <>
                <div className={"layout-patient layout-patient-home" + (isPanelVisible ? " layout-patient-action-menu-open" : "")}>
                    <div className="row">
                        <div className="col col-12 col-lg-3">
                            <div>
                                <PatientInfo {...this.props} />
                            </div>
                            <div>
                                <SupportCircle {...this.props} />
                            </div>
                            <div>
                                <Diagnosis {...this.props} />
                            </div>
                            <div>
                                <MedicalId {...this.props} />
                            </div>
                        </div>
                        <div className="col col-12 col-lg-9">
                            <div className="row">
                                <div className="col col-12 col-lg-8">

                                    <PRODials {...this.props} />

                                    <DiseaseSummary {...this.props} />

                                    <Biomarkers {...this.props} />

                                    <Intervention {...this.props} />

                                </div>
                                <div className="col col-12 col-lg-4">

                                    <Pathology {...this.props} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    cleanPatientData,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataHome);