import React, {Component} from 'react';

class PanelTitle extends Component {

    render() {

        const {
            title,
            hidePanel,
        } = this.props;

        return (
            <div className="panel-title">

                <h4>{title}</h4>

                <button className="btn btn-link" onClick={hidePanel}><i className="fa fa-times"/></button>

            </div>
        )
    }
}

export default PanelTitle;
