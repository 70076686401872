import React from 'react';
import {connect} from "react-redux";
import moment, {now} from "moment";

const BasicHeader = () => {

    return (
        <header>
            <nav className="navbar navbar-light navbar-expand-md sticky-top">
                <div className="navbar-brand">
                    <div className="navbar-logo">
                        <img src="/logo.png" alt="Nixio" />
                    </div>
                    {moment(now()).format("MMMM DD, YYYY")}
                </div>
            </nav>
        </header>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicHeader);
