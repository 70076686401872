import React, {useCallback, useMemo} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import {getErrorMessage} from "../../../utils/selectors";
import {useNavigate} from "react-router-dom";
import PasswordField from "../../../components/PasswordField";

const AuthenticationStep = ({onLogin, error}) => {

    const navigate = useNavigate();

    const handleOnSubmit = useCallback((values, { setSubmitting }) => {
        Promise.resolve(onLogin(values.username, values.password))
            .finally(() => {
                setSubmitting(false);
            });
    }, [onLogin]);

    const loginSchema = useMemo(() => {
        return Yup.object().shape({
            username: Yup.string().required('Required'),
            password: Yup.string().required('Required')
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: loginSchema,
        onSubmit: handleOnSubmit,
    });

    return (
        <div className="login row">
            <div className="login__left-panel col-6">
                <div className="login__header">
                    <div className="login__header-logo"/>
                    <div className="login__header-text">
                        Advocacy
                    </div>
                </div>
                <div className="login__form-container">
                    <form onSubmit={formik.handleSubmit} className="login__form">
                        <div className="login__welcome-msg">Welcome Back!</div>

                        <div className="login__username">
                            {/*<span className="input-group-text">*/}
                            {/*    <i className="fas fa-user"/>*/}
                            {/*</span>*/}
                            <input className="form-control form-control-rounded"
                                   type="text"
                                   placeholder="Email*"
                                   name="username"
                                   aria-label="Username"
                                   value={formik.values.username}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                            />
                            {formik.errors.username && formik.touched.username && (
                                <div className="invalid-feedback">{formik.errors.username}</div>
                            )}
                        </div>

                        <div className="login__password-container">
                            <PasswordField
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.password && formik.touched.password && (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                            )}
                        </div>
                        {error && error.length > 0 && (
                            <div className="login__auth-error invalid-feedback">
                                {error}
                            </div>
                        )}

                        <a className="login__forgot-password" onClick={() => navigate('/forgot-password', { state: { email: formik.values.username } })}>
                            Forgot password?
                        </a>

                        <button
                            className="btn btn-primary btn-rnd btn-save ms-1 login__submit" type="submit"
                            onClick={formik.handleSubmit}
                        >
                            Login
                        </button>
                    </form>
                </div>
                <div className="login__footer">
                    <div>Trouble logging in?</div>
                    <div>Please contact info@n1x10.com</div>
                </div>
            </div>
            <div className="login__right-panel col-6 d-md-none d-lg-block">
                <div className="login__medical-research_img" />
            </div>
        </div>
    );
}

const getAuthError = getErrorMessage(['AUTH']);

const mapStateToProps = (state) => {
    const authErrorMessage = getAuthError(state);
    return {
        authErrorMessage
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationStep);