import React from "react";
import {NA_TEXT} from "./utils";

const DefaultCell = (props) => {
    const {column, value} = props;

    if (Array.isArray(value)) {
        return value.join(", ");
    }

    if (value === false) {
        return <span>No</span>;
    } else if (value === true) {
        return <span>Yes</span>;
    } else if (!value && value !== 0) {
        return <span>{NA_TEXT}</span>;
    }

    return <span>{value}</span>;
}

export default DefaultCell;