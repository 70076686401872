import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import isEmpty from "../../../utils/helpers";
import PhoneInput from "react-phone-number-input";

const EditablePhoneCell = (props) => {
    const { value, error, setValueDebounce, column } = props;

    const [editing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);

    const startEditing = useCallback(() => {
        setEditing(true);
        setEditValue(value);
    }, [value]);

    const stopEditing = useCallback(() => {
        setEditing(false);
    }, []);

    const handleValueOnChange = useCallback((value) => {
        setEditValue(value);
        setValueDebounce(value);
    }, [setValueDebounce]);

    if (editing) {
        return (
            <>
                <PhoneInput
                    focusInputOnCountrySelection
                    international
                    defaultCountry={"US"}
                    value={editValue}
                    limitMaxLength={true}
                    onChange={handleValueOnChange}
                    onBlur={stopEditing}
                />
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </>
        );
    }

    return (
        <>
            <div className="ni-editable-cell" onClick={startEditing}>
                {!isEmpty(value) ? (
                    <div className="cell-value">{value}</div>
                ) : (
                    <div className="cell-empty-value">Enter {column.Header}...</div>
                )}
                {error && (
                    <div className="invalid-feedback">{error}</div>
                )}
            </div>
        </>
    );
}

export default EditablePhoneCell;