import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {loadPatientMolecularDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData, getModalData} from "../../../utils/selectors";
import moment from "moment";
import Loader from "react-spinners/BeatLoader";
import {openDocument} from "../../../utils/document-utils";
import BiomarkersOverview from "../../Biomarkers/BiomarkersOverview";
import _ from "lodash";
import {isNegativeResult, isPositiveResult} from "../../../utils/biomarker-utils";

import fullReportSvg from "../../../images/full_report.svg";
import isEmpty from "../../../utils/helpers";

const TestView = ({test, onViewFullReport}) => {
	const [showUnknown, setShowUnknown] = useState(false);

	const items = useMemo(() => {
		const items = _.sortBy(test.items, i => i.biomarker);
		const positiveItems = [];
		const negativeItems = [];
		const unknownItems = [];

		for (const item of items) {
			if (isPositiveResult(item.result)) {
				positiveItems.push(item);
			} else if (isNegativeResult(item.result)) {
				negativeItems.push(item);
			} else {
				unknownItems.push(item);
			}
		}

		return [...positiveItems, ...unknownItems, ...negativeItems];
	}, [test.items]);

	const itemsInKb = useMemo(() => {
		return items.filter(i => i.isKbBiomarker);
	}, [items]);

	const itemsNotInKb = useMemo(() => {
		return items.filter(i => !i.isKbBiomarker);
	}, [items]);

	return (<>
		<div className="biomarkers-headline">
			<div className="headline-title">
				{(test.site || "Site N/A")}
				{", " + (test.provider || "Institution N/A")}<br/>
				Collection: {!isEmpty(test.specimentDate)? moment.parseZone(test.specimentDate).format("DD-MMM-YYYY"): "N/A"},
				Report: {moment.parseZone(test.date).format("DD-MMM-YYYY")}
			</div>
			{test.documentReference && test.documentReference.fileId &&
				<div className="headline-document">
					<button
						className="btn btn-link"
						onClick={() => {
							onViewFullReport(test.documentReference.fileId, test.documentReference.pageNumber)
						}}
					>
						<img alt="Full Report" style={{width: 30, height: 30}} src={fullReportSvg}/>
					</button>
				</div>
			}
		</div>

		<table className="biomarkers table table-hover table-subtle table-responsive-sm table-responsive-md">
			<thead>
			<tr>
				<th scope="col" className="border-top-0">Biomarker</th>
				<th scope="col" className="border-top-0">Result</th>
				<th scope="col" className="border-top-0">Type</th>
			</tr>
			</thead>
			<tbody>
			{itemsInKb.map(item => (
				<tr>
					<td>{item.biomarker}</td>
					<td>{item.result || '-'}</td>
					<td>{item.type || '-'}</td>
				</tr>
			))}
			</tbody>
			{!_.isEmpty(itemsNotInKb) && (
				<>
					<tbody>
						<tr className="section-header" onClick={() => setShowUnknown(prevState => !prevState)}>
							<td colSpan={3}>
								<span className="font-weight-bold">Unknown Significance</span> <i className={`ni ni-arrow-circle-${showUnknown ? 'up' : 'down'} ni-ml ms-1 align-text-top`} />
							</td>
						</tr>
					</tbody>
					{showUnknown && (
						<tbody>
						{itemsNotInKb.map(item => (
							<tr>
								<td>{item.biomarker}</td>
								<td>{item.result || '-'}</td>
								<td>{item.type || 'Unknown Significance'}</td>
							</tr>
						))}
						</tbody>
					)}
				</>
			)}
		</table>
	</>)
}

const BiomarkersModal = ({patientId, loadPatientMolecularDetailed, loading, patientMolecularDetailed, hideModal}) => {

	useEffect(() => {
		loadPatientMolecularDetailed(patientId);
	}, []);

	const handleViewFullReport = useCallback((documentId, pageNumber) => {
		openDocument(patientId, documentId, pageNumber);
	}, []);

	const hasUntestedBiomarkers = useMemo(() => {
		return patientMolecularDetailed && patientMolecularDetailed.diagnosisBiomarkers && patientMolecularDetailed.diagnosisBiomarkers.find(bm => !bm.tested && bm.type);
	}, [patientMolecularDetailed]);

	const hasTests = useMemo(() => {
		return patientMolecularDetailed && !_.isEmpty(patientMolecularDetailed.tests);
	}, [patientMolecularDetailed]);
	
	const closeModal = useCallback(() => {
		hideModal();
	}, []);

	return (
		<>
			<div className="modal-header p-4">
				<h4 className="modal-title">Biomarkers</h4>
			</div>

			<div className="modal-body p-4">
				{loading
					? (
						<Loader size={10} color={"#ccc"}/>
					) : (!hasTests && !hasUntestedBiomarkers)
						? (
							<span>No available data</span>
						) : (
							<>
								<BiomarkersOverview biomarkers={patientMolecularDetailed.diagnosisBiomarkers} />
								{hasTests &&
									patientMolecularDetailed.tests.map(test => <TestView test={test} onViewFullReport={handleViewFullReport}/>)
								}
							</>
						)
				}
			</div>
		</>
	);
}

BiomarkersModal.propTypes = {
	patientMolecularDetailed: PropTypes.array
}

const patientMolecularDetailedLoader = createLoadingSelector(['LOAD_PATIENT_MOLECULAR_DETAILED']);

const mapStateToProps = (state) => {

	const
		patientId = getModalData(state).modalProps,
		patientMolecularDetailed = getPatientsData(state).patientMolecularDetailed,
		loading = patientMolecularDetailedLoader(state);

	return {
		patientId,
		patientMolecularDetailed,
		loading
	}
};

const mapDispatchToProps = {
	loadPatientMolecularDetailed,
};

export default connect(mapStateToProps, mapDispatchToProps)(BiomarkersModal);
