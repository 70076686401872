import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loadPatientPathologyDetailed} from "../../../actions/patients.actions";
import {createLoadingSelector, getPatientsData, getModalData} from "../../../utils/selectors";
import isEmpty from "../../../utils/helpers";
import moment from "moment";
import Loader from "react-spinners/BeatLoader";

class CBCReportModal extends Component {

    componentDidMount() {
        this.props.loadPatientPathologyDetailed(this.props.patientId);
    }

    closeModal = () => {
        this.props.hideModal();
    };

    render() {
        const { patientPathology, loading } = this.props;

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Pathology</h5>
                </div>

                <div className="modal-body">

                    {!loading && !isEmpty(patientPathology)
                        ?
                        <table className="table table-hover table-subtle table-responsive-sm table-responsive-md">
                            <thead>
                            <tr>
                                <th scope="col">Result Date</th>
                                <th scope="col">Specimen Date</th>
                                <th scope="col">Site</th>
                                <th scope="col">Method</th>
                                <th scope="col">Finding</th>
                            </tr>
                            </thead>
                            <tbody>
                            {patientPathology.map(item => ( <>
                                <tr>
                                    <td>{item.time && moment.parseZone(item.time).format('DD-MMM-YYYY')}</td>
                                    <td>{item.specimenDate && moment.parseZone(item.specimenDate).format('DD-MMM-YYYY')}</td>
                                    <td className="font-weight-bold">{item.pathologySite}</td>
                                    <td>{item.pathologyType}</td>
                                    <td>{item.diagnosis}</td>
                                </tr>
                            </>))}
                            </tbody>
                        </table>
                        :
                        <Loader size={10} color={"#ccc"}/>
                    }

                </div>
            </>
        );
    }
}

CBCReportModal.propTypes = {
    patientPathology: PropTypes.array
}

const patientPathologyDetailedLoader = createLoadingSelector(['LOAD_PATIENT_PATHOLOGY_DETAILED']);

const mapStateToProps = (state) => {

    const
        patientId = getModalData(state).modalProps,
        patientPathology = getPatientsData(state).patientPathologyDetailed,
        loading = patientPathologyDetailedLoader(state);

    return {
        patientId,
        patientPathology,
        loading
    }
}

const mapDispatchToProps = {
    loadPatientPathologyDetailed
}

export default connect(mapStateToProps, mapDispatchToProps)(CBCReportModal);
